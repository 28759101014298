export enum AmbientOptions {
    Wall = 'wall',
    Floor = 'floor',
    Shower = 'shower'
}

export type AmbientTabItem = {
    label: string;
    type: AmbientOptions;
};

export type AmbientMenuItem = {
    type: AmbientOptions;
    thumbnail: string;
    label: string;
    fileName: string;
};

import { Vector3 } from '@babylonjs/core';

export enum RoomDecoratorType {
    Showertiles = 'showertiles',
    ShowerValve = 'showervalve'
}

export type RoomDecorator = {
    type: RoomDecoratorType;
    showerSideOverlap?: number;
    showerHeightOverlap?: number;
    hasShowervalve?: boolean;
};

export enum RoomLayoutType {
    Wall = 'wall',
    Transition = 'transition'
}

export type RoomWall = {
    type: RoomLayoutType.Wall;
    baseLength: number;
    addShowerSide?: boolean;
    decorators?: RoomDecorator[];
};

export type RoomTransition = {
    type: RoomLayoutType.Transition;
    angle: number;
};

export type RoomElement = RoomWall | RoomTransition;

export type Roomplan = {
    id: string;
    layout: RoomElement[];
};

export type RoomInfo = {
    size: Vector3;
    position: Vector3;
};

import React, { useEffect } from 'react';
import { AbstractMesh, PBRMaterial, ReflectionProbe, RenderTargetTexture, Vector3 } from '@babylonjs/core';
import { useBabylonScene } from 'react-babylonjs';
import { TAGLIST } from '../../helper/tagHelper';
interface ReflectionProbeComponentProps {
    position: Vector3;
}

const ReflectionProbeComponent = ({ position = Vector3.Zero() }: ReflectionProbeComponentProps): JSX.Element => {
    const scene = useBabylonScene();
    const [probe, setProbe] = React.useState<ReflectionProbe | undefined>(undefined);

    useEffect(() => {
        if (scene?.isReady) {
            const sceneProbe = new ReflectionProbe('Environment Map', 256, scene);
            sceneProbe.position = position;
            // Reflection probe will be updated in 3D Helper, after changing probe renderlists
            sceneProbe.refreshRate = RenderTargetTexture.REFRESHRATE_RENDER_ONCE;
            scene.getMeshesByTags(TAGLIST.BE_REFELECTED.toString(), (mesh: AbstractMesh) => {
                sceneProbe.cubeTexture.renderList?.push(mesh);
            });
            const reflectMeshes = scene.getMeshesByTags(TAGLIST.REFLECTION.toString(), (mesh: AbstractMesh) => {
                mesh.material?.unfreeze();
                const pbrMat = mesh.material as PBRMaterial;
                if (pbrMat) {
                    pbrMat.unfreeze();
                    pbrMat.reflectionTexture = sceneProbe.cubeTexture;
                }
            });
            sceneProbe.cubeTexture.render();

            reflectMeshes.forEach((mesh) => {
                mesh.material?.freeze;
            });
            setProbe(sceneProbe);
        }
        return (): void => {
            probe?.dispose();
        };
    }, [scene, scene?.isReady]);
    return <></>;
};

export default ReflectionProbeComponent;

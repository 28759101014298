/*eslint-disable no-control-regex */
import { Vector3 } from '@babylonjs/core';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import Collapsible from 'react-collapsible';
import Dropzone from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { from, fromEvent, Observable, of, Subject } from 'rxjs';
import { exhaustMap, switchMap, takeUntil } from 'rxjs/operators';
import { StoreState } from '../../state/ducks';
import {
    ConfigOffer,
    Configuration,
    MergedCatalogueItem,
    Offer,
    OfferItem
} from '../../state/ducks/configurator/interfaces';
import { keys } from '../../state/ducks/configurator/utils';
import ButtonComponent, { ButtonType } from '../button/ButtonComponent';
import FirebaseContext from '../firebase/context';
import ModalContent from '../modal/modal-content/ModalContent.component';
import ModalFooter from '../modal/modal-footer/ModalFooter.component';
import ModalHeader from '../modal/modal-header/ModalHeader.component';
import { DropzonePreview } from './components/DropzonePreview.component';
import './SendOfferForm.component.scss';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../ng/helper/analyticsHelper';
import { getDiscountPrice, hasSpecialOffer, useDiscount } from '../../ng/helper/discountHelper';
export type ContactFormData = {
    name: string;
    email: string;
    zip: number;
    city: string;
    phone: string;
    message: string;
    street: string;
    number: string;
    consent: boolean;
};

interface SendOfferProps {
    onClose: (showSuccessScreen?: boolean) => void;
}

const getConfigAndTotalForOffer = (
    config: Configuration,
    totalPrice: number,
    showerBaseSize: Vector3,
    discountPrice: number | null
): Offer => {
    const configOffer: ConfigOffer = {} as ConfigOffer;

    keys(config).forEach((catalogueProperty) => {
        const { id, description } = config[catalogueProperty] as MergedCatalogueItem;
        configOffer[catalogueProperty] = { id, description } as OfferItem;
    });
    const modelId = `${config.series.id}${config.model.sprinzId}.${config.measurement.sprinzId}`;
    configOffer.model.id = modelId;

    configOffer.measurement.description = `H: ${showerBaseSize.y} mm, T: ${showerBaseSize.z} mm, B: ${showerBaseSize.x} mm`;
    return {
        configOffer,
        totalPrice,
        discountPrice
    };
};

const SendOffer = ({ onClose }: SendOfferProps): JSX.Element => {
    const config = useSelector((state: StoreState) => state.configurator.configuration);
    const totalPrice = useSelector((state: StoreState) => state.configurator.price.total);
    const socialMedia = useSelector((state: StoreState) => state.system.socialMedia);
    const showerBaseSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const discount = useDiscount();
    const discountPrice =
        discount && hasSpecialOffer(discount.startDate, discount.endDate)
            ? getDiscountPrice(totalPrice, discount.percentage)
            : null;

    const firebaseContext = useContext(FirebaseContext);
    const button = useRef(null);
    const { register, getValues, triggerValidation, errors, formState } = useForm<ContactFormData>({
        mode: 'onChange'
    });
    const [consent, setConsent] = useState(false);
    const onConsentChange = (): void => {
        setConsent(!consent);
    };

    const onBack = useCallback(() => {
        onClose(false);
        logAnalyticsEvent(`configurator_abort_offer`);
    }, [onClose]);

    const onSubmitClick = () => {
        const configInformations = {
            series: `ID: ${config.series.id}, Description: ${config.series.description}`,
            model: `ID: ${config.model.id}, Description: ${config.model.description}`,
            knob: `ID: ${config.knob?.id}, Description: ${config.knob?.description}`,
            beam: `ID: ${config.beam.id}, Description: ${config.beam.description}`,
            hinge: `ID: ${config.hinge?.id}, Description: ${config.hinge?.description}`,
            wallmount: `ID: ${config.wallmount.id}, Description: ${config.wallmount.description}`,
            glassmount: `ID: ${config.glassmount.id}, Description: ${config.glassmount.description}`,
            base: `ID: ${config.base.id}, Description: ${config.beam.description}`
        };
        logAnalyticsEvent(`configurator_submit_offer`, { configurator_config: JSON.stringify(configInformations) });
    };

    useEffect(() => {
        firebaseContext.reset();
    }, [firebaseContext]);

    useEffect(() => {
        const destroy$: Subject<boolean> = new Subject<boolean>();
        const buttonRef = button.current;
        if (buttonRef) {
            const mouseup$ = fromEvent<MouseEvent>(buttonRef, 'mouseup');

            mouseup$
                .pipe(
                    takeUntil(destroy$),
                    switchMap(() => from(triggerValidation())),
                    exhaustMap(
                        (valid: boolean): Observable<unknown> => {
                            if (valid) {
                                return firebaseContext.saveContactToDB(
                                    getValues(),
                                    getConfigAndTotalForOffer(config, totalPrice, showerBaseSize, discountPrice),
                                    socialMedia.url,
                                    firebaseContext.attachedFiles
                                );
                            }
                            return of(null);
                        }
                    )
                )
                .subscribe((data) => {
                    if (data !== null) {
                        onClose(true);
                    }
                });

            return (): void => {
                destroy$.next(true);
                destroy$.unsubscribe();
            };
        }
    }, [onClose, config, firebaseContext, getValues, totalPrice, triggerValidation, socialMedia]);

    return (
        <div className="send-offer-form">
            <ModalHeader>Ihre unverbindliche Anfrage an SPRINZ</ModalHeader>
            <ModalContent>
                <form className="send-offer-form-content">
                    <div className="row">
                        <div className="column">
                            <div className="inputLabel">Vor- und Nachname</div>
                            <div>
                                <input type="text" name="name" ref={register()} />
                                {errors.name && <p>{errors.name.message}</p>}
                            </div>
                        </div>
                        <div className="spacer"></div>
                        <div className="column">
                            <div className="inputLabel">E-Mail*</div>
                            <div>
                                <input
                                    style={{ border: errors.email ? '1px solid #c74141' : '' }}
                                    type="text"
                                    name="email"
                                    ref={register({
                                        required: 'Bitte korrekte E-Mail-Adresse angeben.',
                                        pattern: {
                                            value: /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
                                            message: 'Bitte korrekte E-Mail-Adresse angeben.'
                                        }
                                    })}
                                />
                                {errors.email && <p>{errors.email.message}</p>}
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="column">
                            <div className="inputLabel">Straße und Hausnummer</div>
                            <div>
                                <input type="text" name="street" ref={register()} />
                                {errors.street && <p>{errors.street.message}</p>}
                            </div>
                        </div>
                        <div className="spacer"></div>
                        <div className="column">
                            <div className="inputLabel">Telefon</div>
                            <div>
                                <input type="text" name="phone" ref={register()} />
                                {errors.phone && <p>{errors.phone.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="column">
                            <div className="inputLabel">PLZ*</div>
                            <div>
                                <input
                                    style={{ border: errors.zip ? '1px solid #c74141' : '' }}
                                    type="number"
                                    name="zip"
                                    ref={register({
                                        required: 'Bitte korrekte Postleitzahl angeben.',
                                        pattern: {
                                            value: /^[0-9]{4,5}?$/,
                                            message: 'Bitte korrekte Postleitzahl angeben.'
                                        }
                                    })}
                                />
                                {errors.zip && <p>{errors.zip.message}</p>}
                            </div>
                        </div>
                        <div className="spacer"></div>
                        <div className="column">
                            <div className="inputLabel">Ort</div>
                            <div>
                                <input type="text" name="city" ref={register()} />
                                {errors.city && <p>{errors.city.message}</p>}
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <Collapsible trigger="Mitteilung an SPRINZ">
                            <div className="column">
                                <textarea
                                    name="message"
                                    ref={register()}
                                    style={{
                                        resize: 'vertical',
                                        minHeight: '25px',
                                        justifyContent: 'center'
                                    }}
                                />
                                {errors.message && <p>{errors.message.message}</p>}
                            </div>
                        </Collapsible>
                        <Collapsible trigger="Datei anhängen">
                            <div className="column">
                                <Dropzone
                                    maxFiles={5}
                                    inputWithFilesContent="+"
                                    PreviewComponent={DropzonePreview}
                                    inputContent="Dateien zum Anhängen ablegen oder durchsuchen."
                                    accept="image/*,application/pdf"
                                />
                            </div>
                        </Collapsible>
                    </div>
                    <div className="row">
                        <div className="column remarks">
                            <div className="necessary">* Pflichtfelder</div>
                            Unser exklusiver Großhändler wird sich innerhalb weniger Tage mit Ihnen in Verbindung setzen
                            und Ihnen das Angebot zukommen lassen. Unser eigener Aufmaß- und Montageservice steht Ihnen
                            zudem mit über 100 Monteuren flächendeckend in Deutschland, Österreich und der Schweiz zur
                            Seite.
                        </div>
                    </div>
                    <div className="checkbox-container">
                        <div>
                            <label className={`checkbox-label ${errors.consent ? 'error' : ''}`}>
                                <input
                                    name="consent"
                                    type="checkbox"
                                    checked={consent}
                                    onChange={onConsentChange}
                                    ref={register({ required: true })}
                                />
                                <span className="checkbox-custom rectangular"></span>
                                Ich stimme zu, dass meine Angaben aus dem Kontaktformular zur Beantwortung meiner
                                Anfrage erhoben und verarbeitet werden.
                            </label>
                            <div className="checkbox-hint">
                                <div>
                                    <b>Hinweis:</b> Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an
                                    info@sprinz.eu widerrufen.
                                </div>
                                <div>
                                    Detaillierte Information zum Umgang mit Nutzerdaten finden Sie in unserer&nbsp;
                                    <a
                                        href="https://www.sprinz.eu/index.php?Datenschutzerklaerung#art-SPRINZ-Datenschutzerklaerung"
                                        target="_new"
                                        rel="noopener noreferrer"
                                    >
                                        Datenschutzerklärung.
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </ModalContent>
            <ModalFooter>
                <ButtonComponent type={ButtonType.light} onClick={onBack} width={272}>
                    Abbrechen
                </ButtonComponent>
                <ButtonComponent
                    ref={button}
                    onClick={onSubmitClick}
                    type={ButtonType.dominant}
                    disabled={!formState.isValid}
                    width={272}
                >
                    Angebot anfragen
                </ButtonComponent>
            </ModalFooter>
        </div>
    );
};

export default SendOffer;

import React, { useMemo, useCallback } from 'react';
import { ReactComponent as FacebookLogo } from '../../assets/icons/share/facebook-logo.svg';
import 'firebase/analytics';
interface FacebookButtonProps {
    url: string;
    title: string;
    onAfterShare?: () => void;
}

const FacebookButton = ({ url, onAfterShare }: FacebookButtonProps): JSX.Element => {
    const link = useMemo(
        () => `
        http://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`,
        [url]
    );

    const onClick = useCallback(() => {
        onAfterShare?.();
    }, [onAfterShare]);

    return (
        <a href={link} onClick={onClick} target="_blank" rel="noopener noreferrer">
            <FacebookLogo />
            <p className="share-text">Facebook</p>
        </a>
    );
};

export default FacebookButton;

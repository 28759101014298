import { FresnelParameters } from '@babylonjs/core/Materials/fresnelParameters';
import { Color3 } from '@babylonjs/core/Maths/math.color';
import React from 'react';

const GlassMaterialComponent = (): JSX.Element => {
    return (
        <standardMaterial
            name="glassMaterial"
            alpha={0.35}
            specularPower={16}
            diffuseColor={new Color3(0.75, 0.79, 0.81)}
            emissiveColor={new Color3(0.57, 0.57, 0.57)}
            reflectionFresnelParameters={FresnelParameters.Parse({
                isEnabled: true,
                leftColor: [1, 1, 1],
                rightColor: [0, 0, 0],
                bias: 0.1,
                power: 1
            })}
            emissiveFresnelParameters={FresnelParameters.Parse({
                isEnabled: true,
                leftColor: [1, 1, 1],
                rightColor: [0, 0, 0],
                bias: 0.5,
                power: 3
            })}
        />
    );
};

export default GlassMaterialComponent;

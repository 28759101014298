import React, { useCallback, useState } from 'react';
import { ReactComponent as Reset } from '../../../../../assets/icons/common/reset.svg';

type ShowerBaseSizeInputProps = {
    value: number;
    maxValue: number;
    minValue: number;
    label: string;
    onChange: (e: number) => void;
    onReset: () => void;
};

const ShowerBaseSizeInputComponent = ({
    value,
    minValue,
    maxValue,
    label,
    onChange,
    onReset
}: ShowerBaseSizeInputProps): JSX.Element => {
    const [inputValue, setInputValue] = useState<number | string>(Math.round(value));
    const [errMsg, setErrMsg] = useState<string | null>();

    const onChangeTriggered = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /\d+/i;

        if (regex.test(e.currentTarget.value)) {
            const valueAsNumber = parseInt(e.currentTarget.value);
            setInputValue(valueAsNumber);

            if (valueAsNumber > maxValue) {
                setErrMsg(`Maximalwert liegt bei ${Math.floor(maxValue)} mm`);
            } else if (valueAsNumber < minValue) {
                setErrMsg(`Minimalwert liegt bei ${Math.ceil(minValue)} mm`);
            } else {
                setErrMsg(null);
                onChange(valueAsNumber);
            }
        } else {
            setErrMsg(null);
            setInputValue('');
        }
    };

    const resetForm = useCallback(() => {
        onReset();
        setInputValue(value);
        setErrMsg(null);
    }, [onReset, value]);

    return (
        <div className={`shower-base-size-input ${errMsg && 'error'}`}>
            <label className="label" htmlFor={label}>
                {label}
            </label>
            <div className="shower-base-size-input-wrapper">
                <div className="shower-base-size-input-value-border">
                    <input
                        className={'shower-base-size-input-value-input'}
                        id={label}
                        type="text"
                        pattern="[0-9]+"
                        inputMode="decimal"
                        value={inputValue}
                        onChange={(e) => onChangeTriggered(e)}
                    />
                </div>
                {errMsg && <div className="shower-base-size-input-error">{errMsg}</div>}

                <label className="shower-base-size-input-unit" htmlFor={label}>
                    <span>mm</span>
                </label>
                <div className="shower-base-size-input-reset-input" onClick={() => resetForm()}>
                    <Reset />
                </div>
            </div>
        </div>
    );
};

export default ShowerBaseSizeInputComponent;

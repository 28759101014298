import { ArcRotateCamera, Vector2, Vector3 } from '@babylonjs/core';
import React, { useRef } from 'react';
import { CreatedInstance } from 'react-babylonjs';
import { TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';

interface ScreenShotCameraComponentProps {
    offset?: Vector2;
    target?: Vector3;
}
const ScreenShotCameraComponent = ({
    offset = Vector2.Zero(),
    target = Vector3.Zero()
}: ScreenShotCameraComponentProps): JSX.Element => {
    const cameraRef = useRef<CreatedInstance<ArcRotateCamera>>();

    return (
        <arcRotateCamera
            name={'screenshotCamera'}
            ref={cameraRef}
            target={target.add(new Vector3(-offset.x, 100, -offset.y))}
            targetScreenOffset={offset}
            alpha={Math.PI * 1.75}
            beta={Math.PI * 0.4}
            radius={500}
            checkCollisions
            collisionRadius={Vector3.One()}
            layerMask={TOTAL_LAYERMASK}
        />
    );
};

export default ScreenShotCameraComponent;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../state/ducks';
import { applyRotationY, applyTranslation, getRotationYFromDirection } from './3d_helpers';
import BathtubComponent from './components/3d/Accessories/BathtubComponent';
import ShowerBaseComponent from './components/3d/Accessories/ShowerBaseComponent';
import { createDoor, createPartition, createTransition } from './helper/blueprintFactory';
import {
    calcElementBottomOffsetMM,
    calcTransitionBottomOffsetMM,
    calculateZLugPosition,
    getHingePositionType
} from './helper/blueprintHelper';
import { isShowerBaseId, showerBaseHeightMM } from './helper/showerBaseHelper';
import { Blueprint, BlueprintElement, BlueprintLayoutType } from './types/Blueprint';

type BlueprintLoaderComponentProps = {
    blueprint: Blueprint;
};

const BlueprintLoaderComponent = ({ blueprint }: BlueprintLoaderComponentProps): JSX.Element => {
    const showerSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const seriesId = useSelector((state: StoreState) => state.configurator.configuration.series.id);
    const baseID = useSelector((state: StoreState) => state.configurator.configuration.base.id);
    const hasShowerBase = isShowerBaseId(baseID);

    if (blueprint !== null && showerSize) {
        // TODO Check if blueprint contains all we need in the code below
        const initialPositionCM = new Vector3(
            blueprint.origin.x / 10,
            blueprint.origin.y / 10,
            blueprint.origin.z / 10
        );
        const initialDirection = Vector3.Right();

        let currentPositionCM = initialPositionCM;
        let currentDirection = initialDirection;
        let currentRotation = getRotationYFromDirection(currentDirection);

        let xWorldOffset = 0;
        let zWorldOffset = 0;

        const elements = blueprint.layout
            .map((element: BlueprintElement, index: number, layout: BlueprintElement[]) => {
                let component = undefined;
                const hingePosition = getHingePositionType(layout, index);

                const elementBottomOffsetMM = calcElementBottomOffsetMM(
                    layout,
                    index,
                    showerSize.z,
                    seriesId,
                    hasShowerBase,
                    blueprint.bathtubConfig
                );
                switch (element.type) {
                    case BlueprintLayoutType.Transition:
                        const transitionBottomOffsetMM = calcTransitionBottomOffsetMM(
                            layout,
                            index,
                            showerSize.z,
                            hasShowerBase,
                            blueprint.bathtubConfig
                        );
                        component = createTransition(
                            element,
                            index,
                            layout,
                            currentPositionCM,
                            currentDirection,
                            transitionBottomOffsetMM
                        );
                        currentDirection = applyRotationY(currentDirection, element.angle);
                        currentRotation = getRotationYFromDirection(currentDirection);
                        break;
                    case BlueprintLayoutType.Void:
                        currentPositionCM = applyTranslation(currentPositionCM, currentDirection, element.length / 10);
                        break;
                    case BlueprintLayoutType.Door:
                        component = createDoor(
                            element,
                            currentPositionCM,
                            currentRotation,
                            index,
                            hingePosition,
                            elementBottomOffsetMM
                        );
                        currentPositionCM = applyTranslation(currentPositionCM, currentDirection, element.length / 10);
                        break;
                    case BlueprintLayoutType.Partition:
                        const zLugPosition = calculateZLugPosition(
                            layout,
                            index,
                            seriesId,
                            showerSize.z,
                            blueprint.bathtubConfig
                        );
                        const heightAdjustment = blueprint.bathtubConfig?.isUnderShower
                            ? 0
                            : -elementBottomOffsetMM + (hasShowerBase ? showerBaseHeightMM : 0);
                        component = createPartition(
                            element,
                            currentPositionCM,
                            currentRotation,
                            index,
                            hingePosition,
                            elementBottomOffsetMM,
                            zLugPosition,
                            heightAdjustment
                        );
                        currentPositionCM = applyTranslation(currentPositionCM, currentDirection, element.length / 10);
                        break;
                    default:
                        break;
                }

                // Calculate the offset we have on the xz-plane from the positiveX-negativeZ-quarter relative to the origin
                if (currentPositionCM.x < initialPositionCM.x) {
                    xWorldOffset = Math.min(currentPositionCM.x - initialPositionCM.x, xWorldOffset);
                }
                if (currentPositionCM.z > initialPositionCM.z) {
                    zWorldOffset = Math.max(currentPositionCM.z + initialPositionCM.z, zWorldOffset);
                }

                return component;
            })
            .filter((e) => e); // remove "undefined" from list (caused by the transitions and voids)

        return (
            <>
                <transformNode
                    name={`tf_shower_world_offset`}
                    key={`tf_shower_world_offset_${blueprint.id}`}
                    position={new Vector3(-xWorldOffset, 0, -zWorldOffset)}
                >
                    {elements}
                </transformNode>
                {hasShowerBase && !blueprint.bathtubConfig?.isUnderShower && (
                    <ShowerBaseComponent
                        key={'showerBase'}
                        showerOrigin={new Vector3(blueprint.origin.x, blueprint.origin.y, blueprint.origin.z)}
                    />
                )}
                {blueprint.bathtubConfig && (
                    <BathtubComponent
                        bathtubConfig={blueprint.bathtubConfig}
                        showerOrigin={new Vector3(blueprint.origin.x, blueprint.origin.y, blueprint.origin.z)}
                        key={'bathtub'}
                    />
                )}
            </>
        );
    } else {
        return <></>;
    }
};

export default BlueprintLoaderComponent;

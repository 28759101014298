import React from 'react';
import { useDispatch } from 'react-redux';
import { configuratorActions } from '../../../state/ducks/configurator';
import ButtonComponent, { ButtonType } from '../../button/ButtonComponent';
import SocialShare from '../../social-share/SocialShare.component';
import ModalContent from '../modal-content/ModalContent.component';
import ModalFooter from '../modal-footer/ModalFooter.component';
import ModalHeader from '../modal-header/ModalHeader.component';
import './ModalSuccessScreen.component.scss';
import close from '../../../assets/icons/common/close.svg';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../../ng/helper/analyticsHelper';

interface ModalSuccessScreenProps {
    onClose: () => void;
}

const ModalSuccessScreen = ({ onClose }: ModalSuccessScreenProps): JSX.Element => {
    const goToWebsite = (): void => {
        window.location.href = 'https://sprinz.eu/';
    };

    const dispatch = useDispatch();
    const reset = () => {
        dispatch(configuratorActions.resetToDefaultConfig());
    };

    const restartConfigurator = (): void => {
        logAnalyticsEvent('configurator_reset_from_modal_success_screen');
        onClose();
        reset();
    };

    return (
        <div className="modal-success-screen">
            <ModalHeader success>
                <a
                    className="cancel-button"
                    onClick={() => {
                        onClose();
                    }}
                >
                    <img src={close} />
                </a>
            </ModalHeader>

            <ModalContent>
                <div className="modal-success-screen-thanks">Vielen Dank für Ihre Anfrage!</div>
                <div className="modal-success-screen-description">
                    Ein SPRINZ-Mitarbeiter wird Sie schnellstmöglich kontaktieren.
                    <br /> <br />
                    Möchten Sie Ihre Duschkonfiguration teilen?
                </div>
                <div className="modal-success-screen-social">
                    <SocialShare />
                </div>
                <div className="modal-success-screen-description">
                    <p>
                        Haben Sie Anmerkungen oder Wünsche? Wir freuen uns über&nbsp;
                        <a
                            href="https://forms.gle/EeW5QCeA82pzcDxP7"
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Feedback geben"
                        >
                            Ihr Feedback
                        </a>
                        .
                    </p>
                </div>
            </ModalContent>
            <ModalFooter>
                <ButtonComponent onClick={goToWebsite} type={ButtonType.light} width={300}>
                    Zurück zur Website
                </ButtonComponent>
                <ButtonComponent onClick={restartConfigurator} type={ButtonType.dominant} width={300}>
                    weitere Dusche konfigurieren
                </ButtonComponent>
            </ModalFooter>
        </div>
    );
};

export default ModalSuccessScreen;

import { Tools, Vector3 } from '@babylonjs/core';
import { applyRotationY, getRotationYFromDirection } from '../3d_helpers';
import {
    BlueprintDecoratorType,
    BlueprintLayoutType,
    BlueprintTransition,
    BlueprintTransitionConnectorType
} from '../types/Blueprint';
import { PositionType } from '../types/Common';
import { HingeTechConfig } from '../types/HingeTechConfig';
import { MountTechConfig } from '../types/MountTechConfig';
import { RotationCorner } from './commonHelper';
import { showerBaseHeightMM } from './showerBaseHelper';

/**
 * DataClass-Type for a MountSituation containing the position the scaleX and the scaleY.
 * detailViewScaleY is used when the mount is in detailView
 */
export type MountSituation = {
    position: Vector3;
    scaleX: number;
    scaleY: number;
    detailViewScaleY: number;
};

/**
 * DataClass-Type for a NormalHingeSituation (a swinging hinge) containing the position,
 * scaleX, scaleY and the rotation for the two parts.
 */
export type NormalHingeSituation = {
    position: Vector3;
    scaleX: number;
    scaleY: number;
    rotationPartOne: Vector3;
    rotationPartTwo: Vector3;
};

/**
 * DataClass-Type for a SlidingHingeSituation containing the position,
 * scaleX and the sides on which a covering edge should be added.
 */
export type SlidingHingeSituation = {
    position: Vector3;
    scaleX: number;
    startNeedsCover: boolean;
    startTouchesWall: boolean;
    endNeedsCover: boolean;
    endTouchesWall: boolean;
    previousHingeHasCorner: boolean;
    endHasCorner: boolean;
};

/**
 * This function calculates the position for the given mount. The position is always calculated from
 * the bottom of the shower and assumes that the mount is already in the right position on the
 * xz-plane. So the position will always only contain the height / y-value.
 * The calculation also assumes that the 3d models always have they center in the middle of its height.
 *
 * The calculation also considers the scaling of the mount in y direction if the mount uses the whole
 * side of the shower.
 *
 * Can return undefined if the mount does not use the whole side of the shower and the given position
 * is neither PositionType.Top nor PositionType.Bottom.
 * @param mountTechConfig The configuration for the current selected mount
 * @param showerHeightMM The height of the shower in mm
 * @param scaleY The scaling of the mount in y direction
 * @param position The position of the decorator
 */
const getMountPositionAsVector3 = (
    mountTechConfig: MountTechConfig,
    showerHeightMM: number,
    scaleY: number,
    bottomOffsetMM: number,
    isUnderBathtub: boolean,
    hasShowerBase: boolean,
    position?: PositionType
): Vector3 | undefined => {
    if (mountTechConfig.usesWholeSide) {
        const offsetTopThroughScaleYMM = (mountTechConfig.heightMM * (1 - scaleY)) / 2;
        const mountPositionYMM =
            mountTechConfig.positioning.offSetBottom -
            offsetTopThroughScaleYMM +
            mountTechConfig.heightMM / 2 +
            bottomOffsetMM;
        return new Vector3(0, mountPositionYMM / 10, 0);
    } else if (position === PositionType.Top) {
        let adjustedBottomOffset = bottomOffsetMM;
        if (!isUnderBathtub) {
            adjustedBottomOffset = 0;
        }
        if (hasShowerBase) {
            adjustedBottomOffset += showerBaseHeightMM;
        }
        const mountPositionYMM =
            showerHeightMM -
            (mountTechConfig.positioning.offSetTop + mountTechConfig.heightMM / 2) +
            adjustedBottomOffset;
        return new Vector3(0, mountPositionYMM / 10, 0);
    } else if (position === PositionType.Bottom) {
        const mountPositionYMM =
            mountTechConfig.positioning.offSetBottom + mountTechConfig.heightMM / 2 + bottomOffsetMM;
        return new Vector3(0, mountPositionYMM / 10, 0);
    } else {
        console.error(`Mount has been given invalid position argument: ${position}`);
        return undefined;
    }
};

/**
 * This function calculates the y scale for the given mount.
 * The y scale contains direction and scale.
 * Direction can be either -1 or 1 to indicate whether the mount is mirrored on the xz-plane.
 * Scale can be any value to indicate the change of size in direction of the y axis. This is only
 * applied when the mount uses the whole side of the shower so that it fits the size of the shower
 * considering the offsets for top and bottom
 * @param showerHeightMM The height of the shower in mm
 * @param mountTechConfig The configuration for the current selected mount
 * @param position The position of the decorator or undefined if the mount uses the whole side of the shower
 */
const calculateMountScaleY = (
    showerHeightMM: number,
    mountTechConfig: MountTechConfig,
    bottomOffsetMM: number,
    isUnderBathtub: boolean,
    hasShowerBase: boolean,
    position?: PositionType
): number[] => {
    const direction = position === PositionType.Bottom ? -1 : 1;
    let scaling = 1;
    if (mountTechConfig.usesWholeSide) {
        const desiredHeightMM =
            showerHeightMM -
            mountTechConfig.positioning.offSetTop -
            mountTechConfig.positioning.offSetBottom -
            (isUnderBathtub ? 0 : hasShowerBase ? bottomOffsetMM - showerBaseHeightMM : bottomOffsetMM);
        scaling = desiredHeightMM / mountTechConfig.heightMM;
    }

    return [direction * scaling, direction * (mountTechConfig.usesWholeSide ? 200 / mountTechConfig.heightMM : 1)];
};

/**
 * Calculates one MountSituation for the given mount data. Can return undefined if the position
 * is invalid and the mount does not use the whole side of the shower.
 * @param mountTechConfig The configuration for the current selected mount
 * @param showerHeightMM The height of the shower in mm
 * @param prevIsWall Boolean whether previous to the mount is a wall
 * @param nextIsWall Boolean whether next to the mount is a wall
 * @param position The position of the decorator [PositionType.Top | PositionType.Bottom | undefined]
 */
const buildMountSituation = (
    mountTechConfig: MountTechConfig,
    showerHeightMM: number,
    prevIsWall: boolean,
    nextIsWall: boolean,
    bottomOffsetMM: number,
    isUnderBathtub: boolean,
    hasShowerBase: boolean,
    position?: PositionType
): MountSituation | undefined => {
    const [scaleY, detailViewScaleY] = calculateMountScaleY(
        showerHeightMM,
        mountTechConfig,
        bottomOffsetMM,
        isUnderBathtub,
        hasShowerBase,
        position
    );
    const positionAsVector3 = getMountPositionAsVector3(
        mountTechConfig,
        showerHeightMM,
        scaleY,
        bottomOffsetMM,
        isUnderBathtub,
        hasShowerBase,
        position
    );

    if (positionAsVector3) {
        return {
            position: positionAsVector3,
            scaleX: !prevIsWall && nextIsWall ? -1 : 1,
            scaleY: scaleY,
            detailViewScaleY: detailViewScaleY
        };
    } else {
        return undefined;
    }
};

/**
 * This function returns an array of MountSituation.
 * @param prevIsWall Booleane whether previous to the mount is a wall
 * @param nextIsWall Booleane whether next to the mount is a wall
 * @param positions The positions of the decorator [PositionType.Top | PositionType.Bottom]
 * @param showerHeightMM The height of the shower in mm
 * @param mountTechConfig The configuration for the current selected mount
 */
export const buildMountSituations = (
    prevIsWall: boolean,
    nextIsWall: boolean,
    positions: PositionType[],
    showerHeightMM: number,
    mountTechConfig: MountTechConfig,
    bottomOffsetMM: number,
    isUnderBathtub: boolean,
    hasShowerBase: boolean
): MountSituation[] => {
    const result: MountSituation[] = [];
    // If the selected mount uses the whole side of the shower ignore the positions
    if (mountTechConfig.usesWholeSide) {
        const mountSituation = buildMountSituation(
            mountTechConfig,
            showerHeightMM,
            prevIsWall,
            nextIsWall,
            bottomOffsetMM,
            isUnderBathtub,
            hasShowerBase
        );
        if (mountSituation) {
            result.push(mountSituation);
        }
    } else {
        // else build a MountSituation for each position of the decorator
        positions.forEach((position) => {
            const mountSituation = buildMountSituation(
                mountTechConfig,
                showerHeightMM,
                prevIsWall,
                nextIsWall,
                bottomOffsetMM,
                isUnderBathtub,
                hasShowerBase,
                position
            );
            if (mountSituation) {
                result.push(mountSituation);
            }
        });
    }
    return result;
};

/**
 * This function calculates the position as a Vector3 for a hinge. The position
 * is always calculated from the bottom of the floor.
 *
 * Can return undefined if the position is neither top nor bottom
 * @param hingeTechConfig The config of the current hinge
 * @param showerHeightMM The height of the shower in mm
 * @param position Whether the hinge is top or bottom
 * @param connector The connector type of the transition
 */
const getHingePositionAsVector3 = (
    hingeTechConfig: HingeTechConfig,
    showerHeightMM: number,
    position: PositionType,
    connector: BlueprintTransitionConnectorType,
    bottomOffsetMM: number,
    corner: RotationCorner
): Vector3 | undefined => {
    const hingePositionXCM =
        connector === BlueprintTransitionConnectorType.GlassWall
            ? corner === RotationCorner.FrontRight
                ? -hingeTechConfig.wallOffset / 10
                : hingeTechConfig.wallOffset / 10
            : 0;
    if (position === PositionType.Top) {
        const hingePositionYMM = showerHeightMM - hingeTechConfig.positioning.offSetTop + bottomOffsetMM;
        return new Vector3(hingePositionXCM, hingePositionYMM / 10, 0);
    } else if (position === PositionType.Bottom) {
        const hingePositionYMM = hingeTechConfig.positioning.offSetBottom + bottomOffsetMM;
        return new Vector3(hingePositionXCM, hingePositionYMM / 10, 0);
    } else {
        console.error(`Hinge has been given invalid position argument: ${position}`);
        return undefined;
    }
};

/**
 * This function returns the rotation for the two parts of a hinge.
 * @param nextElementType The type of the next element
 * @param transitionAngle The angle of the transition that contains the hinge
 * @param connector The connector type of the transition
 */
const calculateRotationForHingeParts = (
    nextElementType: BlueprintLayoutType,
    transitionAngle: number,
    connector: BlueprintTransitionConnectorType
) => {
    if (connector === BlueprintTransitionConnectorType.GlassWall) {
        return [Vector3.Zero(), Vector3.Zero()];
    } else {
        const rotationPartOneGG =
            nextElementType !== BlueprintLayoutType.Door
                ? new Vector3(0, Tools.ToRadians(-transitionAngle), 0)
                : Vector3.Zero();
        const rotationPartTwoGG =
            nextElementType === BlueprintLayoutType.Door
                ? new Vector3(0, Tools.ToRadians(transitionAngle), 0)
                : Vector3.Zero();

        return [rotationPartOneGG, rotationPartTwoGG];
    }
};

/**
 * This function builds one HingeSituation.
 *
 * Can be undefined if the position is neither top or bottom
 * @param hingeTechConfig The config for the current hinge
 * @param showerHeightMM The height of the shower in mm
 * @param nextElementType The type of the next element
 * @param position The position of the hinge on the shower
 * @param transitionAngle The angle of the transition that holds the hinge
 * @param connector The connector of the transition that holds the hinge
 */
const buildNormalHingeSituation = (
    hingeTechConfig: HingeTechConfig,
    showerHeightMM: number,
    nextElementType: BlueprintLayoutType,
    position: PositionType,
    transitionAngle: number,
    connector: BlueprintTransitionConnectorType,
    bottomOffsetMM: number,
    corner: RotationCorner
): NormalHingeSituation | undefined => {
    const scaleY = position === PositionType.Bottom ? -1 : 1;
    const positionAsVector3 = getHingePositionAsVector3(
        hingeTechConfig,
        showerHeightMM,
        position,
        connector,
        bottomOffsetMM,
        corner
    );
    const [rotationPartOne, rotationPartTwo] = calculateRotationForHingeParts(
        nextElementType,
        transitionAngle,
        connector
    );

    if (positionAsVector3) {
        return {
            position: positionAsVector3,
            scaleX: nextElementType === BlueprintLayoutType.Door ? -1 : 1,
            scaleY: scaleY,
            rotationPartOne: rotationPartOne,
            rotationPartTwo: rotationPartTwo
        };
    } else {
        return undefined;
    }
};

export const buildNormalHingeSituations = (
    nextElementType: BlueprintLayoutType,
    positions: PositionType[],
    showerHeightMM: number,
    hingeTechConfig: HingeTechConfig,
    transitionAngle: number,
    connector: BlueprintTransitionConnectorType,
    bottomOffsetMM: number,
    corner: RotationCorner
): NormalHingeSituation[] => {
    const result: NormalHingeSituation[] = [];

    positions.forEach((position) => {
        const hingeSituation = buildNormalHingeSituation(
            hingeTechConfig,
            showerHeightMM,
            nextElementType,
            position,
            transitionAngle,
            connector,
            bottomOffsetMM,
            corner
        );
        if (hingeSituation) {
            result.push(hingeSituation);
        }
    });
    return result;
};

/**
 * This function calculates the rotation for the decorator of the current transition.
 * @param previousWallDirection The direction of the wall previous to the transition
 * @param transitionAngle The angle of the current transition
 * @param nextElementIsWall Boolean whether the next element after the transition is a wall element
 */
export const calculateDecoratorRotation = (
    previousWallDirection: Vector3,
    transitionAngle: number,
    nextElementIsWall: boolean
): Vector3 => {
    if (nextElementIsWall) {
        // Calculate the rotation from the raw direction since when the next element is a wall the
        // decorator is mirrored on the yz-plane
        return getRotationYFromDirection(previousWallDirection);
    } else {
        // Rotate the direction by the angle of the transition and calculate the rotation from it
        return getRotationYFromDirection(applyRotationY(previousWallDirection, transitionAngle));
    }
};

/**
 * This function builds and returns the filename of the mount with the given data
 * @param prevIsWall Booleane whether previous to the mount is a wall
 * @param nextIsWall Booleane whether next to the mount is a wall
 * @param mountId The id of the selected mount
 */
export const buildMountFilename = (prevIsWall: boolean, nextIsWall: boolean, mountId: string): string => {
    const surrounding = !prevIsWall && !nextIsWall ? 'gg' : 'gw';

    return `mount_${surrounding}_${mountId}.glb`;
};

// ############################################################################
// ## Sliding Hinge
// ############################################################################

export const buildSlidingHingeSituation = (
    transitionAngle: number,
    beforePreviousTransition: BlueprintTransition,
    beforePreviousElementType: BlueprintLayoutType,
    previousTransititon: BlueprintTransition,
    previousElementLengthMM: number,
    nextTransititon: BlueprintTransition,
    afterNextTransition: BlueprintTransition,
    afterNextElementType: BlueprintLayoutType,
    nextElementLengthMM: number,
    hingeTechConfig: HingeTechConfig,
    showerHeightMM: number,
    bottomOffsetMM: number,
    isUnderBathtub: boolean,
    hasShowerBase: boolean
): SlidingHingeSituation[] => {
    const desiredLengthMM = transitionAngle !== 0 ? nextElementLengthMM : previousElementLengthMM + nextElementLengthMM;

    let startNeedsCover = beforePreviousElementType !== BlueprintLayoutType.Wall;
    beforePreviousTransition.decorators?.forEach((decorator) => {
        if (decorator.type === BlueprintDecoratorType.Hinge) {
            startNeedsCover = false;
        }
    });
    const startTouchesWall = beforePreviousElementType !== BlueprintLayoutType.Wall;
    let endNeedsCover = afterNextElementType !== BlueprintLayoutType.Wall;
    afterNextTransition.decorators?.forEach((decorator) => {
        if (decorator.type === BlueprintDecoratorType.Hinge) {
            endNeedsCover = false;
        }
    });
    const endTouchesWall = afterNextElementType !== BlueprintLayoutType.Wall;

    const endHasCorner =
        nextTransititon.angle === 90 &&
        (afterNextTransition.decorators?.some((d) => d.type === BlueprintDecoratorType.Hinge) || false);

    const previousHingeHasCorner =
        previousTransititon.angle === 90 &&
        (beforePreviousTransition.decorators?.some((d) => d.type === BlueprintDecoratorType.Hinge) || false);

    const positionY =
        (showerHeightMM + (isUnderBathtub ? bottomOffsetMM : hasShowerBase ? showerBaseHeightMM : 0)) / 10;

    return [
        {
            position: new Vector3(-(previousElementLengthMM - nextElementLengthMM) / 20, positionY, 0),
            scaleX: desiredLengthMM / hingeTechConfig.lengthMM,
            startNeedsCover: startNeedsCover,
            startTouchesWall: startTouchesWall,
            endNeedsCover: endNeedsCover,
            previousHingeHasCorner: previousHingeHasCorner,
            endHasCorner: endHasCorner,
            endTouchesWall: endTouchesWall
        }
    ];
};

import { Mesh, Vector2, Vector3 } from '@babylonjs/core';
import React, { useEffect, useState } from 'react';
import { CreatedInstance, useBabylonScene } from 'react-babylonjs';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { registerOnReflectionProbe, unregisterOnReflectionProbe } from '../../../3d_helpers';
import { applyTags, TAGLIST } from '../../../helper/tagHelper';
import { TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import ScaledTextureComponent from '../ScaledTextureComponent/ScaledTextureComponent';

const FloorComponent = (): JSX.Element => {
    const [groundRef, setGroundRef] = useState<CreatedInstance<Mesh>>();
    const scene = useBabylonScene();

    const sizeCM = useSelector((state: StoreState) => state.architecture.roomInfo.size).divide(new Vector3(10, 10, 10));
    const roomConfig = useSelector((state: StoreState) => state.configurator.roomConfiguration);

    const roomPosition = useSelector((state: StoreState) => state.architecture.roomInfo.position);
    const floorPosition = roomPosition.divide(new Vector3(20, 20, 20));

    useEffect(() => {
        if (groundRef && groundRef.hostInstance && scene) {
            applyTags([groundRef.hostInstance], [TAGLIST.BE_REFELECTED]);
        }
    }, [groundRef, scene]);

    useEffect(() => {
        if (groundRef && groundRef?.hostInstance && scene) {
            registerOnReflectionProbe(groundRef.hostInstance, scene);
            return (): void => {
                if (groundRef.hostInstance) unregisterOnReflectionProbe(groundRef.hostInstance, scene);
            };
        }
    }, [groundRef, scene]);

    return (
        <ground
            ref={setGroundRef}
            key={`floor_box_${sizeCM}_${roomConfig.floor}`}
            name="floor"
            checkCollisions
            width={sizeCM.x}
            height={sizeCM.z}
            position={floorPosition}
            layerMask={TOTAL_LAYERMASK}
        >
            <standardMaterial name="floor_material">
                <ScaledTextureComponent
                    assignTo="diffuseTexture"
                    sizeCM={new Vector2(sizeCM.x, sizeCM.z)}
                    texture={roomConfig.floor}
                    baseSize={new Vector2(1500, 1500)}
                    scaling={new Vector2(0.3, 0.3)}
                />
            </standardMaterial>
        </ground>
    );
};

export default FloorComponent;

export const SELECT_ARCHITECTURE = 'SELECT_ARCHITECTURE';
export type SELECT_ARCHITECTURE = typeof SELECT_ARCHITECTURE;

export const SELECT_BLUEPRINT = 'SELECT_BLUEPRINT';
export type SELECT_BLUEPRINT = typeof SELECT_BLUEPRINT;

export const LOAD_BLUEPRINT = 'LOAD_BLUEPRINT';
export type LOAD_BLUEPRINT = typeof LOAD_BLUEPRINT;

export const BLUEPRINT_LOADED_SUCCESS = 'BLUEPRINT_LOADED_SUCCESS';
export type BLUEPRINT_LOADED_SUCCESS = typeof BLUEPRINT_LOADED_SUCCESS;

export const BLUEPRINT_LOADED_ERROR = 'BLUEPRINT_LOADED_ERROR';
export type BLUEPRINT_LOADED_ERROR = typeof BLUEPRINT_LOADED_ERROR;

export const BLUEPRINT_UPDATED = 'BLUEPRINT_UPDATED';
export type BLUEPRINT_UPDATED = typeof BLUEPRINT_UPDATED;

export const SELECT_ROOMPLAN = 'SELECT_ROOMPLAN';
export type SELECT_ROOMPLAN = typeof SELECT_ROOMPLAN;

export const LOAD_ROOMPLAN = 'LOAD_ROOMPLAN';
export type LOAD_ROOMPLAN = typeof LOAD_ROOMPLAN;

export const ROOMPLAN_LOADED_SUCCESS = 'ROOMPLAN_LOADED_SUCCESS';
export type ROOMPLAN_LOADED_SUCCESS = typeof ROOMPLAN_LOADED_SUCCESS;

export const ROOMPLAN_LOADED_ERROR = 'ROOMPLAN_LOADED_ERROR';
export type ROOMPLAN_LOADED_ERROR = typeof ROOMPLAN_LOADED_ERROR;

export const UPDATE_SHOWER_BASESIZE = 'UPDATE_SHOWER_BASESIZE';
export type UPDATE_SHOWER_BASESIZE = typeof UPDATE_SHOWER_BASESIZE;

export const UPDATE_SHOWER_HEIGHT = 'UPDATE_SHOWER_HEIGHT';
export type UPDATE_SHOWER_HEIGHT = typeof UPDATE_SHOWER_HEIGHT;

export const UPDATE_SHOWER_BASESIZE_CONSTRAINTS = 'UPDATE_SHOWER_BASESIZE_CONSTRAINTS';
export type UPDATE_SHOWER_BASESIZE_CONSTRAINTS = typeof UPDATE_SHOWER_BASESIZE_CONSTRAINTS;

export const UPDATE_DEFAULT_SHOWER_SIZE = 'UPDATE_DEFAULT_SHOWER_SIZE';
export type UPDATE_DEFAULT_SHOWER_SIZE = typeof UPDATE_DEFAULT_SHOWER_SIZE;

export const UPDATE_ROOM_SIZE = 'UPDATE_ROOM_SIZE';
export type UPDATE_ROOM_SIZE = typeof UPDATE_ROOM_SIZE;

export const UPDATE_ROOM_SIZE_ADJUSTMENT = 'UPDATE_ROOM_SIZE_ADJUSTMENT';
export type UPDATE_ROOM_SIZE_ADJUSTMENT = typeof UPDATE_ROOM_SIZE_ADJUSTMENT;

export const UPDATE_BEAM_INTERSECTION_POINT_X = 'UPDATE_BEAM_INTERSECTION_POINT_X';
export type UPDATE_BEAM_INTERSECTION_POINT_X = typeof UPDATE_BEAM_INTERSECTION_POINT_X;

export const UPDATE_BEAM_INTERSECTION_POINT_Z = 'UPDATE_BEAM_INTERSECTION_POINT_Z';
export type UPDATE_BEAM_INTERSECTION_POINT_Z = typeof UPDATE_BEAM_INTERSECTION_POINT_Z;

export const RESET_BEAM_INTERSECTION_POINT = 'RESET_BEAM_INTERSECTION_POINT';
export type RESET_BEAM_INTERSECTION_POINT = typeof RESET_BEAM_INTERSECTION_POINT;

export const UPDATE_BEAM_INTERSECTION_POINT = 'UPDATE_BEAM_INTERSECTION_POINT';
export type UPDATE_BEAM_INTERSECTION_POINT = typeof UPDATE_BEAM_INTERSECTION_POINT;

export const UPDATE_BLUEPRINT_RATIO = 'UPDATE_BLUEPRINT_RATIO';
export type UPDATE_BLUEPRINT_RATIO = typeof UPDATE_BLUEPRINT_RATIO;

export const UPDATE_LENGTH_MM = 'UPDATE_LENGTH_MM';
export type UPDATE_LENGTH_MM = typeof UPDATE_LENGTH_MM;

export const LOAD_KNOB_TECH_CONFIG = 'LOAD_KNOB_TECH_CONFIG';
export type LOAD_KNOB_TECH_CONFIG = typeof LOAD_KNOB_TECH_CONFIG;

export const KNOB_TECH_CONFIG_LOADED_SUCCESS = 'KNOB_TECH_CONFIG_LOADED_SUCCESS';
export type KNOB_TECH_CONFIG_LOADED_SUCCESS = typeof KNOB_TECH_CONFIG_LOADED_SUCCESS;

export const KNOB_TECH_CONFIG_LOADED_ERROR = 'KNOB_TECH_CONFIG_LOADED_ERROR';
export type KNOB_TECH_CONFIG_LOADED_ERROR = typeof KNOB_TECH_CONFIG_LOADED_ERROR;

export const LOAD_WALL_MOUNT_TECH_CONFIG = 'LOAD_WALL_MOUNT_TECH_CONFIG';
export type LOAD_WALL_MOUNT_TECH_CONFIG = typeof LOAD_WALL_MOUNT_TECH_CONFIG;

export const WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS = 'WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS';
export type WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS = typeof WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS;

export const WALL_MOUNT_TECH_CONFIG_LOADED_ERROR = 'WALL_MOUNT_TECH_CONFIG_LOADED_ERROR';
export type WALL_MOUNT_TECH_CONFIG_LOADED_ERROR = typeof WALL_MOUNT_TECH_CONFIG_LOADED_ERROR;

export const LOAD_GLASS_MOUNT_TECH_CONFIG = 'LOAD_GLASS_MOUNT_TECH_CONFIG';
export type LOAD_GLASS_MOUNT_TECH_CONFIG = typeof LOAD_GLASS_MOUNT_TECH_CONFIG;

export const GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS = 'GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS';
export type GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS = typeof GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS;

export const GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR = 'GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR';
export type GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR = typeof GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR;

export const LOAD_HINGE_TECH_CONFIG = 'LOAD_HINGE_TECH_CONFIG';
export type LOAD_HINGE_TECH_CONFIG = typeof LOAD_HINGE_TECH_CONFIG;

export const HINGE_TECH_CONFIG_LOADED_SUCCESS = 'HINGE_TECH_CONFIG_LOADED_SUCCESS';
export type HINGE_TECH_CONFIG_LOADED_SUCCESS = typeof HINGE_TECH_CONFIG_LOADED_SUCCESS;

export const HINGE_TECH_CONFIG_LOADED_ERROR = 'HINGE_TECH_CONFIG_LOADED_ERROR';
export type HINGE_TECH_CONFIG_LOADED_ERROR = typeof HINGE_TECH_CONFIG_LOADED_ERROR;

export const LOAD_BEAM_TECH_CONFIG = 'LOAD_BEAM_TECH_CONFIG';
export type LOAD_BEAM_TECH_CONFIG = typeof LOAD_BEAM_TECH_CONFIG;

export const BEAM_TECH_CONFIG_LOADED_SUCCESS = 'BEAM_TECH_CONFIG_LOADED_SUCCESS';
export type BEAM_TECH_CONFIG_LOADED_SUCCESS = typeof BEAM_TECH_CONFIG_LOADED_SUCCESS;

export const BEAM_TECH_CONFIG_LOADED_ERROR = 'BEAM_TECH_CONFIG_LOADED_ERROR';
export type BEAM_TECH_CONFIG_LOADED_ERROR = typeof BEAM_TECH_CONFIG_LOADED_ERROR;

export const LOAD_POSSIBLE_BEAM_TECH_CONFIGS = 'LOAD_POSSIBLE_BEAM_TECH_CONFIGS';
export type LOAD_POSSIBLE_BEAM_TECH_CONFIGS = typeof LOAD_POSSIBLE_BEAM_TECH_CONFIGS;

export const LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS = 'LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS';
export type LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS = typeof LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS;

export const LOAD_POSSIBLE_BEAM_TECH_CONFIGS_ERROR = 'LOAD_POSSIBLE_BEAM_TECH_CONFIGS_ERROR';
export type LOAD_POSSIBLE_BEAM_TECH_CONFIGS_ERROR = typeof LOAD_POSSIBLE_BEAM_TECH_CONFIGS_ERROR;

import { Vector3 } from '@babylonjs/core';
import { combineReducers } from 'redux';
import { applyRotationY, getRotationYFromDirection } from '../../../ng/3d_helpers';
import { calculateSideForRotation } from '../../../ng/helper/commonHelper';
import { BeamTechConfig } from '../../../ng/types/BeamTechConfig';
import { Blueprint, BlueprintBaseSizeConstraints, BlueprintLayoutType } from '../../../ng/types/Blueprint';
import { HingeTechConfig } from '../../../ng/types/HingeTechConfig';
import { KnobTechConfig } from '../../../ng/types/KnobTechConfig';
import { MountTechConfig } from '../../../ng/types/MountTechConfig';
import { RoomInfo, Roomplan } from '../../../ng/types/Roomplan';
import { createReducer } from '../../utils';
import {
    BeamTechConfigLoadedErrorAction,
    BeamTechConfigLoadedSuccessAction,
    BlueprintLoadedSuccessAction,
    BlueprintUpdatedAction,
    GlassMountTechConfigLoadedErrorAction,
    GlassMountTechConfigLoadedSuccessAction,
    HingeTechConfigLoadedErrorAction,
    HingeTechConfigLoadedSuccessAction,
    KnobTechConfigLoadedErrorAction,
    KnobTechConfigLoadedSuccessAction,
    LoadPossibleBeamTechConfigsSuccessAction,
    RoomplanLoadedSuccessAction,
    UpdateBeamIntersectionPointXAction,
    UpdateBeamIntersectionPointZAction,
    UpdateBlueprintRatioAction,
    UpdateDefaultShowerSizeAction,
    UpdateRoomSizeAction,
    UpdateRoomSizeAdjustmentAction,
    UpdateShowerBaseSizeAction,
    UpdateShowerBaseSizeConstraintsAction,
    UpdateShowerHeightAction,
    WallMountTechConfigLoadedErrorAction,
    WallMountTechConfigLoadedSuccessAction
} from './interfaces';
import {
    BEAM_TECH_CONFIG_LOADED_ERROR,
    BEAM_TECH_CONFIG_LOADED_SUCCESS,
    BLUEPRINT_LOADED_ERROR,
    BLUEPRINT_LOADED_SUCCESS,
    BLUEPRINT_UPDATED,
    GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR,
    GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS,
    HINGE_TECH_CONFIG_LOADED_ERROR,
    HINGE_TECH_CONFIG_LOADED_SUCCESS,
    KNOB_TECH_CONFIG_LOADED_ERROR,
    KNOB_TECH_CONFIG_LOADED_SUCCESS,
    LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS,
    RESET_BEAM_INTERSECTION_POINT,
    ROOMPLAN_LOADED_ERROR,
    ROOMPLAN_LOADED_SUCCESS,
    UPDATE_BEAM_INTERSECTION_POINT_X,
    UPDATE_BEAM_INTERSECTION_POINT_Z,
    UPDATE_BLUEPRINT_RATIO,
    UPDATE_DEFAULT_SHOWER_SIZE,
    UPDATE_ROOM_SIZE,
    UPDATE_ROOM_SIZE_ADJUSTMENT,
    UPDATE_SHOWER_BASESIZE,
    UPDATE_SHOWER_BASESIZE_CONSTRAINTS,
    UPDATE_SHOWER_HEIGHT,
    WALL_MOUNT_TECH_CONFIG_LOADED_ERROR,
    WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS
} from './types';

export type ElementSize = {
    index: number;
    width: number;
};

export type ElementShowerSideSizes = {
    width: ElementSize[];
    depth: ElementSize[];
};

export type DefaultShowerSizesState = {
    baseSize: Vector3;
    elementSizes: ElementShowerSideSizes;
};

export interface ArchitectureState {
    blueprint: Blueprint;
    roomplan: Roomplan;
    roomInfo: RoomInfo;
    showerBaseSize: Vector3;
    showerBaseSizeConstraints: BlueprintBaseSizeConstraints;
    defaultShowerSizes: DefaultShowerSizesState;
    roomSizeAdjustment: Vector3;
    beamsIntersectionPoint: Vector3;
    techConfigs: TechConfigState;
}

export const defaultShowerHeightMM = 2000;

const blueprint = createReducer<Blueprint | null>(null)({
    [BLUEPRINT_LOADED_SUCCESS]: (_: Blueprint, action: BlueprintLoadedSuccessAction) => action.payload.blueprint,
    [BLUEPRINT_LOADED_ERROR]: () => null,
    [BLUEPRINT_UPDATED]: (_: Blueprint, action: BlueprintUpdatedAction) => action.payload.blueprint,
    [UPDATE_BLUEPRINT_RATIO]: (state: Blueprint, action: UpdateBlueprintRatioAction) => {
        let currentDirection = Vector3.Right();
        let currentRotation = getRotationYFromDirection(currentDirection);

        const updatedBlueprint = action.payload.blueprint.layout.map((element) => {
            if (element.type === BlueprintLayoutType.Transition) {
                currentDirection = applyRotationY(currentDirection, element.angle);
                currentRotation = getRotationYFromDirection(currentDirection);
            }

            if (element.type !== BlueprintLayoutType.Transition) {
                const showerSideLengthMM = calculateSideForRotation(currentRotation, action.payload.baseSize);
                element.ratio = element.length / showerSideLengthMM;
            }
            return element;
        });

        return { ...state, layout: updatedBlueprint };
    }
});

const roomplan = createReducer<Roomplan | null>(null)({
    [ROOMPLAN_LOADED_SUCCESS]: (_: Blueprint, action: RoomplanLoadedSuccessAction) => action.payload.roomplan,
    [ROOMPLAN_LOADED_ERROR]: () => null
});

const roomInfo = createReducer<RoomInfo>({ size: Vector3.Zero(), position: Vector3.Zero() })({
    [UPDATE_ROOM_SIZE]: (_: Vector3, action: UpdateRoomSizeAction) => action.payload
});

const showerBaseSize = createReducer<Vector3>(new Vector3(0, defaultShowerHeightMM, 0))({
    [UPDATE_SHOWER_BASESIZE]: (_: Vector3, action: UpdateShowerBaseSizeAction) => action.payload.baseSize,
    [UPDATE_SHOWER_HEIGHT]: (oldShowerBaseSize: Vector3, action: UpdateShowerHeightAction) => {
        return new Vector3(oldShowerBaseSize.x, action.payload.height, oldShowerBaseSize.z);
    }
});

const showerBaseSizeConstraints = createReducer<Vector3 | null>(null)({
    [UPDATE_SHOWER_BASESIZE_CONSTRAINTS]: (_: Vector3, action: UpdateShowerBaseSizeConstraintsAction) =>
        action.payload.constraints
});

const defaultShowerSizes = createReducer<DefaultShowerSizesState | null>(null)({
    [UPDATE_DEFAULT_SHOWER_SIZE]: (_: DefaultShowerSizesState, action: UpdateDefaultShowerSizeAction) =>
        action.payload.defaultSize
});

const roomSizeAdjustment = createReducer<Vector3 | null>(null)({
    [UPDATE_ROOM_SIZE_ADJUSTMENT]: (_: Vector3, action: UpdateRoomSizeAdjustmentAction) => action.payload.adjustment
});

const beamsIntersectionPoint = createReducer<{ x: number | null; z: number | null }>({ x: null, z: null })({
    [UPDATE_BEAM_INTERSECTION_POINT_X]: (oldState: Vector3, action: UpdateBeamIntersectionPointXAction) => ({
        ...oldState,
        x: action.payload.x
    }),
    [UPDATE_BEAM_INTERSECTION_POINT_Z]: (oldState: Vector3, action: UpdateBeamIntersectionPointZAction) => ({
        ...oldState,
        z: action.payload.z
    }),
    [RESET_BEAM_INTERSECTION_POINT]: () => ({ x: null, z: null })
});

export type TechConfigState = {
    knob?: KnobTechConfig;
    wallMount?: MountTechConfig;
    glassMount?: MountTechConfig;
    hinge?: HingeTechConfig;
    beam?: BeamTechConfig;
    possibleBeams?: BeamTechConfig[];
};

const techConfigs = createReducer<TechConfigState | null>({})({
    [KNOB_TECH_CONFIG_LOADED_SUCCESS]: (state: TechConfigState, action: KnobTechConfigLoadedSuccessAction) => ({
        ...state,
        knob: action.payload.knobTechConfig,
        error: null
    }),
    [KNOB_TECH_CONFIG_LOADED_ERROR]: (state: TechConfigState, action: KnobTechConfigLoadedErrorAction) => ({
        ...state,
        knob: null,
        error: action.payload.error
    }),
    [WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS]: (
        state: TechConfigState,
        action: WallMountTechConfigLoadedSuccessAction
    ) => ({
        ...state,
        wallMount: action.payload.wallMountTechConfig,
        error: null
    }),
    [WALL_MOUNT_TECH_CONFIG_LOADED_ERROR]: (state: TechConfigState, action: WallMountTechConfigLoadedErrorAction) => ({
        ...state,
        wallMount: null,
        error: action.payload.error
    }),
    [GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS]: (
        state: TechConfigState,
        action: GlassMountTechConfigLoadedSuccessAction
    ) => ({
        ...state,
        glassMount: action.payload.glassMountTechConfig,
        error: null
    }),
    [GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR]: (
        state: TechConfigState,
        action: GlassMountTechConfigLoadedErrorAction
    ) => ({
        ...state,
        glassMount: null,
        error: action.payload.error
    }),
    [HINGE_TECH_CONFIG_LOADED_SUCCESS]: (state: TechConfigState, action: HingeTechConfigLoadedSuccessAction) => ({
        ...state,
        hinge: action.payload.hingeTechConfig,
        error: null
    }),
    [HINGE_TECH_CONFIG_LOADED_ERROR]: (state: TechConfigState, action: HingeTechConfigLoadedErrorAction) => ({
        ...state,
        hinge: null,
        error: action.payload.error
    }),
    [BEAM_TECH_CONFIG_LOADED_SUCCESS]: (state: TechConfigState, action: BeamTechConfigLoadedSuccessAction) => ({
        ...state,
        beam: action.payload.beamTechConfig,
        error: null
    }),
    [LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS]: (
        state: TechConfigState,
        action: LoadPossibleBeamTechConfigsSuccessAction
    ) => ({
        ...state,
        possibleBeams: action.payload.beamTechConfigs,
        error: null
    }),
    [BEAM_TECH_CONFIG_LOADED_ERROR]: (state: TechConfigState, action: BeamTechConfigLoadedErrorAction) => ({
        ...state,
        beams: null,
        error: action.payload.error
    })
});

const reducer = combineReducers({
    blueprint,
    roomplan,
    roomInfo,
    showerBaseSize,
    showerBaseSizeConstraints,
    defaultShowerSizes,
    roomSizeAdjustment,
    beamsIntersectionPoint,
    techConfigs
});

export default reducer;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PrivacyDisclaimer from '../../../components/privacy-disclaimer/PrivacyDisclaimer.component';
import OnboardingComponent from '../../../components/onboarding/OnboardingComponent';
import ResetConfigModalComponent from '../../../components/reset-config-screen/ResetConfigModal.component';
import SizeInfoComponent from '../../../components/size-info/SizeInfoComponent';
import SocialShareModalComponent from '../../../components/social-share/SocialShareModal.component';
import { StoreState } from '../../../state/ducks';
import EngineComponent from '../3d/BaseComponents/EngineComponent';
import FullscreenLoaderComponent from '../FullscreenLoaderComponent/FullscreenLoaderComponent';

const PageComponent = (): JSX.Element => {
    const [isReady, setIsReady] = useState(false);
    const [sizeInfoDisplayed, setSizeInfoDisplayed] = useState(isReady);
    const showPrivacyDisclaimer = useSelector((state: StoreState) => state.system.modalPrivacyOpen);
    const [showOnboarding, setShowOnboarding] = useState(true);
    return (
        <>
            <EngineComponent setIsReady={setIsReady} />
            <FullscreenLoaderComponent show={!isReady} />
            <SizeInfoComponent canBeDisplayed={isReady} onDisplayedChanged={setSizeInfoDisplayed} />
            {isReady && showPrivacyDisclaimer && <PrivacyDisclaimer />}
            {isReady && !sizeInfoDisplayed && !showPrivacyDisclaimer && showOnboarding && (
                <OnboardingComponent
                    onTutorialDialogClose={() => {
                        setShowOnboarding(false);
                    }}
                    onOnboardingFinished={() => setShowOnboarding(false)}
                    onTutorialSkip={() => setShowOnboarding(false)}
                ></OnboardingComponent>
            )}
            <ResetConfigModalComponent />
            <SocialShareModalComponent />
        </>
    );
};

export default PageComponent;

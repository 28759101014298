import { Vector3 } from '@babylonjs/core';
import React from 'react';
import useZoomAt from '../../../../hooks/useZoomAt';
import { TAGLIST } from '../../../helper/tagHelper';
import { DETAIL_LAYERMASK, TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import { PositionType } from '../../../types/Common';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type FloorRailComponentProps = {
    railSite: PositionType;
    position: Vector3;
    lengthMM: number;
    parentPositionOffset: Vector3;
};

const FloorRailComponent = ({
    railSite,
    position,
    lengthMM,
    parentPositionOffset
}: FloorRailComponentProps): JSX.Element => {
    const tfName = `floorRail_tf_${position}_${railSite}`;
    const [zoomAt, setZoomAt] = useZoomAt();
    const rootUrl = 'assets/3d/floorRails/';
    const railLengthMM = 30;

    const railPosition = position.clone();
    railPosition.x -= parentPositionOffset.x;
    railPosition.x -= lengthMM / 20;

    if (railSite === PositionType.Right) {
        railPosition.x += lengthMM / 10;
        railPosition.x += railLengthMM / 20;
    } else {
        railPosition.x -= railLengthMM / 20;
    }

    return (
        <transformNode name={tfName} position={railPosition}>
            <ThreeDComponent
                name={'floorRail_rail_threeD'}
                rootUrl={rootUrl}
                sceneFilename={'rail_10-031.glb'}
                onClick={zoomAt !== tfName ? () => setZoomAt(tfName, undefined) : undefined}
                layerMask={zoomAt === tfName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                tagList={[TAGLIST.REFLECTION]}
            />
        </transformNode>
    );
};

export default FloorRailComponent;

import { AmbientOptions } from '../../../components/ambientMenu/AmbientMenuComponent.types';
import {
    Catalogue,
    CatalogueLoadedErrorAction,
    CatalogueLoadedSuccessAction,
    LoadCatalogueAction,
    ResetToDefaultConfigAction,
    SelectAmbientOptionAction,
    SelectConfigOptionAction,
    UpdateBeamPriceAction,
    UpdatePriceAction,
    UpdatePriceErrorAction,
    UpdateShowerSizePriceAction
} from './interfaces';
import {
    CATALOGUE_LOADED_ERROR,
    CATALOGUE_LOADED_SUCCESS,
    LOAD_CATALOGUE,
    RESET_TO_DEFAULT_CONFIG,
    SELECT_AMBIENT_OPTION,
    SELECT_CONFIG_OPTION,
    UPDATE_BEAM_PRICE,
    UPDATE_PRICE,
    UPDATE_PRICE_ERROR,
    UPDATE_SHOWER_SIZE_PRICE
} from './types';

export const loadCatalogue = (email?: string): LoadCatalogueAction => ({
    type: LOAD_CATALOGUE,
    payload: {
        email
    }
});

export const loadCatalogueSuccess = (catalogue: Catalogue): CatalogueLoadedSuccessAction => ({
    type: CATALOGUE_LOADED_SUCCESS,
    payload: {
        catalogue
    }
});

export const loadCatalogueError = (error: string): CatalogueLoadedErrorAction => ({
    type: CATALOGUE_LOADED_ERROR,
    payload: {
        error
    }
});

export const selectConfigOption = (
    optionType: string,
    itemId: string,
    catalogue: Catalogue,
    loadBlueprint = true
): SelectConfigOptionAction => ({
    type: SELECT_CONFIG_OPTION,
    payload: { optionType, itemId, catalogue, loadBlueprint }
});

export const resetToDefaultConfig = (): ResetToDefaultConfigAction => ({ type: RESET_TO_DEFAULT_CONFIG });

export const selectAmbientOption = (fileName: string, optionType: AmbientOptions): SelectAmbientOptionAction => ({
    type: SELECT_AMBIENT_OPTION,
    payload: { fileName, optionType }
});

export const updateBeamPrice = (newBeamPrice: number): UpdateBeamPriceAction => ({
    type: UPDATE_BEAM_PRICE,
    payload: { newBeamPrice }
});

export const updatePrice = (price: number): UpdatePriceAction => ({
    type: UPDATE_PRICE,
    payload: { price }
});

export const updatePriceError = (error: string): UpdatePriceErrorAction => ({
    type: UPDATE_PRICE_ERROR,
    payload: { error }
});

export const updateShowerSizePrice = (newShowerSizePrice: number): UpdateShowerSizePriceAction => ({
    type: UPDATE_SHOWER_SIZE_PRICE,
    payload: { showerSizePrice: newShowerSizePrice }
});

import { Vector2, Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { RoomDecorator, RoomDecoratorType } from '../../../types/Roomplan';
import ShowerTilesComponent from '../Accessories/ShowerTilesComponent';

interface RoomDecoratorComponentProps {
    decorators: RoomDecorator[];
    parentWidthMM: number;
    parentHeightMM: number;
    decoratorDepthMM: number;
    showerSideLengthMM: number;
    decoratorXOffsetMM: number;
}

const RoomDecoratorComponent = ({
    decorators,
    parentWidthMM,
    parentHeightMM,
    decoratorDepthMM,
    showerSideLengthMM,
    decoratorXOffsetMM
}: RoomDecoratorComponentProps): JSX.Element => {
    const roomConfig = useSelector((state: StoreState) => state.configurator.roomConfiguration);
    const roomDecorators = decorators.map((decorator, i) => {
        switch (decorator.type) {
            case RoomDecoratorType.Showertiles:
                const showertilesTexture = roomConfig.shower;
                const showertilesTextureBaseSize = new Vector2(1200, 1200);
                const showertilesTextureScaling = new Vector2(0.5, 0.5);
                return (
                    <ShowerTilesComponent
                        wallOriginCM={new Vector3(-parentWidthMM / 20, -parentHeightMM / 20, 0)}
                        depthMM={decoratorDepthMM}
                        wallWidthMM={parentWidthMM}
                        showerSideLengthMM={showerSideLengthMM}
                        showerHeightOverlapMM={decorator.showerHeightOverlap ?? 0}
                        showerSideOverlapMM={decorator.showerSideOverlap ?? 0}
                        offsetXMM={decoratorXOffsetMM}
                        texture={showertilesTexture}
                        textureBaseSize={showertilesTextureBaseSize}
                        textureScaling={showertilesTextureScaling}
                        hasShowervalve={decorator.hasShowervalve}
                        key={`${i}_${roomConfig.shower}`}
                    />
                );
        }
    });

    return <>{roomDecorators}</>;
};

export default RoomDecoratorComponent;

import React, { useState } from 'react';
import './PrivacyDisclaimer.component.scss';
import 'firebase/auth';
import '../../state/ducks/system/actions';
import { useDispatch } from 'react-redux';
import { systemActions } from '../../state/ducks/system';
import { Cookies } from '../firebase/Firebase.utils';
import { logAnalyticsEvent, setTrackingDisabled } from '../../ng/helper/analyticsHelper';
const PrivacyDisclaimer = (): JSX.Element => {
    const [hideInfoText, setHideInfoText] = useState(true);
    const infoButtonText = hideInfoText ? 'Mehr Info' : 'Weniger Info';
    const dispatch = useDispatch();

    const onClickAccept = () => {
        setTrackingDisabled(false);
        logAnalyticsEvent('configurator_select_privacy_settings_full');
        dispatch(systemActions.closeModalPrivacy());
        window.localStorage.setItem('ga', Cookies.Accepted);
    };
    const onClickBasics = () => {
        logAnalyticsEvent('configurator_select_privacy_settings_basic');
        setTrackingDisabled(true);
        dispatch(systemActions.closeModalPrivacy());
        window.localStorage.setItem('ga', Cookies.OnlyEssentials);
    };
    return (
        <div className="privacy-disclaimer-component">
            <div className={`privacy-disclaimer ${!hideInfoText && 'more-info'}`}>
                <div className="privacy-disclaimer-headline">Wir nutzen Cookies auf unserer Website</div>
                <div className="privacy-disclaimer-description">
                    Einige von ihnen sind essenziell, während andere uns helfen, diese Website und Ihre Erfahrung damit
                    zu verbessern.
                </div>
                <div className="privacy-disclaimer-body">
                    <a
                        className="privacy-disclaimer-button button-yes"
                        onClick={() => {
                            onClickAccept();
                        }}
                    >
                        Ich akzeptiere
                    </a>
                    <a
                        className="privacy-disclaimer-button button-no"
                        onClick={() => {
                            onClickBasics();
                        }}
                    >
                        Nur essenzielle Cookies akzeptieren
                    </a>
                    {!hideInfoText && (
                        <div className={'info-dienst'}>
                            <p>
                                Statistik-Cookies helfen Webseiten-Besitzern zu verstehen, wie Besucher mit Webseiten
                                interagieren, indem Informationen anonym gesammelt und gemeldet werden.
                                <br />
                                <br />
                                <span style={{ textDecoration: 'underline' }}>
                                    Folgende Cookies werden von Google Analytics gesetzt:
                                </span>
                                <br />
                                Cookie _ga:
                                <br />
                                <em>Anbieter: www.sprinz.eu, Ablauf: 2 Jahre, Typ: HTTP</em>
                                <br />
                                Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der
                                Besucher die Website nutzt, zu generieren.
                                <br />
                                <br />
                                Cookie _gat:
                                <br />
                                <em>Anbieter: www.sprinz.eu, Ablauf: 1 Tag, Typ: HTTP</em>
                                <br />
                                Wird von Google Analytics verwendet, um die Anforderungsrate einzuschränken
                                <br />
                                <br />
                                Cookie _gid:
                                <br />
                                <em>Anbieter: www.sprinz.eu, Ablauf: 1 Tag, Typ: HTTP</em>
                                <br />
                                Registriert eine eindeutige ID, die verwendet wird, um statistische Daten dazu, wie der
                                Besucher die Website nutzt, zu generieren.
                                <br />
                                <br />
                                <a
                                    className="hyperlinkfe targetself"
                                    href="https://www.sprinz.eu/ncrwl/index.php?Datenschutzerklaerung"
                                    rel="nofollow"
                                    target="_self"
                                >
                                    <span className="hyperlinkfe targetself">
                                        Weitere Informationen finden Sie in unserer Datenschutzerklärung.
                                    </span>
                                </a>
                            </p>
                        </div>
                    )}
                    <span
                        className="privacy-disclaimer-button button-info"
                        onClick={() => {
                            setHideInfoText(!hideInfoText);
                            return;
                        }}
                    >
                        <span className="moreinfos">{infoButtonText}</span>
                    </span>
                </div>
                <div className="privacy-disclaimer-footer">
                    <a
                        href="https://www.sprinz.eu/index.php?Kontakt#art-Kontakt-Einleitung"
                        title="Datenschutzerklärung"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Kontakt
                    </a>
                    &nbsp;|&nbsp;
                    <a
                        href="https://www.sprinz.eu/index.php?Datenschutzerklaerung"
                        title="Datenschutzerklärung"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Datenschutzerklärung
                    </a>
                    &nbsp;|&nbsp;
                    <a
                        href="https://www.sprinz.eu/index.php?Impressum#art-SPRINZ-Impressumsangabe"
                        title="Impressum"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Impressum
                    </a>
                </div>
            </div>
        </div>
    );
};

export default PrivacyDisclaimer;

import { Vector3 } from '@babylonjs/core';
import { applyRotationY } from '../3d_helpers';
import { Blueprint, BlueprintLayoutType } from '../types/Blueprint';
import { RoomElement, RoomInfo, RoomLayoutType, RoomWall } from '../types/Roomplan';
import { getNextElementIndex, getPreviousElementIndex } from './layoutHelper';

/**
 * This function takes the roomCoordinates and calculates the size and
 * the position of the room from it.
 * @param roomCoordinatesMM The room coordinates representing the room corners
 */
export const calculateRoomSizeAndPosition = (roomCoordinatesMM: Vector3[], decoratorDepthMM: number): RoomInfo => {
    const maxXMM = Math.max(...roomCoordinatesMM.map((c) => c.x));
    const minXMM = Math.min(...roomCoordinatesMM.map((c) => c.x));
    const maxZMM = Math.max(...roomCoordinatesMM.map((c) => c.z));
    const minZMM = Math.min(...roomCoordinatesMM.map((c) => c.z));
    const roomSizeXMM = maxXMM - minXMM + decoratorDepthMM;
    const roomSizeZMM = maxZMM - minZMM + decoratorDepthMM;
    const position = new Vector3(roomSizeXMM - decoratorDepthMM / 2, 0, -roomSizeZMM + decoratorDepthMM / 2);
    return { size: new Vector3(roomSizeXMM, 0, roomSizeZMM), position };
};

/**
 * This function calculates the length that has to be added to the wall element at
 * currentElementIndex pre to the wall itself. This additinal length intends to close
 * gaps that come from moving the wall element previous to the current wall element away
 * from the current wall element
 * @param currendElementIndex The index of the current wall element
 * @param layout The layout of the roomplan
 * @param decoratorDepthMM The depth of the decorator that can possibly be applied to the walls
 * @param wallDepthMM The depth of the wall itself
 */
export const calculatePreAdditionalWallLength = (
    currendElementIndex: number,
    layout: RoomElement[],
    decoratorDepthMM: number,
    wallDepthMM: number
): number => {
    let currentIndex = getPreviousElementIndex(currendElementIndex, layout.length);
    let previousWallElement = layout[currentIndex];

    // Find previous element of type wall
    while (previousWallElement.type !== RoomLayoutType.Wall) {
        currentIndex = getPreviousElementIndex(currentIndex, layout.length);
        previousWallElement = layout[currentIndex];
    }

    // If the transition between the two wall elements got rotated by -90 instead of 90 degree
    // we need to invert our result:
    // Expect the next element of the previous wall is the transition between the both walls
    const transitionElement = layout[getNextElementIndex(currentIndex, layout.length)];
    let invertionFactor = 1;
    if (transitionElement.type === RoomLayoutType.Transition) {
        invertionFactor = transitionElement.angle < 0 ? -1 : 1;
    }

    return previousWallElement.decorators ? (invertionFactor * decoratorDepthMM) / 2 : wallDepthMM;
};

/**
 * This function calculates the length that has to be added to the wall element at
 * currentElementIndex post to the wall itself. This additinal length intends to close
 * gaps that come from moving the wall element next to the current wall element away
 * from the current wall element
 * @param currendElementIndex The index of the current wall element
 * @param layout The layout of the roomplan
 * @param decoratorDepthMM The depth of the decorator that can possibly be applied to the walls
 * @param wallDepthMM The depth of the wall itself
 */
export const calculatePostAdditionalWallLength = (
    currendElementIndex: number,
    layout: RoomElement[],
    decoratorDepthMM: number,
    wallDepthMM: number
): number => {
    let currentIndex = getNextElementIndex(currendElementIndex, layout.length);
    let nextWallElement = layout[currentIndex];

    while (nextWallElement.type !== RoomLayoutType.Wall) {
        currentIndex = getNextElementIndex(currentIndex, layout.length);
        nextWallElement = layout[currentIndex];
    }

    // If the transition between the two wall elements got rotated by -90 instead of 90 degree
    // we need to invert our result:
    // Expect the next element of the previous wall is the transition between the both walls
    const transitionElement = layout[getNextElementIndex(currendElementIndex, layout.length)];
    let invertionFactor = 1;
    if (transitionElement.type === RoomLayoutType.Transition) {
        invertionFactor = transitionElement.angle < 0 ? -1 : 1;
    }

    // Add the wallDepthMM / 2 because we have an inroom corner
    const additionalLengthWithDecoratorMM = invertionFactor === -1 ? decoratorDepthMM : decoratorDepthMM + wallDepthMM;
    return nextWallElement.decorators ? (invertionFactor * additionalLengthWithDecoratorMM) / 2 : 0;
};

/**
 * This function calculates the inner side wall length depending on the baseLength
 * of the wall and the corresponding length of the showerBaseSize
 * @param element The wall element to calculate the length for
 * @param showerBaseSize The corresponding length of the the shower
 * @param roomSideLengthMM The corresponding length of the the room
 */
export const calculateWallLength = (
    element: RoomWall,
    showerSideLengthMM: number,
    roomSideAdjustmentLengthMM: number
): number => {
    let wallLength = element.baseLength;
    element.addShowerSide && (wallLength += showerSideLengthMM + roomSideAdjustmentLengthMM);

    return wallLength;
};

export const calculateShowerSideLengthGlobalMinimumForRotation = (direction: Vector3, blueprint: Blueprint): number => {
    let currentDirection = Vector3.Right();
    let globalMinimumLength = 0;
    blueprint.layout.map((element) => {
        if (element.type === BlueprintLayoutType.Transition) {
            currentDirection = applyRotationY(currentDirection, element.angle);
        }

        if (element.type !== BlueprintLayoutType.Transition && currentDirection.equals(direction)) {
            globalMinimumLength = globalMinimumLength + (element.minLength || 0);
        }
    });
    return globalMinimumLength;
};

/**
 * This function calculates the offset of the shower in the room for a given
 * wallBaseLengthMM and a given rotation. The shower offset in the room depents
 * on the rotation of the wall with the given wallBaseLengthMM.
 * IMPORTANT: This only works correct with roomplans that only contain 90 degree angles
 * @param wallRotation The rotation of the wall
 * @param showerOriginMM The shower origin as offset from (0/0/0)
 * @param wallBaseLengthMM The baseLength of the wall
 */
export const calculateShowerOriginOffsetForWallRotation = (
    wallRotation: Vector3,
    showerOriginMM: Vector3,
    wallBaseLengthMM: number,
    roomSizeAdjustment: Vector3
): number => {
    if (wallRotation.y > -Math.PI * 0.25 && wallRotation.y < Math.PI * 0.25) {
        // rot > -45° && rot < 45° => 0°
        return showerOriginMM.x;
    } else if (
        (wallRotation.y > 0.75 * Math.PI && wallRotation.y <= Math.PI) ||
        (wallRotation.y >= -Math.PI && wallRotation.y < 0.75 * -Math.PI)
    ) {
        // rot > 135° && rot <= 180° || rot >= -180° && rot < -135° => 180°
        return wallBaseLengthMM + roomSizeAdjustment.x - showerOriginMM.x;
    } else if (wallRotation.y <= -Math.PI * 0.25 && wallRotation.y >= -Math.PI * 0.75) {
        // rot <= -45° && rot >= -135° => 270° / -90°
        return wallBaseLengthMM + roomSizeAdjustment.z + showerOriginMM.z;
    } else {
        // 90 Grad
        return -showerOriginMM.z;
    }
};

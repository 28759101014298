import React, { forwardRef } from 'react';
import './ButtonComponet.scss';

export enum ButtonType {
    light = 'light',
    dominant = 'dominant',
    soft = 'soft'
}

interface ButtonComponentProps {
    width: number;
    onClick?: () => void;
    disabled?: boolean;
    type?: ButtonType;
    children: string;
}

const ButtonComponent = forwardRef<HTMLButtonElement, ButtonComponentProps>(
    ({ children, onClick, disabled, type = ButtonType.soft, width }: ButtonComponentProps, ref): JSX.Element => {
        return (
            <button
                ref={ref}
                className={`button-component ${type}`}
                onClick={onClick}
                disabled={disabled}
                style={{ width }}
            >
                {children}
            </button>
        );
    }
);

ButtonComponent.displayName = 'ButtonComponent';

export default ButtonComponent;

import { AbstractMesh, ArcRotateCamera, TransformNode, Vector2, Vector3 } from '@babylonjs/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CreatedInstance, useBabylonScene } from 'react-babylonjs';
import { useSelector } from 'react-redux';
import { DETAIL_LAYERMASK, HIDDEN_LAYERMASK } from '../../../helper/zoomAtHelper';
import { StoreState } from '../../../../state/ducks';

const zoomAtSmallSizeCompensateFactor = 250; //Factor that compensates smaller Objects using ZoomAt

const calculateCenterOfChild = (zoomAt: TransformNode[]): Vector3 => {
    const sum = zoomAt.map((t) => {
        const boundingVectors = t.getHierarchyBoundingVectors();
        const center = Vector3.Center(boundingVectors.max, boundingVectors.min);
        return center;
    });
    let center = Vector3.Zero();
    sum.forEach((l) => {
        center = new Vector3(Math.max(l.x, center.x), Math.max(l.y, center.y), Math.min(l.z, center.z));
    });
    return center;
};

const getOptimalDistance = (zoomAt: TransformNode[]): number => {
    let min = new Vector3(10000, 10000, 10000);
    let max = new Vector3(-10000, -10000, -10000);

    zoomAt.forEach((n) => {
        if (n.getChildMeshes().length === 0) {
            return;
        }
        const boundingVectors = n.getHierarchyBoundingVectors();
        n.computeWorldMatrix();
        const worldMatrix = n.getWorldMatrix();

        const maxVec = Vector3.TransformCoordinates(boundingVectors.max, worldMatrix);
        const minVec = Vector3.TransformCoordinates(boundingVectors.min, worldMatrix);

        max = Vector3.Maximize(max, maxVec);
        min = Vector3.Minimize(min, minVec);
    });

    const width = Math.abs(max.x - min.x);
    const height = Math.abs(max.y - min.y);
    const depth = Math.abs(max.z - min.z);
    const maxSizeValue = Math.max(width, height, depth);
    return maxSizeValue * 1.15 + 17.5;
};

const DetailCameraComponent = (): JSX.Element => {
    const scene = useBabylonScene();
    const camRef = useRef<CreatedInstance<ArcRotateCamera>>();
    const config = useSelector((state: StoreState) => state.configurator.configuration);
    const zoomAt = useSelector((state: StoreState) => state.system.zoomAt);
    const menuOpen = useSelector((state: StoreState) => state.system.menu.menuOpen);

    const [nodeAdded, setNodeAdded] = useState<string>();

    const nodeAddedCallback = useCallback(
        (newNode: TransformNode) => {
            if (zoomAt === newNode.name) {
                setNodeAdded(newNode.name);
            }
        },
        [zoomAt]
    );

    // Add Callbacks from Scene
    useEffect(() => {
        scene?.onNewTransformNodeAddedObservable.add(nodeAddedCallback);
        return () => {
            scene?.onNewTransformNodeAddedObservable.removeCallback(nodeAddedCallback);
        };
    }, [nodeAddedCallback]);

    useEffect(() => {
        const detailCamera = camRef.current?.hostInstance;

        if (detailCamera) {
            detailCamera.layerMask = HIDDEN_LAYERMASK;
        }
        if (scene && detailCamera && zoomAt) {
            scene.executeWhenReady(() => {
                const transforms = scene.getTransformNodesByID(zoomAt);
                const transformAmount = transforms.length;
                if (transformAmount > 0) {
                    let meshList: AbstractMesh[] = [];
                    transforms.forEach((transformNode) => {
                        const childMeshes = transformNode.getChildMeshes();
                        if (childMeshes.length === 0) {
                            return;
                        }
                        meshList = meshList.concat(childMeshes);
                    });

                    //Calculate extra offset to add Radius after using ZoomAt, to avoid that smaller items get same size on Screen as big ones
                    const maxRadius = getOptimalDistance(transforms);
                    const smallSizeCompensate = (1 / maxRadius) * zoomAtSmallSizeCompensateFactor;

                    detailCamera.target = calculateCenterOfChild(transforms);
                    detailCamera.lowerRadiusLimit = 0;
                    detailCamera.upperRadiusLimit = 100000;
                    detailCamera.zoomOnFactor = 1.5;
                    detailCamera.zoomOn(meshList);
                    detailCamera.radius = detailCamera.radius + smallSizeCompensate;

                    detailCamera.lowerRadiusLimit = detailCamera.radius;
                    detailCamera.upperRadiusLimit = detailCamera.radius;
                    detailCamera.maxZ = 1000;
                    detailCamera.alpha = -Math.PI * 0.25;
                    detailCamera.beta = Math.PI * 0.33;
                    detailCamera.layerMask = DETAIL_LAYERMASK;
                    detailCamera.targetScreenOffset = new Vector2(menuOpen ? detailCamera.radius / 5 : 0, 0);
                }
            });
        }
    }, [zoomAt, config, scene, menuOpen, nodeAdded]);

    return (
        <arcRotateCamera
            ref={camRef}
            name={'detailCamera_arcRotateCamera'}
            target={Vector3.Zero()}
            targetScreenOffset={Vector2.Zero()}
            alpha={0}
            beta={0}
            radius={0}
            lowerRadiusLimit={0}
            upperRadiusLimit={0}
            layerMask={HIDDEN_LAYERMASK}
        />
    );
};

export default DetailCameraComponent;

import '@babylonjs/inspector';
import React from 'react';
import { useBabylonScene } from 'react-babylonjs';

type DebugComponentProps = {
    show: boolean;
};

const DebugComponent = ({ show }: DebugComponentProps): JSX.Element => {
    const scene = useBabylonScene();

    if (scene) {
        if (show) {
            scene.debugLayer.show({ overlay: false });
        } else {
            scene.debugLayer.hide();
        }
    }

    return <></>;
};

export default DebugComponent;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { isShowerBaseId } from '../../../helper/showerBaseHelper';
import { applyRotationY, getRotationYFromDirection } from '../../../3d_helpers';
import { calculateRotationCornerFromRotation } from '../../../helper/commonHelper';
import {
    buildMountFilename,
    buildMountSituations,
    buildNormalHingeSituations,
    buildSlidingHingeSituation,
    calculateDecoratorRotation,
    MountSituation,
    NormalHingeSituation,
    SlidingHingeSituation
} from '../../../helper/transitionHelper';
import {
    BlueprintDecoratorType,
    BlueprintLayoutType,
    BlueprintTransition,
    BlueprintTransitionConnectorType
} from '../../../types/Blueprint';
import MountComponent from './MountComponent';
import NormalHingeComponent from './NormalHingeComponent';
import SlidingHingeComponent from './SlidingHingeComponent';

type TransitionComponentProps = {
    element: BlueprintTransition;
    beforePreviousTransition: BlueprintTransition;
    beforePreviousElementType: BlueprintLayoutType;
    previousElementType: BlueprintLayoutType;
    previousElementLengthMM: number;
    previousTransition: BlueprintTransition;
    nextTransititon: BlueprintTransition;
    afterNextTransition: BlueprintTransition;
    afterNextElementType: BlueprintLayoutType;
    nextElementLengthMM: number;
    nextElementType: BlueprintLayoutType;
    positionCM: Vector3;
    previousWallDirection: Vector3;
    bottomOffsetMM: number;
};

const TransitionComponent = ({
    element,
    beforePreviousElementType,
    previousElementType,
    previousElementLengthMM,
    beforePreviousTransition,
    previousTransition,
    nextTransititon,
    afterNextTransition,
    afterNextElementType,
    nextElementType,
    nextElementLengthMM,
    positionCM,
    previousWallDirection,
    bottomOffsetMM
}: TransitionComponentProps): JSX.Element => {
    const showerHeight = useSelector((state: StoreState) => state.architecture.showerBaseSize.y);
    const wallMountTechConfig = useSelector((state: StoreState) => state.architecture.techConfigs.wallMount);
    const glassMountTechConfig = useSelector((state: StoreState) => state.architecture.techConfigs.glassMount);
    const hingeTechConfig = useSelector((state: StoreState) => state.architecture.techConfigs.hinge);
    const isUnderBathtub = useSelector(
        (state: StoreState) => state.architecture.blueprint.bathtubConfig?.isUnderShower
    );
    const hasShowerBase = isShowerBaseId(useSelector((state: StoreState) => state.configurator.configuration.base.id));
    let mountSituations: MountSituation[];
    let mountFilename: string;
    let hingeSituations: NormalHingeSituation[] | SlidingHingeSituation[];

    const mountsOrHinges = element.decorators?.map((decorator, i) => {
        // Build mounts
        const mountTechConfig =
            decorator.connector === BlueprintTransitionConnectorType.GlassWall && wallMountTechConfig
                ? wallMountTechConfig
                : glassMountTechConfig
                ? glassMountTechConfig
                : undefined;

        if (decorator.type === BlueprintDecoratorType.Mount && mountTechConfig) {
            const previousElementIsWall = previousElementType === BlueprintLayoutType.Wall;
            const nextElementIsWall = nextElementType === BlueprintLayoutType.Wall;

            mountSituations = buildMountSituations(
                previousElementIsWall,
                nextElementIsWall,
                decorator.position ?? [],
                showerHeight,
                mountTechConfig,
                bottomOffsetMM,
                isUnderBathtub || false,
                hasShowerBase
            );

            mountFilename = buildMountFilename(previousElementIsWall, nextElementIsWall, mountTechConfig.id);
        }

        const decoratorRotation = calculateDecoratorRotation(
            previousWallDirection,
            element.angle,
            nextElementType === BlueprintLayoutType.Wall
        );

        const elementCorner = calculateRotationCornerFromRotation(
            getRotationYFromDirection(applyRotationY(previousWallDirection, element.angle))
        );

        // Build hinges
        if (decorator.type === BlueprintDecoratorType.Hinge && hingeTechConfig) {
            if (hingeTechConfig.isSlidingDoor) {
                hingeSituations = buildSlidingHingeSituation(
                    element.angle,
                    beforePreviousTransition,
                    beforePreviousElementType,
                    previousTransition,
                    previousElementLengthMM,
                    nextTransititon,
                    afterNextTransition,
                    afterNextElementType,
                    nextElementLengthMM,
                    hingeTechConfig,
                    showerHeight,
                    bottomOffsetMM,
                    isUnderBathtub || false,
                    hasShowerBase
                );
            } else {
                hingeSituations = buildNormalHingeSituations(
                    nextElementType,
                    decorator.position ?? [],
                    showerHeight,
                    hingeTechConfig,
                    element.angle,
                    decorator.connector as BlueprintTransitionConnectorType,
                    bottomOffsetMM,
                    elementCorner
                );
            }
        }

        return (
            <transformNode
                name="transition_tf"
                position={positionCM}
                key={`transition_tf_${decorator.type}_${decorator.position}_${i}`}
            >
                {mountSituations?.map((mountSituation: MountSituation, index: number) => (
                    <MountComponent
                        sceneFilename={mountFilename}
                        position={mountSituation.position}
                        rotation={decoratorRotation}
                        scaling={new Vector3(mountSituation.scaleX, mountSituation.scaleY, 1)}
                        detailViewScaleY={mountSituation.detailViewScaleY}
                        corner={elementCorner}
                        key={`mount_${i}_${index}_${mountSituation.position}`}
                        usesWholeSide={mountTechConfig?.usesWholeSide}
                    />
                ))}
                {hingeTechConfig &&
                    (hingeTechConfig.isSlidingDoor
                        ? (hingeSituations as SlidingHingeSituation[])?.map(
                              (hingeSituation: SlidingHingeSituation, index: number) => (
                                  <SlidingHingeComponent
                                      hingeId={hingeTechConfig?.id}
                                      position={hingeSituation.position}
                                      rotation={decoratorRotation}
                                      xScaling={hingeSituation.scaleX}
                                      baseLengthMM={hingeTechConfig.lengthMM}
                                      coverStart={hingeSituation.startNeedsCover}
                                      startTouchesWall={hingeSituation.startTouchesWall}
                                      coverEnd={hingeSituation.endNeedsCover}
                                      endTouchesWall={hingeSituation.endTouchesWall}
                                      previousHingeHasCorner={hingeSituation.previousHingeHasCorner}
                                      endHasCorner={hingeSituation.endHasCorner}
                                      key={`hinge_${i}_${index}`}
                                  />
                              )
                          )
                        : (hingeSituations as NormalHingeSituation[])?.map(
                              (hingeSituation: NormalHingeSituation, index: number) => (
                                  <NormalHingeComponent
                                      hingeId={hingeTechConfig?.id}
                                      connector={decorator.connector as BlueprintTransitionConnectorType}
                                      position={hingeSituation.position}
                                      rotation={decoratorRotation}
                                      scaling={new Vector3(hingeSituation.scaleX, hingeSituation.scaleY, 1)}
                                      rotationPartOne={hingeSituation.rotationPartOne}
                                      rotationPartTwo={hingeSituation.rotationPartTwo}
                                      corner={elementCorner}
                                      angle={element.angle}
                                      key={`hinge_${i}_${index}_${hingeSituation.position}`}
                                  />
                              )
                          ))}
            </transformNode>
        );
    });

    return <>{mountsOrHinges}</>;
};

export default TransitionComponent;

import { Vector2, Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { defaultShowerHeightMM } from '../../../../state/ducks/architecture/reducers';
import { calcElementBottomOffsetMM } from '../../../helper/blueprintHelper';
import { isShowerBaseId, showerBaseHeightMM } from '../../../helper/showerBaseHelper';
import { TAGLIST } from '../../../helper/tagHelper';
import ResponsiveBoxComponent from '../ResponsiveBoxComponent/ResponsiveBoxComponent';
import ScaledTextureComponent from '../ScaledTextureComponent/ScaledTextureComponent';
import ShowerDrainComponent from './ShowerDrainComponent';
import ShowerValveComponent from './ShowerValveComponent';

type ShowerTilesComponentProps = {
    wallOriginCM: Vector3;
    depthMM: number;
    wallWidthMM: number;
    showerSideLengthMM: number;
    showerHeightOverlapMM: number;
    showerSideOverlapMM: number;
    texture: string;
    textureBaseSize: Vector2;
    textureScaling?: Vector2;
    offsetXMM: number;
    hasShowervalve?: boolean;
};

const ShowerTilesComponent = ({
    wallOriginCM,
    depthMM,
    wallWidthMM,
    showerSideLengthMM,
    showerHeightOverlapMM,
    showerSideOverlapMM,
    texture,
    textureBaseSize,
    textureScaling = Vector2.One(),
    offsetXMM,
    hasShowervalve
}: ShowerTilesComponentProps): JSX.Element => {
    const blueprint = useSelector((state: StoreState) => state.architecture.blueprint);
    const seriesId = useSelector((state: StoreState) => state.configurator.configuration.series.id);
    const showerSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const baseID = useSelector((state: StoreState) => state.configurator.configuration.base.id);
    const hasShowerBase = isShowerBaseId(baseID);
    const maxShowerBottomOffsetMM = blueprint.bathtubConfig?.isUnderShower
        ? blueprint.layout.reduce((currentMaxShowerBottomOffsetMM, _, index) => {
              const bottomOffsetForElement = calcElementBottomOffsetMM(
                  blueprint.layout,
                  index,
                  showerSize.z,
                  seriesId,
                  hasShowerBase,
                  blueprint.bathtubConfig
              );
              return Math.max(currentMaxShowerBottomOffsetMM, bottomOffsetForElement);
          }, 0)
        : 0;

    const heightMM = Math.max(defaultShowerHeightMM, showerSize.y + showerHeightOverlapMM + maxShowerBottomOffsetMM);

    const preDecoratorAdditionalWidthMM = Math.max(0 - offsetXMM, 0);
    const postDecoratorAdditionalWidthMM = Math.max(
        // Wall most right point - showerCornerRightOnWall - OffsetOfNextWallInDepth
        -(wallWidthMM - offsetXMM - showerSideLengthMM - 0),
        0
    );
    const widthMM =
        showerSideLengthMM + 2 * showerSideOverlapMM + preDecoratorAdditionalWidthMM + postDecoratorAdditionalWidthMM;
    const positionCM = new Vector3(
        (widthMM / 2 + offsetXMM - preDecoratorAdditionalWidthMM - showerSideOverlapMM) / 10,
        heightMM / 20,
        0
    );

    const hasShowerdrain = hasShowervalve;

    let showerDrainOffset = 0;
    if (hasShowerBase) {
        showerDrainOffset = -showerBaseHeightMM / (2 * 10);
    }
    return (
        <transformNode name="tf_shower_tiles" position={wallOriginCM}>
            <ResponsiveBoxComponent
                name={'shower_tiles'}
                checkCollisions
                width={widthMM / 10}
                height={heightMM / 10}
                depth={depthMM / 10}
                position={positionCM}
                tagList={[TAGLIST.BE_REFELECTED]}
            >
                <standardMaterial name="shower_tile_material">
                    <ScaledTextureComponent
                        assignTo="diffuseTexture"
                        sizeCM={new Vector2(widthMM / 10, heightMM / 10)}
                        texture={texture}
                        baseSize={textureBaseSize}
                        scaling={textureScaling}
                    />
                </standardMaterial>
                {hasShowervalve && <ShowerValveComponent wallDepthMM={depthMM} floorPositionY={-positionCM.y} />}
                {hasShowerdrain && (
                    <ShowerDrainComponent
                        wallDepthMM={depthMM}
                        showerWidthMM={showerSideLengthMM}
                        distanceToFloor={heightMM / 20 + showerDrainOffset}
                    />
                )}
            </ResponsiveBoxComponent>
        </transformNode>
    );
};

export default ShowerTilesComponent;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import useZoomAt from '../../../../hooks/useZoomAt';
import { ConfigOptionEnum } from '../../../../state/ducks/system/interfaces';
import { RotationCorner } from '../../../helper/commonHelper';
import { TAGLIST } from '../../../helper/tagHelper';
import { DETAIL_LAYERMASK, TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import { BlueprintTransitionConnectorType } from '../../../types/Blueprint';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

const buildNormalHingeFilename = (hingeId: string, connector: BlueprintTransitionConnectorType): string[] => {
    const attachment = connector === BlueprintTransitionConnectorType.GlassWall ? 'wall' : 'glass';

    return [`hinge_door_${hingeId}.glb`, `hinge_${attachment}_${hingeId}.glb`];
};

type NormalHingeComponentProps = {
    hingeId: string;
    connector: BlueprintTransitionConnectorType;
    position: Vector3;
    scaling: Vector3;
    rotation: Vector3;
    rotationPartOne: Vector3;
    rotationPartTwo: Vector3;
    corner: RotationCorner;
    angle: number;
};

const NormalHingeComponent = ({
    hingeId,
    connector,
    position,
    scaling,
    rotation,
    rotationPartOne,
    rotationPartTwo,
    corner,
    angle
}: NormalHingeComponentProps): JSX.Element => {
    const positionAsString = position.y > 95 ? 'top' : 'bottom';
    const tfName = `normalHinge_tf_pos=${positionAsString}_rot=${corner.toString()}${angle}`;
    const [zoomAt, setZoomAt] = useZoomAt();
    const rootUrl = 'assets/3d/hinges/';

    const [sceneFilenamePartOne, sceneFilenamePartTwo] = buildNormalHingeFilename(hingeId, connector);

    return (
        <transformNode name={tfName} position={position} scaling={scaling} rotation={rotation}>
            <transformNode name={'normalHinge_tf_part1_rotation'} rotation={rotationPartOne}>
                <ThreeDComponent
                    name={`normalHinge_threeD_y=${position.y}_part1`}
                    rootUrl={rootUrl}
                    sceneFilename={sceneFilenamePartOne}
                    tagList={[TAGLIST.REFLECTION]}
                    onClick={zoomAt !== tfName ? () => setZoomAt(tfName, ConfigOptionEnum.Series) : undefined}
                    layerMask={zoomAt === tfName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                />
            </transformNode>
            <transformNode name={'normalHinge_tf_part2_rotation'} rotation={rotationPartTwo}>
                <ThreeDComponent
                    name={`normalHinge_threeD_y=${position.y}_part2`}
                    rootUrl={rootUrl}
                    sceneFilename={sceneFilenamePartTwo}
                    tagList={[TAGLIST.REFLECTION]}
                    onClick={zoomAt !== tfName ? () => setZoomAt(tfName, ConfigOptionEnum.Series) : undefined}
                    layerMask={zoomAt === tfName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                />
            </transformNode>
        </transformNode>
    );
};

export default NormalHingeComponent;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import { TAGLIST } from '../../../helper/tagHelper';
import { TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type SealComponentProps = {
    scaling: Vector3;
    position: Vector3;
};

const SealComponent = ({ position, scaling }: SealComponentProps): JSX.Element => {
    return (
        <transformNode name={'seal_tf'} position={position} scaling={scaling}>
            <ThreeDComponent
                name={`seal_threeD`}
                rootUrl="assets/3d/seals/"
                sceneFilename={`seal.glb`}
                layerMask={TOTAL_LAYERMASK}
                tagList={[TAGLIST.REFLECTION]}
            />
        </transformNode>
    );
};

export default SealComponent;

import { Vector3 } from '@babylonjs/core';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { architectureActions } from '../../../../state/ducks/architecture';
import ShowerBaseSizeInputComponent from './ShowerBaseSizeInput/ShowerBaseSizeInputComponent';

const ShowerBaseSizeComponent = (): JSX.Element => {
    const showerBaseSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const showerBaseSizeConstraints = useSelector((state: StoreState) => state.architecture.showerBaseSizeConstraints);
    const defaultShowerSizes = useSelector((state: StoreState) => state.architecture.defaultShowerSizes);
    const dispatch = useDispatch();
    const updateShowerBaseSize = (size: Vector3) => dispatch(architectureActions.updateShowerBaseSize(size));
    const updateShowerElementSize = (index: number, lengthMM: number) =>
        dispatch(architectureActions.updateLengthMM(index, lengthMM));

    const onChangeWidth = useCallback(
        (size: number) => {
            const newShowerBaseSize = new Vector3(size, showerBaseSize.y, showerBaseSize.z);
            updateShowerBaseSize(newShowerBaseSize);
        },
        [showerBaseSize]
    );
    const onChangeHeight = useCallback(
        (size: number) => {
            const newShowerBaseSize = new Vector3(showerBaseSize.x, size, showerBaseSize.z);
            updateShowerBaseSize(newShowerBaseSize);
        },
        [showerBaseSize]
    );
    const onChangeDepth = useCallback(
        (size: number) => {
            const newShowerBaseSize = new Vector3(showerBaseSize.x, showerBaseSize.y, size);
            updateShowerBaseSize(newShowerBaseSize);
        },
        [showerBaseSize]
    );

    const onResetWidth = useCallback(() => {
        onChangeWidth(defaultShowerSizes.baseSize.x);
        defaultShowerSizes.elementSizes.width.forEach((element) => {
            updateShowerElementSize(element.index, element.width);
        });
    }, [defaultShowerSizes.baseSize.x, onChangeWidth]);

    const onResetHeight = useCallback(() => {
        onChangeHeight(defaultShowerSizes.baseSize.y);
    }, [defaultShowerSizes.baseSize.y, onChangeHeight]);

    const onResetDepth = useCallback(() => {
        onChangeDepth(defaultShowerSizes.baseSize.z);
        defaultShowerSizes.elementSizes.depth.forEach((element) => {
            updateShowerElementSize(element.index, element.width);
        });
    }, [defaultShowerSizes.baseSize.z, onChangeDepth]);

    return (
        <div className="shower-base-size-component">
            <ShowerBaseSizeInputComponent
                label="Höhe"
                minValue={showerBaseSizeConstraints.min.y}
                maxValue={showerBaseSizeConstraints.max.y}
                onChange={onChangeHeight}
                onReset={onResetHeight}
                value={showerBaseSize?.y}
                key={`shower_height_input_${showerBaseSize?.y}`}
            />
            <ShowerBaseSizeInputComponent
                label="Tiefe"
                minValue={showerBaseSizeConstraints.min.z}
                maxValue={showerBaseSizeConstraints.max.z}
                onChange={onChangeDepth}
                onReset={onResetDepth}
                value={showerBaseSize?.z}
                key={`shower_depth_input_${showerBaseSize?.z}`}
            />
            <ShowerBaseSizeInputComponent
                label="Breite"
                minValue={showerBaseSizeConstraints.min.x}
                maxValue={showerBaseSizeConstraints.max.x}
                onChange={onChangeWidth}
                onReset={onResetWidth}
                value={showerBaseSize?.x}
                key={`shower_width_input_${showerBaseSize?.x}`}
            />
        </div>
    );
};

export default ShowerBaseSizeComponent;

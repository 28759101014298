import React from 'react';

interface DropzoneIconProps {
    icon: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const DropzoneIcon = ({ icon, onClick }: DropzoneIconProps): JSX.Element => {
    return <div onClick={onClick} className={`sp-icon ${icon}`}></div>;
};

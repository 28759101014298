import React from 'react';

interface DropzoneLoadingProps {
    actual: number;
}

export const DropzoneLoading = ({ actual }: DropzoneLoadingProps): JSX.Element => {
    const percent = (actual / 100) * 100;
    const widthCss = {
        width: percent + '%'
    };
    return (
        <div className={`sp-loading-wrapper`}>
            <div className={`sp-loading`} style={widthCss}></div>
        </div>
    );
};

import { Vector3 } from '@babylonjs/core';
import { BeamTechConfig } from '../../../ng/types/BeamTechConfig';
import { Blueprint, BlueprintBaseSizeConstraints } from '../../../ng/types/Blueprint';
import { HingeTechConfig } from '../../../ng/types/HingeTechConfig';
import { KnobTechConfig } from '../../../ng/types/KnobTechConfig';
import { MountTechConfig } from '../../../ng/types/MountTechConfig';
import { Roomplan } from '../../../ng/types/Roomplan';
import {
    BeamTechConfigLoadedErrorAction,
    BeamTechConfigLoadedSuccessAction,
    BlueprintLoadedErrorAction,
    BlueprintLoadedSuccessAction,
    BlueprintUpdatedAction,
    GlassMountTechConfigLoadedErrorAction,
    GlassMountTechConfigLoadedSuccessAction,
    HingeTechConfigLoadedErrorAction,
    HingeTechConfigLoadedSuccessAction,
    KnobTechConfigLoadedErrorAction,
    KnobTechConfigLoadedSuccessAction,
    LoadBeamTechConfigAction,
    LoadBlueprintAction,
    LoadGlassMountTechConfigAction,
    LoadHingeTechConfigAction,
    LoadKnobTechConfigAction,
    LoadPossibleBeamTechConfigsAction,
    LoadPossibleBeamTechConfigsErrorAction,
    LoadPossibleBeamTechConfigsSuccessAction,
    LoadRoomplanAction,
    LoadWallMountTechConfigAction,
    ResetBeamIntersectionPointAction,
    RoomplanLoadedErrorAction,
    RoomplanLoadedSuccessAction,
    SelectArchitectureAction,
    UpdateBeamIntersectionPointXAction,
    UpdateBeamIntersectionPointZAction,
    UpdateBlueprintRatioAction,
    UpdateDefaultShowerSizeAction,
    UpdateLengthMMAction,
    UpdateRoomSizeAction,
    UpdateRoomSizeAdjustmentAction,
    UpdateShowerBaseSizeAction,
    UpdateShowerBaseSizeConstraintsAction,
    UpdateShowerHeightAction,
    WallMountTechConfigLoadedErrorAction,
    WallMountTechConfigLoadedSuccessAction
} from './interfaces';
import { DefaultShowerSizesState } from './reducers';
import {
    BEAM_TECH_CONFIG_LOADED_ERROR,
    BEAM_TECH_CONFIG_LOADED_SUCCESS,
    BLUEPRINT_LOADED_ERROR,
    BLUEPRINT_LOADED_SUCCESS,
    BLUEPRINT_UPDATED,
    GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR,
    GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS,
    HINGE_TECH_CONFIG_LOADED_ERROR,
    HINGE_TECH_CONFIG_LOADED_SUCCESS,
    KNOB_TECH_CONFIG_LOADED_ERROR,
    KNOB_TECH_CONFIG_LOADED_SUCCESS,
    LOAD_BEAM_TECH_CONFIG,
    LOAD_BLUEPRINT,
    LOAD_GLASS_MOUNT_TECH_CONFIG,
    LOAD_HINGE_TECH_CONFIG,
    LOAD_KNOB_TECH_CONFIG,
    LOAD_POSSIBLE_BEAM_TECH_CONFIGS,
    LOAD_POSSIBLE_BEAM_TECH_CONFIGS_ERROR,
    LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS,
    LOAD_ROOMPLAN,
    LOAD_WALL_MOUNT_TECH_CONFIG,
    RESET_BEAM_INTERSECTION_POINT,
    ROOMPLAN_LOADED_ERROR,
    ROOMPLAN_LOADED_SUCCESS,
    SELECT_ARCHITECTURE,
    UPDATE_BEAM_INTERSECTION_POINT_X,
    UPDATE_BEAM_INTERSECTION_POINT_Z,
    UPDATE_BLUEPRINT_RATIO,
    UPDATE_DEFAULT_SHOWER_SIZE,
    UPDATE_LENGTH_MM,
    UPDATE_ROOM_SIZE,
    UPDATE_ROOM_SIZE_ADJUSTMENT,
    UPDATE_SHOWER_BASESIZE,
    UPDATE_SHOWER_BASESIZE_CONSTRAINTS,
    UPDATE_SHOWER_HEIGHT,
    WALL_MOUNT_TECH_CONFIG_LOADED_ERROR,
    WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS
} from './types';

export const selectArchitecture = (blueprintId: string | undefined, roomplanId: string): SelectArchitectureAction => ({
    type: SELECT_ARCHITECTURE,
    payload: { blueprintId, roomplanId }
});

// BLUEPRINT
export const loadBlueprint = (id: string): LoadBlueprintAction => ({
    type: LOAD_BLUEPRINT,
    payload: { id }
});

export const blueprintLoadedSucess = (blueprint: Blueprint): BlueprintLoadedSuccessAction => ({
    type: BLUEPRINT_LOADED_SUCCESS,
    payload: { blueprint }
});

export const blueprintLoadedError = (error: string): BlueprintLoadedErrorAction => ({
    type: BLUEPRINT_LOADED_ERROR,
    payload: { error }
});

export const blueprintUpdated = (blueprint: Blueprint): BlueprintUpdatedAction => ({
    type: BLUEPRINT_UPDATED,
    payload: { blueprint }
});

// ROOMPLAN
export const loadRoomplan = (id: string): LoadRoomplanAction => ({
    type: LOAD_ROOMPLAN,
    payload: { id }
});

export const roomplanLoadedSucess = (roomplan: Roomplan): RoomplanLoadedSuccessAction => ({
    type: ROOMPLAN_LOADED_SUCCESS,
    payload: { roomplan }
});

export const roomplanLoadedError = (error: string): RoomplanLoadedErrorAction => ({
    type: ROOMPLAN_LOADED_ERROR,
    payload: { error }
});

export const updateBlueprintRatio = (baseSize: Vector3, blueprint: Blueprint): UpdateBlueprintRatioAction => ({
    type: UPDATE_BLUEPRINT_RATIO,
    payload: { baseSize, blueprint }
});

export const updateShowerBaseSize = (baseSize: Vector3): UpdateShowerBaseSizeAction => {
    return {
        type: UPDATE_SHOWER_BASESIZE,
        payload: { baseSize }
    };
};

export const updateShowerHeight = (height: number): UpdateShowerHeightAction => {
    return {
        type: UPDATE_SHOWER_HEIGHT,
        payload: { height }
    };
};

export const updateShowerBaseSizeConstraints = (
    constraints: BlueprintBaseSizeConstraints
): UpdateShowerBaseSizeConstraintsAction => {
    return {
        type: UPDATE_SHOWER_BASESIZE_CONSTRAINTS,
        payload: { constraints }
    };
};

export const updateDefaultShowerSize = (defaultSize: DefaultShowerSizesState): UpdateDefaultShowerSizeAction => {
    return {
        type: UPDATE_DEFAULT_SHOWER_SIZE,
        payload: { defaultSize }
    };
};

export const updateRoomSize = (size: Vector3, position: Vector3): UpdateRoomSizeAction => {
    return {
        type: UPDATE_ROOM_SIZE,
        payload: { size, position }
    };
};

export const updateRoomSizeAdjustment = (adjustment: Vector3): UpdateRoomSizeAdjustmentAction => {
    return {
        type: UPDATE_ROOM_SIZE_ADJUSTMENT,
        payload: { adjustment }
    };
};

export const updateBeamIntersectionPointX = (x: number): UpdateBeamIntersectionPointXAction => {
    return {
        type: UPDATE_BEAM_INTERSECTION_POINT_X,
        payload: { x }
    };
};

export const updateBeamIntersectionPointZ = (z: number): UpdateBeamIntersectionPointZAction => {
    return {
        type: UPDATE_BEAM_INTERSECTION_POINT_Z,
        payload: { z }
    };
};

export const resetBeamIntersectionPoint = (baseSize: Vector3): ResetBeamIntersectionPointAction => {
    return {
        type: RESET_BEAM_INTERSECTION_POINT,
        payload: { baseSize }
    };
};

export const updateLengthMM = (elementIndex: number, elementLengthMM: number): UpdateLengthMMAction => ({
    type: UPDATE_LENGTH_MM,
    payload: {
        elementIndex,
        elementLengthMM
    }
});

// KNOBS
export const loadKnob = (id: string): LoadKnobTechConfigAction => ({
    type: LOAD_KNOB_TECH_CONFIG,
    payload: { id }
});

export const knobTechConfigLoadedSucess = (knobTechConfig: KnobTechConfig): KnobTechConfigLoadedSuccessAction => ({
    type: KNOB_TECH_CONFIG_LOADED_SUCCESS,
    payload: { knobTechConfig }
});

export const knobTechConfigLoadedError = (error: string): KnobTechConfigLoadedErrorAction => ({
    type: KNOB_TECH_CONFIG_LOADED_ERROR,
    payload: { error }
});

// WALL_MOUNTS
export const loadWallMountTechConfig = (id: string): LoadWallMountTechConfigAction => ({
    type: LOAD_WALL_MOUNT_TECH_CONFIG,
    payload: { id }
});

export const wallMountTechConfigLoadedSucess = (
    wallMountTechConfig: MountTechConfig
): WallMountTechConfigLoadedSuccessAction => ({
    type: WALL_MOUNT_TECH_CONFIG_LOADED_SUCCESS,
    payload: { wallMountTechConfig }
});

export const wallMountTechConfigLoadedError = (error: string): WallMountTechConfigLoadedErrorAction => ({
    type: WALL_MOUNT_TECH_CONFIG_LOADED_ERROR,
    payload: { error }
});

// GLASS_MOUNTS
export const loadGlassMountTechConfig = (id: string): LoadGlassMountTechConfigAction => ({
    type: LOAD_GLASS_MOUNT_TECH_CONFIG,
    payload: { id }
});

export const glassMountTechConfigLoadedSucess = (
    wallMount: MountTechConfig
): GlassMountTechConfigLoadedSuccessAction => ({
    type: GLASS_MOUNT_TECH_CONFIG_LOADED_SUCCESS,
    payload: { glassMountTechConfig: wallMount }
});

export const glassMountTechConfigLoadedError = (error: string): GlassMountTechConfigLoadedErrorAction => ({
    type: GLASS_MOUNT_TECH_CONFIG_LOADED_ERROR,
    payload: { error }
});

// HINGES
export const loadHingeTechConfig = (id: string): LoadHingeTechConfigAction => ({
    type: LOAD_HINGE_TECH_CONFIG,
    payload: { id }
});

export const hingeTechConfigLoadedSucess = (hinge: HingeTechConfig): HingeTechConfigLoadedSuccessAction => ({
    type: HINGE_TECH_CONFIG_LOADED_SUCCESS,
    payload: { hingeTechConfig: hinge }
});

export const hingeTechConfigLoadedError = (error: string): HingeTechConfigLoadedErrorAction => ({
    type: HINGE_TECH_CONFIG_LOADED_ERROR,
    payload: { error }
});

// BEAMS
export const loadTechConfigBeam = (id: string): LoadBeamTechConfigAction => ({
    type: LOAD_BEAM_TECH_CONFIG,
    payload: { id }
});

export const beamLoadedSucess = (beamTechConfig: BeamTechConfig | null): BeamTechConfigLoadedSuccessAction => ({
    type: BEAM_TECH_CONFIG_LOADED_SUCCESS,
    payload: { beamTechConfig: beamTechConfig }
});

export const beamLoadedError = (error: string): BeamTechConfigLoadedErrorAction => ({
    type: BEAM_TECH_CONFIG_LOADED_ERROR,
    payload: { error }
});

export const loadPossibleBeams = (ids: string[]): LoadPossibleBeamTechConfigsAction => ({
    type: LOAD_POSSIBLE_BEAM_TECH_CONFIGS,
    payload: { ids }
});

export const possibleBeamsLoadedSuccess = (beams: BeamTechConfig[]): LoadPossibleBeamTechConfigsSuccessAction => ({
    type: LOAD_POSSIBLE_BEAM_TECH_CONFIGS_SUCCESS,
    payload: { beamTechConfigs: beams }
});

export const possibleBeamsLoadedError = (error: string): LoadPossibleBeamTechConfigsErrorAction => ({
    type: LOAD_POSSIBLE_BEAM_TECH_CONFIGS_ERROR,
    payload: { error }
});

import React from 'react';
import { AmbientMenuItem, AmbientOptions } from './AmbientMenuComponent.types';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../ng/helper/analyticsHelper';

export const createTab = (
    label: string,
    index: number,
    selectedTab: number,
    setSelectedTab: React.Dispatch<React.SetStateAction<number>>
): JSX.Element => {
    return (
        <div
            onClick={(): void => setSelectedTab(index)}
            className={`ambient-menu-component-tab-menu-item ${index === selectedTab && 'selected'}`}
            key={label}
        >
            {label}
        </div>
    );
};

export const createItem = (
    menuItem: AmbientMenuItem,
    onClickCallback: (fileName: string, ambientType: AmbientOptions) => void,
    selected: boolean
): JSX.Element => {
    return (
        <div
            className={`ambient-menu-component-content-item ${selected && 'selected'}`}
            key={`${menuItem.label}_${selected}`}
            onClick={() => {
                logAnalyticsEvent(`configurator_select_ambient`, {
                    configurator_ambient_type: menuItem.type,
                    configurator_ambient_label: menuItem.label
                });
                onClickCallback(menuItem.fileName, menuItem.type);
            }}
        >
            {selected && <div className={`ambient-menu-component-content-item-selected`}></div>}
            <img
                src={menuItem.thumbnail}
                alt={menuItem.label}
                className="ambient-menu-component-content-item-image"
                draggable="false"
            />
        </div>
    );
};

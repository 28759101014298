import { Color3, Color4, Vector3 } from '@babylonjs/core';
import FontFaceObserver from 'fontfaceobserver';
import React, { useEffect, useMemo, useState } from 'react';
import { Scene, useBabylonScene } from 'react-babylonjs';
import { Provider, useSelector } from 'react-redux';
import useDebugMode from '../../../../../hooks/useDebugMode';
import { StoreState } from '../../../../../state/ducks';
import store from '../../../../../state/store';
import BlueprintLoaderComponent from '../../../../BlueprintLoaderComponent';
import RoomplanLoaderComponent from '../../../../RoomplanLoaderComponent';
import DebugComponent from '../../DebugComponent/DebugComponent';
import MarkersComponent from '../../GUIComponents/Marker/MarkersComponent';
import ReflectionProbeComponent from '../../ReflectionProbeComponent';
import DetailCameraComponent from '../DetailCameraComponent';
import ScreenShotCameraComponent from '../ScreenShotCameraComponent';
import ShowerCameraComponent from '../ShowerCameraComponent';
import ShowerCollisionBoxComponent from '../ShowerCollisionBoxComponent';
import { SceneIsReadyComponent } from './SceneIsReadyComponent';

interface SceneProps {
    setIsReady: (isReady: boolean) => void;
}

const SceneComponent = ({ setIsReady }: SceneProps): JSX.Element => {
    const scene = useBabylonScene();
    const [debugModeActive] = useDebugMode();
    const [fontLoaded, setFontLoaded] = useState(false);
    const backgroundColor = new Color4(0.506, 0.592, 0.576, 1); //Background Color used e.g in Detail view (Hex Code #768688)
    const blueprint = useSelector((state: StoreState) => state.architecture.blueprint);
    const zoomAt = useSelector((state: StoreState) => state.system.zoomAt);
    const [ready, setReady] = useState(false);
    const [sceneWorkDone, setSceneWorkDone] = useState(false);

    useEffect(() => {
        setIsReady(ready);
        scene?.freezeMaterials();
    }, [ready]);

    useEffect(() => {
        setReady(sceneWorkDone);
    }, [sceneWorkDone]);

    useEffect(() => {
        const fontLight = new FontFaceObserver('Source Sans Pro', { weight: 300 });
        const fontNormal = new FontFaceObserver('Source Sans Pro', { weight: 400 });
        const fontSemiBold = new FontFaceObserver('Source Sans Pro', { weight: 600 });
        const fontBold = new FontFaceObserver('Source Sans Pro', { weight: 700 });

        Promise.all([fontLight.load(), fontNormal.load(), fontSemiBold.load(), fontBold.load()]).then(() => {
            setFontLoaded(true);
        });
    });

    const showerOriginMM = useMemo(
        () => (blueprint ? new Vector3(blueprint.origin.x, blueprint.origin.y, blueprint.origin.z) : Vector3.Zero()),
        [blueprint]
    );

    const showerBaseSizeMM = useSelector((state: StoreState) => state.architecture.showerBaseSize);

    const markersVisible = useSelector((state: StoreState) => state.system.markerVisibility);

    const userConfig = useSelector((state: StoreState) => state.system.userConfig);

    const markersHighlighted = useSelector((state: StoreState) => state.system.markersHighlighted);
    const guiBackground = markersHighlighted ? new Color4(0, 0, 0, 0.3) : new Color4(0, 0, 0, 0);

    return fontLoaded ? (
        <Scene collisionsEnabled clearColor={backgroundColor}>
            <Provider store={store}>
                <SceneIsReadyComponent setIsReady={setSceneWorkDone} />

                <adtFullscreenUi name="adtFullscreenUI">
                    {zoomAt ? (
                        <>
                            <DetailCameraComponent />
                        </>
                    ) : (
                        <>
                            <stackPanel
                                zIndex={-10}
                                height="100%"
                                width="100%"
                                background={`${guiBackground.toHexString()}`}
                            ></stackPanel>
                            {ready && markersVisible && !userConfig && <MarkersComponent />}
                            <ShowerCameraComponent />
                            <ScreenShotCameraComponent target={showerOriginMM.divide(new Vector3(10, 10, 10))} />
                        </>
                    )}
                </adtFullscreenUi>
                <hemisphericLight
                    name="lightUp"
                    intensity={0.87}
                    direction={new Vector3(4, 6, -1)}
                    diffuse={new Color3(1, 0.973, 0.961)}
                />
                <hemisphericLight
                    name="lightDown"
                    intensity={0.38}
                    direction={new Vector3(0, 1, 0)}
                    diffuse={new Color3(1, 1, 1)}
                />
                <hemisphericLight name="lightLeft" intensity={0.08} direction={new Vector3(0.5, 0.1, 0)} />
                <hemisphericLight
                    name="lightRight"
                    intensity={0.055}
                    direction={new Vector3(-4, 0, -10)}
                    diffuse={new Color3(1, 1, 1)}
                />
                <RoomplanLoaderComponent showerOriginMM={showerOriginMM} />
                <ShowerCollisionBoxComponent showerOriginMM={showerOriginMM} showerBaseSizeMM={showerBaseSizeMM} />
                <BlueprintLoaderComponent blueprint={blueprint} />
                <DebugComponent show={debugModeActive} />
                {ready && <ReflectionProbeComponent position={new Vector3(100, 100, -200)} />}
            </Provider>
        </Scene>
    ) : (
        <></>
    );
};

export default SceneComponent;

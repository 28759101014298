import React from 'react';
import { openModalPrivacy } from '../../state/ducks/system/actions';
import { useDispatch } from 'react-redux';
import './Legal.component.scss';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../ng/helper/analyticsHelper';

const Legal = (): JSX.Element => {
    const dispatch = useDispatch();

    return (
        <div className="legal">
            <a
                href="#"
                title="DSGVO"
                rel="noopener noreferrer"
                onClick={() => {
                    logAnalyticsEvent('configurator_open_modal_privacy');
                    dispatch(openModalPrivacy());
                }}
            >
                DSGVO
            </a>
        </div>
    );
};

export default Legal;

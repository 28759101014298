import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { BlueprintDecorator } from '../../../types/Blueprint';
import { PositionType } from '../../../types/Common';
import KnobComponent from '../Accessories/KnobComponent';
import SealComponent from '../Accessories/SealComponent';
import ResponsiveBoxComponent from '../ResponsiveBoxComponent/ResponsiveBoxComponent';
import BlueprintElementDecoratorComponent from './BlueprintElementDecoratorComponent';
import GlassMaterialComponent from './GlassMaterialComponent';

const getKnobOffsetFromDoorFrameForSeries = (seriesId: string): number => {
    if (seriesId === 'TA') {
        return 6;
    } else {
        return 18;
    }
};

interface DoorComponentProps {
    widthMM: number;
    depthMM?: number;
    position: Vector3;
    rotation?: Vector3;
    hingePosition: PositionType;
    sliderLabel?: string;
    positionOffset: Vector3;
    decorators?: BlueprintDecorator[];
    bottomOffsetMM: number;
    index: number;
}

const DoorComponent = ({
    widthMM,
    depthMM = 8,
    position,
    rotation = Vector3.Zero(),
    hingePosition,
    sliderLabel,
    positionOffset = Vector3.Zero(),
    decorators,
    bottomOffsetMM,
    index
}: DoorComponentProps): JSX.Element => {
    const showerBaseSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const seriesId = useSelector((state: StoreState) => state.configurator.configuration.series.id);
    const knobOffsetFromDoorFrame = getKnobOffsetFromDoorFrameForSeries(seriesId);

    const widthCM = widthMM / 10;
    const heightCM = showerBaseSize.y / 10;

    const boxPosition = useMemo(
        () => new Vector3(widthCM * 0.5, heightCM * 0.5 + bottomOffsetMM / 10, 0).addInPlace(positionOffset),
        [widthCM, heightCM, bottomOffsetMM, positionOffset]
    );
    const knobPosition =
        hingePosition === PositionType.Right
            ? new Vector3(-widthCM * 0.5 + knobOffsetFromDoorFrame, -bottomOffsetMM / 20, 0)
            : new Vector3(widthCM * 0.5 - knobOffsetFromDoorFrame, -bottomOffsetMM / 20, 0);
    const sealPosition =
        hingePosition === PositionType.Left ? new Vector3(widthCM * 0.5, 0, 0) : new Vector3(-widthCM * 0.5, 0, 0);

    const hasHinge = hingePosition !== PositionType.None;

    return (
        <transformNode name={`tf_door_${index}`} position={position} rotation={rotation}>
            <ResponsiveBoxComponent
                name={'box_door'}
                width={widthCM}
                height={heightCM}
                depth={depthMM / 10}
                position={boxPosition}
                isPickable={false}
                sliderLabel={sliderLabel}
                bottomOffsetMM={bottomOffsetMM}
            >
                <GlassMaterialComponent />
                {hasHinge && (
                    <transformNode name={`door_tf_knob_${hingePosition}`} position={knobPosition}>
                        <KnobComponent positionType={hingePosition} />
                    </transformNode>
                )}
                {hasHinge && showerBaseSize && showerBaseSize.y && (
                    <SealComponent position={sealPosition} scaling={new Vector3(1, heightCM / 200, 1)} />
                )}
                {decorators && (
                    <BlueprintElementDecoratorComponent
                        decorators={decorators}
                        parentWidthMM={widthMM}
                        parentPosition={boxPosition}
                        parentPositionOffset={positionOffset}
                        parentRotation={rotation}
                        parentHingePosition={hingePosition}
                        parentBottomOffsetMM={bottomOffsetMM}
                    />
                )}
            </ResponsiveBoxComponent>
        </transformNode>
    );
};

export default DoorComponent;

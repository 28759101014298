import { Vector2, Vector3 } from '@babylonjs/core';
import {
    collisionRadiusX,
    collisionRadiusZ,
    safetyCollisionRadius
} from '../components/3d/BaseComponents/ShowerCameraComponent';
import { Blueprint } from '../types/Blueprint';
import { ArcPosition } from '../types/Common';

type CameraPosition = ArcPosition & {
    cameraDirection: Vector3;
    cameraRotation: Vector2;
};

/**
 * This functions maps specific names to an ArcRotation. Returns the fallbackPosition of the name
 * is unknown and logs an error with the blueprintId in which it happened
 * @param name The name of the ArcPosition
 * @param fallbackPosition The position if the name is unknown
 * @param blueprintId The id of the blueprint in which the unknown name appeared
 */
const mapNameToArcPosition = (name: string, fallbackPosition: ArcPosition, blueprintId: number) => {
    switch (name) {
        case 'leftCorner':
            return {
                alpha: Math.PI * 1.7,
                beta: Math.PI * 0.46,
                radius: 500
            };
        case 'offsetOnX':
            return {
                alpha: Math.PI * 1.55,
                beta: Math.PI * 0.46,
                radius: 500
            };
        case 'recessLeftCorner':
            return {
                alpha: Math.PI * 1.6,
                beta: Math.PI * 0.46,
                radius: 500
            };
        default:
            console.error(
                `Found invalid named initialCameraPosition with name \"${name}\" in blueprint(id=${blueprintId})`
            );
            return fallbackPosition;
    }
};

/**
 * This function reads the initialCameraPosition from the given blueprint and returns an ArcPosition.
 * If the blueprint is undefined, the initialCameraPosition is not a string or an ArcPosition or the
 * given name in the initialCameraPosition is unknow a fallbackPosition is returned and an error is logged.
 * @param blueprint The blueprint to get the initialCameraPosition from
 */
export const getInitialCameraPosition = (blueprint: Blueprint): CameraPosition => {
    // default fallback position
    let position = {
        alpha: Math.PI * 1.6,
        beta: Math.PI * 0.46,
        radius: 500
    };

    if (blueprint) {
        const initialArcPosition = blueprint.initialCameraPosition as ArcPosition;
        if (initialArcPosition?.alpha && initialArcPosition?.beta && initialArcPosition?.radius) {
            position = initialArcPosition;
        } else {
            const initialStringPosition = blueprint.initialCameraPosition as string;
            if (initialStringPosition) {
                position = mapNameToArcPosition(initialStringPosition, position, blueprint.id);
            } else {
                console.error(
                    `Invalid initialCameraPosition in blueprint (id=${blueprint.id}): ${blueprint.initialCameraPosition}`
                );
            }
        }
    }

    return {
        ...position,
        cameraDirection: Vector3.Zero(),
        cameraRotation: Vector2.Zero()
    };
};

/**
 * This function checks if the actual global camera position is outside of the room.
 * @param cameraPosition The absolute global position of the camera
 * @param roomSize The size of the room
 */

export const isOutsideRoom = (cameraPosition: Vector3, roomSize: Vector3): [boolean, boolean] => {
    const outsideOnXAxis = cameraPosition.x + collisionRadiusX + safetyCollisionRadius > roomSize.x;
    const outsideOnZAxis = cameraPosition.z - collisionRadiusZ - safetyCollisionRadius < -roomSize.z;

    return [outsideOnXAxis, outsideOnZAxis];
};

/**
 * This function checks if the actual global camera position is inside the shower.
 * A special case is the recess shower, where also the outside room (look at the floor tiles for a better understanding)
 *  next to the recess is considered to be 'inside' the shower
 * @param cameraPosition The absolute global position of the camera
 * @param showerBaseSize The size of the shower
 * @param showerOrigin The origin of the shower (e.g. offset)
 */

export const isInsideShower = (
    cameraPosition: Vector3,
    showerBaseSizeMM: Vector3,
    showerOriginMM: Vector3
): [boolean, boolean] => {
    const showerBaseSizeCM = showerBaseSizeMM.divide(new Vector3(10, 10, 10));
    const showerOriginCM = showerOriginMM.divide(new Vector3(10, 10, 10));

    const camPosX = cameraPosition.x - safetyCollisionRadius;
    const camPosZ = cameraPosition.z + safetyCollisionRadius;

    const insideOnXAxis = camPosX > showerOriginCM.x && camPosX < showerBaseSizeCM.x + showerOriginCM.x;
    const insideOnZAxis = camPosZ < -showerOriginCM.z && camPosZ > -showerBaseSizeCM.z - showerOriginCM.z;

    return [insideOnXAxis, insideOnZAxis];
};

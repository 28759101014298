export const menuSelector = '.menu';
export const menuButtonSelector = '.menu-btn';
export const productOverviewButtonSelector = '.product-overview-caret-wrapper';
export const productOverviewSelector = '.product-overview';
export type anyFunction = (...args: unknown[]) => void | unknown;

export const alignHTMLElement = (toAlign: HTMLDivElement, target: HTMLElement): void => {
    const rect = target.getBoundingClientRect();
    const offsetTop = rect.top;
    const offsetLeft = rect.left;

    const targetWidth = rect.width;
    const targetHeight = rect.height;

    toAlign.style.top = `${offsetTop}px`;
    toAlign.style.left = `${offsetLeft}px`;
    toAlign.style.width = `${targetWidth}px`;
    toAlign.style.height = `${targetHeight}px`;
};

export const alignPointerWithElement = (toAlign: HTMLDivElement, target: HTMLElement): void => {
    const targetRect = target.getBoundingClientRect();
    const offsetTop = targetRect.top;
    const offsetLeft = targetRect.left;

    toAlign.style.top = `${offsetTop}px`;
    toAlign.style.left = `${offsetLeft}px`;
};

export const pointerIsOnTargetElement = (pointer: HTMLDivElement, target: HTMLElement): boolean => {
    const deltaThreshold = 0;
    const targetRect = target.getBoundingClientRect();
    const pointerLeft = parseInt(pointer.style.left);
    const pointerTop = parseInt(pointer.style.top);

    const targetLeft = targetRect.left;
    const targetTop = targetRect.top;

    const deltaLeft = Math.abs(targetLeft - pointerLeft);
    const deltaTop = Math.abs(targetTop - pointerTop);
    if (deltaLeft <= deltaThreshold && deltaTop <= deltaThreshold) {
        return false;
    } else {
        return true;
    }
};

export const greyOutElementByQuery = (querySelect: string): void => {
    const element = document.querySelector<HTMLElement>(querySelect);
    element?.style.setProperty('filter', 'brightness(0.7)');
};

export const undoGreyOutElementByQuery = (querySelect: string): void => {
    const element = document.querySelector<HTMLElement>(querySelect);
    element?.style.removeProperty('filter');
};

export const getMenuHTMLElement = (): HTMLElement | null => {
    return document.querySelector<HTMLElement>(menuSelector);
};

export const applyBoxShadow = (targetSelector: string): void => {
    const targetElement = document.querySelector<HTMLDivElement>(targetSelector);
    targetElement?.classList.add('onboarding-box-shadow-highlight');
};

export const undoBoxShadow = (targetSelector: string): void => {
    const targetElement = document.querySelector<HTMLDivElement>(targetSelector);
    targetElement?.classList.remove('onboarding-box-shadow-highlight');
};

export const addEventListenerByQuery = (listenerName: string, querySelector: string, func: anyFunction): void => {
    const element = document.querySelector<HTMLElement>(querySelector);
    element?.addEventListener(listenerName, func);
};

export const removeEventListenerByQuery = (listenerName: string, querySelector: string, func: anyFunction): void => {
    const element = document.querySelector<HTMLElement>(querySelector);
    element?.removeEventListener(listenerName, func);
};

import { AbstractMesh, AnimationGroup, AssetContainer, IParticleSystem, SceneLoader, Skeleton } from '@babylonjs/core';
import '@babylonjs/loaders';
import { useEffect, useRef, useState } from 'react';
import { useBabylonScene } from 'react-babylonjs';
import { defer } from 'rxjs';

type LoaderResult = {
    meshes: AbstractMesh[];
    skeletons: Skeleton[];
    animationGroups: AnimationGroup[];
    particleSystems: IParticleSystem[];
};

const defaultResult = {
    animationGroups: [],
    meshes: [],
    skeletons: [],
    particleSystems: []
};

const useLoaderNG = (rootUrl: string, sceneFilename?: string): [boolean, LoaderResult] => {
    const scene = useBabylonScene();
    const [loaded, setLoaded] = useState<boolean>(false);
    const [results, setResults] = useState<LoaderResult>(defaultResult);
    const oldAssetContainer = useRef<AssetContainer>();

    useEffect(() => {
        const subscription = defer(() =>
            SceneLoader.LoadAssetContainerAsync(rootUrl, sceneFilename, scene, undefined, undefined)
        ).subscribe((assetContainer) => {
            oldAssetContainer.current?.dispose();
            oldAssetContainer.current = assetContainer;
            assetContainer.addAllToScene();
            setResults({
                animationGroups: assetContainer.animationGroups,
                meshes: assetContainer.meshes,
                skeletons: assetContainer.skeletons,
                particleSystems: assetContainer.particleSystems
            });
            setLoaded(true);
        });

        return (): void => {
            if (scene) scene.blockfreeActiveMeshesAndRenderingGroups = true;
            oldAssetContainer.current?.dispose();
            subscription.unsubscribe();
            if (scene) scene.blockfreeActiveMeshesAndRenderingGroups = false;
        };
    }, [rootUrl, sceneFilename, scene]);

    return [loaded, results];
};

export default useLoaderNG;

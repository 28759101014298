import { Tags, AbstractMesh } from '@babylonjs/core';

export enum TAGLIST {
    // The mesh has a surface that is reflecting the environment
    REFLECTION,
    // The mesh is added to the reflection environment
    BE_REFELECTED
}

/**
 * Applys an list of tags to each element of a list of meshes
 * @param meshes meshes, on which the tags should be applied
 * @param tagList tags, which should be applied to the meshes
 */
export const applyTags = (meshes: AbstractMesh[], tagList: TAGLIST[]): void => {
    const tagString = tagList.join(' ');
    meshes.forEach((obj) => {
        Tags.EnableFor(obj);
        Tags.AddTagsTo(obj, tagString);
    });
};

/**
 * Removes a list of tags from each element of a list of meshes
 * @param meshes meshes, from which the tags should be removed
 * @param tagList tags, which should be removed from the meshes
 */
export const removeTags = (meshes: AbstractMesh[], tagList: TAGLIST[]): void => {
    const tagString = tagList.join(' ');
    meshes.forEach((obj) => {
        Tags.RemoveTagsFrom(obj, tagString);
    });
};

import '@babylonjs/loaders';
import React from 'react';
import { Engine } from 'react-babylonjs';
import SceneComponent from './SceneComponent/SceneComponent';

type EngineComponentProps = {
    setIsReady: (isReady: boolean) => void;
};

const EngineComponent = ({ setIsReady }: EngineComponentProps): JSX.Element => {
    return (
        <>
            <Engine antialias adaptToDeviceRatio canvasId="canvas">
                <SceneComponent setIsReady={setIsReady} />
            </Engine>
        </>
    );
};

export default EngineComponent;

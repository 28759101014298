import { Vector3 } from '@babylonjs/core';
import { BeamPricing } from '../../state/ducks/configurator/interfaces';
import { getRotationYFromDirection } from '../3d_helpers';
import {
    BlueprintBeamConnectorType,
    BlueprintDecoratorType,
    BlueprintElement,
    BlueprintLayoutType
} from '../types/Blueprint';
import { PositionType } from '../types/Common';
import { getDirectionForCurrentIndexInLayout } from './blueprintHelper';
import { Axis, calculateAxisForRotation } from './commonHelper';

const tBeamIds = ['93-011', 'B93-011'];
const beamOffsetCM = 18;

export const isTBeam = (beamId: string): boolean => {
    return tBeamIds.some((tBeamId) => tBeamId === beamId);
};

export const calculateBeamAxis = (elementRotation: Vector3): Axis => {
    const partitionAxis = calculateAxisForRotation(elementRotation);
    return partitionAxis === Axis.x ? Axis.z : Axis.x;
};

export const calculateBeamPosition = (decoratorPosition: PositionType, elementPosition: Vector3): Vector3 => {
    return decoratorPosition === PositionType.Right
        ? new Vector3(elementPosition.x - beamOffsetCM, elementPosition.y, 0)
        : new Vector3(-elementPosition.x + beamOffsetCM, elementPosition.y, 0);
};

export const calculateBeamAxisForLayout = (layout: BlueprintElement[]): Axis | undefined => {
    let beamIndex = undefined;

    layout.forEach((element, idx) => {
        if (
            (element.type === BlueprintLayoutType.Door || element.type === BlueprintLayoutType.Partition) &&
            element.decorators?.some(
                (d) => d.type === BlueprintDecoratorType.Beam && d.connector !== BlueprintBeamConnectorType.GlassCenter
            )
        ) {
            beamIndex = idx;
        }
    });

    if (!beamIndex) {
        return undefined;
    }

    const beamDirection = getDirectionForCurrentIndexInLayout(layout, beamIndex);
    const beamRotation = getRotationYFromDirection(beamDirection);
    return calculateBeamAxis(beamRotation);
};

export const calculateBeamPrice = (showerSideLength: number, pricing: BeamPricing[]): number => {
    const sortedPricing = pricing.sort((pA, pB) => pA.price - pB.price);

    for (let i = 0; i < sortedPricing.length; i++) {
        if (showerSideLength <= sortedPricing[i].length) {
            return sortedPricing[i].price;
        }
    }
    return 0;
};

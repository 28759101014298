export type Vector3 = {
    x: number;
    y: number;
    z: number;
};

export enum PositionType {
    Top = 'top',
    Left = 'left',
    Right = 'right',
    Bottom = 'bottom',
    None = 'none'
}

export type PositionInfo = {
    offSetTop: number;
    offSetBottom: number;
};

export type Size = {
    widthMM: number;
    heightMM: number;
    depthMM: number;
};

export type ArcPosition = {
    alpha: number;
    beta: number;
    radius: number;
};

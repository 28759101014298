import React, { ReactNode, useState } from 'react';
import { ReactComponent as Info } from '../../../assets/icons/common/information.svg';
import './InfoDialogComponent.scss';

interface InfoDialogComponentProps {
    children: ReactNode;
}

const InfoDialogComponent = ({ children }: InfoDialogComponentProps): JSX.Element => {
    const [show, setShow] = useState(false);

    const onClick = (e: React.MouseEvent<SVGSVGElement | HTMLDivElement>) => {
        e.stopPropagation();
        setShow(!show);
    };

    return (
        <div className={`info-dialog-component`}>
            <Info className="info-icon" onClick={(e) => onClick(e)} />
            <div className={`background ${show ? 'show' : ''}`} onClick={(e) => onClick(e)} />
            <div className={`dialog ${show ? 'show' : ''}`} onClick={(e) => e.stopPropagation()}>
                <div className="content">{children}</div>
            </div>
        </div>
    );
};

export default InfoDialogComponent;

import React from 'react';
import Spinner from '../../../components/spinner/Spinner.component';

type FullscreenLoaderComponent = {
    show: boolean;
};

const FullscreenLoaderComponent = ({ show }: FullscreenLoaderComponent): JSX.Element => (
    <div>
        {show ? (
            <div
                style={{
                    top: 0,
                    left: 0,
                    zIndex: 2000,
                    width: '100vw',
                    height: '100vh',
                    background: 'white',
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column'
                }}
            >
                <h1 style={{ fontFamily: 'Source Sans Pro', marginBottom: -75 }}>
                    Duschenkonfigurator wird geladen...
                </h1>
                <Spinner />
            </div>
        ) : (
            <></>
        )}
    </div>
);

export default FullscreenLoaderComponent;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Vector3 } from '@babylonjs/core';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { from, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { StoreState } from '..';
import { calculateBeamAxisForLayout, calculateBeamPrice } from '../../../ng/helper/beamHelper';
import { Axis } from '../../../ng/helper/commonHelper';
import { UpdateShowerBaseSizeAction } from '../architecture/interfaces';
import { UPDATE_SHOWER_BASESIZE } from '../architecture/types';
import { ConfigOptionEnum } from '../system/interfaces';
import {
    loadCatalogueError,
    loadCatalogueSuccess,
    selectConfigOption,
    updateBeamPrice,
    updatePrice,
    updatePriceError,
    updateShowerSizePrice
} from './actions';
import json from './catalogue.json';
import {
    Catalogue,
    CatalogueLoadedErrorAction,
    CatalogueLoadedSuccessAction,
    ConfigurationDefaultLoadedAction,
    LoadCatalogueAction,
    MergedCatalogueMeasurement,
    ResetToDefaultConfigAction,
    SelectConfigOptionAction,
    UpdateBeamPriceAction,
    UpdatePriceAction,
    UpdatePriceErrorAction,
    UpdateShowerSizePriceAction
} from './interfaces';
import {
    CATALOGUE_LOADED_SUCCESS,
    CONFIGURATION_DEFAULT_LOADED_ERROR,
    CONFIGURATION_DEFAULT_LOADED_SUCCESS,
    LOAD_CATALOGUE,
    RESET_TO_DEFAULT_CONFIG,
    SELECT_CONFIG_OPTION,
    UPDATE_BEAM_PRICE,
    UPDATE_SHOWER_SIZE_PRICE
} from './types';
import { calculateNumberOfDoors, getDefaultBeamIdForModel, getDefaultConfiguration, getModelForSeries } from './utils';

import firebase from 'firebase/app';
import 'firebase/firestore';

const loadCatalogueEpic = (
    action$: Observable<LoadCatalogueAction>
): Observable<CatalogueLoadedSuccessAction | CatalogueLoadedErrorAction> => {
    return action$.pipe(
        ofType(LOAD_CATALOGUE),
        switchMap((action: LoadCatalogueAction) => {
            if (action.payload.email) {
                return from(
                    firebase
                        .firestore()
                        .collection('retailers')
                        .where('email', '==', action.payload.email)
                        .limit(1)
                        .get()
                ).pipe(
                    map((querySnapshot) => {
                        if (querySnapshot.docs.length > 0) {
                            const doc = querySnapshot.docs[0].data();
                            const catalogue = JSON.parse(doc.catalogue);
                            return catalogue;
                        } else {
                            return json;
                        }
                    })
                );
            } else {
                return of(json);
            }
        }),
        map((catalogue) => loadCatalogueSuccess(catalogue as Catalogue)),
        catchError((error) => of(loadCatalogueError(error)))
    );
};

const catalogueLoadedSuccessEpic = (action$: Observable<CatalogueLoadedSuccessAction>): Observable<any> => {
    return action$.pipe(
        ofType(CATALOGUE_LOADED_SUCCESS),
        map(
            (action: CatalogueLoadedSuccessAction): ConfigurationDefaultLoadedAction => ({
                type: CONFIGURATION_DEFAULT_LOADED_SUCCESS,
                payload: {
                    configuration: getDefaultConfiguration(action.payload.catalogue)
                }
            })
        ),
        catchError((error) =>
            of({
                type: CONFIGURATION_DEFAULT_LOADED_ERROR,
                error
            })
        )
    );
};

const calculateNewMeasurement = (
    sortedModelMeasurements: MergedCatalogueMeasurement[],
    showerBaseSize: Vector3,
    ignoreDepthForPrice: boolean
): MergedCatalogueMeasurement => {
    let newMeasurement: MergedCatalogueMeasurement | undefined;

    const allHeightsAreEqual = sortedModelMeasurements.map((m) => m.height).every((m, _, a) => m === a[0]);

    sortedModelMeasurements.forEach((modelMeasurement) => {
        if (
            !newMeasurement &&
            (ignoreDepthForPrice || modelMeasurement.depth >= showerBaseSize.z) &&
            modelMeasurement.width >= showerBaseSize.x &&
            (allHeightsAreEqual || modelMeasurement.height >= showerBaseSize.y)
        ) {
            newMeasurement = modelMeasurement;
        }
    });

    return newMeasurement ? newMeasurement : sortedModelMeasurements[sortedModelMeasurements.length - 1];
};

const calculateShowerOverSizePrice = (
    glasOversizePricePer100MM: number,
    showerBaseSize: Vector3,
    measurement: MergedCatalogueMeasurement,
    ignoreDepthForPrice: boolean
): number => {
    const overSizeLengthStepsMM = 100;

    const overSizeWidthMM = Math.max(0, showerBaseSize.x - measurement.width);
    const overSizeDepthMM = ignoreDepthForPrice ? 0 : Math.max(0, showerBaseSize.z - measurement.depth);
    const overSizeHeightMM = Math.max(0, showerBaseSize.y - measurement.height);

    const overSizeFactorWidth = Math.ceil(overSizeWidthMM / overSizeLengthStepsMM);
    const overSizeFactorDepth = Math.ceil(overSizeDepthMM / overSizeLengthStepsMM);
    const overSizeFactorHeight = Math.ceil(overSizeHeightMM / overSizeLengthStepsMM);

    return glasOversizePricePer100MM * (overSizeFactorWidth + overSizeFactorDepth + overSizeFactorHeight);
};

const calculateMeasurementConfigOptionEpic = (
    action$: Observable<UpdateShowerBaseSizeAction>,
    state$: StateObservable<StoreState>
): Observable<SelectConfigOptionAction> => {
    return action$.pipe(
        ofType(UPDATE_SHOWER_BASESIZE),
        map(() => {
            const showerBaseSize = state$.value.architecture.showerBaseSize;
            const ignoreDepthForPrice = state$.value.configurator.configuration.model.ignoreDepthForShowerSizePrice;
            const modelMeasurements = state$.value.configurator.configuration.model.measurements;
            const sortedModelMeasurements = modelMeasurements.sort((mMA, mMB) => mMA.price - mMB.price);

            const newMeasurement = calculateNewMeasurement(
                sortedModelMeasurements,
                showerBaseSize,
                ignoreDepthForPrice
            );

            return selectConfigOption(
                ConfigOptionEnum.Measurement,
                newMeasurement?.id ?? sortedModelMeasurements[0].id,
                state$.value.configurator.catalogue,
                false
            );
        })
    );
};

const calculateShowerSizePriceEpic = (
    action$: Observable<SelectConfigOptionAction>,
    state$: StateObservable<StoreState>
): Observable<UpdateShowerSizePriceAction> => {
    return action$.pipe(
        ofType(SELECT_CONFIG_OPTION),
        map(() => {
            const showerBaseSize = state$.value.architecture.showerBaseSize;
            const ignoreDepthForPrice = state$.value.configurator.configuration.model.ignoreDepthForShowerSizePrice;
            const glasOversizePricePer100MM = state$.value.configurator.configuration.glass.oversizePrice;

            const measurement = state$.value.configurator.configuration.measurement;

            const overSizePrice = calculateShowerOverSizePrice(
                glasOversizePricePer100MM,
                showerBaseSize,
                measurement,
                ignoreDepthForPrice
            );

            return updateShowerSizePrice(Math.round((measurement?.price ?? 0) + overSizePrice));
        })
    );
};

const updateBeamPriceEpic = (
    action$: Observable<SelectConfigOptionAction | UpdateShowerBaseSizeAction>,
    state$: StateObservable<StoreState>
): Observable<UpdateBeamPriceAction> => {
    return action$.pipe(
        ofType(SELECT_CONFIG_OPTION, UPDATE_SHOWER_BASESIZE),
        mergeMap((action) => {
            if (
                (action.type === SELECT_CONFIG_OPTION && action.payload.optionType === ConfigOptionEnum.Beam) ||
                action.type === UPDATE_SHOWER_BASESIZE
            ) {
                const catalogue = state$.value.configurator.catalogue;
                const config = state$.value.configurator.configuration;
                const model = getModelForSeries(catalogue, config.series.id, config.model.id);
                const defaultBeamId = model && getDefaultBeamIdForModel(model);

                const beamAxis = calculateBeamAxisForLayout(state$.value.architecture.blueprint.layout);
                const showerBaseSize = state$.value.architecture.showerBaseSize;

                let newBeamPrice = 0;

                if (!defaultBeamId) {
                    newBeamPrice = calculateBeamPrice(
                        beamAxis === Axis.x ? showerBaseSize.x : showerBaseSize.z,
                        config.beam.pricing
                    );
                }

                return [updateBeamPrice(newBeamPrice)];
            } else {
                return [];
            }
        })
    );
};

const calculatePriceEpic = (
    action$: Observable<SelectConfigOptionAction | UpdateBeamPriceAction | UpdateShowerSizePriceAction>,
    state$: StateObservable<StoreState>
): Observable<UpdatePriceAction | UpdatePriceErrorAction> => {
    return action$.pipe(
        ofType(SELECT_CONFIG_OPTION, UPDATE_BEAM_PRICE, UPDATE_SHOWER_SIZE_PRICE),
        map(() => {
            const showerBaseSizePrice = state$.value.configurator.price.showerBaseSize;
            const config = state$.value.configurator.configuration;
            const numberOfDoors = calculateNumberOfDoors(state$.value.architecture.blueprint?.layout || []);

            const newPrice =
                showerBaseSizePrice +
                config.glass.price +
                config.base.price +
                config.beam.price +
                (config.knob ? config.knob.price : 0) * numberOfDoors +
                config.wallmount.price;

            return updatePrice(Math.round(newPrice));
        }),
        catchError((error) => of(updatePriceError(error)))
    );
};

const resetToDefaultConfigurationEpic = (
    action$: Observable<ResetToDefaultConfigAction>,
    state: Observable<StoreState>
): any | Observable<any> => {
    return action$.pipe(
        ofType(RESET_TO_DEFAULT_CONFIG),
        map(() => {
            let catalogue;
            state.pipe(take(1)).subscribe((x) => {
                catalogue = x.configurator.catalogue;
            });
            return {
                type: CATALOGUE_LOADED_SUCCESS,
                payload: {
                    catalogue
                }
            };
        })
    );
};

export default combineEpics(
    loadCatalogueEpic,
    catalogueLoadedSuccessEpic,
    calculateShowerSizePriceEpic,
    updateBeamPriceEpic,
    calculateMeasurementConfigOptionEpic,
    calculatePriceEpic,
    resetToDefaultConfigurationEpic
);

import { Axis } from '../helper/commonHelper';
import { ArcPosition, PositionType, Vector3 } from './Common';

export enum BlueprintDecoratorType {
    Hinge = 'hinge',
    Mount = 'mount',
    Beam = 'beam',
    FloorRail = 'floorRail'
}

export type BlueprintHingeType = {
    type: BlueprintDecoratorType.Hinge;
};

export type BlueprintMountType = {
    type: BlueprintDecoratorType.Mount;
};

export type BlueprintDecorator = {
    type: BlueprintDecoratorType;
    position: PositionType[];
    connector: BlueprintTransitionConnectorType | BlueprintBeamConnectorType;
};

export enum BlueprintLayoutType {
    Door = 'door',
    Partition = 'partition',
    Transition = 'transition',
    Void = 'void',
    Wall = 'wall'
}

export type BlueprintDoor = {
    type: BlueprintLayoutType.Door;
    length: number;
    ratio?: number;
    minLength: number;
    maxLength: number;
    maxLengthWeight?: number;
    sliderLabel?: string;
    decorators?: BlueprintDecorator[];
    offset?: Vector3;
};

export type BlueprintPartition = {
    type: BlueprintLayoutType.Partition;
    length: number;
    ratio?: number;
    minLength: number;
    maxLength: number;
    sliderLabel?: string;
    decorators?: BlueprintDecorator[];
};

export enum BlueprintTransitionConnectorType {
    GlassWall = 'gw',
    GlassGlass = 'gg'
}

export enum BlueprintBeamConnectorType {
    GlassWall = 'gw',
    GlassGlass = 'gg',
    GlassCenter = 'gc'
}

export type BlueprintTransition = {
    type: BlueprintLayoutType.Transition;
    angle: number;
    decorators?: BlueprintDecorator[];
};

export type BlueprintVoid = {
    type: BlueprintLayoutType.Void;
    length: number;
    ratio?: number;
    minLength: number;
    maxLength: number;
    sliderLabel?: string;
};

export type BathtubConfiguration = {
    sizeOffset?: Vector3;
    isUnderShower: boolean;
};

export type BlueprintElement = BlueprintDoor | BlueprintPartition | BlueprintTransition | BlueprintVoid;

export type Blueprint = {
    id: number;
    origin: Vector3;
    initialCameraPosition: ArcPosition | string;
    bathtubConfig?: BathtubConfiguration;
    layout: BlueprintElement[];
    accMaxLenghtForOnlyDoorAxisConstraints?: { [Axis.x]: number; [Axis.z]: number };
};

export type BlueprintLight = {
    id: string;
    layout: (BlueprintLightElement | null)[];
};

export type BlueprintLightElement = {
    length: number;
    ratio?: number;
};

export type BlueprintBaseSizeConstraints = {
    min: Vector3;
    max: Vector3;
};

export type BlueprintElementSizeConstraints = {
    partition: { min: number; max: number };
    door: { min: number; max: number; maxWeight: number };
};

import {
    ActionEvent,
    ActionManager,
    ExecuteCodeAction,
    StandardMaterial,
    Tools,
    TransformNode,
    Vector3
} from '@babylonjs/core';
import React, { useEffect, useRef } from 'react';
import { CreatedInstance, useBabylonScene } from 'react-babylonjs';
import useLoaderNG from '../../../../hooks/useLoaderNG';
import { applyTags, removeTags, TAGLIST } from '../../../helper/tagHelper';
import { TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';

const shouldReflect = (tagList: TAGLIST[] | undefined) => {
    return tagList && tagList.indexOf(TAGLIST.REFLECTION) > -1;
};

type ThreeDCompononentProps = {
    name: string;
    sceneFilename: string;
    rootUrl?: string;
    layerMask?: number;
    onClick?: (ev: ActionEvent) => void;
    tagList?: TAGLIST[];
};

const ThreeDComponent = ({
    name,
    sceneFilename,
    rootUrl = 'assets/3d/',
    layerMask = TOTAL_LAYERMASK,
    onClick,
    tagList
}: ThreeDCompononentProps): JSX.Element | null => {
    const scene = useBabylonScene();
    const tfRef = useRef<CreatedInstance<TransformNode>>();
    const [loaded, { meshes }] = useLoaderNG(rootUrl, sceneFilename);

    // Click handler
    useEffect(() => {
        if (scene && onClick && meshes && loaded) {
            const action = new ExecuteCodeAction(ActionManager.OnPickTrigger, (ev) => onClick(ev));
            meshes.forEach((mesh) => {
                if (!mesh.actionManager) {
                    mesh.actionManager = new ActionManager(scene);
                }
                mesh.actionManager.registerAction(action);
            });
            return (): void => {
                meshes.forEach((mesh) => {
                    mesh.actionManager?.unregisterAction(action);
                });
            };
        }
    }, [scene, meshes, onClick, loaded]);

    // add layerMask
    useEffect(() => {
        if (scene) {
            meshes?.forEach((mesh) => {
                mesh.layerMask = layerMask;
            });
        }
    }, [scene, meshes, layerMask]);

    // add Tags
    useEffect(() => {
        if (loaded && meshes) {
            applyTags(meshes, tagList ?? []);
            return (): void => {
                removeTags(meshes, tagList ?? []);
            };
        }
    }, [meshes, tagList, loaded]);

    // add Reflection
    useEffect(() => {
        if (
            shouldReflect(tagList) &&
            scene?.reflectionProbes &&
            meshes &&
            scene.reflectionProbes.length > 0 &&
            loaded
        ) {
            meshes.forEach((mesh) => {
                const material: StandardMaterial = mesh.material as StandardMaterial;
                if (material) {
                    material.unfreeze();
                    material.reflectionTexture = scene.reflectionProbes[0].cubeTexture;
                }
            });
            return (): void => {
                meshes.forEach((mesh) => {
                    const material: StandardMaterial = mesh.material as StandardMaterial;
                    if (material) {
                        material.reflectionTexture = null;
                    }
                });
            };
        }
        meshes.forEach((mesh) => mesh.material?.freeze());
    }, [tagList, scene, meshes, loaded]);

    // put needed settings to mesh: __root__
    useEffect(() => {
        const tf = tfRef.current?.hostInstance;
        if (tf && meshes[0]) {
            meshes[0].setParent(tf);
            meshes[0].setPositionWithLocalVector(new Vector3(0, 0, 0));
            meshes[0].rotation = new Vector3(0, Tools.ToRadians(180), 0);
            meshes[0].scaling = new Vector3(-1, 1, 1);
        }
    }, [meshes, tfRef.current?.hostInstance, loaded]);

    return loaded ? <transformNode name={name} ref={tfRef}></transformNode> : null;
};

export default ThreeDComponent;

import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as Close } from '../../assets/icons/common/close.svg';
import { StoreState } from '../../state/ducks';
import { systemActions } from '../../state/ducks/system';
import SocialShare from './SocialShare.component';
import './SocialShareModal.component.scss';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../ng/helper/analyticsHelper';

const modalStyle = {
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
    },
    content: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        maxHeight: '255px',
        height: '255px',
        maxWidth: '680px',
        margin: 'auto'
    }
};

const SocialShareModal = (): JSX.Element => {
    const show = useSelector((state: StoreState) => state.system.modalShareOpen);

    const dispatch = useDispatch();

    const close = () => {
        logAnalyticsEvent('configurator_close_social_media_modal');
        dispatch(systemActions.closeModalShare());
    };

    const appElement = document.getElementById('app');
    appElement && Modal.setAppElement(appElement);

    return (
        <Modal isOpen={show} contentLabel="Angebot erfolgreich abgegeben" style={modalStyle}>
            <div className="social-share-modal">
                <div>
                    <p className="header">Teilen Sie Ihre Konfiguration</p>
                    <div className="close" onClick={close}>
                        <Close></Close>
                    </div>
                </div>
                <SocialShare />
            </div>
        </Modal>
    );
};

export default SocialShareModal;

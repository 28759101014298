import { Tools } from '@babylonjs/core';

export enum MarkerAlignment {
    top,
    right,
    bottom
}

export interface StackPanelConfig {
    panelContentHeight: number;
    panelContentWidth: number;
    panelPaddingTop: number;
    panelPaddingLeft: number;
    isVertical: boolean;
    arrowRotation: number;
}

export const getStackPanelConfig = (
    markerAlignment: MarkerAlignment,
    markerSize: number,
    markerLabelPadding: number,
    labelWidth: number,
    labelHeight: number
): StackPanelConfig => {
    let paddingTop = 0;
    let paddingLeft = 0;
    let contentHeight = markerSize + markerLabelPadding + labelHeight;
    const contentWidth = markerSize + markerLabelPadding + labelWidth;
    const markerIsVertical = markerAlignment === MarkerAlignment.top || markerAlignment === MarkerAlignment.bottom;
    let arrowRotation = Tools.ToRadians(0);
    if (markerAlignment === MarkerAlignment.top) {
        arrowRotation = Tools.ToRadians(270);
        paddingTop = -labelHeight / 2;
    }
    if (markerAlignment === MarkerAlignment.bottom) {
        arrowRotation = Tools.ToRadians(90);
        paddingTop = (labelHeight + markerLabelPadding) / 2; //markerLabelPadding / 2;
    }
    if (markerAlignment === MarkerAlignment.right) {
        contentHeight = Math.max(markerSize, labelHeight);
        paddingLeft = (markerLabelPadding + labelWidth) / 2; // To align the marker
    }
    return {
        panelContentHeight: contentHeight,
        panelContentWidth: contentWidth,
        panelPaddingTop: paddingTop,
        panelPaddingLeft: paddingLeft,
        isVertical: markerIsVertical,
        arrowRotation: arrowRotation
    };
};

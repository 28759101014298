import React from 'react';
import './DialogStepIndicator.scss';

interface StepIndicatorProps {
    totalNumber: number;
    activeNumber: number;
    onActiveNumberChanged?: (idx: number) => void;
}

const DialogStepIndicatorComponent = ({
    totalNumber,
    activeNumber,
    onActiveNumberChanged
}: StepIndicatorProps): JSX.Element => {
    const bulletList = [];
    for (let i = 0; i < totalNumber; i++) {
        bulletList.push(
            <span
                key={`step_inidicator_bullet_${i}`}
                className={`bullet ${i === activeNumber && 'active'}`}
                onClick={() => {
                    onActiveNumberChanged && onActiveNumberChanged(i);
                }}
            ></span>
        );
    }
    return <div className="step-indicator">{bulletList}</div>;
};

export default DialogStepIndicatorComponent;

import React, { useEffect } from 'react';
import { useBabylonScene } from 'react-babylonjs';
import sceneService from '../../../../../services/scene.service';

type SceneIsReadyComponentProps = {
    setIsReady: (isReady: boolean) => void;
};

export const SceneIsReadyComponent = ({ setIsReady }: SceneIsReadyComponentProps): JSX.Element => {
    const scene = useBabylonScene();

    useEffect(() => {
        scene?.executeWhenReady(() => setIsReady?.(true));

        if (scene) {
            sceneService.setScene(scene);
        }

        return (): void => {
            setIsReady?.(false);
        };
    }, [scene, setIsReady]);

    return <></>;
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../state/ducks';
import { configuratorActions } from '../../../state/ducks/configurator';
import { Configuration, Catalogue } from '../../../state/ducks/configurator/interfaces';
import { getBases } from '../../../state/ducks/configurator/utils';
import { ConfigOptionEnum } from '../../../state/ducks/system/interfaces';
import { isShowerBaseId } from '../../../../src/ng/helper/showerBaseHelper';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../../ng/helper/analyticsHelper';

interface ShowerBaseMenuOptionProps {
    config: Configuration;
    catalogue: Catalogue;
}

const ShowerBaseMenuOption = ({ config, catalogue }: ShowerBaseMenuOptionProps): JSX.Element => {
    const bases = getBases(catalogue, config.series.id);
    const dispatch = useDispatch();
    const baseId = useSelector((state: StoreState) => state.configurator.configuration.base.id);
    const hasShowerBase = isShowerBaseId(baseId);

    return (
        <div className="config-option-page-shower-base model">
            <div>
                <label className={`checkbox-label`}>
                    <input
                        name="showerBaseCheckbox"
                        type="checkbox"
                        checked={hasShowerBase}
                        onChange={(event) => {
                            if (bases.length < 2) {
                                console.warn('Series lacks showerBase in config!');
                                return;
                            }
                            if (event.target.checked) {
                                logAnalyticsEvent('configurator_select_shower_base');
                                dispatch(
                                    configuratorActions.selectConfigOption(
                                        ConfigOptionEnum.Base,
                                        bases[1].id,
                                        catalogue
                                    )
                                );
                            } else {
                                logAnalyticsEvent('configurator_deselect_shower_base');
                                dispatch(
                                    configuratorActions.selectConfigOption(
                                        ConfigOptionEnum.Base,
                                        bases[0].id,
                                        catalogue
                                    )
                                );
                            }
                        }}
                    />
                    <span className={'checkbox-custom rectangular'}></span>
                    <div className={'checkbox-hint'}>Duschwanne anzeigen*</div>
                </label>
            </div>
            <div style={{ marginTop: '19px', marginBottom: '14px' }}>*Wird nicht mitgeliefert.</div>
            <div>
                Die Position Ihrer Dusche im Badezimmer und die Öffnungsrichtung Ihrer Tür passen wir gerne Ihren
                individuellen Wünschen an.
            </div>
        </div>
    );
};

export default ShowerBaseMenuOption;

import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { systemActions } from '../../../state/ducks/system';
import { ConfigOptionEnum } from '../../../state/ducks/system/interfaces';

import './GoToSubmenuComponent.scss';

type GoToSubmenuComponentProps = {
    infoText: string;
    linkText: string;
    submenu: ConfigOptionEnum;
};

const GoToSubmenuComponent = ({ infoText, linkText, submenu }: GoToSubmenuComponentProps): JSX.Element => {
    const dispatch = useDispatch();

    const goToSubmenu = useCallback(() => {
        dispatch(systemActions.openMenuWith(submenu));
    }, [submenu]);

    return (
        <div className="go-to-submenu-component">
            <div>
                {infoText}
                <a onClick={goToSubmenu}>{` ${linkText}`}</a>
            </div>
        </div>
    );
};

export default GoToSubmenuComponent;

import React from 'react';
import './ProductItem.component.scss';

interface ProductItemComponentProps {
    label: string;
    description: string;
    price?: number;
    mountType?: string;
    noSign?: boolean;
    goldenDescription?: boolean;
}

const ProductItem = ({
    label,
    description,
    price,
    noSign,
    mountType,
    goldenDescription
}: ProductItemComponentProps): JSX.Element => {
    const surcharge = !!(price && price > 0);

    return (
        <li className={`product-item ${surcharge && !noSign && 'surplus'}`}>
            <div className="product-item-label">{label}</div>
            <div className="product-item-content">
                {surcharge && !noSign && <div className="product-item-content-surplus">Aufpreis</div>}
                <div className={`product-item-description ${goldenDescription ? 'gold' : ''}`}>
                    <span>{description}</span>
                    {mountType && <span> ({mountType})</span>}
                </div>
                {price !== undefined && <div className="product-item-price">{surcharge && `${price},- EUR`}</div>}
            </div>
        </li>
    );
};

export default ProductItem;

import { Vector3 } from '@babylonjs/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useZoomAt from '../../../../hooks/useZoomAt';
import { StoreState } from '../../../../state/ducks';
import { ConfigOptionEnum } from '../../../../state/ducks/system/interfaces';
import { getPositionOffsetFor, getRotationFor } from '../../../helper/techConfigHelper';
import { TAGLIST } from '../../../helper/tagHelper';
import { DETAIL_LAYERMASK, TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import { PositionType } from '../../../types/Common';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type KnobComponentProps = {
    positionType: PositionType;
};

const KnobComponent = ({ positionType }: KnobComponentProps): JSX.Element => {
    const [position, setPosition] = useState(Vector3.Zero());
    const [rotation, setRotation] = useState(Vector3.Zero());
    const tfName = `knob_tf_${positionType}`;
    const [zoomAt, setZoomAt] = useZoomAt();
    const knobTechConfig = useSelector((state: StoreState) => state.architecture.techConfigs.knob);

    useEffect(() => {
        if (knobTechConfig) {
            const rotation = getRotationFor(knobTechConfig, positionType);
            setRotation(rotation);

            const position = getPositionOffsetFor(knobTechConfig, positionType);
            setPosition(position);
        }
    }, [knobTechConfig, positionType]);

    return knobTechConfig ? (
        <transformNode name={tfName} position={position} rotation={rotation}>
            <ThreeDComponent
                name={`knob_threeD_${positionType}`}
                rootUrl="assets/3d/knobs/"
                sceneFilename={`knob_${knobTechConfig.id}.glb`}
                onClick={zoomAt !== tfName ? () => setZoomAt(tfName, ConfigOptionEnum.Knob) : undefined}
                layerMask={zoomAt === tfName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                tagList={[TAGLIST.REFLECTION]}
            />
        </transformNode>
    ) : (
        <></>
    );
};

export default KnobComponent;

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SliderComponent from '../../../components/common/slider/SliderComponent';
import { StoreState } from '../../../state/ducks';
import { architectureActions } from '../../../state/ducks/architecture';
import { BlueprintDoor, BlueprintPartition, BlueprintVoid } from '../../types/Blueprint';
import ShowerBaseSizeComponent from './ShowerBaseSize/ShowerBaseSizeComponent';
import './ShowerSizeMenuComponent.scss';
import ToggleSliderComponent from '../../../components/common/toggle-slider/toggle-slider.component';
import { systemActions } from '../../../state/ducks/system';

const ShowerSizeMenuComponent = (): JSX.Element => {
    const blueprint = useSelector((state: StoreState) => state.architecture.blueprint);

    const dispatch = useDispatch();

    const updateLength = (index: number, lengthMM: number) => {
        dispatch(architectureActions.updateLengthMM(index, lengthMM));
    };

    let j = 0;

    const displayRatioOptions = useSelector((state: StoreState) => state.system.menu.displayRatioOptions);
    const onChange = (value: boolean) => dispatch(systemActions.setDisplayRatioOptions(value));
    return (
        <div className="shower-size-menu-component">
            <ShowerBaseSizeComponent />
            <ToggleSliderComponent
                checked={displayRatioOptions}
                onChange={onChange}
                labelText={'Breite aller Duschwände anpassen'}
            ></ToggleSliderComponent>
            {displayRatioOptions && (
                <div style={{ marginTop: '30px' }}>
                    {blueprint?.layout
                        .map((l, i) => {
                            if ((l as BlueprintPartition | BlueprintDoor | BlueprintVoid).sliderLabel) {
                                j += 1;
                                return (
                                    <SliderComponent
                                        id={j}
                                        key={i}
                                        min={Math.round(
                                            (l as BlueprintPartition | BlueprintDoor | BlueprintVoid).minLength
                                        )}
                                        max={Math.round(
                                            (l as BlueprintPartition | BlueprintDoor | BlueprintVoid).maxLength
                                        )}
                                        value={(l as BlueprintPartition | BlueprintDoor | BlueprintVoid).length}
                                        defaultValue={500}
                                        onChange={(length): void => updateLength(i, parseInt(length))}
                                        label={`Breite-${
                                            (l as BlueprintPartition | BlueprintDoor | BlueprintVoid).sliderLabel
                                        }`}
                                    />
                                );
                            }
                        })
                        .reverse()}
                </div>
            )}
        </div>
    );
};

export default ShowerSizeMenuComponent;

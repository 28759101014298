import { useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../state/ducks';
import { systemActions } from '../state/ducks/system';
import { ConfigOptionEnum } from '../state/ducks/system/interfaces';

const useZoomAt = (): [
    string | null,
    (nodeName?: string, configOption?: ConfigOptionEnum, sceneFilename?: string) => void
] => {
    const zoomAt = useSelector((state: StoreState) => state.system.zoomAt);
    const dispatch = useDispatch();
    const filenamesThatShouldNotOpenMenu = ['mount_gw_10-031.glb'];

    const currentOpenedMenu = useRef<ConfigOptionEnum | undefined>(undefined);
    const menuIsOpen = useRef<boolean>(false);
    const productOverViewOpen = useRef<boolean>(false);
    menuIsOpen.current = useSelector((state: StoreState) => state.system.menu.menuOpen);
    productOverViewOpen.current = useSelector((state: StoreState) => state.system.menu.productOverViewOpen);
    currentOpenedMenu.current = useSelector((state: StoreState) => state.system.menu.openedMenu);

    // Set new item to zoomAt when no other item is set
    const setZoomAt = useCallback(
        (nodeName?: string, configOption?: ConfigOptionEnum, sceneFilename?: string): void => {
            if (nodeName) {
                const menuRestoreState = {
                    menuOpen: menuIsOpen.current,
                    productOverViewOpen: productOverViewOpen.current,
                    openedMenu: currentOpenedMenu.current
                };
                dispatch(systemActions.setMenuRestoreState(menuRestoreState));
                dispatch(systemActions.setZoomAt(nodeName));
                if (configOption) {
                    const shouldOpenMenu = sceneFilename
                        ? !filenamesThatShouldNotOpenMenu.some((filename) => filename === sceneFilename)
                        : true;

                    shouldOpenMenu && dispatch(systemActions.openMenuWith(configOption));
                }
            } else {
                dispatch(systemActions.setZoomAt(null));
            }
        },
        [zoomAt, dispatch]
    );

    return [zoomAt, setZoomAt];
};

export default useZoomAt;

export const OPEN_MENU = 'OPEN_MENU';
export type OPEN_MENU = typeof OPEN_MENU;

export const OPEN_MENU_WITH = 'OPEN_MENU_WITH';
export type OPEN_MENU_WITH = typeof OPEN_MENU_WITH;

export const CLOSE_MENU_WITH = 'CLOSE_MENU_WITH';
export type CLOSE_MENU_WITH = typeof CLOSE_MENU_WITH;

export const CLOSE_MENU = 'CLOSE_MENU';
export type CLOSE_MENU = typeof CLOSE_MENU;

export const OPEN_PRODUCT_OVERVIEW = 'OPEN_PRODUCT_OVERVIEW';
export type OPEN_PRODUCT_OVERVIEW = typeof OPEN_PRODUCT_OVERVIEW;

export const TOGGLE_PRODUCT_OVERVIEW = 'TOGGLE_PRODUCT_OVERVIEW';
export type TOGGLE_PRODUCT_OVERVIEW = typeof TOGGLE_PRODUCT_OVERVIEW;

export const CLOSE_PRODUCT_OVERVIEW = 'CLOSE_PRODUCT_OVERVIEW';
export type CLOSE_PRODUCT_OVERVIEW = typeof CLOSE_PRODUCT_OVERVIEW;

export const OPEN_AMBIENT_MENU = 'OPEN_AMBIENT_MENU';
export type OPEN_AMBIENT_MENU = typeof OPEN_AMBIENT_MENU;

export const CLOSE_AMBIENT_MENU = 'CLOSE_AMBIENT_MENU';
export type CLOSE_AMBIENT_MENU = typeof CLOSE_AMBIENT_MENU;

export const TOGGLE_AMBIENT_MENU = 'TOGGLE_AMBIENT_MENU';
export type TOGGLE_AMBIENT_MENU = typeof TOGGLE_AMBIENT_MENU;

export const TOGGLE_MENU = 'TOGGLE_MENU';
export type TOGGLE_MENU = typeof TOGGLE_MENU;

export const OPEN_MODAL_RESET = 'OPEN_MODAL_RESET';
export type OPEN_MODAL_RESET = typeof OPEN_MODAL_RESET;

export const CLOSE_MODAL_RESET = 'CLOSE_MODAL_RESET';
export type CLOSE_MODAL_RESET = typeof CLOSE_MODAL_RESET;

export const OPEN_MODAL_SHARE = 'OPEN_MODAL_SHARE';
export type OPEN_MODAL_SHARE = typeof OPEN_MODAL_SHARE;

export const CLOSE_MODAL_SHARE = 'CLOSE_MODAL_SHARE';
export type CLOSE_MODAL_SHARE = typeof CLOSE_MODAL_SHARE;

export const OPEN_MODAL_PRIVACY = 'OPEN_MODAL_PRIVACY';
export type OPEN_MODAL_PRIVACY = typeof OPEN_MODAL_PRIVACY;

export const CLOSE_MODAL_PRIVACY = 'CLOSE_MODAL_PRIVACY';
export type CLOSE_MODAL_PRIVACY = typeof CLOSE_MODAL_PRIVACY;

export const TOGGLE_MODAL_PRIVACY = 'TOGGLE_MODAL_PRIVACY';
export type TOGGLE_MODAL_PRIVACY = typeof TOGGLE_MODAL_PRIVACY;

export const UPDATE_SOCIAL_MEDIA_SUCCESS = 'UPDATE_SOCIAL_MEDIA_SUCCESS';
export type UPDATE_SOCIAL_MEDIA_SUCCESS = typeof UPDATE_SOCIAL_MEDIA_SUCCESS;

export const UPDATE_SOCIAL_MEDIA_ERROR = 'UPDATE_SOCIAL_MEDIA_ERROR';
export type UPDATE_SOCIAL_MEDIA_ERROR = typeof UPDATE_SOCIAL_MEDIA_ERROR;

export const UPDATE_ATTACHED_FILES = 'UPDATE_ATTACHED_FILES';
export type UPDATE_ATTACHED_FILES = typeof UPDATE_ATTACHED_FILES;

export const SET_ZOOM_AT = 'SET_ZOOM_AT';
export type SET_ZOOM_AT = typeof SET_ZOOM_AT;

export const SHOW_MARKER = 'SHOW_MARKER';
export type SHOW_MARKER = typeof SHOW_MARKER;

export const TOGGLE_MARKER_VISIBILITY = 'TOGGLE_MARKER_VISIBILITY';
export type TOGGLE_MARKER_VISIBILITY = typeof TOGGLE_MARKER_VISIBILITY;

export const HIDE_MARKER = 'HIDE_MARKER';
export type HIDE_MARKER = typeof HIDE_MARKER;

export const SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER';
export type SET_LOGGED_IN_USER = typeof SET_LOGGED_IN_USER;

export const SET_USER_CONFIG = 'SET_USER_CONFIG';
export type SET_USER_CONFIG = typeof SET_USER_CONFIG;

export const SET_DISPLAY_RATIO_OPTIONS = 'SET_DISPLAY_RATIO_OPTIONS';
export type SET_DISPLAY_RATIO_OPTIONS = typeof SET_DISPLAY_RATIO_OPTIONS;

export const SET_MARKERS_HIGHLIGHTED = 'SET_MARKERS_HIGHLIGHTED';
export type SET_MARKERS_HIGHLIGHTED = typeof SET_MARKERS_HIGHLIGHTED;

export const SET_TUTORIAL_ACTIVE = 'SET_TUTORIAL_ACTIVE';
export type SET_TUTORIAL_ACTIVE = typeof SET_TUTORIAL_ACTIVE;

export const SET_MENU_RESTORE_STATE = 'SET_MENU_RESTORE_STATE';
export type SET_MENU_RESTORE_STATE = typeof SET_MENU_RESTORE_STATE;

import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../../state/ducks';
import MarkerComponent from './MarkerComponent';
import { ConfigOptionEnum } from '../../../../../state/ducks/system/interfaces';
import { MarkerAlignment } from './MarkerComponentUtil';

const MarkersComponent = (): JSX.Element => {
    const knobNodeList = ['knob_tf_right', 'knob_tf_left'];
    const mountNodeList = [
        'mount_tf_pos=top_rot=backLeft',
        'mount_tf_pos=top_rot=frontLeft',
        'mount_tf_pos=top_rot=backRight',
        'mount_tf_pos=top_rot=frontRight',

        'mount_tf_wholeSide_rot=frontLeft',
        'mount_tf_wholeSide_rot=backLeft',

        'mount_tf_wholeSide_rot=frontRight',
        'mount_tf_wholeSide_rot=backRight'
    ];

    const modelNodeList = [
        'normalHinge_tf_pos=bottom_rot=frontRight0',
        'normalHinge_tf_pos=bottom_rot=frontLeft0',
        'normalHinge_tf_pos=bottom_rot=backLeft0',
        'normalHinge_tf_pos=bottom_rot=backRight0',
        'normalHinge_tf_pos=bottom_rot=frontRight90',
        'normalHinge_tf_pos=bottom_rot=frontLeft90',
        'normalHinge_tf_pos=bottom_rot=backLeft90',
        'normalHinge_tf_pos=bottom_rot=backRight90',
        'normalHinge_tf_pos=bottom_rot=frontRight180',
        'normalHinge_tf_pos=bottom_rot=frontLeft180',
        'normalHinge_tf_pos=bottom_rot=backLeft180',
        'normalHinge_tf_pos=bottom_rot=backRight180',

        'slidingHinge_tf_touchesWall=start',
        'slidingHinge_tf_touchesWall=both',
        'slidingHinge_tf_touchesWall=none',

        'slidingHinge_base_tf_touchesWall=start',
        'slidingHinge_base_tf_touchesWall=both',
        'slidingHinge_base_tf_touchesWall=none'
    ];

    const beamNodeList = ['beam_tf_part2'];

    const config = useSelector((state: StoreState) => state.configurator.configuration);
    const blueprintId = useSelector((state: StoreState) => state.architecture.blueprint?.id);

    const knobKey = `${config.knob?.id}_${config.base.id}`;
    const mountKey = `${config.wallmount?.id}_${config.base.id}`;
    const beamKey = `${blueprintId}_${config.base.id}`;
    const modelKey = `${config.model?.id}_${config.base.id}`;

    return (
        <>
            <MarkerComponent
                key={`knob_${knobKey}`}
                connectToNodeName={knobNodeList}
                label="Griffe"
                labelWidthPx={78}
                connectToMenu={ConfigOptionEnum.Knob}
            />
            <MarkerComponent
                markerAlignment={MarkerAlignment.bottom}
                key={`mount_${mountKey}`}
                connectToNodeName={mountNodeList}
                label="Wandbefestigung"
                labelWidthPx={161}
                connectToMenu={ConfigOptionEnum.Wallmount}
            />
            <MarkerComponent
                markerAlignment={MarkerAlignment.top}
                key={`meas_${beamKey}`}
                connectToNodeName={beamNodeList}
                label="Haltestange"
                labelWidthPx={124}
                connectToMenu={ConfigOptionEnum.Beam}
            />
            <MarkerComponent
                key={`material_${modelKey}`}
                connectToNodeName={modelNodeList}
                label="Beschlag"
                labelWidthPx={103}
                connectToMenu={ConfigOptionEnum.MountInfo}
            />
        </>
    );
};

export default MarkersComponent;

import React, { ReactNode } from 'react';
import './ModalContent.component.scss';

interface ModalContentProps {
    children: ReactNode;
}

const ModalContent = ({ children }: ModalContentProps): JSX.Element => {
    return <div className="sp-modal-content">{children}</div>;
};

export default ModalContent;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { TAGLIST } from '../../../helper/tagHelper';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type ShowerValveComponentProps = {
    wallDepthMM: number;
    floorPositionY: number;
};

const ShowerValveComponent = ({ wallDepthMM, floorPositionY }: ShowerValveComponentProps): JSX.Element => {
    // Usually 3D-Objects are rendered on the "outside" of the (shower)-wall (e.g. knob).
    // In order to render the valve correctly on the inside we need to rotate it by 180deg and
    // move the object to the other side of the wall, depending on how thick/deep the wall is
    const showerValvePositionY = 130; // cm
    const materialId = useSelector((state: StoreState) => state.configurator.configuration.material.id);
    const filename = materialId === '1' ? `showervalve.glb` : `showervalve_black.glb`;
    const insideWallRotation = new Vector3(0, Math.PI, 0);
    const insideWallPosition = new Vector3(0, floorPositionY + showerValvePositionY, -wallDepthMM / 20);
    return (
        <transformNode name={'tf_shower_valve'} position={insideWallPosition} rotation={insideWallRotation}>
            <ThreeDComponent
                name={`showervalve_threeD`}
                rootUrl="assets/3d/showervalves/"
                sceneFilename={filename}
                tagList={[TAGLIST.REFLECTION]}
            />
        </transformNode>
    );
};

export default ShowerValveComponent;

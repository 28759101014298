import React from 'react';
import { ReactComponent as CopyLinkLogo } from '../../assets/icons/share/copy-link-logo.svg';
import 'firebase/analytics';
interface CopyLinkButtoProps {
    url: string;
    onAfterShare?: () => void;
}

const CopyLinkButton = ({ url, onAfterShare }: CopyLinkButtoProps): JSX.Element => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>): void => {
        e.preventDefault();
        e.stopPropagation();

        const el = document.createElement('textarea');
        el.value = url;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        onAfterShare?.();
    };

    return (
        <a onClick={onClick} href={url}>
            <CopyLinkLogo />
            <p className="share-text">Link teilen</p>
        </a>
    );
};

export default CopyLinkButton;

import React, { ReactNode, useState } from 'react';
import { useEffect } from 'react';
import DialogStepIndicatorComponent from '../step-indicator/DialogStepIndicator';
import './OnboardingDialogComponent.scss';
import close from '../../../assets/icons/common/close.svg';
import ButtonComponent from '../../button/ButtonComponent';

interface OnboardingDialogProps {
    zIndex?: number;
    totalAmountOfSteps: number;
    finishedText: string;
    onStepSelectionChanged?: (idx: number) => void;
    onAfterLastStep?: () => void;
    onDialogClosed?: () => void;
    onFinishedButtonClick?: () => void;
    children: ReactNode;
}

const OnboardingDialogComponent = ({
    zIndex,
    totalAmountOfSteps,
    finishedText,
    onStepSelectionChanged,
    onDialogClosed,
    onFinishedButtonClick,
    onAfterLastStep,
    children
}: OnboardingDialogProps): JSX.Element => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isFinished, setFinished] = useState(false);

    useEffect(() => {
        onStepSelectionChanged && onStepSelectionChanged(currentStep);
    }, [currentStep]);

    const nextStep = () => {
        if (currentStep < totalAmountOfSteps - 1) {
            setCurrentStep(currentStep + 1);
        } else {
            onAfterLastStep && onAfterLastStep();
            setFinished(true);
        }
    };
    return (
        <div className="onboarding-dialog" style={{ zIndex: zIndex ? zIndex : 'auto' }}>
            <div className="onboarding-dialog-header-wrapper">
                <div className={`onboarding-dialog-header ${isFinished && 'finished'}`}>
                    <a
                        className="close-button"
                        onClick={() => {
                            onDialogClosed && onDialogClosed();
                        }}
                    >
                        <img src={close} />
                    </a>
                    {isFinished ? (
                        <>
                            <div className="finish-headline">Startklar!</div>
                        </>
                    ) : (
                        <>
                            <div className="tutorial-headline">Tutorial</div>
                            <a className="next-button" onClick={nextStep}>
                                Weiter
                            </a>
                        </>
                    )}
                </div>
            </div>
            <div className="onboarding-dialog-content">
                {isFinished ? (
                    <>
                        <div className="finish-text">{finishedText}</div>
                        <ButtonComponent width={404} onClick={onFinishedButtonClick}>
                            Ok
                        </ButtonComponent>
                    </>
                ) : (
                    children
                )}
            </div>
            {!isFinished && (
                <div className="onboarding-dialog-footer">
                    <DialogStepIndicatorComponent
                        totalNumber={totalAmountOfSteps}
                        activeNumber={currentStep}
                        onActiveNumberChanged={setCurrentStep}
                    ></DialogStepIndicatorComponent>
                </div>
            )}
        </div>
    );
};

export default OnboardingDialogComponent;

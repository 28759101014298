import React, { useMemo, useCallback } from 'react';
import { ReactComponent as PinterestLogo } from '../../assets/icons/share/pinterest-logo.svg';
import 'firebase/analytics';
interface PinterestButtonProps {
    url: string;
    media: string;
    title: string;
    description: string;
    onAfterShare?: () => void;
}

const PinterestButton = ({ url, media, title, description, onAfterShare }: PinterestButtonProps): JSX.Element => {
    const link = useMemo(
        () =>
            'http://pinterest.com/pin/create/link/' +
            `?url=${encodeURIComponent(url)}` +
            `&media=${encodeURIComponent(media)}` +
            `&description=${encodeURIComponent(`${title} ${description}`)}`,
        [url, media, title, description]
    );

    const onClick = useCallback(() => {
        onAfterShare?.();
    }, [onAfterShare]);

    return (
        <a href={link} onClick={onClick} target="_blank" rel="noopener noreferrer">
            <PinterestLogo />
            <p className="share-text">Pinterest</p>
        </a>
    );
};

export default PinterestButton;

/* eslint-disable @typescript-eslint/no-explicit-any */
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map } from 'rxjs/operators';
import { StoreState } from '..';
import { AmbientOptions } from '../../../components/ambientMenu/AmbientMenuComponent.types';
import { encodeBase64UrlSave } from '../../../components/url/Url.utils';
import { convertToBlueprintLight } from '../../../ng/helper/blueprintHelper';
import { BlueprintUpdatedAction } from '../architecture/interfaces';
import { BLUEPRINT_UPDATED } from '../architecture/types';
import { SelectAmbientOptionAction, SelectConfigOptionAction } from '../configurator/interfaces';
import { SELECT_AMBIENT_OPTION, SELECT_CONFIG_OPTION } from '../configurator/types';
import { createNewParams } from '../configurator/utils';
import { UPDATE_SOCIAL_MEDIA_ERROR, UPDATE_SOCIAL_MEDIA_SUCCESS } from './types';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const LZUTF8 = require('lzutf8');

const updateSocialMediaEpic = (
    action$: Observable<SelectConfigOptionAction | BlueprintUpdatedAction | SelectAmbientOptionAction>,
    state$: StateObservable<StoreState>
): any | Observable<any> => {
    return action$.pipe(
        ofType(SELECT_CONFIG_OPTION, BLUEPRINT_UPDATED, SELECT_AMBIENT_OPTION),
        debounceTime(1000),
        map((action) => {
            const config = state$.value.configurator.configuration;
            const params = new URLSearchParams();
            const blueprint =
                action.type === 'BLUEPRINT_UPDATED' ? action.payload.blueprint : state$.value.architecture.blueprint;

            const roomConfigFloor =
                action.type === 'SELECT_AMBIENT_OPTION' && action.payload.optionType === AmbientOptions.Floor
                    ? action.payload.fileName
                    : state$.value.configurator.roomConfiguration.floor;

            const roomConfigWall =
                action.type === 'SELECT_AMBIENT_OPTION' && action.payload.optionType === AmbientOptions.Wall
                    ? action.payload.fileName
                    : state$.value.configurator.roomConfiguration.wall;

            const roomConfigShower =
                action.type === 'SELECT_AMBIENT_OPTION' && action.payload.optionType === AmbientOptions.Shower
                    ? action.payload.fileName
                    : state$.value.configurator.roomConfiguration.shower;

            const blueprintLight = convertToBlueprintLight(blueprint);

            const showerHeight = state$.value.architecture.showerBaseSize.y;

            const search = createNewParams(params, [
                { key: 's', value: config.series.id },
                { key: 'm', value: config.model.id },
                { key: 'b', value: config.base.id },
                { key: 'k', value: config.knob?.id ?? '' },
                { key: 'w', value: config.wallmount.id },
                { key: 'be', value: config.beam.id },
                { key: 'rcf', value: roomConfigFloor },
                { key: 'rcw', value: roomConfigWall },
                { key: 'rcs', value: roomConfigShower },
                { key: 'sh', value: `${showerHeight}` },
                { key: 'bpl', value: `${JSON.stringify(blueprintLight)}` }
            ]);

            const compressedSearch = LZUTF8.compress(search);
            const compressedSearchBuffer = Buffer.from(compressedSearch);

            const searchEncoded = encodeBase64UrlSave(compressedSearchBuffer);

            const baseUrl = 'https://' + window.location.hostname;
            const title = 'Meine persönliche Omega Dusche von SPRINZ.';
            const description = 'Nutzen Sie unseren Konfigurator um Ihre Dusche individuell zu gestalten.';
            const image = `${baseUrl}/img?id=${searchEncoded}`;
            const url = `${baseUrl}/?id=${searchEncoded}`;
            const crawlerUrl = `${baseUrl}/crawler?id=${searchEncoded}`;

            return {
                type: UPDATE_SOCIAL_MEDIA_SUCCESS,
                payload: {
                    socialMedia: {
                        title,
                        description,
                        image,
                        url,
                        crawlerUrl,
                        search: searchEncoded
                    }
                }
            };
        }),
        catchError((error) =>
            of({
                type: UPDATE_SOCIAL_MEDIA_ERROR,
                error
            })
        )
    );
};
export default combineEpics(updateSocialMediaEpic);

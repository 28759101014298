import { Vector2, Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../state/ducks';
import { applyRotationY, applyTranslation, getRotationYFromDirection } from './3d_helpers';
import CeilingComponent from './components/3d/BaseComponents/CeilingComponent';
import FloorComponent from './components/3d/BaseComponents/FloorComponent';
import RoomCollisionBoxComponent from './components/3d/BaseComponents/RoomCollisionBoxComponent';
import WallComponent from './components/3d/RoomBaseComponents/WallComponent';
import { calculateSideForRotation } from './helper/commonHelper';
import { calculateShowerOriginOffsetForWallRotation, calculateWallLength } from './helper/roomplanHelper';
import { RoomDecorator, RoomElement, RoomLayoutType } from './types/Roomplan';

// TODO Check where to get this variables acctually from
const wallDepthMM = 1;
export const wallHeightMM = 7000;
export const decoratorDepthMM = wallDepthMM + 10;

const createWall = (
    positionCM: Vector3,
    rotation: Vector3,
    lengthMM: number,
    showerSideMM: number,
    showerOriginOffsetMM: number,
    index: number,
    texture: string,
    decorators?: RoomDecorator[]
): JSX.Element => {
    return (
        <WallComponent
            positionCM={positionCM}
            rotation={rotation}
            widthMM={lengthMM}
            heightMM={wallHeightMM}
            depthMM={wallDepthMM}
            decoratorDepthMM={decoratorDepthMM}
            showerSideLengthMM={showerSideMM}
            decorators={decorators}
            texture={texture}
            textureBaseSize={new Vector2(800, 1200)}
            textureScaling={new Vector2(0.3, 0.3)}
            decoratorXOffsetMM={showerOriginOffsetMM}
            key={`wall_${index}+${texture}`}
        />
    );
};

type RoomplanLoaderComponentProps = {
    showerOriginMM: Vector3;
};

const RoomplanLoaderComponent = ({ showerOriginMM }: RoomplanLoaderComponentProps): JSX.Element => {
    const roomplan = useSelector((state: StoreState) => state.architecture.roomplan);
    const showerBaseSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const roomSizeAdjustment = useSelector((state: StoreState) => state.architecture.roomSizeAdjustment);
    const roomConfig = useSelector((state: StoreState) => state.configurator.roomConfiguration);

    if (roomplan !== null && showerBaseSize !== null && roomSizeAdjustment !== null) {
        // TODO Check if roomplan contains all we need in the code below
        let currentPositionCM = Vector3.Zero();
        let currentDirection = Vector3.Right();
        let currentRotation = getRotationYFromDirection(currentDirection);

        const roomWalls = roomplan.layout
            .map((element: RoomElement, index: number) => {
                let component = undefined;

                switch (element.type) {
                    case RoomLayoutType.Transition:
                        currentDirection = applyRotationY(currentDirection, element.angle);
                        currentRotation = getRotationYFromDirection(currentDirection);
                        break;
                    case RoomLayoutType.Wall:
                        const showerSideLengthMM = calculateSideForRotation(currentRotation, showerBaseSize);
                        const roomSideAdjustmentLengthMM = calculateSideForRotation(
                            currentRotation,
                            roomSizeAdjustment
                        );
                        const wallLengthMM = calculateWallLength(
                            element,
                            showerSideLengthMM,
                            roomSideAdjustmentLengthMM
                        );

                        const showerOriginOffsetMM = calculateShowerOriginOffsetForWallRotation(
                            currentRotation,
                            roomplan.id === 'recess' ? Vector3.Zero() : showerOriginMM,
                            element.baseLength,
                            roomSizeAdjustment
                        );

                        component = createWall(
                            currentPositionCM,
                            currentRotation,
                            wallLengthMM,
                            showerSideLengthMM,
                            showerOriginOffsetMM,
                            index,
                            roomConfig.wall,
                            element.decorators
                        );
                        currentPositionCM = applyTranslation(currentPositionCM, currentDirection, wallLengthMM / 10);

                        break;
                    default:
                        break;
                }

                return component;
            })
            .filter((w) => w); // remove "undefined" from list (caused by the transitions)

        return (
            <>
                <CeilingComponent />
                <RoomCollisionBoxComponent />
                {roomWalls}
                <FloorComponent />
            </>
        );
    } else {
        return <></>;
    }
};

export default RoomplanLoaderComponent;

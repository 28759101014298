import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { calculateBeamAxisForLayout } from '../../../ng/helper/beamHelper';
import { Axis } from '../../../ng/helper/commonHelper';
import { StoreState } from '../../../state/ducks';
import { Catalogue, Configuration, GeneralCatalogueItem } from '../../../state/ducks/configurator/interfaces';
import {
    getBeams,
    getDoors,
    getGlasses,
    getGlassmounts,
    getKnobs,
    getMaterials,
    getModels,
    getSeries,
    getWallmounts
} from '../../../state/ducks/configurator/utils';
import { ConfigOptionEnum } from '../../../state/ducks/system/interfaces';
import InfoDialogComponent from '../../common/info-dialog/InfoDialogComponent';
import SubMenuInfoComponent from '../../common/sub-menu-info/SubMenuInfoComponent';
import ConfigOptionItem from '../../config-option/config-option-item/ConfigOptionItem.component';
import GoToSubmenuComponent from '../GoToSubmenuComponent/GoToSubmenuComponent';
import ShowerBaseMenuOption from '../ShowerBaseMenuOption/ShowerBaseMenuOption.component';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../../ng/helper/analyticsHelper';

interface ConfigOptionPageProps {
    configOption: ConfigOptionEnum;
    config: Configuration;
    catalogue: Catalogue;
}

interface TabLabel {
    label: string;
    infoText?: string;
}

const isUnique = (item: string, index: number, self: string[]): boolean => self.indexOf(item) === index;

const getLabelsSubMenu = (configOptionList: GeneralCatalogueItem[], labelShowAllTypes: string | null): TabLabel[] => {
    const labels = configOptionList
        .filter((x) => x)
        .map((configOption) => configOption.type)
        .filter(isUnique)
        .map((label) => ({
            label,
            infoText: getInfoTextForLabel(label)
        }));
    labelShowAllTypes && labels.unshift({ label: labelShowAllTypes, infoText: undefined });

    return labels;
};

const getInfoTextForLabel = (label: string): string | undefined => {
    const listOfLabelInfotexts = [
        {
            label: 'Rahmenlos',
            infoText: 'Bei einer rahmenlosen Dusche verbinden Beschläge die Glaselemente der Wand oder Tür miteinander.'
        },
        {
            label: 'Teilgerahmt',
            infoText:
                'Bei einer teilgerahmten Dusche werden die Glaselemente mit Profilen an der Zimmerwand angebracht.'
        }
    ];
    return listOfLabelInfotexts.find((x) => x.label === label)?.infoText;
};

const getItems = (
    configOption: ConfigOptionEnum,
    config: Configuration,
    catalogue: Catalogue
): GeneralCatalogueItem[] => {
    switch (configOption) {
        case ConfigOptionEnum.Knob:
            return getKnobs(catalogue, config.series.id, config.model.id);
        case ConfigOptionEnum.Model:
            return getModels(catalogue, config.series.id);
        case ConfigOptionEnum.Wallmount:
            return getWallmounts(catalogue, config.series.id, config.model.id);
        case ConfigOptionEnum.Glassmount:
            return getGlassmounts(catalogue, config.series.id);
        case ConfigOptionEnum.Door:
            return getDoors(catalogue, config.series.id, config.model.id);
        case ConfigOptionEnum.Glass:
            return getGlasses(catalogue, config.series.id);
        case ConfigOptionEnum.Series:
            return getSeries(catalogue);
        case ConfigOptionEnum.Beam:
            return getBeams(catalogue, config.series.id, config.model.id);
        case ConfigOptionEnum.Material:
            return getMaterials(catalogue, config.series.id, config.model.id);
        case ConfigOptionEnum.Measurement:
            return getModels(catalogue, config.series.id);
        default:
            return [];
    }
};

const ConfigOptionPage = ({ configOption, config, catalogue }: ConfigOptionPageProps): JSX.Element => {
    const items = useMemo(() => getItems(configOption, config, catalogue), [configOption, config, catalogue]);
    const isSeries = configOption === ConfigOptionEnum.Series;
    const isModel = configOption === ConfigOptionEnum.Model;
    const isBeam = configOption === ConfigOptionEnum.Beam;
    const isKnob = configOption === ConfigOptionEnum.Knob;

    let hasBathtubUnderShower = false;
    let unavailableItemIds: string[] = [];

    if (isModel) {
        const bathtubConfig = useSelector((state: StoreState) => state.architecture.blueprint.bathtubConfig);
        hasBathtubUnderShower = bathtubConfig?.isUnderShower || false;
    } else if (isBeam) {
        unavailableItemIds = [];
        const blueprintLayout = useSelector((state: StoreState) => state.architecture.blueprint.layout);
        const showerBaseSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
        const possibleBeamTechConfigs = useSelector(
            (state: StoreState) => state.architecture.techConfigs.possibleBeams
        );
        const beamAxis = calculateBeamAxisForLayout(blueprintLayout);
        const showerBaseSizeForBeam =
            beamAxis === Axis.x ? showerBaseSize.x : beamAxis === Axis.z ? showerBaseSize.z : undefined;

        if (possibleBeamTechConfigs && showerBaseSizeForBeam) {
            items.forEach((beam) => {
                const beamTechConfig = possibleBeamTechConfigs.find((techConfig) => techConfig.id === beam.id);

                if ((!beamTechConfig || showerBaseSizeForBeam > beamTechConfig.sizeConstraints.max) && beam.id) {
                    unavailableItemIds.push(beam.id);
                }
            });
        }
    }

    const labelShowAllTypes = isSeries ? 'Alle Modelle' : null;
    const labelsSubMenu = useMemo(() => getLabelsSubMenu(items, labelShowAllTypes), [items]);

    const initalSelectedTab = isSeries
        ? 0
        : Math.max(
              labelsSubMenu.findIndex((submenuLabel) => submenuLabel.label === config[configOption]?.type),
              0
          );

    const [selectedTab, setSelectedTab] = useState(initalSelectedTab);

    const configOptionItems = useMemo(
        () =>
            items
                .filter((item) =>
                    labelsSubMenu[selectedTab].label === labelShowAllTypes
                        ? true
                        : item.type === labelsSubMenu[selectedTab].label
                )
                .map((item, index) => (
                    <ConfigOptionItem
                        key={item.id}
                        configOptionType={configOption}
                        labelNumber={index + 1}
                        item={item}
                        selected={item.id === config[configOption]?.id}
                        large={isSeries}
                        unavailable={unavailableItemIds.some((unavailableItemId) => unavailableItemId === item.id)}
                    ></ConfigOptionItem>
                )),
        [items, configOption, labelsSubMenu, config, selectedTab]
    );

    const tabsSubMenu = useMemo(() => {
        const submenuDisabled = isKnob && configOptionItems?.length === 0;

        const labels = labelsSubMenu.length > 0 ? labelsSubMenu : [{ label: 'Knopfgriffe' }, { label: 'Stossgriffe' }];

        return labels.map((itemOfSubMenu: TabLabel, index: number) => (
            <div
                onClick={(): void => {
                    logAnalyticsEvent(`configurator_select_submenu:${labelsSubMenu[index].label}`);
                    !submenuDisabled && setSelectedTab(index);
                }}
                className={`config-option-page-submenu-link  ${submenuDisabled ? 'disabled' : ''} ${
                    index === selectedTab && !submenuDisabled ? 'selected' : ''
                }`}
                key={itemOfSubMenu.label}
            >
                <div style={{ display: 'inline-block' }}>{itemOfSubMenu.label}</div>
                {itemOfSubMenu.infoText && <InfoDialogComponent>{itemOfSubMenu.infoText}</InfoDialogComponent>}
            </div>
        ));
    }, [labelsSubMenu, selectedTab]);

    const onlyNoBeamSelectable = isBeam && configOptionItems.length === 1 && configOptionItems[0].key === 'null';

    return (
        <>
            <div className="config-option-page-submenu">{tabsSubMenu}</div>
            <div className={'config-option-page-content'}>
                {(!isBeam || !onlyNoBeamSelectable) && (!isKnob || configOptionItems.length > 0) && (
                    <div className={`config-option-page-content-config-items-window ${isModel ? ' model' : ''}`}>
                        <div className={'config-option-page-content-config-items-window-content'}>
                            {configOptionItems}
                        </div>
                    </div>
                )}
                {isKnob && configOptionItems.length === 0 && (
                    <SubMenuInfoComponent infoText="Die ausgewählte Modellvariante wird serienmäßig ohne Griffe geliefert." />
                )}
                {onlyNoBeamSelectable && (
                    <SubMenuInfoComponent infoText="Die ausgewählte Modellvariante wird serienmäßig ohne Haltestange geliefert." />
                )}
                {isBeam && unavailableItemIds.length > 0 && (
                    <GoToSubmenuComponent
                        infoText="Beim Überschreiten der Maximalgröße kann keine Haltestange ausgewählt werden."
                        linkText="Zurück zu den Maßen."
                        submenu={ConfigOptionEnum.Measurement}
                    />
                )}
                {isModel && !hasBathtubUnderShower && <ShowerBaseMenuOption config={config} catalogue={catalogue} />}
            </div>
        </>
    );
};

export default ConfigOptionPage;

import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import menuCaretSvg from '../../../assets/icons/menu/back-icon.svg';
import sprinzLogoSvg from '../../../assets/icons/menu/sprinz-logo.svg';
import useZoomAt from '../../../hooks/useZoomAt';
import { StoreState } from '../../../state/ducks';
import { systemActions } from '../../../state/ducks/system';
import { ConfigOptionEnum } from '../../../state/ducks/system/interfaces';
import 'firebase/app';
import { logAnalyticsEvent } from '../../../ng/helper/analyticsHelper';

interface MenuHeaderComponentProps {
    configOption?: ConfigOptionEnum;
}

const MenuHeaderContent = ({ configOption }: MenuHeaderComponentProps): JSX.Element => {
    const [, setZoomAt] = useZoomAt();
    const userConfig = useSelector((state: StoreState) => state.system.userConfig);
    const dispatch = useDispatch();

    const closeMenuWith = (configOption: ConfigOptionEnum) => {
        dispatch(systemActions.closeMenuWith(configOption));
    };

    const navigateBack = useCallback(
        (ev: React.MouseEvent): void => {
            ev.stopPropagation();
            if (configOption) {
                closeMenuWith?.(configOption);
                setZoomAt();
            }
        },
        [closeMenuWith, configOption]
    );

    const navigateToUrl = useCallback(
        (ev: React.MouseEvent, url: string): void => {
            logAnalyticsEvent('configurator_open_homepage_url');
            ev.stopPropagation();
            window.open(url);
        },
        [userConfig]
    );

    let subMenuLabel;
    switch (configOption) {
        case ConfigOptionEnum.Series:
            subMenuLabel = 'Modell';
            break;
        case ConfigOptionEnum.Model:
            subMenuLabel = 'Modellvariante';
            break;
        case ConfigOptionEnum.Knob:
            subMenuLabel = 'Griffe';
            break;
        case ConfigOptionEnum.Beam:
            subMenuLabel = 'Zubehör';
            break;
        case ConfigOptionEnum.Wallmount:
            subMenuLabel = 'Wandbefestigung';
            break;
        case ConfigOptionEnum.Door:
            subMenuLabel = 'Türen';
            break;
        case ConfigOptionEnum.Glass:
            subMenuLabel = 'Glas';
            break;
        case ConfigOptionEnum.Measurement:
            subMenuLabel = 'Masse';
            break;
        case ConfigOptionEnum.Material:
            subMenuLabel = 'Beschlagsfarben';
            break;
        case ConfigOptionEnum.MountInfo:
            subMenuLabel = 'Beschlag';
            break;
        default:
            break;
    }

    return configOption ? (
        <>
            <div className="menu-header-content-back-button" onClick={navigateBack}>
                <img src={menuCaretSvg} alt="Go back to Main Menu" className="menu-header-content-caret" />
            </div>
            <label className="menu-header-content-label">{subMenuLabel}</label>
        </>
    ) : (
        <div className="logo-wrapper">
            <img
                onClick={(e) => navigateToUrl(e, userConfig?.logoUrl || 'https://www.sprinz.eu')}
                src={userConfig?.logoPath || sprinzLogoSvg}
                alt="Sprinz Logo"
                className={`logo menu-click ${userConfig?.logoUrl && 'retailer'}`}
                draggable="false"
            />
        </div>
    );
};

export default MenuHeaderContent;

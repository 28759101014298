import { ArcRotateCamera, Vector2, Vector3 } from '@babylonjs/core';
import React, { useEffect, useMemo, useRef } from 'react';
import { CreatedInstance } from 'react-babylonjs';
import { useSelector } from 'react-redux';
import useDebugMode from '../../../../hooks/useDebugMode';
import useUpdateBJSCtorParameter from '../../../../hooks/useUpdateBJSCtorParameter';
import { StoreState } from '../../../../state/ducks';
import { getInitialCameraPosition, isInsideShower, isOutsideRoom } from '../../../helper/cameraHelper';
import { TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
export const collisionRadiusX = 60;
export const collisionRadiusZ = 60;
export const safetyCollisionRadius = 10;

const ShowerCameraComponent = (): JSX.Element => {
    const collisionRadius = new Vector3(collisionRadiusX, 80, collisionRadiusZ);
    const cameraRef = useRef<CreatedInstance<ArcRotateCamera>>();
    const menuOpen = useSelector((state: StoreState) => state.system.menu.menuOpen);
    const showerBaseSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const blueprint = useSelector((state: StoreState) => state.architecture.blueprint);
    const roomplan = useSelector((state: StoreState) => state.architecture.roomplan);
    const initialCameraPosition = useMemo(() => getInitialCameraPosition(blueprint), [blueprint?.id]);
    const [debugModeActive] = useDebugMode();
    const roomSize = useSelector((state: StoreState) => state.architecture.roomInfo.size);

    // Move to the side when menu opens
    const targetScreenOffset = useMemo(() => (menuOpen ? new Vector2(50, 0) : Vector2.Zero()), [menuOpen]);

    // Read shower origin from blueprint
    const showerOriginMM = useMemo(
        () => (blueprint ? new Vector3(blueprint.origin.x, blueprint.origin.y, blueprint.origin.z) : Vector3.Zero()),
        [blueprint]
    );

    // Set target to be the shower
    const target = useMemo(
        () =>
            showerOriginMM
                .add(
                    new Vector3(
                        showerOriginMM?.x ? (showerBaseSize?.x || 0) / 2 : 0,
                        showerBaseSize?.y ? showerBaseSize.y / 2 : 1000,
                        showerOriginMM?.z ? (showerBaseSize?.z || 0) / 2 : 0
                    )
                )
                .divide(new Vector3(10, 10, 10)),
        [showerOriginMM, showerBaseSize]
    );

    // update target when is changes
    useEffect(() => {
        useUpdateBJSCtorParameter<ArcRotateCamera>(cameraRef, {
            target,
            targetScreenOffset
        });
    }, [target, targetScreenOffset]);

    // Set camera back into room, when outside of room or inside shower
    useEffect(() => {
        const cameraGlobalPos = cameraRef.current?.hostInstance?.globalPosition;

        if (cameraGlobalPos && roomSize && showerBaseSize && roomplan) {
            const roomSizeCM = roomSize.divide(new Vector3(10, 10, 10));
            const [outsideRoomOnXAxis, outsideRoomOnZAxis] = isOutsideRoom(cameraGlobalPos, roomSizeCM);

            const [insideShowerOnXAxis, insideShowerOnZAxis] =
                roomplan.id === 'recess'
                    ? isInsideShower(
                          cameraGlobalPos,
                          new Vector3(roomSizeCM.x * 10, 0, showerBaseSize.z),
                          Vector3.Zero()
                      )
                    : isInsideShower(cameraGlobalPos, showerBaseSize, showerOriginMM);

            if (insideShowerOnXAxis || insideShowerOnZAxis || outsideRoomOnXAxis || outsideRoomOnZAxis) {
                useUpdateBJSCtorParameter<ArcRotateCamera>(cameraRef, {
                    position: new Vector3(
                        roomSizeCM.x - collisionRadiusX - safetyCollisionRadius,
                        cameraGlobalPos.y,
                        -roomSizeCM.z + collisionRadiusZ + safetyCollisionRadius
                    )
                });
                return;
            }
        }
    }, [roomSize]);

    // Update cam initial position
    useEffect(() => {
        useUpdateBJSCtorParameter<ArcRotateCamera>(cameraRef, initialCameraPosition);
    }, [initialCameraPosition]);

    useEffect(() => {
        useUpdateBJSCtorParameter<ArcRotateCamera>(cameraRef, {
            checkCollisions: !debugModeActive
        });
    }, [debugModeActive]);

    return (
        <arcRotateCamera
            ref={cameraRef}
            name={'camera_arcRotateCamera'}
            target={target}
            targetScreenOffset={targetScreenOffset}
            alpha={initialCameraPosition.alpha}
            beta={initialCameraPosition.beta}
            radius={initialCameraPosition.radius}
            inertia={0.6}
            checkCollisions
            collisionRadius={collisionRadius}
            layerMask={TOTAL_LAYERMASK}
        />
    );
};

export default ShowerCameraComponent;

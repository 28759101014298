import React from 'react';
import { useState } from 'react';
import './ProductOverViewDiscountInfoComponent.scss';

interface ProductOverviewDiscountProps {
    startDate: Date;
    endDate: Date;
    orderDeadline: Date;
    percentage: number;
}

const ProductOverViewDiscountComponent = ({
    startDate,
    endDate,
    orderDeadline,
    percentage
}: ProductOverviewDiscountProps): JSX.Element => {
    const [discountClosed, setDiscountClosed] = useState(true);

    return (
        <div className={`discount-info ${discountClosed && 'closed'}`}>
            <div className="discount-info-display" onClick={() => setDiscountClosed(!discountClosed)}>
                <strong className="discount-info-display-text">
                    {`-${percentage}% bis zum ${endDate.getDate()}.${endDate.getMonth() + 1}.${
                        endDate.getFullYear() % 1000
                    }*`}
                </strong>
                <svg className={`discount-info-display-caret ${!discountClosed && 'opened'}`}></svg>
            </div>
            <div className="discount-info-disclaimer">
                *Diese Aktion gilt ausschließlich für Duschen, die im Aktionszeitraum {startDate.getDate()}.
                {startDate.getMonth() + 1}.{startDate.getFullYear()} - {endDate.getDate()}.{endDate.getMonth() + 1}.
                {endDate.getFullYear()} über den Konfigurator erstellt und unverbindlich angefragt werden. Das
                anschließende Angebot behält seine Gültigkeit bei Bestelleingang bis spätestens{' '}
                {orderDeadline.getDate()}.{orderDeadline.getMonth() + 1}.{orderDeadline.getFullYear()}
            </div>
        </div>
    );
};

export default ProductOverViewDiscountComponent;

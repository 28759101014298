import { Vector3 } from '@babylonjs/core';
import { BathtubConfiguration } from '../types/Blueprint';

export const bathtubDefaultDepthMM = 900;
export const bathtubDefaultLengthMM = 1800;
export const bathtubDefaultHeightMM = 550;

export const bathtubElementOverlapMM = 70;

export const bathtubDrainSpaceMM = 100;
export const bathtubShowerSizeOverlapWhenUnderneathMM = 10;

export const calculateBathtubSizeMM = (
    bathtubConfig: BathtubConfiguration,
    showerSize: Vector3,
    cornerPieceBaseSizeMM: number,
    drainOffset: number
): Vector3 => {
    const bathtubBaseSizeMin = 2 * cornerPieceBaseSizeMM;
    let size = new Vector3(bathtubDefaultLengthMM, -1, bathtubDefaultDepthMM);

    if (bathtubConfig.isUnderShower) {
        size = showerSize.clone();
        size.x += bathtubShowerSizeOverlapWhenUnderneathMM;
        size.z += bathtubShowerSizeOverlapWhenUnderneathMM;
    }

    if (bathtubConfig.sizeOffset) {
        size.addInPlaceFromFloats(bathtubConfig.sizeOffset.x, bathtubConfig.sizeOffset.y, bathtubConfig.sizeOffset.z);
    }

    size.x = Math.max(size.x, bathtubBaseSizeMin + drainOffset + bathtubDrainSpaceMM);
    size.z = Math.max(size.z, bathtubBaseSizeMin);

    return size;
};

export const calculateBathtubOriginMM = (
    bathtubConfig: BathtubConfiguration,
    showerOrigin: Vector3,
    showerSize: Vector3
): Vector3 => {
    const origin = showerOrigin.clone();

    if (!bathtubConfig.isUnderShower) {
        origin.addInPlaceFromFloats(showerSize.x, 0, 0);
    }

    return origin;
};

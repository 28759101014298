/** -------------------------------------------------------------------------------ONBOARDING COMPONENT------------------------------------------------------------------------------
 * Component that uses a State machine to manage the tutorial
 * The Tutorial consist of several steps. One Step can consist of several "OnboardingStep"´s
 * The mapping of the tutorial steps to the Onboarding steps is done by using the "tutorialStepToInternalStepMapping"
 * which stores the number of the first OnboardingStep that belongs to the tutorial step.
 *
 * CURSOR-SIMULATION:
 * --------------------
 * To draw the users attention, a spherical indicator is used (css selector: "onboarding-pointer") which represents the mouse cursor.
 * This cursor has an animation which represents a mouse click.
 * You can define for each step a function, that will be executed, when the mouse click animation has finished (onboardingstep.onPointerClick).
 * As well as a an element, where Mouse cursor should move and point to befor tha click animation will be played (onboardingStep.pointerTarget).
 * This system is implemented using eventlisteners of the mouse-indicator/pointer element. The movement of the cursor is realized using css transition.
 * So css will automatically animate the movement of the cursor, when we change the position. So when the transition is finished, the event 'transitionend' will be executed and all registerde callback functions executed. In our case, the Mouse click animation. To execute the 'onPointerClick()' function.´,
 * We attach an callback function to the pointer/cursors event 'onanimationend', which will be executed, when the animation is finished.
 *
 * A problem with the transition approach is, if the cursor/pointer has already the targets position/ doesnt change the position since the last step, no transition will be executed.
 * So we are checking also if there is a difference between the targets position and our current pointer position (pointerIsOnTargetElement(pointer, target)).
 * If yes, so we have a transition, if not, we dont have.
 *
 *
 * ONBOARDING-STEPS:
 * -------------------
 * Stepst with event listenser:
 * The Menu component and the Productoverview use some transition/animation when they change their state and got collaed/opened. So we had problem to get the position when the animation is finished.
 * So for example the pointer gets the position of the Menubutton when the animation starts, but should be on the position when the animation is over, and the menu has moved.
 * To fix this, we added in some steps also a listener to the 'animationend' event of the menu or the 'transitionend' event of the productoverview.
 * This is done by using the functions addEventListenerByQuery and removeEventListenerByQuery.
 * So the cursor gets the position when the animation/transition is over.
 *
 * TIMER/ NEXTSTEP DELAY:
 * ---------------------------
 * A lot of Tutorial steps show how a element like a button gets clicked/toggled and then toggled/clicked again. This is realized using the function 'delayFunction' which internally sets a timer (setTimeout) which executes the passed callback function. The timeout/timer id is stored in the variable currTimeout and well be canceled it necessary in 'onStepEnd' using the 'cleanUp' function.
 *
 * HIGHLIGHTING:
 * ----------------------
 * For the Highlighting we used a semi-transparent div (css class: "onboarding-dark-area"), which covers the whole Screen. To realize the highlighting, we used 2 Techniques.
 * 1.) The first one is to grey out all the elements, that are not the highlightet object.
 *
 * 2.) The last one, ist usage of the css property "box-shadow". So the element gets an large Box shadow with an additional high Z-Value. Thats in case, we want to highlight an object, that is within another Object. So for example the "Angebot anfragen" Button will be highlighted using this technique. In addition to that, the Menu needs to be behind the grey out area, so the z-index is increased at this step.
 *
 */

import React, { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import OnboardingDialogComponent from './onboarding-dialog/OnboardingDialogComponent';
import './OnboardingComponent.scss';
import { useDispatch, useSelector } from 'react-redux';
import { systemActions } from '../../state/ducks/system';
import {
    addEventListenerByQuery,
    alignPointerWithElement,
    anyFunction,
    applyBoxShadow,
    menuButtonSelector,
    menuSelector,
    productOverviewButtonSelector,
    productOverviewSelector,
    removeEventListenerByQuery,
    pointerIsOnTargetElement,
    undoBoxShadow,
    greyOutElementByQuery,
    undoGreyOutElementByQuery,
    getMenuHTMLElement
} from './Onboarding.utils';
import StartInfoComponent from '../start_info/StartInfoComponent';
import { StoreState } from '../../state/ducks';

type OnboardingStep = {
    highlightTargets?: string[]; //Element which should be highlighted using the onboarding-highlighter-rectangle
    dialogText: string; //The Text, which shoud be Displayed on the Tutorials Dialog Component
    pointerTarget?: string; //Element, where Pointer/Cursor should move to/ click on
    onStepBegin?: () => void; //Function that will be executed at the beginning of a step, to set up all necessary stuff
    onPointerClick?: () => void; //Function that will be executed, when the Pointer Click Animation if finished
    onStepEnd?: () => void; //Changes, that needs to be cleaned up befor go to next step
};

interface OnboardingComponentProps {
    onTutorialSkip?: () => void;
    onTutorialDialogClose?: () => void;
    onOnboardingFinished?: () => void;
}

const OnboardingComponent = ({
    onTutorialSkip,
    onTutorialDialogClose,
    onOnboardingFinished
}: OnboardingComponentProps): JSX.Element => {
    const tutorialActive = useSelector((state: StoreState) => state.system.tutorialActive);
    //The Reference, that simulates the mouse cursor/click
    const pointerRef = useRef<HTMLDivElement>(null);
    //Reference to the semi transparent grey area, that covers the whole screen
    const darkAreaRef = useRef<HTMLDivElement>(null);

    const [internalStepNumber, setInternalStep] = useState(0);
    const currTimeout = useRef<NodeJS.Timeout | null>();
    const dispatch = useDispatch();
    const tutorialStepToInternalStepMapping = [0, 2, 4, 5, 7, 9];
    const allElementList = [
        menuSelector,
        '#canvas',
        '#share-button',
        '#ambient-button',
        '#reset-button',
        '#markervisible-button',
        '#debug-button'
    ];

    const alignPointer = (targetSelector: string) => {
        const targetElement = document.querySelector<HTMLDivElement>(targetSelector);
        pointerRef.current && targetElement && alignPointerWithElement(pointerRef.current, targetElement);
    };

    const alignPointerWithMenuButton = () => {
        alignPointer(menuButtonSelector);
    };

    const alignPointerWithProductOverviewButton = () => {
        alignPointer(productOverviewButtonSelector);
    };

    const alignPointerWithFooterButton = () => {
        alignPointer('.footer-right');
    };

    const hideGreyOutLayer = () => {
        darkAreaRef.current?.style.setProperty('visibility', 'hidden');
    };

    const showGreyOutLayer = () => {
        darkAreaRef.current?.style.removeProperty('visibility');
    };

    const greyOutElementsBesidesExcludedByQuery = (elementsList: string[], excludedObject: string[]) => {
        elementsList.forEach((element) => {
            if (excludedObject.indexOf(element) < 0) {
                greyOutElementByQuery(element);
            }
        });
    };

    const undoGreyOutElementsByQuery = (elementsList: string[]) => {
        elementsList.forEach((element: string) => {
            undoGreyOutElementByQuery(element);
        });
    };

    const setPointerVisibility = (visible: boolean) => {
        if (visible) {
            pointerRef.current && pointerRef.current.style.setProperty('visibility', 'visible');
        } else {
            pointerRef.current && pointerRef.current.style.setProperty('visibility', 'hidden');
        }
    };

    const nextInternalStep = () => {
        if (internalStepNumber <= onboardingSteps.length) {
            setInternalStep(internalStepNumber + 1);
        }
    };

    const delayFunction = (func: anyFunction, delayTime: number) => {
        currTimeout.current = setTimeout(func, delayTime);
    };

    const setTutorialStep = (stepNo: number) => setInternalStep(tutorialStepToInternalStepMapping[stepNo]);

    //Functions to repeat the steps, so that the animation at onboarding is in a loop.
    //The functions will be added at the end of each tutorial step, to start the step again.
    //The delay is to add some pause
    const resetDelayTime = 2000;
    const resetTutorialStep0 = () => {
        delayFunction(() => setTutorialStep(0), resetDelayTime);
    };

    const resetTutorialStep1 = () => {
        delayFunction(() => setTutorialStep(1), resetDelayTime);
    };

    const resetTutorialStep3 = () => {
        delayFunction(() => setTutorialStep(3), resetDelayTime);
    };

    const resetTutorialstep4 = () => {
        delayFunction(() => setTutorialStep(4), resetDelayTime);
    };

    const onboardingSteps: OnboardingStep[] = [
        {
            //Menu Close
            highlightTargets: [menuSelector],
            pointerTarget: menuButtonSelector,
            dialogText:
                'Das <span class="highlightet-text">Konfigurationsmenü</span> ist ein-und ausklappbar. Jeder Menüpunkt kann zu jedem Zeitpunkt angewählt werden.',
            onStepBegin: () => {
                dispatch(systemActions.openMenu());
                addEventListenerByQuery('animationend', menuSelector, alignPointerWithMenuButton);
            },
            onPointerClick: () => {
                removeEventListenerByQuery('animationend', menuSelector, alignPointerWithMenuButton);
                dispatch(systemActions.closeMenu());
                addEventListenerByQuery('animationend', menuSelector, nextInternalStep);
            },
            onStepEnd: () => {
                removeEventListenerByQuery('animationend', menuSelector, alignPointerWithMenuButton);
                removeEventListenerByQuery('animationend', menuSelector, nextInternalStep);
            }
        },
        //Menu Open
        {
            highlightTargets: [menuSelector],
            pointerTarget: menuButtonSelector,
            dialogText:
                'Das <span class="highlightet-text">Konfigurationsmenü</span> ist ein-und ausklappbar. Jeder Menüpunkt kann zu jedem Zeitpunkt angewählt werden.',
            onStepBegin: () => {
                dispatch(systemActions.closeMenu());
                addEventListenerByQuery('animationend', menuSelector, alignPointerWithMenuButton);
            },
            onPointerClick: () => {
                removeEventListenerByQuery('animationend', menuSelector, alignPointerWithMenuButton);
                dispatch(systemActions.openMenu());
                addEventListenerByQuery('animationend', menuSelector, alignPointerWithMenuButton);
                pointerRef.current?.addEventListener('transitionend', resetTutorialStep0);
            },
            onStepEnd: () => {
                removeEventListenerByQuery('animationend', menuSelector, alignPointerWithMenuButton);
                pointerRef.current?.removeEventListener('transitionend', resetTutorialStep0);
                dispatch(systemActions.openMenu());
            }
        },
        //Open Product Overview
        {
            highlightTargets: [menuSelector],
            pointerTarget: productOverviewButtonSelector,
            dialogText:
                'Die <span class="highlightet-text">Produktübersicht</span> ist eine Zusammenfassung der Konfiguration inklusive unverbindlicher Preisauskunft.',
            onStepBegin: () => {
                dispatch(systemActions.openMenu());
                dispatch(systemActions.closeProductOverview());
                addEventListenerByQuery('animationend', menuSelector, alignPointerWithProductOverviewButton);
                addEventListenerByQuery(
                    'transitionend',
                    productOverviewSelector,
                    alignPointerWithProductOverviewButton
                );
            },
            onPointerClick: () => {
                removeEventListenerByQuery('animationend', menuSelector, alignPointerWithProductOverviewButton);
                removeEventListenerByQuery(
                    'transitionend',
                    productOverviewSelector,
                    alignPointerWithProductOverviewButton
                );
                dispatch(systemActions.openProductOverview());
                addEventListenerByQuery('transitionend', productOverviewSelector, nextInternalStep);
            },
            onStepEnd: () => {
                removeEventListenerByQuery('animationend', menuSelector, alignPointerWithProductOverviewButton);
                removeEventListenerByQuery(
                    'transitionend',
                    productOverviewSelector,
                    alignPointerWithProductOverviewButton
                );
                removeEventListenerByQuery('transitionend', productOverviewSelector, nextInternalStep);
                dispatch(systemActions.closeProductOverview());
            }
        },
        //Close product overview
        {
            highlightTargets: [menuSelector],
            pointerTarget: productOverviewButtonSelector,
            dialogText:
                'Die <span class="highlightet-text">Produktübersicht</span> ist eine Zusammenfassung der Konfiguration inklusive unverbindlicher Preisauskunft.',
            onStepBegin: () => {
                dispatch(systemActions.openMenu());
                dispatch(systemActions.openProductOverview());
                addEventListenerByQuery(
                    'transitionend',
                    productOverviewSelector,
                    alignPointerWithProductOverviewButton
                );
            },
            onPointerClick: () => {
                removeEventListenerByQuery(
                    'transitionend',
                    productOverviewSelector,
                    alignPointerWithProductOverviewButton
                );
                dispatch(systemActions.closeProductOverview());
                addEventListenerByQuery(
                    'transitionend',
                    productOverviewSelector,
                    alignPointerWithProductOverviewButton
                );
                pointerRef.current?.addEventListener('transitionend', resetTutorialStep1);
            },
            onStepEnd: () => {
                removeEventListenerByQuery(
                    'transitionend',
                    productOverviewSelector,
                    alignPointerWithProductOverviewButton
                );
                pointerRef.current?.removeEventListener('transitionend', resetTutorialStep1);
                dispatch(systemActions.closeProductOverview());
            }
        },
        //Highlight 3D Markers
        {
            highlightTargets: ['#markervisible-button', '#canvas'],
            dialogText:
                'Über Die <span class="highlightet-text">Hinweise</span> im 3D-Modell kann die Detailansicht verschiedener Elemente aufgerufen werden.',
            onStepBegin: () => {
                dispatch(systemActions.setMarkersHighlighted(true));
                setPointerVisibility(false);
            },
            onStepEnd: () => {
                dispatch(systemActions.setMarkersHighlighted(false));
                setPointerVisibility(true);
            }
        },
        //Hide Markers
        {
            highlightTargets: ['#markervisible-button', '#canvas'],
            pointerTarget: '#markervisible-button',
            onPointerClick: () => {
                dispatch(systemActions.hideMarker());
            },
            dialogText:
                'Mit dem <span class="highlightet-text">Auge-Button</span> können die Hinweise für die Detailansicht ein- und ausgeschaltet werden. ',
            onStepBegin: () => {
                dispatch(systemActions.setMarkersHighlighted(true));
                dispatch(systemActions.showMarker());
                delayFunction(nextInternalStep, 2000);
            },
            onStepEnd: () => {
                dispatch(systemActions.showMarker());
                dispatch(systemActions.setMarkersHighlighted(false));
            }
        },
        //Show Markers Markers
        {
            highlightTargets: ['#markervisible-button', '#canvas'],
            pointerTarget: '#markervisible-button',
            onPointerClick: () => {
                dispatch(systemActions.showMarker());
                resetTutorialStep3();
            },
            dialogText:
                'Mit dem <span class="highlightet-text">Auge-Button</span> können die Hinweise für die Detailansicht ein- und ausgeschaltet werden. ',
            onStepBegin: () => {
                dispatch(systemActions.setMarkersHighlighted(true));
                dispatch(systemActions.hideMarker());
            },
            onStepEnd: () => {
                dispatch(systemActions.showMarker());
                dispatch(systemActions.setMarkersHighlighted(false));
            }
        },
        //Show Ambient Menu
        {
            highlightTargets: ['#ambient-button'],
            pointerTarget: '#ambient-button',
            dialogText:
                'Mit dem <span class="highlightet-text">Pinsel</span> können Wand-, Boden-, und Duschenfliesen des 3D-Raums geändert werden. ',
            onStepBegin: () => dispatch(systemActions.closeAmbientMenu()),
            onPointerClick: () => {
                delayFunction(nextInternalStep, 800);
                dispatch(systemActions.openAmbientMenu());
            },
            onStepEnd: () => dispatch(systemActions.closeAmbientMenu())
        },
        //Hide Ambient Menu
        {
            highlightTargets: ['#ambient-button'],
            pointerTarget: '#ambient-button',
            dialogText:
                'Mit dem <span class="highlightet-text">Pinsel</span> können Wand-, Boden-, und Duschenfliesen des 3D-Raums geändert werden. ',
            onStepBegin: () => {
                dispatch(systemActions.openAmbientMenu());
            },
            onPointerClick: () => {
                dispatch(systemActions.closeAmbientMenu());
                resetTutorialstep4();
            },
            onStepEnd: () => dispatch(systemActions.closeAmbientMenu())
        },
        //Highlight footer
        {
            pointerTarget: '.footer-right',
            dialogText:
                'Sie haben Fragen oder wollen ein unverbindliches Angebot anfragen? Dann <span class="highlightet-text">kontaktieren</span> Sie uns hier!',
            onStepBegin: () => {
                dispatch(systemActions.openMenu());
                dispatch(systemActions.closeProductOverview());
                addEventListenerByQuery('animationend', menuSelector, alignPointerWithFooterButton);
                addEventListenerByQuery('transitionend', productOverviewSelector, alignPointerWithFooterButton);
                hideGreyOutLayer();
                applyBoxShadow('.footer-right');
                getMenuHTMLElement()?.style.setProperty('z-index', '999');
            },
            onStepEnd: () => {
                showGreyOutLayer();
                getMenuHTMLElement()?.style.removeProperty('z-index');
                removeEventListenerByQuery('animationend', menuSelector, alignPointerWithFooterButton);
                removeEventListenerByQuery('transitionend', productOverviewSelector, alignPointerWithFooterButton);
                undoBoxShadow('.footer-right');
            }
        }
    ];

    const playPointerClickAnimation = () => {
        pointerRef.current?.classList.remove('pointer-animation');
        pointerRef.current?.classList.add('pointer-animation');
    };

    const pointerTransitionClick = () => {
        playPointerClickAnimation();
    };

    const stopPointerAnimation = () => {
        pointerRef.current?.classList.remove('pointer-animation');
    };

    const cleanUp = () => {
        stopPointerAnimation();
        if (currTimeout.current != null) {
            clearTimeout(currTimeout.current);
            currTimeout.current = null;
        }
        pointerRef.current?.removeEventListener('transitionstart', stopPointerAnimation);
        const oldStep = onboardingSteps[internalStepNumber];
        if (oldStep.onPointerClick) {
            pointerRef.current?.removeEventListener('transitionend', pointerTransitionClick);
            pointerRef.current?.removeEventListener('animationend', oldStep.onPointerClick);
        }

        oldStep.highlightTargets && showGreyOutLayer();
        oldStep.highlightTargets && undoGreyOutElementsByQuery(allElementList);
        oldStep.onStepEnd && oldStep.onStepEnd();
    };

    const onTutorialStepChanged = (newStepNumber: number) => {
        cleanUp();
        setInternalStep(tutorialStepToInternalStepMapping[newStepNumber]);
    };

    const onNewStep = () => {
        if (tutorialActive) {
            const newStep = onboardingSteps[internalStepNumber];
            newStep.onStepBegin && newStep.onStepBegin();

            /*Check if Pointer is already at the same position as 'pointerTargetElement'.
             * It this is the case, then execute Pointerclick animation
             * Otherwise play the Animation at the end of transition
             * The function 'onPointerClick' will be executed at the end of the PointerClick animation
             */
            let clickOnStart = false;
            if (newStep.pointerTarget && pointerRef.current) {
                const pointerTargetElement = document.querySelector<HTMLElement>(newStep.pointerTarget);
                if (pointerTargetElement && !pointerIsOnTargetElement(pointerRef.current, pointerTargetElement)) {
                    playPointerClickAnimation();
                    clickOnStart = true;
                }
                pointerTargetElement && alignPointerWithElement(pointerRef.current, pointerTargetElement);
            }

            if (newStep.onPointerClick) {
                pointerRef.current?.addEventListener('animationend', newStep.onPointerClick, { once: true });
                !clickOnStart &&
                    pointerRef.current?.addEventListener('transitionend', pointerTransitionClick, { once: true });
            }
            newStep.highlightTargets && greyOutElementsBesidesExcludedByQuery(allElementList, newStep.highlightTargets);
            newStep.highlightTargets && hideGreyOutLayer();
        }
    };

    useEffect(() => {
        if (tutorialActive) onNewStep();
    }, [internalStepNumber]);

    useEffect(() => {
        window.addEventListener('resize', onNewStep);
        return () => {
            cleanUp();
            window.removeEventListener('resize', onNewStep);
        };
    });

    useEffect(() => {
        if (tutorialActive) {
            onNewStep();
        } else {
            cleanUp();
        }
    }, [tutorialActive]);

    return (
        <div>
            {!tutorialActive && (
                <StartInfoComponent
                    onTutorialStart={() => dispatch(systemActions.setTutorialActive(true))}
                    onTutorialSkipClick={() => {
                        onTutorialSkip && onTutorialSkip();
                    }}
                />
            )}
            {tutorialActive && (
                <>
                    <div ref={pointerRef} className="onboarding-pointer"></div>
                    <div className="onboarding-click-blocker"></div>
                    <div ref={darkAreaRef} className="onboarding-dark-area"></div>
                    <OnboardingDialogComponent
                        onDialogClosed={() => {
                            onTutorialDialogClose && onTutorialDialogClose();
                            dispatch(systemActions.setTutorialActive(false));
                        }}
                        onAfterLastStep={() => {
                            cleanUp();
                            setPointerVisibility(false);
                        }}
                        finishedText="Jetzt sind Sie bereit und können mit dem konfigurieren ihrer individuellen SPRINZ-Dusche beginnen. Viel Spaß!"
                        zIndex={1001}
                        onStepSelectionChanged={onTutorialStepChanged}
                        totalAmountOfSteps={tutorialStepToInternalStepMapping.length}
                        onFinishedButtonClick={() => {
                            cleanUp();
                            onTutorialDialogClose && onTutorialDialogClose();
                            onOnboardingFinished && onOnboardingFinished();
                            dispatch(systemActions.setTutorialActive(false));
                        }}
                    >
                        <div dangerouslySetInnerHTML={{ __html: onboardingSteps[internalStepNumber].dialogText }}></div>
                    </OnboardingDialogComponent>
                </>
            )}
        </div>
    );
};

export default OnboardingComponent;

import { Vector2, Vector3 } from '@babylonjs/core';
import React from 'react';
import { TAGLIST } from '../../../helper/tagHelper';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type ShowerDrainComponentProps = {
    wallDepthMM: number;
    showerWidthMM: number;
    distanceToFloor: number;
};

const ShowerDrainComponent = ({
    wallDepthMM,
    showerWidthMM,
    distanceToFloor
}: ShowerDrainComponentProps): JSX.Element => {
    const offsetFromWallCM = new Vector2(20, 20);
    // Usually 3D-Objects are rendered on the "outside" of the (shower)-wall (e.g. knob).
    // In order to render the valve correctly on the inside we need to rotate it by 180deg (y) 90deg (x) and
    // move the object to the other side of the wall, depending on how thick/deep the wall is
    const insideWallPosition = new Vector3(0, 0, -wallDepthMM / 20);
    const insideWallRotation = new Vector3(0, Math.PI, 0);

    const floorPosition = insideWallPosition.add(
        new Vector3(showerWidthMM / 20 - offsetFromWallCM.x, -distanceToFloor, -offsetFromWallCM.y)
    );

    return (
        <transformNode name={'tf_shower_drain'} position={floorPosition} rotation={insideWallRotation}>
            <ThreeDComponent
                name={`showerdrain_threeD`}
                rootUrl="assets/3d/showerdrains/"
                sceneFilename={`showerdrain.glb`}
                tagList={[TAGLIST.REFLECTION]}
            />
        </transformNode>
    );
};

export default ShowerDrainComponent;

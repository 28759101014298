import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { systemActions } from '../../../../state/ducks/system';
import { ZoomAtAction } from '../../../../state/ducks/system/interfaces';
import icon from '../../../../assets/icons/common/close.svg';
import SideButtonsComponent from './SideButtonsComponent/SideButtonsComponent';

const CloseDetailViewButton = (): JSX.Element => {
    const menuRestoreState = useSelector((state: StoreState) => state.system.menuRestoreState);
    const dispatch = useDispatch();
    const setZoomAt = useCallback((zoomAt: string | null): ZoomAtAction => dispatch(systemActions.setZoomAt(zoomAt)), [
        dispatch
    ]);
    const onClick = useCallback(() => {
        setZoomAt(null);
        if (menuRestoreState) {
            if (menuRestoreState.openedMenu && menuRestoreState.menuOpen) {
                dispatch(systemActions.openMenuWith(menuRestoreState.openedMenu));
            } else if (menuRestoreState.menuOpen) {
                dispatch(systemActions.closeMenu());
                dispatch(systemActions.openMenu());
            } else {
                dispatch(systemActions.closeMenu());
            }

            if (menuRestoreState.productOverViewOpen) {
                dispatch(systemActions.openProductOverview());
            }
        }
    }, [setZoomAt, menuRestoreState]);

    return <SideButtonsComponent config={[{ onClick, icon, active: false }]} />;
};

export default CloseDetailViewButton;

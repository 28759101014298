import { Vector3 } from '@babylonjs/core';
import { applyRotationY, getRotationYFromDirection } from '../3d_helpers';
import {
    BathtubConfiguration,
    BlueprintDoor,
    BlueprintElement,
    BlueprintElementSizeConstraints,
    BlueprintLayoutType,
    BlueprintPartition,
    BlueprintVoid
} from '../types/Blueprint';
import { calcElementBottomOffsetMM, getDirectionForCurrentIndexInLayout } from './blueprintHelper';
import { calculateSideForRotation } from './commonHelper';

/**
 * This function calculates the index of the previous element in an list with elementCount elements.
 * If the index becomes negative it starts from the end of the list again.
 * @param currendElementIndex The index of the current element
 * @param elementCount The count of all elements in the list
 */
export const getPreviousElementIndex = (currendElementIndex: number, elementCount: number): number => {
    return (currendElementIndex - 1 + elementCount) % elementCount;
};

/**
 * This function calculates the index of the next element in an list with elementCount elements.
 * If the index becomes greater than elementCount it starts from the beginning of the list again.
 * @param currentElementIndex The index of the current element
 * @param elementCount The count of all elements in the list
 */
export const getNextElementIndex = (currentElementIndex: number, elementCount: number): number => {
    return (currentElementIndex + 1) % elementCount;
};

export const applyLayoutConstraints = (
    blueprintLayout: BlueprintElement[],
    constraints: BlueprintElementSizeConstraints,
    showerBaseSize: Vector3,
    seriesId: string,
    hasShowerBase: boolean,
    bathtubConfig?: BathtubConfiguration
): BlueprintElement[] => {
    let currentDirection = Vector3.Right();
    let currentRotation = getRotationYFromDirection(currentDirection);

    const blueprintWithMaxConstraints = blueprintLayout.map((element, idx) => {
        if (element.type === BlueprintLayoutType.Transition) {
            currentDirection = applyRotationY(currentDirection, element.angle);
            currentRotation = getRotationYFromDirection(currentDirection);
            return element;
        }

        const showerSideLengthMM = calculateSideForRotation(currentRotation, showerBaseSize);
        const [maxLength, maxLengthWeight] = calculateElementConstraintsMax(
            element,
            idx,
            blueprintLayout,
            constraints,
            showerBaseSize,
            seriesId,
            hasShowerBase,
            showerSideLengthMM,
            bathtubConfig
        );

        return { ...element, maxLength, maxLengthWeight };
    });

    currentDirection = Vector3.Right();
    currentRotation = getRotationYFromDirection(currentDirection);

    const bpWithMinMaxConstraints = blueprintWithMaxConstraints.map((element, idx) => {
        if (element.type === BlueprintLayoutType.Transition) {
            currentDirection = applyRotationY(currentDirection, element.angle);
            currentRotation = getRotationYFromDirection(currentDirection);
            return element;
        }

        const showerSideLengthMM = calculateSideForRotation(currentRotation, showerBaseSize);

        const minLength = calculateElementConstraintsMin(
            element,
            idx,
            blueprintWithMaxConstraints,
            constraints,
            showerSideLengthMM
        );

        return { ...element, minLength };
    });
    return bpWithMinMaxConstraints;
};

export const calculateElementConstraintsMax = (
    element: BlueprintDoor | BlueprintPartition | BlueprintVoid,
    elementIndex: number,
    blueprintLayout: BlueprintElement[],
    constraints: BlueprintElementSizeConstraints,
    showerBaseSize: Vector3,
    seriesId: string,
    hasShowerBase: boolean,
    showerSideLengthMM: number,
    bathtubConfig?: BathtubConfiguration
): [number, number | undefined] => {
    const elementsOfSamePlane = calculateElementsOfSamePlane(elementIndex, blueprintLayout);
    const showerSideMinLengthMM = calculateAccMinLengthOfShowerSide(elementsOfSamePlane, constraints);

    let elementMax;
    let maxWidthWeight;

    if (element.type === BlueprintLayoutType.Door) {
        const elementHeightMM =
            showerBaseSize.y -
            calcElementBottomOffsetMM(
                blueprintLayout,
                elementIndex,
                showerBaseSize.z,
                seriesId,
                hasShowerBase,
                bathtubConfig
            );

        maxWidthWeight = calculateMaxWidthFromWeight(elementHeightMM, constraints.door.maxWeight);
        let maxWidth = maxWidthWeight;

        if (seriesId === 'TA') {
            maxWidth -= maxWidth % 100;
        }

        elementMax = Math.min(
            constraints.door.max,
            showerSideLengthMM - (showerSideMinLengthMM - constraints.door.min),
            maxWidth
        );
    } else {
        elementMax = Math.min(
            constraints.partition.max,
            showerSideLengthMM - (showerSideMinLengthMM - constraints.partition.min)
        );
    }

    return [elementMax, maxWidthWeight];
};

export const calculateElementConstraintsMin = (
    element: BlueprintDoor | BlueprintPartition | BlueprintVoid,
    elementIndex: number,
    blueprintLayout: BlueprintElement[],
    constraints: BlueprintElementSizeConstraints,
    showerSideLengthMM: number
): number => {
    const elementsOfSamePlane = calculateElementsOfSamePlane(elementIndex, blueprintLayout);
    const showerSideMaxLengthMM = calculateAccMaxLengthForCurrentShowerBaseSize(elementsOfSamePlane);

    let elementMin = 0;

    if (element.type === BlueprintLayoutType.Door) {
        elementMin = Math.max(constraints.door.min, showerSideLengthMM - (showerSideMaxLengthMM - element.maxLength));
    } else {
        elementMin = Math.max(
            constraints.partition.min,
            showerSideLengthMM - (showerSideMaxLengthMM - element.maxLength)
        );
    }

    return Math.round(elementMin);
};

const calculateMaxWidthFromWeight = (height: number, maxWeight: number): number => {
    return maxWeight / (height * 8 * 0.0000025);
};

const calculateAccMinLengthOfShowerSide = (
    elementsOfPlain: BlueprintElement[],
    constraints: BlueprintElementSizeConstraints
) => {
    let accMinLenght = 0;
    elementsOfPlain.map((element) => {
        if (element.type === BlueprintLayoutType.Door) accMinLenght += constraints.door.min;
        if (element.type === BlueprintLayoutType.Partition) accMinLenght += constraints.partition.min;
        if (element.type === BlueprintLayoutType.Void) accMinLenght += constraints.partition.min;
    });
    return accMinLenght;
};

const calculateAccMaxLengthForCurrentShowerBaseSize = (elementsOfPlane: BlueprintElement[]) => {
    let accMaxLenght = 0;
    elementsOfPlane.map((element) => {
        if (element.type !== BlueprintLayoutType.Transition) accMaxLenght += element.maxLength;
    });
    return accMaxLenght;
};

/**
 * This function calculates all elements that are on the same plane. A plane is
 * one side of the shower but there can be multiple planes of the same showerside
 * if the showersides a parallel to each other.
 * @param elementIndex The index of the current element of which the plane should be calculated
 * @param blueprintLayout The blueprint layout of the current shower
 */
export const calculateElementsOfSamePlane = (
    elementIndex: number,
    blueprintLayout: BlueprintElement[]
): BlueprintElement[] => {
    const elementDirection = getDirectionForCurrentIndexInLayout(blueprintLayout, elementIndex);
    const rotationOfElement = getRotationYFromDirection(elementDirection);

    let currentDirection = Vector3.Right();
    let currentRotation = getRotationYFromDirection(currentDirection);

    // WARNING: This returns every element with the same rotation.y as the element at elementIndex has.
    // This does not consider if between those elements are transitions with angles > 0°
    return blueprintLayout.filter((element) => {
        if (element.type === BlueprintLayoutType.Transition) {
            currentDirection = applyRotationY(currentDirection, element.angle);
            currentRotation = getRotationYFromDirection(currentDirection);

            return false;
        }

        return rotationOfElement.y === currentRotation.y;
    });
};

export const applyConstraints = (newLength: number, elementMin?: number, elementMax?: number): number => {
    if (elementMax) newLength = Math.min(newLength, elementMax);
    if (elementMin) newLength = Math.max(newLength, elementMin);

    return newLength;
};

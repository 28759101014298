export const cookieURLParam = 'p';
export const gaIDurlParam = 'gN';

export enum Cookies {
    NotYetAccepted = '0',
    OnlyEssentials = '1',
    Accepted = '2'
}

export const getCookieAcceptanceFromUrlParams = (): Cookies => {
    // TODO: Ask SPRINZ to add params to their routing to the konfigurator
    /* konfigurator.sprinz.eu -> GA disabled, show Privacy Modal */
    /* konfigurator.sprinz.eu?p=true -> GA disabled, show no Privacy Modal */
    /* konfigurator.sprinz.eu?gN=XXXXXXXXX ->  GA enabled, show no Privacy Modal */
    const query = window.location.search;
    const urlParams = new URLSearchParams(query);
    const onlyEssentialCookiesAccepted = urlParams.get(cookieURLParam);
    const referredGoogleAnalyticsID = urlParams.get(gaIDurlParam);

    if (onlyEssentialCookiesAccepted) {
        return Cookies.OnlyEssentials;
    }

    if (referredGoogleAnalyticsID) {
        return Cookies.Accepted;
    }

    return Cookies.NotYetAccepted;
};

export const getAnalyticsIDFromParams = (): string | null => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(gaIDurlParam);
};

export const getCookieAcceptanceFromLocalStorage = (): Cookies => {
    switch (window.localStorage.getItem('ga')) {
        case Cookies.Accepted:
            return Cookies.Accepted;
        case Cookies.OnlyEssentials:
            return Cookies.OnlyEssentials;
        default:
            return Cookies.NotYetAccepted;
    }
};

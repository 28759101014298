import { Scene, AbstractMesh, TransformNode, Node, Tools } from '@babylonjs/core';
import { AdvancedDynamicTexture } from '@babylonjs/gui';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import pngToJpeg from 'png-to-jpeg';

class SceneService {
    private scene?: Scene;
    private filter: Array<string> = [];

    setScene = (scene: Scene): void => {
        this.scene = scene;
        this.scene.animationsEnabled = false;
    };

    setHitFilter = (filter: Array<string>): void => {
        this.filter = filter;
    };

    private predicate = (m: AbstractMesh): boolean => {
        if (this.filter && this.filter.length > 0) {
            return m.isEnabled() && this.filter.find((n) => m.name.toLowerCase() === n.toLowerCase()) !== undefined;
        } else {
            return true;
        }
    };

    isDirectHit = (expectedMeshName: string): boolean => {
        if (this.scene === undefined) return false;

        const pickingInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        if (pickingInfo !== null && pickingInfo.pickedMesh !== null) {
            return pickingInfo.pickedMesh.name === expectedMeshName;
        }

        return false;
    };

    private isClickOn2dMenu = (): boolean => {
        if (this.scene === undefined) return false;

        const { pointerX, pointerY } = this.scene;

        const advancedTexture = this.scene.textures.find((t) => t.name === 'UI') as AdvancedDynamicTexture;
        const buttons =
            advancedTexture?.getDescendants(false, (c) => c.name !== undefined && c.name.endsWith('Button')) ?? [];
        return buttons
            .map(
                (b) =>
                    pointerX < b.centerX + b.widthInPixels / 2 &&
                    pointerX > b.centerX - b.widthInPixels / 2 &&
                    pointerY < b.centerY + b.heightInPixels / 2 &&
                    pointerY > b.centerY - b.heightInPixels / 2
            )
            .some((hit) => hit === true);
    };

    findHitMesh = (): AbstractMesh | undefined => {
        if (this.scene === undefined) return undefined;

        if (this.isClickOn2dMenu()) {
            return undefined;
        }

        const pickingInfoForTransformNode = this.scene.pick(this.scene.pointerX, this.scene.pointerY);
        if (pickingInfoForTransformNode !== null && pickingInfoForTransformNode.pickedMesh !== null) {
            const tf = this.findTransformNode(pickingInfoForTransformNode.pickedMesh);
            if (tf) {
                return pickingInfoForTransformNode.pickedMesh;
            }
        }

        const pickingInfo = this.scene.pick(this.scene.pointerX, this.scene.pointerY, this.predicate);
        if (pickingInfo !== null && pickingInfo.pickedMesh !== null) {
            return pickingInfo.pickedMesh;
        }

        return undefined;
    };

    private isTransformNode = (node: Node): boolean => node.getClassName() === 'TransformNode';

    createScreenShot = (saveFn: (png: string) => Promise<unknown>): Promise<boolean> => {
        return new Promise((resolve, reject) => {
            const engine = this.scene?.getEngine();
            const camera = this.scene?.activeCamera;
            const screenshotCamera = this.scene?.getCameraByID('screenshotCamera');
            const canvas = engine?.getRenderingCanvas();
            if (engine && camera && screenshotCamera && canvas) {
                Tools.CreateScreenshotUsingRenderTarget(engine, screenshotCamera, { precision: 1.5 }, (png) => {
                    const buffer = new Buffer(png.split(/,\s*/)[1], 'base64');
                    pngToJpeg({ quality: 60 })(buffer).then((jpg: string) => {
                        saveFn(jpg).then(() => resolve(true));
                    });
                });
            } else {
                reject(false);
            }
        });
    };

    findTransformNode = (mesh: AbstractMesh): TransformNode | undefined => {
        if (this.scene === undefined) return undefined;

        let node = mesh.parent;
        while (node !== null && node !== undefined) {
            const tmp = node;
            if (this.isTransformNode(node)) {
                if (this.filter.length > 0 && this.filter?.find((f) => f === tmp.name)) {
                    return tmp as TransformNode;
                } else if (this.filter.length === 0) {
                    return tmp as TransformNode;
                }
            }
            node = tmp.parent;
        }
        return undefined;
    };
}

export default new SceneService();

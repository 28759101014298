import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../state/ducks';
import { ConfigOptionEnum } from '../../../state/ducks/system/interfaces';
import ConfigOption from '../../config-option/ConfigOption.component';
import './MenuOptionsList.component.scss';

const SubMenuList = (): JSX.Element => {
    const userConfig = useSelector((state: StoreState) => state.system.userConfig);
    const showMeasurementOption = !userConfig || userConfig.measurementMenuEnabled;
    const showAccessoriesOption = !userConfig || userConfig.accessoriesMenuEnabled;

    return (
        <ul className="submenu-list">
            <ConfigOption configOption={ConfigOptionEnum.Series} label="Modell"></ConfigOption>
            <ConfigOption configOption={ConfigOptionEnum.Model} label="Modellvariante"></ConfigOption>
            <ConfigOption configOption={ConfigOptionEnum.Wallmount} label="Wandbefestigung"></ConfigOption>
            {showMeasurementOption && (
                <ConfigOption configOption={ConfigOptionEnum.Measurement} label="Masse"></ConfigOption>
            )}
            <ConfigOption configOption={ConfigOptionEnum.Knob} label="Griffe"></ConfigOption>
            <ConfigOption configOption={ConfigOptionEnum.Material} label="Beschlagsfarbe"></ConfigOption>
            <ConfigOption configOption={ConfigOptionEnum.Glass} label="Glas"></ConfigOption>
            {showAccessoriesOption && (
                <ConfigOption configOption={ConfigOptionEnum.Beam} label="Zubehör"></ConfigOption>
            )}
        </ul>
    );
};

export default SubMenuList;

import { Tools, TransformNode, Vector3 } from '@babylonjs/core';
import React, { useEffect, useRef, useState } from 'react';
import { CreatedInstance } from 'react-babylonjs';
import { useDispatch, useSelector } from 'react-redux';
import useZoomAt from '../../../../hooks/useZoomAt';
import { StoreState } from '../../../../state/ducks';
import { architectureActions } from '../../../../state/ducks/architecture';
import { ConfigOptionEnum } from '../../../../state/ducks/system/interfaces';
import { isTBeam } from '../../../helper/beamHelper';
import { Axis } from '../../../helper/commonHelper';
import { TAGLIST } from '../../../helper/tagHelper';
import { getPositionOffsetFor, getRotationFor } from '../../../helper/techConfigHelper';
import { DETAIL_LAYERMASK, TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import { BeamTechConfig } from '../../../types/BeamTechConfig';
import { BlueprintBeamConnectorType } from '../../../types/Blueprint';
import { PositionType } from '../../../types/Common';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

export const buildBeamFilename = (beamId: string, connector: BlueprintBeamConnectorType): string[] => {
    let attachment: string;

    switch (connector) {
        case BlueprintBeamConnectorType.GlassWall:
            attachment = 'wall';
            break;
        case BlueprintBeamConnectorType.GlassGlass:
            attachment = 'glass';
            break;
        case BlueprintBeamConnectorType.GlassCenter:
            attachment = 'center';
            break;

        default:
            throw Error('BeamTyp nicht vorhanden.');
    }
    return [`beam_glass_${beamId}.glb`, `beam_${beamId}.glb`, `beam_${attachment}_${beamId}.glb`];
};

type BeamComponentProps = {
    techConfig: BeamTechConfig;
    connector: BlueprintBeamConnectorType;
    side: PositionType;
    axis: Axis;
    position: Vector3;
};

const BeamComponent = ({ techConfig, connector, side, axis, position }: BeamComponentProps): JSX.Element => {
    const tfName = `beam_tf`;
    const rootUrl = 'assets/3d/beams/';

    const beamId = useSelector((state: StoreState) => state.configurator.configuration.beam.id);
    const showerBaseSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const intersectionPoint = useSelector((state: StoreState) => state.architecture.beamsIntersectionPoint);

    const [positionOffset, setPositionOffset] = useState(Vector3.Zero());
    const [rotation, setRotation] = useState(Vector3.Zero());
    const [zoomAt, setZoomAt] = useZoomAt();

    const dispatch = useDispatch();
    const updateBeamIntersectionPointX = (x: number) => dispatch(architectureActions.updateBeamIntersectionPointX(x));
    const updateBeamIntersectionPointZ = (x: number) => dispatch(architectureActions.updateBeamIntersectionPointZ(x));

    const ref = useRef<CreatedInstance<TransformNode>>();
    const pointRef = useRef<number>();

    const [beamLengthMM, setBeamLengthMM] = useState(showerBaseSize[axis]);

    const [sceneFilenamePartOne, sceneFilenamePartTwo, sceneFilenamePartThree] = buildBeamFilename(
        techConfig.id,
        connector
    );
    const isCenterBeam = sceneFilenamePartThree.indexOf('center') !== -1;

    const updateBeamIntersectionPoint = (axis: Axis): void => {
        if (ref && ref.current && ref.current.hostInstance && showerBaseSize) {
            if (pointRef.current === undefined) {
                pointRef.current = ref.current.hostInstance.getAbsolutePosition()[axis];
            }
            // Set to next JS Event Loop Frame, otherwise getAbsolutePosition() will not return the correct value
            setTimeout(() => {
                if (ref?.current?.hostInstance && showerBaseSize) {
                    if (axis === Axis.x) {
                        updateBeamIntersectionPointX(ref.current.hostInstance.getAbsolutePosition()[axis]);
                    } else {
                        updateBeamIntersectionPointZ(ref.current.hostInstance.getAbsolutePosition()[axis]);
                    }
                }
            }, 0);
        }
    };

    useEffect(() => {
        if (techConfig) {
            const rotation = getRotationFor(techConfig, side);
            setRotation(rotation);

            const position = getPositionOffsetFor(techConfig, side);
            setPositionOffset(position);
        }
    }, [techConfig, side]);

    useEffect(() => {
        updateBeamIntersectionPoint(axis === Axis.z ? Axis.x : Axis.z);
    }, [showerBaseSize, positionOffset]);

    useEffect(() => {
        if (ref.current && ref.current.hostInstance) {
            if (isCenterBeam) {
                if (axis === Axis.z && intersectionPoint.z !== null) {
                    setBeamLengthMM((intersectionPoint.z - ref.current.hostInstance.getAbsolutePosition().z) * 10);
                }
                if (axis === Axis.x && intersectionPoint.x !== null) {
                    setBeamLengthMM((ref.current.hostInstance.getAbsolutePosition().x - intersectionPoint.x) * 10);
                }
            } else {
                setBeamLengthMM(showerBaseSize[axis]);
            }
        }
    }, [intersectionPoint, showerBaseSize, ref.current?.hostInstance]);

    return (
        <transformNode
            name={tfName}
            position={position}
            key={`beam_tf_${side}_${techConfig.id}_${connector}_${axis}_${beamLengthMM}`}
        >
            {((isCenterBeam && isTBeam(beamId) && intersectionPoint[axis] !== null) || !isCenterBeam) && (
                <transformNode
                    ref={ref}
                    name={'beam_tf_posititon_offset'}
                    position={positionOffset}
                    rotation={rotation}
                >
                    <transformNode name={'beam_tf_part1'}>
                        <ThreeDComponent
                            name={`beam_threeD_y=${axis}_part1`}
                            rootUrl={rootUrl}
                            sceneFilename={sceneFilenamePartOne}
                            tagList={[TAGLIST.REFLECTION]}
                            onClick={zoomAt !== tfName ? () => setZoomAt(tfName, ConfigOptionEnum.Beam) : undefined}
                            layerMask={zoomAt === tfName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                        />
                    </transformNode>
                    <transformNode
                        name={'beam_tf_part2'}
                        position-x={(beamLengthMM * 0.5) / 10}
                        scaling={new Vector3(beamLengthMM / (techConfig?.lengthBarMM ?? 100), 1, 1)}
                    >
                        <ThreeDComponent
                            name={`beam_threeD_y=${axis}_part2`}
                            rootUrl={rootUrl}
                            sceneFilename={sceneFilenamePartTwo}
                            tagList={[TAGLIST.REFLECTION]}
                            onClick={zoomAt !== tfName ? () => setZoomAt(tfName, ConfigOptionEnum.Beam) : undefined}
                            layerMask={zoomAt === tfName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                        />
                    </transformNode>
                    <transformNode
                        name={'beam_tf_part3'}
                        position-x={beamLengthMM / 10}
                        rotation={connector === 'gg' ? new Vector3(0, Tools.ToRadians(180), 0) : undefined}
                    >
                        <ThreeDComponent
                            name={`beam_threeD_y=${axis}_part3`}
                            rootUrl={rootUrl}
                            sceneFilename={sceneFilenamePartThree}
                            tagList={[TAGLIST.REFLECTION]}
                            onClick={zoomAt !== tfName ? () => setZoomAt(tfName, ConfigOptionEnum.Beam) : undefined}
                            layerMask={zoomAt === tfName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                        />
                    </transformNode>
                </transformNode>
            )}
        </transformNode>
    );
};

export default BeamComponent;

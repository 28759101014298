import React from 'react';
import Switch from 'react-switch';
import './toggle-slider.component.scss';

interface ToggleSliderComponentProps {
    checked: boolean;
    onChange: (checked: boolean) => void;
    labelText?: string;
}

const ToggleSliderComponent = ({ checked, onChange, labelText }: ToggleSliderComponentProps): JSX.Element => {
    return (
        <label className="toggle-slider-component">
            <Switch
                id="react-switch"
                checked={checked}
                onChange={onChange}
                uncheckedIcon={false}
                checkedIcon={false}
                width={35}
                height={10}
                handleDiameter={20}
                onHandleColor={'#b9a484'}
                offHandleColor={'#b9a484'}
                offColor={'#e2e2e2'}
                onColor={'#e2e2e2'}
                activeBoxShadow={'#000000'}
            />
            <span>{labelText}</span>
        </label>
    );
};

export default ToggleSliderComponent;

import { Tools, Vector3 } from '@babylonjs/core';
import { BeamTechConfig } from '../types/BeamTechConfig';
import { PositionType } from '../types/Common';
import { KnobTechConfig } from '../types/KnobTechConfig';

export const getRotationFor = (techConfig: BeamTechConfig | KnobTechConfig, positionType: PositionType): Vector3 => {
    const typeData = positionType === PositionType.Left ? techConfig.position.left : techConfig.position.right;
    const { x: rotX, y: rotY, z: rotZ } = typeData.rotation;
    const rotation = new Vector3(Tools.ToRadians(rotX), Tools.ToRadians(rotY), Tools.ToRadians(rotZ));
    return rotation;
};

export const getPositionOffsetFor = (
    techConfig: BeamTechConfig | KnobTechConfig,
    positionType: PositionType
): Vector3 => {
    const typeData = positionType === PositionType.Left ? techConfig.position.left : techConfig.position.right;
    const { x, y, z } = typeData.offset;
    const positionOffset = new Vector3(x, y, z);
    return positionOffset;
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../state/ducks';
import { configuratorActions } from '../../../state/ducks/configurator';
import { GeneralCatalogueItem } from '../../../state/ducks/configurator/interfaces';
import { setZoomAt } from '../../../state/ducks/system/actions';
import { ConfigOptionEnum } from '../../../state/ducks/system/interfaces';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../../ng/helper/analyticsHelper';
import { ReactComponent as Info } from '../../../assets/icons/common/information.svg';
import { useState } from 'react';
const createImageName = (configOptionType: ConfigOptionEnum, itemId: string): string => {
    const configOption = configOptionType.toLocaleLowerCase();

    const imageName = `/assets/img/menu/${configOption}/${configOption}_${itemId}`;

    //ToDo: Hack due to deadline, please introduce proper solution
    const userConfig = useSelector((state: StoreState) => state.system.userConfig);
    const retailerMounts = configOptionType === ConfigOptionEnum.Wallmount && userConfig != null;

    const imageNameSuffix =
        configOptionType === ConfigOptionEnum.Knob ||
        configOptionType === ConfigOptionEnum.Series ||
        configOptionType === ConfigOptionEnum.Beam ||
        configOptionType === ConfigOptionEnum.Material ||
        retailerMounts
            ? '.png'
            : '.svg';

    return imageName + imageNameSuffix;
};

interface ConfigOptionItemComponentProps {
    configOptionType: ConfigOptionEnum;
    labelNumber: number;
    selected?: boolean;
    item: GeneralCatalogueItem;
    large: boolean;
    unavailable: boolean;
}

const ConfigOptionItem = ({
    configOptionType,
    labelNumber,
    selected = false,
    item,
    large,
    unavailable
}: ConfigOptionItemComponentProps): JSX.Element => {
    const catalogue = useSelector((state: StoreState) => state.configurator.catalogue);

    const [showSeriesInfo, setShowSeriesInfo] = useState(false);
    const dispatch = useDispatch();

    const onSelected = (): void => {
        logAnalyticsEvent(`configurator_select_config:${configOptionType}`, {
            configID: item.id,
            description: item.description
        });
        dispatch(configuratorActions.selectConfigOption(configOptionType, item.id, catalogue));

        if (item.id === null || configOptionType === ConfigOptionEnum.Series) {
            dispatch(setZoomAt(null));
        }
    };

    const getSeriesInformation = (itemID: string) => {
        const element = catalogue.series.find((value) => value.id == itemID);
        if (element) {
            return element?.seriesInfo;
        } else {
            return '';
        }
    };

    const imageName = createImageName(configOptionType, item.id);

    const isBeam = configOptionType === ConfigOptionEnum.Beam;
    let maxLengthMM = undefined;
    if (isBeam) {
        const possibleBeamTechConfigs = useSelector(
            (state: StoreState) => state.architecture.techConfigs.possibleBeams
        );
        maxLengthMM = possibleBeamTechConfigs?.find((beam) => beam.id === item.id)?.sizeConstraints.max || maxLengthMM;
    }

    //ToDo: Hack due to deadline, please introduce proper solution
    const userConfig = useSelector((state: StoreState) => state.system.userConfig);
    const retailerMounts = configOptionType === ConfigOptionEnum.Wallmount && userConfig != null;

    const isSeries = configOptionType === ConfigOptionEnum.Series;
    const isMaterial = configOptionType === ConfigOptionEnum.Material;
    const medium = isBeam && !large;
    const smallWithLabel = isMaterial || retailerMounts;
    const showItemLabel = isBeam || isSeries || isMaterial || retailerMounts;

    const outline = !selected;

    return (
        <div
            className={`sp-config-option-item ${medium ? 'medium' : ''} ${large ? 'large' : ''} ${
                smallWithLabel ? 'small-with-label' : ''
            } ${selected ? 'selected' : ''} ${unavailable && item.id !== null ? 'unavailable' : ''} ${
                outline ? 'outline' : ''
            }`}
            // onClick={unavailable ? undefined : onSelected}
        >
            <div className="sp-config-option-item-number-image-content">
                {isSeries && (
                    <div className={`series-infotext-area ${showSeriesInfo ? '' : 'closed'}`}>
                        {getSeriesInformation(item.id)}
                    </div>
                )}
                <label className={'sp-config-option-item-number'}>
                    {labelNumber < 10 ? `0${labelNumber}` : `${labelNumber}`}
                </label>
                <img
                    src={imageName}
                    alt={item.description}
                    className="sp-config-option-item-image"
                    draggable="false"
                    onClick={unavailable ? undefined : onSelected}
                />
            </div>
            {selected && <div className={`sp-config-option-item-selected-label`} />}
            {isBeam && maxLengthMM && (
                <div className="sp-config-option-item-beam-max-length">{`Max. ${maxLengthMM} mm`}</div>
            )}

            {showItemLabel && (
                <div className="sp-config-option-item-label" onClick={unavailable ? undefined : onSelected}>
                    <div className="left-column"></div>
                    <div className="description">{item.description}</div>
                    {isSeries && (
                        <div className="right-column">
                            <Info
                                className={`info`}
                                onClick={(event) => {
                                    setShowSeriesInfo(!showSeriesInfo);
                                    event.stopPropagation();
                                }}
                            ></Info>
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default ConfigOptionItem;

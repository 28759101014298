import { useSelector } from 'react-redux';
import { StoreState } from '../../state/ducks';

interface DiscountData {
    startDate: Date;
    endDate: Date;
    orderDeadLine: Date;
    percentage: number;
}

export const hasSpecialOffer = (startDate: Date, endDate: Date): boolean => {
    const currDate = new Date();
    return currDate >= startDate && currDate <= endDate;
};

export const getDiscountPrice = (regularPrice: number, percentageDiscount: number): number => {
    const ratio = (100 - percentageDiscount) / 100;
    return Math.round(regularPrice * ratio);
};

export const useDiscount = (): DiscountData | undefined => {
    const discountInfo = useSelector((state: StoreState) => state.configurator.catalogue.globalDiscount);
    if (discountInfo && discountInfo.percentage && discountInfo.startDate && discountInfo.endDate) {
        const startDate = new Date(discountInfo.startDate);
        if (isNaN(startDate.getTime())) {
            console.warn(`Error, globalDiscount.startdate ${discountInfo.startDate} from Catalogue is not valid.`);
        }
        const endDate = new Date(discountInfo.endDate);
        if (isNaN(endDate.getTime())) {
            console.warn(`Error, globalDiscount.endDate ${discountInfo.endDate} from Catalogue is not valid.`);
        }
        const orderDeadLine = new Date(discountInfo.orderDeadline);
        if (isNaN(orderDeadLine.getTime())) {
            console.warn(
                `Error, globalDiscount.orderDeadline ${discountInfo.orderDeadline} from Catalogue is not valid.`
            );
        }
        const percentage = discountInfo.percentage;
        return { startDate, endDate, orderDeadLine, percentage };
    }
    return undefined;
};

import React, { useState } from 'react';
import ButtonComponent from '../button/ButtonComponent';
import './StartInfoComponent.scss';

type StartInfoComponentProps = {
    onTutorialSkipClick?: () => void;
    onTutorialStart?: () => void;
};

const StartInfoComponent = ({ onTutorialSkipClick, onTutorialStart }: StartInfoComponentProps): JSX.Element => {
    const [dismissed, setDismissed] = useState(false);

    const hideDialog = () => {
        setDismissed(true);
    };

    if (!dismissed) {
        return (
            <div className="start-info-component">
                <div className="dialog" onClick={(e) => e.stopPropagation()}>
                    <h1>Willkommen</h1>
                    <p>beim SPRINZ-Duschenkonfigurator! </p>
                    <p>
                        Wir zeigen Ihnen, wie Sie Ihre SPRINZ-Dusche in wenigen Schritten nach Ihren eigenen Wünschen
                        gestalten können.
                    </p>
                    <ButtonComponent
                        onClick={() => {
                            setDismissed(true);
                            onTutorialStart && onTutorialStart();
                        }}
                        width={404}
                    >
                        Jetzt loslegen!
                    </ButtonComponent>
                    <a
                        onClick={() => {
                            hideDialog();
                            onTutorialSkipClick && onTutorialSkipClick();
                        }}
                    >
                        <u>Tutorial überspringen</u>
                    </a>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default StartInfoComponent;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import useZoomAt from '../../../../hooks/useZoomAt';
import { ConfigOptionEnum } from '../../../../state/ducks/system/interfaces';
import { TAGLIST } from '../../../helper/tagHelper';
import { DETAIL_LAYERMASK, TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type SlidingHingeComponentProps = {
    hingeId: string;
    position: Vector3;
    xScaling: number;
    baseLengthMM: number;
    rotation: Vector3;
    coverStart: boolean;
    startTouchesWall: boolean;
    coverEnd: boolean;
    endTouchesWall: boolean;
    previousHingeHasCorner: boolean;
    endHasCorner: boolean;
};

const SlidingHingeComponent = ({
    hingeId,
    position,
    xScaling,
    baseLengthMM,
    rotation,
    coverStart,
    startTouchesWall,
    coverEnd,
    endTouchesWall,
    previousHingeHasCorner,
    endHasCorner
}: SlidingHingeComponentProps): JSX.Element => {
    let wallSituation = 'none';
    if (startTouchesWall && endTouchesWall) {
        wallSituation = 'both';
    } else if (startTouchesWall) {
        wallSituation = 'start';
    } else if (endTouchesWall) {
        wallSituation = 'end';
    }
    const [zoomAt, setZoomAt] = useZoomAt();

    const tfName = `slidingHinge_tf`;
    const rootUrl = 'assets/3d/hinges/';
    const coverFilename = `hinge_${hingeId}_cover.glb`;
    const cornerFileName = `hinge_${hingeId}_corner.glb`;
    const isZoomedAt = zoomAt === tfName;
    const glassWidthMM = 8;
    const hingeCornerWidthCM = 6.65;
    const hingeAnchorOffsetCM = 5;
    const desiredLengthInDetailViewMM = 200;

    let lengthMM = baseLengthMM * xScaling;
    if (previousHingeHasCorner) {
        lengthMM -= hingeAnchorOffsetCM * 10;
        position.x += hingeAnchorOffsetCM / 2;
        if (isZoomedAt) {
            const oldLengthMM = lengthMM;
            lengthMM = desiredLengthInDetailViewMM;
            const lengthDiffMM = oldLengthMM - lengthMM;
            position.x -= lengthDiffMM / 20;
        }
    }
    if (endHasCorner) {
        lengthMM -= hingeAnchorOffsetCM * 10;
        position.x -= hingeAnchorOffsetCM / 2;
        if (isZoomedAt) {
            const oldLengthMM = lengthMM;
            lengthMM = desiredLengthInDetailViewMM;
            const lengthDiffMM = oldLengthMM - lengthMM;
            position.x += lengthDiffMM / 20;
        }
    }
    const halfLengthCM = lengthMM / 20;

    const coverStartPosition = new Vector3(-halfLengthCM - glassWidthMM / 10, 0, 0);
    const coverEndPosition = new Vector3(halfLengthCM + glassWidthMM / 10, 0, 0);

    const cornerPosition = new Vector3(halfLengthCM + hingeCornerWidthCM / 2, 0, 0);

    return (
        <transformNode name={tfName} rotation={rotation}>
            <transformNode name={`slidingHinge_tf_touchesWall=${wallSituation}`} position={position}>
                {coverStart && (
                    <transformNode name={`slidingHinge_coverStart_tf_position`} position={coverStartPosition}>
                        <transformNode name={`slidingHinge_coverStart_tf_scaling`} scaling={new Vector3(-1, 1, 1)}>
                            <ThreeDComponent
                                name={`slidingHinge_coverStart_threeD`}
                                rootUrl={rootUrl}
                                sceneFilename={coverFilename}
                                tagList={[TAGLIST.REFLECTION]}
                                onClick={isZoomedAt ? undefined : () => setZoomAt(tfName, ConfigOptionEnum.Series)}
                                layerMask={isZoomedAt ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                            />
                        </transformNode>
                    </transformNode>
                )}
                <transformNode
                    name={`slidingHinge_slide_tf_scaling`}
                    scaling={new Vector3(lengthMM / baseLengthMM, 1, 1)}
                >
                    <ThreeDComponent
                        name={`slidingHinge_threeD`}
                        rootUrl={rootUrl}
                        sceneFilename={`hinge_${hingeId}.glb`}
                        tagList={[TAGLIST.REFLECTION]}
                        onClick={isZoomedAt ? undefined : () => setZoomAt(tfName, ConfigOptionEnum.Series)}
                        layerMask={isZoomedAt ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                    />
                </transformNode>
                {coverEnd && (
                    <transformNode name={`slidingHinge_coverEnd_tf_position`} position={coverEndPosition}>
                        <ThreeDComponent
                            name={`slidingHinge_coverEnd_threeD`}
                            rootUrl={rootUrl}
                            sceneFilename={coverFilename}
                            tagList={[TAGLIST.REFLECTION]}
                            onClick={isZoomedAt ? undefined : () => setZoomAt(tfName, ConfigOptionEnum.Series)}
                            layerMask={isZoomedAt ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                        />
                    </transformNode>
                )}
                {endHasCorner && (
                    <transformNode name={'slidingHinge_end_corner'} position={cornerPosition}>
                        <ThreeDComponent
                            name={'slidingHinge_end_corner_threeD'}
                            rootUrl={rootUrl}
                            sceneFilename={cornerFileName}
                            tagList={[TAGLIST.REFLECTION]}
                            onClick={isZoomedAt ? undefined : () => setZoomAt(tfName, ConfigOptionEnum.Series)}
                            layerMask={isZoomedAt ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                        />
                    </transformNode>
                )}
            </transformNode>
        </transformNode>
    );
};

export default SlidingHingeComponent;

import { Observable } from 'rxjs';
import { BeamTechConfig } from '../types/BeamTechConfig';
import { Blueprint } from '../types/Blueprint';
import { HingeTechConfig } from '../types/HingeTechConfig';
import { KnobTechConfig } from '../types/KnobTechConfig';
import { MountTechConfig } from '../types/MountTechConfig';
import { Roomplan } from '../types/Roomplan';

const fetchPartWithIdRx = <T>(partUrl: string, id: string): Observable<T> =>
    new Observable((observer) => {
        fetch(`assets/${partUrl}${id}.json`)
            .then((response) => response.json())
            .then((data) => {
                observer.next(data);
                observer.complete();
            })
            .catch((err) => observer.error(err));
    });

export const fetchBlueprintRx = (id: string): Observable<Blueprint> =>
    fetchPartWithIdRx<Blueprint>('tech_config/blueprints/', id);

export const fetchRoomplanRx = (id: string): Observable<Roomplan> =>
    fetchPartWithIdRx<Roomplan>('tech_config/roomplans/', id);

export const fetchKnobTechConfigRx = (id: string): Observable<KnobTechConfig> =>
    fetchPartWithIdRx<KnobTechConfig>('tech_config/knobs/', id);

export const fetchMountTechConfigRx = (id: string): Observable<MountTechConfig> =>
    fetchPartWithIdRx<MountTechConfig>('tech_config/mounts/', id);

export const fetchHingeTechConfigRx = (id: string): Observable<HingeTechConfig> =>
    fetchPartWithIdRx<HingeTechConfig>('tech_config/hinges/', id);

export const fetchBeamTechConfigRx = (id: string): Observable<BeamTechConfig> =>
    fetchPartWithIdRx<BeamTechConfig>('tech_config/beams/', id);

import React, { useLayoutEffect, useMemo, useState } from 'react';
import close from '../../assets/icons/common/close.svg';
import './SizeInfoComponent.scss';
import 'firebase/analytics';
type SizeInfoComponentProps = {
    canBeDisplayed: boolean;
    onDisplayedChanged: (isDisplayed: boolean) => void;
};

const SizeInfoComponent = ({ canBeDisplayed, onDisplayedChanged }: SizeInfoComponentProps): JSX.Element => {
    const minApplicationWidth = 850;
    const minApplicationHeight = 650;
    const [dismissed, setDismissed] = useState(false);
    const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });

    useLayoutEffect(() => {
        function updateSize() {
            setSize({ width: window.innerWidth, height: window.innerHeight });
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const displayInfo = useMemo(() => {
        const screenIsTooSmall = size.width < minApplicationWidth || size.height < minApplicationHeight;
        const isDisplayed = screenIsTooSmall && canBeDisplayed && !dismissed;
        setTimeout(() => onDisplayedChanged(isDisplayed));
        return isDisplayed;
    }, [size, canBeDisplayed, dismissed]);

    if (displayInfo) {
        return (
            <div className="size-info-component" onClick={() => setDismissed(true)}>
                <div className="dialog" onClick={(e) => e.stopPropagation()}>
                    <div className="cancel-button" onClick={() => setDismissed(true)}>
                        <img src={close} />
                    </div>
                    <p>
                        Nutzen Sie für die optimale Darstellung ein Tablet im Querformat oder einen Computer sowie die
                        aktuellste Version Ihres Browsers.
                    </p>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default SizeInfoComponent;

import { Mesh, Texture, TransformNode, Vector3 } from '@babylonjs/core';
import { default as React, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { BabylonNode, CreatedInstance, FiberBoxPropsCtor, FiberMeshProps, useBabylonScene } from 'react-babylonjs';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { registerOnReflectionProbe, unregisterOnReflectionProbe } from '../../../3d_helpers';
import { applyTags, TAGLIST } from '../../../helper/tagHelper';
import { TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';

type ResponsiveBoxComponentProps = {
    name: string;
    width: number;
    height: number;
    depth: number;
    bottomOffsetMM?: number;
    sliderLabel?: string;
    position?: Vector3;
    rotation?: Vector3;
    children?: ReactNode;
    checkCollisions?: boolean;
    isPickable?: boolean;
    tagList?: TAGLIST[];
} & FiberMeshProps &
    FiberBoxPropsCtor &
    BabylonNode<Mesh>;

const ResponsiveBoxComponent = ({
    name,
    width,
    height,
    depth,
    bottomOffsetMM = 0,
    sliderLabel,
    children,
    tagList,
    ...meshProps
}: ResponsiveBoxComponentProps): JSX.Element => {
    // We need to use a state to trigger a rerender when the refered object changes
    const [currentBoxRef, setBoxRef] = useState<CreatedInstance<Mesh>>();
    const tfScalingCompensationRef = useRef<CreatedInstance<TransformNode>>();
    const measurementOpen = useSelector((state: StoreState) => state.system.menu.measurementOpen);
    const displayRatioOptions = useSelector((state: StoreState) => state.system.menu.displayRatioOptions);
    const tf = tfScalingCompensationRef.current?.hostInstance;
    const scene = useBabylonScene();

    useEffect(() => {
        if (currentBoxRef?.hostInstance && tf) {
            const boundingInfo = currentBoxRef.hostInstance.getBoundingInfo();
            const currentWidth = Math.abs(boundingInfo.maximum.x - boundingInfo.minimum.x);
            const currentHeight = Math.abs(boundingInfo.maximum.y - boundingInfo.minimum.y);
            const currentDepth = Math.abs(boundingInfo.maximum.z - boundingInfo.minimum.z);
            const currentSize = new Vector3(currentWidth, currentHeight, currentDepth);
            const scalingVector = new Vector3(width, height, depth).divide(currentSize);
            const invertedScalingVector = Vector3.One().divide(scalingVector);
            currentBoxRef.hostInstance.scaling = scalingVector;
            tf.scaling = invertedScalingVector;
        }
    }, [currentBoxRef, tfScalingCompensationRef.current, width, height, depth]);

    const setBoxRefCallback = useCallback(
        (node) => {
            if (node !== null) {
                setBoxRef(node);
                if (tagList) {
                    applyTags([node.hostInstance], tagList);
                }
            }
        },
        [tagList]
    );

    useEffect(() => {
        if (tagList && tagList.indexOf(TAGLIST.BE_REFELECTED) > -1) {
            if (currentBoxRef?.hostInstance && scene) {
                registerOnReflectionProbe(currentBoxRef.hostInstance, scene);
                return (): void => {
                    if (currentBoxRef.hostInstance) unregisterOnReflectionProbe(currentBoxRef.hostInstance, scene);
                };
            }
        }
    }, [width, depth, height, currentBoxRef]);

    return (
        <box
            name={name}
            ref={setBoxRefCallback}
            width={width}
            height={height}
            depth={depth}
            {...meshProps}
            layerMask={TOTAL_LAYERMASK}
        >
            <transformNode ref={tfScalingCompensationRef} name="tf_box_scaling_compensation">
                {sliderLabel && measurementOpen && displayRatioOptions && (
                    <plane
                        name="dialog"
                        width={30}
                        height={30}
                        rotation={new Vector3(0, Math.PI, 0)}
                        position-y={25 - bottomOffsetMM / 20}
                    >
                        <advancedDynamicTexture
                            name="dialogTexture"
                            height={1024}
                            width={1024}
                            createForParentMesh
                            generateMipMaps={true}
                            samplingMode={Texture.TRILINEAR_SAMPLINGMODE}
                            isBlocking={false}
                        >
                            <textBlock text={sliderLabel} color="white" fontSize={700} fontStyle="bold" />
                        </advancedDynamicTexture>
                    </plane>
                )}
                {children}
            </transformNode>
        </box>
    );
};

export default ResponsiveBoxComponent;

import { Tools, Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { TAGLIST } from '../../../helper/tagHelper';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type ShowerBaseComponentProps = {
    showerOrigin: Vector3;
};

const ShowerBaseComponent = ({ showerOrigin }: ShowerBaseComponentProps): JSX.Element => {
    const showerSizeMM = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const rootUrl = 'assets/3d/showerbase/';
    const pieceBaseSizeMM = 450;
    const pieceBaseSizeCM = pieceBaseSizeMM / 10;
    const originMM = showerOrigin;
    const originCM = new Vector3(originMM.x / 10, originMM.y / 10, originMM.z / 10);

    // INFO The ShowerBase is build in a 3x3 grid of pieces
    // Rows
    const backSidePositionZ = -pieceBaseSizeCM / 2;
    const middlePositionZ = -showerSizeMM.z / 20;
    const frontSidePositionZ = -(showerSizeMM.z / 10 - pieceBaseSizeCM / 2);

    // Columns
    const leftSidePositionX = pieceBaseSizeCM / 2;
    const middlePositionX = showerSizeMM.x / 20;
    const rightSidePositionX = showerSizeMM.x / 10 - pieceBaseSizeCM / 2;

    //Calculate Scaling for side and head piece and prevent negative Scaling
    const sidePieceScaleXCalculation = (showerSizeMM.x - 2 * pieceBaseSizeMM) / pieceBaseSizeMM;
    const sidePieceScaleX = Math.max(sidePieceScaleXCalculation, 0);
    const headPieceScaleZCalculation = (showerSizeMM.z - 2 * pieceBaseSizeMM) / pieceBaseSizeMM;
    const headPieceScaleZ = Math.max(headPieceScaleZCalculation, 0);
    const floorScaling = new Vector3(sidePieceScaleX, 1, headPieceScaleZ);

    return (
        <transformNode name={'base_tf_position'} position={originCM}>
            {/* BACK SIDE */}
            <transformNode
                name={'base_tf_corner_back_left'}
                position={new Vector3(leftSidePositionX, 0, backSidePositionZ)}
                rotation={new Vector3(0, Tools.ToRadians(90), 0)}
            >
                <ThreeDComponent
                    name={`base_threeD_corner_back_left`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'base_tf_side_back'}
                position={new Vector3(middlePositionX, 0, backSidePositionZ)}
                scaling={new Vector3(1, 1, sidePieceScaleX)}
                rotation={new Vector3(0, Tools.ToRadians(90), 0)}
            >
                <ThreeDComponent
                    name={`base_threeD_side_back`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_side.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'base_tf_corner_back_right'}
                rotation={new Vector3(0, Tools.ToRadians(180), 0)}
                position={new Vector3(rightSidePositionX, 0, backSidePositionZ)}
            >
                <ThreeDComponent
                    name={`base_threeD_corner_back_right`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            {/* MIDDLE */}
            <transformNode
                name={'base_tf_head_left'}
                position={new Vector3(leftSidePositionX, 0, middlePositionZ)}
                scaling={new Vector3(1, 1, headPieceScaleZ)}
            >
                <ThreeDComponent
                    name={`base_threeD_head_left`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_side.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'base_tf_floor'}
                position={new Vector3(middlePositionX, 0, middlePositionZ)}
                scaling={floorScaling}
            >
                <ThreeDComponent
                    name={`base_threeD_floor`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_middle.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'base_tf_head_right'}
                position={new Vector3(rightSidePositionX, 0, middlePositionZ)}
                rotation={new Vector3(0, Tools.ToRadians(180), 0)}
                scaling={new Vector3(1, 1, headPieceScaleZ)}
            >
                <ThreeDComponent
                    name={`base_threeD_head_right`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_side.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            {/* FRONT SIDE */}
            <transformNode
                name={'base_tf_corner_front_left'}
                position={new Vector3(leftSidePositionX, 0, frontSidePositionZ)}
            >
                <ThreeDComponent
                    name={`base_threeD_corner_front_left`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'base_tf_side_front'}
                position={new Vector3(middlePositionX, 0, frontSidePositionZ)}
                scaling={new Vector3(1, 1, sidePieceScaleX)}
                rotation={new Vector3(0, Tools.ToRadians(270), 0)}
            >
                <ThreeDComponent
                    name={`base_threeD_side_front`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_side.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'base_tf_corner_front_right'}
                rotation={new Vector3(0, Tools.ToRadians(270), 0)}
                position={new Vector3(rightSidePositionX, 0, frontSidePositionZ)}
            >
                <ThreeDComponent
                    name={`base_threeD_corner_front_right`}
                    rootUrl={rootUrl}
                    sceneFilename={`base_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
        </transformNode>
    );
};

export default ShowerBaseComponent;

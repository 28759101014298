import { useEffect, useState } from 'react';

let globalIsDebugMode = false;
const listeners = new Set<() => void>();

const toggleGlobalDebugMode = (): void => {
    globalIsDebugMode = !globalIsDebugMode;
    listeners.forEach((listener) => listener());
};

const useDebugMode = (): [isDebugMode: boolean, toggleDebugMode: () => void] => {
    const [debugModeActive, setDebugModeActive] = useState(globalIsDebugMode);

    useEffect(() => {
        const listener = () => {
            setDebugModeActive(globalIsDebugMode);
        };
        listeners.add(listener);
        listener(); // In case its already changed
        return () => {
            listeners.delete(listener); // cleanup
        };
    }, []);

    return [debugModeActive, toggleGlobalDebugMode];
};

export default useDebugMode;

import { Node } from '@babylonjs/core';
import { MutableRefObject } from 'react';
import { CreatedInstance } from 'react-babylonjs';

/**
 * This custom hook, helps to update constructor parameters of BabylonJS Nodes.
 * @param ref The ref to the instance of the node (e.g. Mesh, TransformNode, ArcRorateCamera)
 * @param data The parameters to update on the instance
 */
const useUpdateBJSCtorParameter = <T extends Node>(
    ref: MutableRefObject<CreatedInstance<T> | undefined>,
    data: Partial<T>
): void => {
    if (ref.current?.hostInstance) {
        Object.keys(data).forEach((k) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            ref.current.hostInstance[k] = data[k];
        });
    }
};

export default useUpdateBJSCtorParameter;

import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../state/ducks';
import { configuratorActions } from '../../state/ducks/configurator';
import './AmbientMenuComponent.scss';
import { AmbientMenuItem, AmbientOptions, AmbientTabItem } from './AmbientMenuComponent.types';
import { INITIAL_STATE } from './AmbientMenuInitialState';
import { createItem, createTab } from './AmbientMenuUtils';

const AmbientMenuComponent = (): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState(0);
    const dispatch = useDispatch();
    const selectAmbientOption = (fileName: string, optionType: AmbientOptions) =>
        dispatch(configuratorActions.selectAmbientOption(fileName, optionType));

    const roomConfig = useSelector((state: StoreState) => state.configurator.roomConfiguration);
    const menuOpen = useSelector((state: StoreState) => state.system.ambientMenu.menuOpen);

    const onItemClick = (fileName: string, ambientType: AmbientOptions) => {
        selectAmbientOption(fileName, ambientType);
    };

    const tabs = useMemo(
        () =>
            INITIAL_STATE.tabs.map((tabItem: AmbientTabItem, index: number) =>
                createTab(tabItem.label, index, selectedTab, setSelectedTab)
            ),
        [selectedTab]
    );

    const content = useMemo(
        () =>
            INITIAL_STATE.items
                .filter((menuItem) => {
                    const selectedTabItem = INITIAL_STATE.tabs[selectedTab];
                    return menuItem.type === selectedTabItem.type;
                })
                .map((menuItem: AmbientMenuItem) =>
                    createItem(
                        menuItem,
                        onItemClick,
                        menuItem.fileName === roomConfig[INITIAL_STATE.tabs[selectedTab].type]
                    )
                ),
        [selectedTab, roomConfig]
    );

    return menuOpen ? (
        <div className="ambient-menu-component">
            <div className="ambient-menu-component-tab-menu">{tabs}</div>
            <div className="ambient-menu-component-content">{content}</div>
        </div>
    ) : (
        <></>
    );
};

export default AmbientMenuComponent;

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
function createReducer<T>(initialState: T) {
    return function (reducerMap: any) {
        return function (state: T = initialState, action: any): T {
            const reducer = reducerMap[action.type];
            return reducer ? reducer(state, action) : state;
        };
    };
}

export default createReducer;

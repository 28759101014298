import { AbstractMesh, Matrix, Scene, Tools, Vector3 } from '@babylonjs/core';
import { TAGLIST } from './helper/tagHelper';

/**
 * This function returns a new Vector3 that is calculated by translating
 * the given position in the given direction by the given length.
 * @param position The current position to translate from (in cm)
 * @param direction The direction in which to translate
 * @param length The length of how far to translate the position (in cm)
 */
export const applyTranslation = (position: Vector3, direction: Vector3, length: number): Vector3 => {
    // Calculate direction vector of correct length
    const translation = direction.normalize().multiplyByFloats(length, length, length);
    // Combine current position with translation
    return position.add(translation);
};

/**
 * This function returns a new Vector3 that holds a direction which is calculated
 * by rotating the given currentDirection around the y-axis by the given angle.
 * @param currentDirection The direction that will be rotated
 * @param angle The angle by which the currentDirection will be rotated (in deg)
 */
export const applyRotationY = (currentDirection: Vector3, angle: number): Vector3 => {
    // Create rotation matrix for angle around y axis
    const rotationMatrix = Matrix.RotationY(Tools.ToRadians(angle));
    // Calculate the new angle vector by combining the the vectors
    return Vector3.TransformCoordinates(currentDirection, rotationMatrix);
};

/**
 * This function returns a Vector3 containing the rotation around the y-axis in rad.
 * @param direction The direction on the xz-plane to get the rotation from
 */
export const getRotationYFromDirection = (direction: Vector3): Vector3 => {
    const angle = Vector3.GetAngleBetweenVectors(Vector3.Right(), direction, Vector3.Up());
    return new Vector3(0, angle, 0);
};

/**
 * This function adds a mesh to the renderlist of each reflection Probe in the passed scene
 * @param mesh The mesh that will be added to the renderlists
 * @param scene The scene which contains the reflection probes
 */
export const registerOnReflectionProbe = (mesh: AbstractMesh, scene: Scene): void => {
    scene.reflectionProbes?.forEach((probe) => {
        if (probe.renderList && !probe.renderList.some((probeMesh) => probeMesh.uniqueId === mesh.uniqueId)) {
            probe.renderList.push(mesh);
        }
        const meshes = scene.getMeshesByTags(TAGLIST.REFLECTION.toString(), (mesh) => mesh.material?.unfreeze());
        probe.cubeTexture.render();
        meshes.forEach((mesh) => mesh.material?.freeze());
    });
};

/**
 * This function removes a given mesh from all reflection probes in a scene
 * @param mesh The mesh that will be removed
 * @param scene The scene which contains the reflection probes
 */
export const unregisterOnReflectionProbe = (reflectedMesh: AbstractMesh, scene: Scene): void => {
    scene.reflectionProbes?.forEach((probe) => {
        const meshList = probe.renderList;
        if (meshList && probe.renderList) {
            const meshesToKeep = meshList.filter((mesh) => mesh.uniqueId !== reflectedMesh.uniqueId);
            while (probe.renderList.length > 0) {
                probe.renderList.pop();
            }
            meshesToKeep.forEach((mesh) => {
                probe.renderList?.push(mesh);
            });
        }
        const meshes = scene.getMeshesByTags(TAGLIST.REFLECTION.toString(), (mesh) => mesh.material?.unfreeze());
        probe.cubeTexture.render();
        meshes.forEach((mesh) => mesh.material?.freeze());
    });
};

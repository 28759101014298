import {
    CloseAmbientMenuAction,
    CloseMenuAction,
    CloseMenuWithAction,
    CloseModalResetAction,
    CloseModalShareAction,
    ConfigOptionEnum,
    OpenAmbientMenuAction,
    OpenMenuAction,
    OpenMenuWithAction,
    OpenModalResetAction,
    OpenModalShareAction,
    ToggleAmbientMenuAction,
    ToggleMenuAction,
    ZoomAtAction,
    ToggleMarkerVisibilityAction,
    LoggedInUserAction,
    SetUserConfigAction,
    OpenModalPrivacyAction,
    CloseModalPrivacyAction,
    ToggleModalPrivacyAction,
    SetDisplayRatioOptions,
    ShowMarkerAction,
    HideMarkerAction,
    OpenProductOverviewAction,
    CloseProductOverviewAction,
    ToggleProductOverViewAction,
    SetMarkersHighlightedAction,
    SetTutorialActiveAction,
    SetMenuRestoreState
} from './interfaces';
import { MenuRestoreState, UserConfig } from './reducers';
import {
    CLOSE_AMBIENT_MENU,
    CLOSE_MENU,
    CLOSE_MENU_WITH,
    CLOSE_MODAL_RESET,
    CLOSE_MODAL_SHARE,
    OPEN_AMBIENT_MENU,
    OPEN_MENU,
    OPEN_MENU_WITH,
    OPEN_MODAL_RESET,
    OPEN_MODAL_SHARE,
    SHOW_MARKER,
    HIDE_MARKER,
    SET_ZOOM_AT,
    TOGGLE_AMBIENT_MENU,
    TOGGLE_MENU,
    TOGGLE_MARKER_VISIBILITY,
    SET_LOGGED_IN_USER,
    SET_USER_CONFIG,
    OPEN_MODAL_PRIVACY,
    CLOSE_MODAL_PRIVACY,
    TOGGLE_MODAL_PRIVACY,
    SET_DISPLAY_RATIO_OPTIONS,
    SET_MENU_RESTORE_STATE,
    CLOSE_PRODUCT_OVERVIEW,
    TOGGLE_PRODUCT_OVERVIEW,
    OPEN_PRODUCT_OVERVIEW,
    SET_TUTORIAL_ACTIVE,
    SET_MARKERS_HIGHLIGHTED
} from './types';

export const openMenu = (): OpenMenuAction => ({
    type: OPEN_MENU
});

export const openMenuWith = (optionPicker: ConfigOptionEnum): OpenMenuWithAction => ({
    type: OPEN_MENU_WITH,
    payload: {
        optionPicker
    }
});

export const closeMenuWith = (optionPicker: ConfigOptionEnum): CloseMenuWithAction => ({
    type: CLOSE_MENU_WITH,
    payload: {
        optionPicker
    }
});

export const closeMenu = (): CloseMenuAction => ({
    type: CLOSE_MENU
});

export const openProductOverview = (): OpenProductOverviewAction => ({
    type: OPEN_PRODUCT_OVERVIEW
});

export const toggleProductOverview = (): ToggleProductOverViewAction => ({
    type: TOGGLE_PRODUCT_OVERVIEW
});

export const closeProductOverview = (): CloseProductOverviewAction => ({
    type: CLOSE_PRODUCT_OVERVIEW
});

export const openAmbientMenu = (): OpenAmbientMenuAction => ({
    type: OPEN_AMBIENT_MENU
});

export const closeAmbientMenu = (): CloseAmbientMenuAction => ({
    type: CLOSE_AMBIENT_MENU
});

export const toggleAmbientMenu = (): ToggleAmbientMenuAction => ({
    type: TOGGLE_AMBIENT_MENU
});

export const toggleMenu = (): ToggleMenuAction => ({
    type: TOGGLE_MENU
});

export const openModalReset = (): OpenModalResetAction => ({
    type: OPEN_MODAL_RESET
});

export const closeModalReset = (): CloseModalResetAction => ({
    type: CLOSE_MODAL_RESET
});

export const openModalShare = (): OpenModalShareAction => ({
    type: OPEN_MODAL_SHARE
});

export const closeModalShare = (): CloseModalShareAction => ({
    type: CLOSE_MODAL_SHARE
});

export const openModalPrivacy = (): OpenModalPrivacyAction => ({
    type: OPEN_MODAL_PRIVACY
});

export const closeModalPrivacy = (): CloseModalPrivacyAction => ({
    type: CLOSE_MODAL_PRIVACY
});

export const toggleModalPrivacy = (): ToggleModalPrivacyAction => ({
    type: TOGGLE_MODAL_PRIVACY
});

export const setZoomAt = (zoomAt: string | null): ZoomAtAction => ({
    type: SET_ZOOM_AT,
    payload: { zoomAt }
});

export const showMarker = (): ShowMarkerAction => ({
    type: SHOW_MARKER
});

export const toggleMarkerVisibility = (): ToggleMarkerVisibilityAction => ({
    type: TOGGLE_MARKER_VISIBILITY
});

export const hideMarker = (): HideMarkerAction => ({
    type: HIDE_MARKER
});

export const setLoggedInUser = (loggedInUser: string | null): LoggedInUserAction => ({
    type: SET_LOGGED_IN_USER,
    payload: { loggedInUser }
});

export const setUserConfig = (userConfig: UserConfig | null): SetUserConfigAction => ({
    type: SET_USER_CONFIG,
    payload: { userConfig }
});

export const setDisplayRatioOptions = (displayRatioOptions: boolean): SetDisplayRatioOptions => ({
    type: SET_DISPLAY_RATIO_OPTIONS,
    payload: { displayRatioOptions }
});

export const setMarkersHighlighted = (markersHighlighted: boolean): SetMarkersHighlightedAction => ({
    type: SET_MARKERS_HIGHLIGHTED,
    payload: { markersHighlighted }
});

export const setTutorialActive = (tutorialActive: boolean): SetTutorialActiveAction => ({
    type: SET_TUTORIAL_ACTIVE,
    payload: {
        tutorialActive
    }
});

export const setMenuRestoreState = (menuRestoreState: MenuRestoreState): SetMenuRestoreState => ({
    type: SET_MENU_RESTORE_STATE,
    payload: { menuRestoreState }
});

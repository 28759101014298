import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { take } from 'rxjs/operators';
import sceneService from '../../services/scene.service';
import { StoreState } from '../../state/ducks';
import FirebaseContext from '../firebase/context';
import Spinner from '../spinner/Spinner.component';
import CopyLinkButton from './CopyLinkButton';
import FacebookButton from './FacebookButton';
import PinterestButton from './PinterestButton';
import './SocialShare.component.scss';

interface SocialShareProps {
    onAfterShare?: () => void;
}

const SocialShare = ({ onAfterShare }: SocialShareProps): JSX.Element => {
    const componentIsMounted = useRef(true);
    const [copiedLink, setCopiedLink] = useState('');
    const [show, setShow] = useState(false);
    const firebase = useContext(FirebaseContext);

    const socialMedia = useSelector((state: StoreState) => state.system.socialMedia);

    useEffect(() => {
        return (): void => {
            componentIsMounted.current = false;
        };
    }, []);

    useEffect(() => {
        sceneService
            .createScreenShot(
                (png) =>
                    new Promise<void>((res) =>
                        firebase
                            .saveImageToDB(socialMedia.search, png)
                            .pipe(take(1))
                            .subscribe(() => res())
                    )
            )
            .then(setShow)
            .catch(setShow);
    }, [socialMedia, firebase]);

    const onAfterShareSocial = useCallback(() => {
        setCopiedLink('');
        onAfterShare?.();
    }, [onAfterShare]);

    const onAfterShareLink = useCallback(() => {
        onAfterShare?.();
        setCopiedLink('Link wurde in die Zwischenablage kopiert.');
        setTimeout(() => {
            if (componentIsMounted.current) {
                setCopiedLink('');
            }
        }, 3000);
    }, [onAfterShare]);

    return (
        <div className="social-share">
            {show && (
                <div className="buttons">
                    <FacebookButton
                        onAfterShare={onAfterShareSocial}
                        url={socialMedia.crawlerUrl}
                        title={socialMedia.title}
                    />
                    <PinterestButton
                        onAfterShare={onAfterShareSocial}
                        url={socialMedia.crawlerUrl}
                        title={socialMedia.title}
                        media={socialMedia.image}
                        description={socialMedia.description}
                    />
                    <CopyLinkButton onAfterShare={onAfterShareLink} url={socialMedia.url} />
                </div>
            )}
            {!show && <Spinner />}
            <p className="message">{copiedLink}</p>
        </div>
    );
};

export default SocialShare;

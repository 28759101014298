import React, { useEffect, useState } from 'react';
import './SliderComponent.scss';

interface SliderComponentProps {
    id: number;
    min: number;
    max: number;
    value: number;
    defaultValue: number;
    onChange: (length: string) => void;
    label: string;
}

const SliderComponent = ({ min, max, value, onChange, label, id }: SliderComponentProps): JSX.Element => {
    const [error, setError] = useState<string | null>(null);
    const [inputValue, setInputvalue] = useState<number>(value);

    useEffect(() => {
        setError(null);
        setInputvalue(value);
    }, [value]);

    const valueChanged = (value: string) => {
        const newValue = parseInt(value);

        if (!!newValue) {
            setInputvalue(newValue);
            if (newValue > max) {
                setError(`Max. ${max} mm`);
                return;
            }
            if (newValue < min) {
                setError(`Min. ${min} mm`);
                return;
            }
            setError(null);
            onChange(value);
        }
    };

    return (
        <div className={`slider ${min === max ? 'disabled' : ''}`}>
            <label className="label-id" htmlFor={`shower-size-${id}`}>
                {label}
            </label>
            <input
                id={`shower-size-${id}`}
                type="range"
                min={min}
                max={max}
                value={Math.round(value)}
                onChange={(e) => valueChanged(e.target.value)}
            />
            <label className="label-length" htmlFor={`shower-size-${id}`}>
                <div className="slider-input-wrapper">
                    <input
                        className={`slider-shower-size ${error && 'error'}`}
                        type="number"
                        inputMode="decimal"
                        value={Math.round(inputValue)}
                        onChange={(e) => valueChanged(e.target.value)}
                    />
                    <span className="label-length-unit">mm</span>
                    <div className="shower-base-size-input-error">{error}</div>
                </div>
            </label>
        </div>
    );
};

export default SliderComponent;

import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { Tools } from '@babylonjs/core/Misc';
import React from 'react';
import { useSelector } from 'react-redux';
import useZoomAt from '../../../../hooks/useZoomAt';
import { StoreState } from '../../../../state/ducks';
import { TAGLIST } from '../../../helper/tagHelper';
import { DETAIL_LAYERMASK, TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import { BlueprintDecorator } from '../../../types/Blueprint';
import { PositionType } from '../../../types/Common';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';
import ResponsiveBoxComponent from '../ResponsiveBoxComponent/ResponsiveBoxComponent';
import BlueprintElementDecoratorComponent from './BlueprintElementDecoratorComponent';
import GlassMaterialComponent from './GlassMaterialComponent';

interface PartitionComponentProps {
    widthMM: number;
    depthMM?: number;
    position: Vector3;
    rotation?: Vector3;
    sliderLabel?: string;
    decorators?: BlueprintDecorator[];
    hingePosition: PositionType;
    bottomOffsetMM: number;
    zLugPosition: PositionType;
    heightAdjustment: number;
    index: number;
}

const PartitionComponent = ({
    widthMM,
    depthMM = 8,
    position,
    rotation = Vector3.Zero(),
    sliderLabel,
    decorators,
    hingePosition,
    bottomOffsetMM,
    zLugPosition,
    heightAdjustment,
    index
}: PartitionComponentProps): JSX.Element => {
    const showerHeight = useSelector((state: StoreState) => state.architecture.showerBaseSize.y);
    const [zoomAt, setZoomAt] = useZoomAt();
    const zLugSideOffsetCM = 8;
    const zLugZOffsetCM = -0.8;
    const zLugName = 'partition_threeD_z_lug';
    const widthCM = widthMM / 10;
    const heightCM = (showerHeight + heightAdjustment) / 10;
    const boxPosition = new Vector3(widthCM * 0.5, heightCM * 0.5 + bottomOffsetMM / 10, 0);

    const zLugXCM =
        zLugPosition === PositionType.Left
            ? zLugSideOffsetCM
            : zLugPosition === PositionType.Right
            ? widthCM - zLugSideOffsetCM
            : undefined;

    return (
        <transformNode name={`tf_partition_${index}`} position={position} rotation={rotation}>
            <ResponsiveBoxComponent
                name="box_partition"
                width={widthCM}
                height={heightCM}
                depth={depthMM / 10}
                position={boxPosition}
                isPickable={false}
                sliderLabel={sliderLabel}
                bottomOffsetMM={bottomOffsetMM}
            >
                <GlassMaterialComponent />
                {decorators && (
                    <BlueprintElementDecoratorComponent
                        decorators={decorators}
                        parentWidthMM={widthMM}
                        parentPosition={boxPosition}
                        parentRotation={rotation}
                        parentHingePosition={hingePosition}
                        parentBottomOffsetMM={bottomOffsetMM}
                    />
                )}
            </ResponsiveBoxComponent>
            {zLugXCM && (
                <transformNode
                    name="partition_tf_z_lug"
                    position={new Vector3(zLugXCM, bottomOffsetMM / 10, zLugZOffsetCM)}
                >
                    <transformNode name="partition_tf_z_lug_rotation" rotation-y={Tools.ToRadians(180)}>
                        <ThreeDComponent
                            name={zLugName}
                            rootUrl={'assets/3d/z_lugs/'}
                            sceneFilename={'91-017.glb'}
                            tagList={[TAGLIST.REFLECTION]}
                            onClick={zoomAt !== zLugName ? () => setZoomAt(zLugName) : undefined}
                            layerMask={zoomAt === zLugName ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
                        />
                    </transformNode>
                </transformNode>
            )}
        </transformNode>
    );
};

export default PartitionComponent;

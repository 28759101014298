import 'normalize.css';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.scss';
import AmbientMenuComponent from './components/ambientMenu/AmbientMenuComponent';
import Firebase, { FirebaseContext } from './components/firebase/Firebase.component';
import Legal from './components/legal/Legal.component';
import RetailLoginComponent from './components/login/RetailLogin.component';
import MenuComponent from './components/menu/Menu.component';
import Url from './components/url/Url.Component';
import CloseDetailViewButton from './ng/components/3d/GUIComponents/CloseDetailViewButton';
import SideButtonsComponent from './ng/components/3d/GUIComponents/SideButtonsComponent/SideButtonsComponent';
import PageComponent from './ng/components/PageComponent/PageComponent';
import { StoreState } from './state/ducks';

function App(): JSX.Element {
    const config = useSelector((state: StoreState) => state.configurator.configuration);
    const blueprint = useSelector((state: StoreState) => state.architecture.blueprint);
    const loggedInUser = useSelector((state: StoreState) => state.system.loggedInUser);

    const isRetailPath =
        window.location.pathname.startsWith('/retailer') || window.location.pathname.startsWith('/app/retailer');
    const retailerLogin = !loggedInUser && isRetailPath;
    const userConfig = useSelector((state: StoreState) => state.system.userConfig);
    const sideButtonsVisible = !userConfig || userConfig.showSideButtons;

    const zoomAt = useSelector((state: StoreState) => state.system.zoomAt);
    return (
        <FirebaseContext.Provider value={Firebase}>
            <Router>
                <Route path="/:path?">
                    {retailerLogin && <RetailLoginComponent></RetailLoginComponent>}
                    {!retailerLogin && config !== null && (
                        <div id="app" className="App">
                            <aside>
                                <MenuComponent />
                                {!zoomAt && sideButtonsVisible && <SideButtonsComponent />}
                                {zoomAt && <CloseDetailViewButton />}
                            </aside>
                            <main>
                                <PageComponent />
                            </main>
                            <aside>
                                <AmbientMenuComponent />
                            </aside>
                            <footer>
                                <Legal />
                            </footer>
                            {blueprint !== null && <Url />}
                        </div>
                    )}
                </Route>
            </Router>
        </FirebaseContext.Provider>
    );
}

export default App;

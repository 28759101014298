import firebase from 'firebase/app';
import 'firebase/analytics';
import firebaseConfig from '../../firebase_app.json';
import { FirebaseConfiguration } from '../../components/firebase/Firebase.component';

declare global {
    interface Window {
        [key: string]: unknown;
    }
    function gtag(param1: string, param2: unknown, param3?: unknown): void;
}

export const setTrackingDisabledForID = (id: string, disabled: boolean): void => {
    window[`ga-disable-${id}`] = disabled;
};

export const setTrackingDisabled = (disabled: boolean): void => {
    firebase.analytics().setAnalyticsCollectionEnabled(!disabled);

    (firebaseConfig as FirebaseConfiguration).additionalTrackingIds?.forEach((id) => {
        setTrackingDisabledForID(id, disabled);
    });
    setTrackingDisabledForID('GTM-5G82S2K', disabled);
};

export const setTrackingUserID = (userID: string): void => {
    firebase.analytics().setUserId(userID);
    gtag('set', { user_id: userID });
};

export const addTrackingId = (id: string): void => {
    gtag('config', id, { groups: null });
};

export const initAnalytics = (): void => {
    const userStayConversionInSeconds = 90;

    firebase.analytics();
    (firebaseConfig as FirebaseConfiguration).additionalTrackingIds?.forEach((id) => {
        addTrackingId(id);
    });
    setTrackingDisabled(true);
    window.onbeforeunload = () => {
        logAnalyticsEvent('configurator_leave_page');
    };
    // Event, that gets triggered, when a user stays longer than userStayConversionInSeconds
    setTimeout(() => {
        logAnalyticsEvent(`configurator_user_stayed_on_page_${userStayConversionInSeconds}_sec`, {
            seconds: userStayConversionInSeconds
        });
    }, userStayConversionInSeconds * 1000);
};

export const logAnalyticsEvent = (eventName: string, eventParams?: { [key: string]: unknown }): void => {
    gtag('event', eventName, Object.assign({}, { event_category: 'Konfigurator' }, eventParams ?? eventParams));
};

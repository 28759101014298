import { combineReducers } from 'redux';
import { createReducer } from '../../utils';
import { ConfigOptionEnum } from '../system/interfaces';
import 'firebase/analytics';
import {
    Catalogue,
    CatalogueLoadedSuccessAction,
    Configuration,
    ConfigurationDefaultLoadedAction,
    PriceState,
    RoomConfiguration,
    SelectAmbientOptionAction,
    SelectConfigOptionAction,
    UpdateBeamPriceAction,
    UpdatePriceAction,
    UpdateShowerSizePriceAction
} from './interfaces';
import {
    CATALOGUE_LOADED_SUCCESS,
    CONFIGURATION_DEFAULT_LOADED_SUCCESS,
    SELECT_AMBIENT_OPTION,
    SELECT_CONFIG_OPTION,
    UPDATE_BEAM_PRICE,
    UPDATE_PRICE,
    UPDATE_SHOWER_SIZE_PRICE
} from './types';
import { getConfiguration, getDefaultBeamIdForModel, getDefaultConfiguration, getModelForSeries } from './utils';

export interface ConfiguratorState {
    catalogue: Catalogue;
    configuration: Configuration;
    roomConfiguration: RoomConfiguration;
    price: PriceState;
}

const catalogue = createReducer<Catalogue | null>(null)({
    [CATALOGUE_LOADED_SUCCESS]: (_: Catalogue | undefined, action: CatalogueLoadedSuccessAction) => {
        return action.payload.catalogue;
    }
});

const configuration = createReducer<Configuration | null>(null)({
    [CONFIGURATION_DEFAULT_LOADED_SUCCESS]: (_: Configuration, action: ConfigurationDefaultLoadedAction) => {
        return action.payload.configuration;
    },
    [SELECT_CONFIG_OPTION]: (oldConfig: Configuration, action: SelectConfigOptionAction) => {
        const catalogue = action.payload.catalogue;
        const option = action.payload.optionType;
        const id = action.payload.itemId;
        const isSeries = option === ConfigOptionEnum.Series;

        const isModel = option === ConfigOptionEnum.Model;
        const modelId = isModel ? action.payload.itemId : oldConfig.model.id;
        const model = getModelForSeries(catalogue, oldConfig.series.id, modelId);
        const defaultBeamId = model && getDefaultBeamIdForModel(model);

        let beamId = oldConfig.beam.id;
        if (isModel && defaultBeamId !== undefined) {
            beamId = defaultBeamId;
        }

        let newConfig;

        if (isSeries) {
            newConfig = getDefaultConfiguration(catalogue, id);
            newConfig.base = oldConfig.base;
        } else {
            newConfig = getConfiguration(
                catalogue,
                option === ConfigOptionEnum.Series ? id : oldConfig.series.id,
                option === ConfigOptionEnum.Model ? id : oldConfig.model.id,
                option === ConfigOptionEnum.Base ? id : oldConfig.base.id,
                option === ConfigOptionEnum.Glass ? id : oldConfig.glass.id,
                option === ConfigOptionEnum.Wallmount ? id : oldConfig.wallmount.id,
                option === ConfigOptionEnum.Glassmount ? id : oldConfig.glassmount.id,
                option === ConfigOptionEnum.Measurement ? id : oldConfig.measurement.id,
                option === ConfigOptionEnum.Material ? id : oldConfig.material.id,
                option === ConfigOptionEnum.Beam ? id : beamId,
                option === ConfigOptionEnum.Knob ? id : oldConfig.knob?.id,
                option === ConfigOptionEnum.Door ? id : oldConfig.door?.id,
                option === ConfigOptionEnum.Hinge ? id : oldConfig.hinge?.id
            );
        }
        newConfig.beam.price = oldConfig.beam.price;

        return newConfig;
    },
    [UPDATE_BEAM_PRICE]: (oldConfig: Configuration, action: UpdateBeamPriceAction) => {
        oldConfig.beam.price = action.payload.newBeamPrice;
        return { ...oldConfig };
    }
});

const initialRoomConfig = {
    floor: 'floor/basic_floor.jpg',
    wall: 'wall/basic_wall.jpg',
    shower: 'shower/basic_shower.jpg'
};

const roomConfiguration = createReducer<RoomConfiguration>(initialRoomConfig)({
    [SELECT_AMBIENT_OPTION]: (roomConfig: RoomConfiguration, action: SelectAmbientOptionAction): RoomConfiguration => ({
        ...roomConfig,
        [action.payload.optionType]: action.payload.fileName
    })
});

const price = createReducer<PriceState>({ total: 0, showerBaseSize: 0 })({
    [UPDATE_PRICE]: (priceState: PriceState, action: UpdatePriceAction): PriceState => {
        return {
            ...priceState,
            total: action.payload.price
        };
    },
    [UPDATE_SHOWER_SIZE_PRICE]: (priceState: PriceState, action: UpdateShowerSizePriceAction): PriceState => {
        return {
            ...priceState,
            showerBaseSize: action.payload.showerSizePrice
        };
    }
});

const reducer = combineReducers({
    catalogue,
    price,
    configuration,
    roomConfiguration
});

export default reducer;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { calculateBeamAxis, calculateBeamPosition } from '../../../helper/beamHelper';
import { BlueprintBeamConnectorType, BlueprintDecorator, BlueprintDecoratorType } from '../../../types/Blueprint';
import { PositionType } from '../../../types/Common';
import BeamComponent from '../Accessories/BeamComponent';
import FloorRailComponent from '../Accessories/FloorRailComponent';
interface BlueprintElementDecoratorComponentProps {
    decorators: BlueprintDecorator[];
    parentRotation: Vector3;
    parentPosition: Vector3;
    parentPositionOffset?: Vector3;
    parentWidthMM: number;
    parentHingePosition: PositionType;
    parentBottomOffsetMM: number;
}

const BlueprintElementDecoratorComponent = ({
    decorators,
    parentRotation,
    parentPosition,
    parentPositionOffset = Vector3.Zero(),
    parentWidthMM,
    parentHingePosition,
    parentBottomOffsetMM
}: BlueprintElementDecoratorComponentProps): JSX.Element => {
    const blueprintElementDecorators = decorators.map((decorator, index) => {
        switch (decorator.type) {
            case BlueprintDecoratorType.Beam:
                const beamTechConfig = useSelector((state: StoreState) => state.architecture.techConfigs.beam);

                if (beamTechConfig && decorator.connector) {
                    const beams = decorator.position.map((decoratorPosition, i) => {
                        return (
                            <BeamComponent
                                techConfig={beamTechConfig}
                                axis={calculateBeamAxis(parentRotation)}
                                connector={decorator.connector as BlueprintBeamConnectorType}
                                side={decoratorPosition}
                                position={calculateBeamPosition(
                                    decoratorPosition,
                                    parentPosition.add(new Vector3(0, -parentBottomOffsetMM / 10, 0))
                                )}
                                key={`beam_${i}`}
                            />
                        );
                    });
                    return beams;
                }
                break;

            case BlueprintDecoratorType.FloorRail:
                if (parentBottomOffsetMM > 0) {
                    return;
                }
                return (
                    <FloorRailComponent
                        railSite={parentHingePosition}
                        lengthMM={parentWidthMM}
                        position={new Vector3(0, -parentPosition.y, 0)}
                        parentPositionOffset={parentPositionOffset}
                        key={`floorRail_${index}`}
                    />
                );
        }
    });

    return <>{blueprintElementDecorators}</>;
};

export default BlueprintElementDecoratorComponent;

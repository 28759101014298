/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Modal from 'react-modal';
import { useSelector } from 'react-redux';
import menuCaretSvg from '../../../assets/icons/menu/caret.svg';
import { getDiscountPrice, hasSpecialOffer, useDiscount } from '../../../ng/helper/discountHelper';
import { StoreState } from '../../../state/ducks';
import './MenuFooterComponent.scss';

type MenuFooterComponentProps = {
    hidden: boolean;
    openSendOfferModal: () => void;
};

const MenuFooterComponent = ({ hidden, openSendOfferModal }: MenuFooterComponentProps): JSX.Element => {
    const totalPrice = useSelector((state: StoreState) => state.configurator.price.total);
    const appElement = document.getElementById('app');
    appElement && Modal.setAppElement(appElement);

    const userConfig = useSelector((state: StoreState) => state.system.userConfig);
    const offerButtonVisible = !userConfig || userConfig.showOfferButton;

    const discountData = useDiscount();
    let specialOfferActive = false;
    let discountPrice = 0;
    if (discountData) {
        discountPrice = getDiscountPrice(totalPrice, discountData.percentage);
        specialOfferActive = hasSpecialOffer(discountData.startDate, discountData.endDate);
    }
    return (
        <>
            <div className={`footer ${hidden ? 'hidden' : ''}`}>
                <div className="footer-left">
                    {specialOfferActive && !hidden && (
                        <div className="footer-left-discount-sign">
                            <strong className="footer-left-discount-sign-text">-{discountData?.percentage}%*</strong>
                        </div>
                    )}
                    {!specialOfferActive && (
                        <span>
                            <strong className="footer-left-srp">UVP</strong>
                            <span>ab {totalPrice},- EUR</span>
                        </span>
                    )}
                    {specialOfferActive && (
                        <>
                            <span className="footer-left-discount-original-price">{totalPrice},-€</span>&nbsp;&nbsp;
                            <strong>ab {discountPrice},-€</strong>
                        </>
                    )}
                    <div className="footer-left-tax-hint">zzgl. MwSt.</div>
                </div>
                {offerButtonVisible && (
                    <div className="footer-right" onClick={openSendOfferModal}>
                        <div>Angebot anfragen</div>

                        <div className="footer-right-caret">
                            <img src={menuCaretSvg} alt="Open/Close Menu" />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default MenuFooterComponent;

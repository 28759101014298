import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import brushButtonIcon from '../../../../../assets/icons/sidebar/brush-icon.svg';
import eyesClosedIcon from '../../../../../assets/icons/sidebar/eyes-closed-icon.svg';
import eyesOpenedIcon from '../../../../../assets/icons/sidebar/eyes-opened-icon.svg';
import resetConfigButtonIcon from '../../../../../assets/icons/sidebar/reset-icon.svg';
import shareButtonIcon from '../../../../../assets/icons/sidebar/share-icon.svg';
import debugModeInactiveIcon from '../../../../../assets/icons/sidebar/debug-mode-inactive-icon.svg';
import debugModeActiveIcon from '../../../../../assets/icons/sidebar/debug-mode-active-icon.svg';
import useDebugMode from '../../../../../hooks/useDebugMode';
import { systemActions } from '../../../../../state/ducks/system';
import {
    OpenModalResetAction,
    OpenModalShareAction,
    ToggleAmbientMenuAction,
    ToggleMarkerVisibilityAction
} from '../../../../../state/ducks/system/interfaces';
import { StoreState } from '../../../../../state/ducks';
import { toggleMarkerVisibility } from '../../../../../state/ducks/system/actions';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../../../helper/analyticsHelper';
import './SideButtonsComponent.scss';

export type SideButtonConfig = {
    icon: string;
    active: boolean;
    onClick?: () => void;
    id?: string;
};

interface SideButtonsComponentProps {
    config?: SideButtonConfig[];
}

const SideButtonsComponent = ({ config }: SideButtonsComponentProps): JSX.Element => {
    const isDevelopmentBuild = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
    const [debugModeActive, toggleDebugModeActive] = useDebugMode();
    const modalShareOpen = useSelector((state: StoreState) => state.system.modalShareOpen);
    const modalResetOpen = useSelector((state: StoreState) => state.system.modalResetOpen);
    const markerVisible = useSelector((state: StoreState) => state.system.markerVisibility);
    const ambientMenuOpen = useSelector((state: StoreState) => state.system.ambientMenu.menuOpen);

    const dispatch = useDispatch();
    const openShareDialog = useCallback((): OpenModalShareAction => {
        logAnalyticsEvent('configurator_open_social_media_modal');
        return dispatch(systemActions.openModalShare());
    }, [dispatch]);
    const resetConfig = useCallback((): OpenModalResetAction => {
        logAnalyticsEvent('configurator_open_reset_configuration_modal');
        return dispatch(systemActions.openModalReset());
    }, [dispatch]);
    const toggleAmbientMenu = useCallback((): ToggleAmbientMenuAction => {
        logAnalyticsEvent('configurator_toggle_ambient_menu');
        return dispatch(systemActions.toggleAmbientMenu());
    }, [dispatch]);
    const toggleMarkerVisibilityAction = useCallback((): ToggleMarkerVisibilityAction => {
        logAnalyticsEvent('configurator_toggle_marker_visibility');
        return dispatch(toggleMarkerVisibility());
    }, [dispatch]);

    const defaultConfig: SideButtonConfig[] = useMemo(() => {
        const config: SideButtonConfig[] = [
            { id: 'share-button', icon: shareButtonIcon, active: modalShareOpen, onClick: openShareDialog },
            { id: 'reset-button', icon: resetConfigButtonIcon, active: modalResetOpen, onClick: resetConfig },
            {
                id: 'markervisible-button',
                icon: markerVisible ? eyesOpenedIcon : eyesClosedIcon,
                active: markerVisible,
                onClick: toggleMarkerVisibilityAction
            },
            { id: 'ambient-button', icon: brushButtonIcon, active: ambientMenuOpen, onClick: toggleAmbientMenu }
        ];

        if (isDevelopmentBuild) {
            config.push({
                id: 'debug-button',
                icon: debugModeActive ? debugModeActiveIcon : debugModeInactiveIcon,
                active: debugModeActive,
                onClick: toggleDebugModeActive
            });
        }

        return config;
    }, [debugModeActive, markerVisible, modalShareOpen, modalResetOpen, ambientMenuOpen]);

    return (
        <div className="sidebuttons-component">
            {(config || defaultConfig).map((sideButtonConfig: SideButtonConfig) => {
                return (
                    <button
                        id={sideButtonConfig.id && sideButtonConfig.id}
                        className={`sidebutton ${sideButtonConfig.active && 'active'}`}
                        key={`${sideButtonConfig.icon}_${sideButtonConfig.active}`}
                        type="button"
                        onClick={sideButtonConfig.onClick}
                    >
                        <img src={sideButtonConfig.icon}></img>
                    </button>
                );
            })}
        </div>
    );
};

export default SideButtonsComponent;

import React from 'react';
import './SubMenuInfoComponent.scss';

interface SubMenuInfoComponentProps {
    infoText: string;
}

const SubMenuInfoComponent = ({ infoText }: SubMenuInfoComponentProps): JSX.Element => {
    return (
        <div className="sub-menu-component">
            <p>{infoText}</p>
        </div>
    );
};

export default SubMenuInfoComponent;

import React, { useEffect, useRef, useState } from 'react';
import { Container, Control } from '@babylonjs/gui';
import { useSelector } from 'react-redux';
import { ConfigOptionEnum } from '../../../../../state/ducks/system/interfaces';
import { useCallback } from 'react';
import { AbstractMesh, TransformNode, Vector3 } from '@babylonjs/core';
import { useBabylonScene } from 'react-babylonjs';
import { StoreState } from '../../../../../state/ducks';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../../../helper/analyticsHelper';
import { getStackPanelConfig, MarkerAlignment } from './MarkerComponentUtil';
import useZoomAt from '../../../../../hooks/useZoomAt';
type NodeInfo = {
    priority: number;
    node: TransformNode;
};

type MarkerComponentProps = {
    markerAlignment?: MarkerAlignment;
    label: string;
    labelWidthPx: number;
    connectToMenu: ConfigOptionEnum;
    connectToNodeName: string[];
    worldOffset?: Vector3;
};

const MarkerComponent = ({
    markerAlignment = MarkerAlignment.right,
    label,
    connectToMenu,
    labelWidthPx,
    connectToNodeName: connectToNodeNames,
    worldOffset
}: MarkerComponentProps): JSX.Element => {
    const scene = useBabylonScene();
    const [, setZoomAt] = useZoomAt();
    const markerColor = 'white';
    const markerSize = 40 * window.devicePixelRatio;
    const markerLabelPadding = 22 * window.devicePixelRatio;
    const labelWidth = labelWidthPx * window.devicePixelRatio;
    const labelHeight = 45 * window.devicePixelRatio;
    const labelFontSize = 14;
    const arrowPadding = 5;

    const panelStackConfig = getStackPanelConfig(
        markerAlignment,
        markerSize,
        markerLabelPadding,
        labelWidth,
        labelHeight
    );
    const foundNodes = useRef<NodeInfo[]>([]);
    const [marker, setMarker] = useState<Container>();
    const onMarkerRef = useCallback((node) => setMarker(node?.hostInstance), [setMarker]);
    const node = useRef<TransformNode>();
    const [markerLinkedWithNode, setMarkerLinkedWithNode] = useState<boolean>(false);
    // HACK to force rerender / recheck of useEffect
    const [forceUseEffectHighPrio, setForceUseEffectHighPrio] = useState(0);
    const [forceUseEffectLink, setForceUseEffectLink] = useState(0);

    // Init
    useEffect(() => {
        const initialNodes = connectToNodeNames
            .map((name: string, idx: number): NodeInfo | undefined => {
                const foundNode = scene?.getTransformNodeByName(name);
                if (foundNode) {
                    return { node: foundNode, priority: idx };
                }
            })
            .filter((nodeInfo) => nodeInfo) as NodeInfo[];

        foundNodes.current = initialNodes;
        return () => unlinkNode();
    }, []);

    const unlinkNode = useCallback(() => {
        node.current = undefined;
        setMarkerLinkedWithNode(false);
    }, [node, node.current]);

    const selectedModelId = useSelector((state: StoreState) => state.configurator.configuration.model.id);
    useEffect(() => {
        unlinkNode();
    }, [selectedModelId]);

    // Create Callback when transform was added to scene
    const nodeAddedCallback = useCallback(
        (newNode: TransformNode) => {
            const newNodeIndex = connectToNodeNames.findIndex((nodeName) => newNode.name === nodeName);
            if (newNodeIndex >= 0) {
                const oldNodeIndex = foundNodes.current.findIndex((nodeInfo) => nodeInfo.node.name === newNode.name);
                if (oldNodeIndex >= 0) {
                    foundNodes.current.splice(oldNodeIndex, 1);
                    foundNodes.current = foundNodes.current.slice();
                    if (node.current?.name === newNode.name) {
                        unlinkNode();
                    }
                }
                const newNodeInfo = { node: newNode, priority: newNodeIndex };
                foundNodes.current = [...foundNodes.current, newNodeInfo];
                setForceUseEffectHighPrio((forceUseEffectHighPrio + 1) % 10);
            }
        },
        [connectToNodeNames, foundNodes.current, node, node.current]
    );

    // Create Callback, when transform got removed from scene
    const nodeDeletedCallback = useCallback(
        (deletedNode: TransformNode) => {
            const removedNodeIndex = foundNodes.current.findIndex(
                (nodeInfo) => deletedNode.uniqueId === nodeInfo.node.uniqueId
            );
            if (removedNodeIndex >= 0) {
                if (deletedNode.uniqueId === node.current?.uniqueId) {
                    unlinkNode();
                }
                foundNodes.current.splice(removedNodeIndex, 1);
                foundNodes.current = foundNodes.current.slice();
                setForceUseEffectHighPrio((forceUseEffectHighPrio + 1) % 10);
            }
        },
        [foundNodes.current, foundNodes.current.length]
    );

    // Add / Remove Callbacks from Scene
    useEffect(() => {
        scene?.onNewTransformNodeAddedObservable.add(nodeAddedCallback);
        scene?.onTransformNodeRemovedObservable.add(nodeDeletedCallback);
        return () => {
            scene?.onNewTransformNodeAddedObservable.removeCallback(nodeAddedCallback);
            scene?.onTransformNodeRemovedObservable.removeCallback(nodeDeletedCallback);
        };
    }, [nodeAddedCallback]);

    // Find highest prio node
    useEffect(() => {
        const newNodeInfo = foundNodes.current.reduce((previousNodeInfo: NodeInfo | undefined, currentNodeInfo) => {
            if ((previousNodeInfo?.priority ?? 99) <= currentNodeInfo?.priority) {
                return previousNodeInfo;
            } else {
                return currentNodeInfo;
            }
        }, undefined);

        if (newNodeInfo) {
            const newSceneNode = scene?.getTransformNodeByName(newNodeInfo.node.name);
            if (newSceneNode) {
                node.current = newSceneNode;
                setForceUseEffectLink((forceUseEffectLink + 1) % 10);
            } else {
                // Node has left the scene. Shouldn't happen but it's a failsafe
                const goneNodeIdx = foundNodes.current.findIndex(
                    (nodeInfo) => nodeInfo.node.name === newNodeInfo.node.name
                );
                foundNodes.current.splice(goneNodeIdx, 1);
                foundNodes.current = foundNodes.current.slice();
                setForceUseEffectHighPrio((forceUseEffectHighPrio + 1) % 10);
                unlinkNode();
            }
        } else {
            unlinkNode();
        }
    }, [foundNodes, foundNodes.current, foundNodes.current.length]);

    // Link Marker to Node
    useEffect(() => {
        if (marker && node.current) {
            if (worldOffset) {
                const markerAnchor = new TransformNode(node.current.name + '_marker_anchor', scene);
                markerAnchor.setParent(node.current);
                markerAnchor.position = worldOffset;
                marker.linkWithMesh(markerAnchor as AbstractMesh);
            } else {
                marker.linkWithMesh(node.current as AbstractMesh);
            }
            setMarkerLinkedWithNode(true);
        }
    }, [marker, node, node.current, worldOffset, forceUseEffectLink]);

    const onMarkerClick = () => {
        logAnalyticsEvent(`configurator_click_marker:${label}`);
        if (node.current && connectToMenu !== ConfigOptionEnum.Measurement) {
            let tfName = node.current.name;
            if (node.current.name.startsWith('slidingHinge')) {
                tfName = 'slidingHinge_tf';
            } else if (connectToMenu == ConfigOptionEnum.Beam) {
                tfName = 'beam_tf';
            }
            setZoomAt(tfName, connectToMenu);
        }
    };

    useEffect(() => {
        markerAlignment === MarkerAlignment.top && marker?.children.reverse();
    }, [marker]);

    return (
        <stackPanel
            name={`marker_stack-panel_${label}`}
            isVertical={panelStackConfig.isVertical}
            isVisible={markerLinkedWithNode}
            ref={onMarkerRef}
            widthInPixels={panelStackConfig.panelContentWidth + panelStackConfig.panelPaddingLeft} // The padding is content
            heightInPixels={panelStackConfig.panelContentHeight + panelStackConfig.panelPaddingTop}
            paddingLeft={panelStackConfig.panelPaddingLeft}
            paddingTop={panelStackConfig.panelPaddingTop}
            zIndex={-1}
        >
            <babylon-button
                adaptHeightToChildren={true}
                adaptWidthToChildren={true}
                onPointerClickObservable={onMarkerClick}
                color="transparent"
                hoverCursor="pointer"
            >
                <babylon-ellipse
                    name={`ellipse_${label}`}
                    widthInPixels={markerSize}
                    heightInPixels={markerSize}
                    thickness={3}
                    color={markerColor}
                    background="transparent"
                >
                    <babylon-ellipse
                        name={`ellipse_center_${label}`}
                        widthInPixels={window.devicePixelRatio * 10}
                        heightInPixels={window.devicePixelRatio * 10}
                        thickness={0}
                        background={markerColor}
                    />
                </babylon-ellipse>
            </babylon-button>
            <rectangle widthInPixels={arrowPadding} heightInPixels={arrowPadding} thickness={0}></rectangle>
            <babylon-image
                rotation={panelStackConfig.arrowRotation}
                widthInPixels={14}
                heightInPixels={14}
                source={'assets/img/marker/polygon.svg'}
            ></babylon-image>

            <babylon-button
                name={`button_${label}`}
                widthInPixels={labelWidth}
                heightInPixels={labelHeight}
                thickness={0}
                background={markerColor}
                onPointerClickObservable={onMarkerClick}
                verticalAlignment={Control.HORIZONTAL_ALIGNMENT_CENTER}
                horizontalAlignment={Control.HORIZONTAL_ALIGNMENT_CENTER}
                hoverCursor="pointer"
                fontFamily="Source Sans Pro"
                fontStyle="normal"
                fontWeight={'500'}
                fontSize={labelFontSize * window.devicePixelRatio}
                color="#504f4e"
            >
                <textBlock
                    name={`textLabel_${label}`}
                    text={label.toUpperCase()}
                    verticalAlignment={Control.HORIZONTAL_ALIGNMENT_CENTER}
                    horizontalAlignment={Control.HORIZONTAL_ALIGNMENT_CENTER}
                    width="100%"
                    textWrapping={true}
                />
            </babylon-button>
        </stackPanel>
    );
};

export default MarkerComponent;

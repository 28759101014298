import { Vector2, Vector3 } from '@babylonjs/core';
import React from 'react';
import { TAGLIST } from '../../../helper/tagHelper';
import { RoomDecorator } from '../../../types/Roomplan';
import RoomDecoratorComponent from '../BlueprintBaseComponents/RoomDecoratorComponent';
import ResponsiveBoxComponent from '../ResponsiveBoxComponent/ResponsiveBoxComponent';
import ScaledTextureComponent from '../ScaledTextureComponent/ScaledTextureComponent';

/**
 * This component builds a wall element with the given properties
 * @param positionCM The bottom left position of the new wall element
 * @param rotation The rotation of the wall
 * @param widthMM The width of the wall
 * @param heightMM The height of the wall
 * @param depthMM The depth of the wall
 * @param preAdditionalWidthMM The length to add pre to the wall
 * @param postAdditionalWidthMM The length to add post to the wall
 * @param textureUrl The texture url for the wall
 * @param decorator The optional decorator for the wall
 * @param decoratorDepthMM The depth of the decorator
 * @param showerSideLengthMM The length of the shower that is parallel to the wall
 * @param showerHeighthMM The height of the shower
 * @param decoratorXOffsetMM The offset of the decorator from its origin
 */
type WallComponentProps = {
    positionCM: Vector3;
    rotation: Vector3;
    widthMM: number;
    heightMM: number;
    depthMM: number;
    texture: string;
    textureBaseSize: Vector2;
    textureScaling?: Vector2;
    decorators?: RoomDecorator[];
    decoratorDepthMM: number;
    showerSideLengthMM: number;
    decoratorXOffsetMM: number;
};

const WallComponent = ({
    positionCM,
    rotation,
    widthMM,
    heightMM,
    depthMM,
    texture,
    textureBaseSize,
    textureScaling,
    decorators,
    decoratorDepthMM,
    showerSideLengthMM,
    decoratorXOffsetMM
}: WallComponentProps): JSX.Element => {
    const widthCM = widthMM / 10;
    const heightCM = heightMM / 10;
    const wallPosition = new Vector3(widthCM * 0.5, heightCM * 0.5, 0);

    return (
        <transformNode name="tf_wall" position={positionCM} rotation={rotation}>
            <ResponsiveBoxComponent
                checkCollisions
                name={'wall_name'}
                width={widthCM}
                height={heightMM / 10}
                depth={depthMM / 10}
                position={wallPosition}
                tagList={[TAGLIST.BE_REFELECTED]}
            >
                <standardMaterial name="wall_rendering">
                    <ScaledTextureComponent
                        assignTo="diffuseTexture"
                        sizeCM={new Vector2(widthMM / 10, heightMM / 10)}
                        texture={texture}
                        baseSize={textureBaseSize}
                        scaling={textureScaling}
                    />
                </standardMaterial>
                {decorators && (
                    <RoomDecoratorComponent
                        decorators={decorators}
                        parentWidthMM={widthMM}
                        parentHeightMM={heightMM}
                        decoratorDepthMM={decoratorDepthMM}
                        showerSideLengthMM={showerSideLengthMM}
                        decoratorXOffsetMM={decoratorXOffsetMM}
                    ></RoomDecoratorComponent>
                )}
            </ResponsiveBoxComponent>
        </transformNode>
    );
};

export default WallComponent;

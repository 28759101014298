import { AmbientMenuItem, AmbientOptions, AmbientTabItem } from './AmbientMenuComponent.types';

const tabs: AmbientTabItem[] = [
    { label: 'Wand', type: AmbientOptions.Wall },
    { label: 'Boden', type: AmbientOptions.Floor },
    { label: 'Dusche', type: AmbientOptions.Shower }
];

const items: AmbientMenuItem[] = [
    {
        type: AmbientOptions.Floor,
        thumbnail: '/assets/img/ambientMenu/floor/basic_floor_UI.jpg',
        label: 'Boden 1',
        fileName: 'floor/basic_floor.jpg'
    },
    {
        type: AmbientOptions.Floor,
        thumbnail: '/assets/img/ambientMenu/floor/puristic_floor_UI.jpg',
        label: 'Boden 2',
        fileName: 'floor/puristic_floor.jpg'
    },
    {
        type: AmbientOptions.Floor,
        thumbnail: '/assets/img/ambientMenu/floor/mediterranean_floor_UI.jpg',
        label: 'Boden 3',
        fileName: 'floor/mediterranean_floor.jpg'
    },
    {
        type: AmbientOptions.Floor,
        thumbnail: '/assets/img/ambientMenu/floor/natural_floor_UI.jpg',
        label: 'Boden 4',
        fileName: 'floor/natural_floor.jpg'
    },
    {
        type: AmbientOptions.Shower,
        thumbnail: '/assets/img/ambientMenu/shower/basic_shower_UI.jpg',
        label: 'Duschfliesen 1',
        fileName: 'shower/basic_shower.jpg'
    },
    {
        type: AmbientOptions.Shower,
        thumbnail: '/assets/img/ambientMenu/shower/puristic_shower_UI.jpg',
        label: 'Duschfliesen 2',
        fileName: 'shower/puristic_shower.jpg'
    },
    {
        type: AmbientOptions.Shower,
        thumbnail: '/assets/img/ambientMenu/shower/mediterranean_shower_UI.jpg',
        label: 'Duschfliesen 3',
        fileName: 'shower/mediterranean_shower.jpg'
    },
    {
        type: AmbientOptions.Shower,
        thumbnail: '/assets/img/ambientMenu/shower/natural_shower_UI.jpg',
        label: 'Duschfliesen 4',
        fileName: 'shower/natural_shower.jpg'
    },
    {
        type: AmbientOptions.Wall,
        thumbnail: '/assets/img/ambientMenu/wall/basic_wall_UI.jpg',
        label: 'Wand 1',
        fileName: 'wall/basic_wall.jpg'
    },
    {
        type: AmbientOptions.Wall,
        thumbnail: '/assets/img/ambientMenu/wall/puristic_wall_UI.jpg',
        label: 'Wand 2',
        fileName: 'wall/puristic_wall.jpg'
    },
    {
        type: AmbientOptions.Wall,
        thumbnail: '/assets/img/ambientMenu/wall/mediterranean_wall_UI.jpg',
        label: 'Wand 3',
        fileName: 'wall/mediterranean_wall.jpg'
    },
    {
        type: AmbientOptions.Wall,
        thumbnail: '/assets/img/ambientMenu/wall/natural_wall_UI.jpg',
        label: 'Wand 4',
        fileName: 'wall/natural_wall.jpg'
    }
];

export const INITIAL_STATE = {
    tabs,
    items
};

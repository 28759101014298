import { Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';

const RoomCollisionBoxComponent = (): JSX.Element => {
    const cameraHeightLimit = 320;

    const roomSize = useSelector((state: StoreState) => state.architecture.roomInfo.size).divide(
        new Vector3(10, 10, 10)
    );
    const roomPosition = useSelector((state: StoreState) => state.architecture.roomInfo.position);

    const position = roomPosition.divide(new Vector3(20, 20, 20));

    return (
        <box
            name="ceilingCollisionBox"
            width={roomSize.x}
            height={10}
            checkCollisions
            depth={roomSize.z}
            position={position.add(new Vector3(0, cameraHeightLimit, 0))}
            rotation={new Vector3(0, 0, Math.PI)}
            visibility={0}
            key={`width=${roomSize.x}_depth=${roomSize.z}`}
        />
    );
};

export default RoomCollisionBoxComponent;

import { Tools, Vector3 } from '@babylonjs/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../../state/ducks';
import { calculateBathtubOriginMM, calculateBathtubSizeMM } from '../../../helper/bathtubHelper';
import { TAGLIST } from '../../../helper/tagHelper';
import { BathtubConfiguration } from '../../../types/Blueprint';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type BathtubComponentProps = {
    bathtubConfig: BathtubConfiguration;
    showerOrigin: Vector3;
};

const BathtubComponent = ({ bathtubConfig, showerOrigin }: BathtubComponentProps): JSX.Element => {
    const showerSizeMM = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const rootUrl = 'assets/3d/bathtub/';
    const pieceBaseSizeMM = 450;
    const drainOffsetFromLeftMM = 0;
    const pieceBaseSizeCM = pieceBaseSizeMM / 10;
    const sizeMM = calculateBathtubSizeMM(bathtubConfig, showerSizeMM, pieceBaseSizeMM, drainOffsetFromLeftMM);

    const originMM = calculateBathtubOriginMM(bathtubConfig, showerOrigin, showerSizeMM);
    const originCM = new Vector3(originMM.x / 10, originMM.y / 10, originMM.z / 10);

    // INFO The bathtub is build in a 3x3 grid of pieces
    // Rows
    const backSidePositionZ = -pieceBaseSizeCM / 2;
    const middlePositionZ = -sizeMM.z / 20;
    const frontSidePositionZ = -(sizeMM.z / 10 - pieceBaseSizeCM / 2);

    // Columns
    const leftSidePositionX = pieceBaseSizeCM / 2;
    const middlePositionX = sizeMM.x / 20;
    const rightSidePositionX = sizeMM.x / 10 - pieceBaseSizeCM / 2;

    const sidePieceScaleX = (sizeMM.x - 2 * pieceBaseSizeMM) / pieceBaseSizeMM;
    const desiredHeadPieceSizeMM = sizeMM.z - 2 * pieceBaseSizeMM;
    const minHeadPieceSizeMM = 20;
    const headPieceScaleZ = Math.max(desiredHeadPieceSizeMM, minHeadPieceSizeMM) / pieceBaseSizeMM;
    const floorScaling = new Vector3(sidePieceScaleX, 1, headPieceScaleZ);

    return (
        <transformNode name={'bathtub_tf_position'} position={originCM}>
            {/* BACK SIDE */}
            <transformNode
                name={'bathtub_tf_corner_back_left'}
                position={new Vector3(leftSidePositionX, 0, backSidePositionZ)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_corner_back_left`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'bathtub_tf_side_back'}
                position={new Vector3(middlePositionX, 0, backSidePositionZ)}
                scaling={new Vector3(sidePieceScaleX, 1, 1)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_side_back`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_side.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'bathtub_tf_corner_back_right'}
                scaling={new Vector3(-1, 1, 1)}
                position={new Vector3(rightSidePositionX, 0, backSidePositionZ)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_corner_back_right`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            {/* MIDDLE */}
            <transformNode
                name={'bathtub_tf_head_left'}
                position={new Vector3(leftSidePositionX, 0, middlePositionZ)}
                scaling={new Vector3(1, 1, headPieceScaleZ)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_head_left`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_head.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode name={'bathtub_tf_closure'} position={new Vector3(leftSidePositionX, 0, middlePositionZ)}>
                <ThreeDComponent
                    name={`bathtub_threeD_closure`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_closure.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'bathtub_tf_floor'}
                position={new Vector3(middlePositionX, 0, middlePositionZ)}
                scaling={floorScaling}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_floor`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_floor.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'bathtub_tf_drain'}
                position={
                    new Vector3(
                        leftSidePositionX + pieceBaseSizeCM / 2 + drainOffsetFromLeftMM / 10,
                        0,
                        middlePositionZ
                    )
                }
            >
                <ThreeDComponent
                    name={`bathtub_threeD_drain`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_drain.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'bathtub_tf_head_right'}
                position={new Vector3(rightSidePositionX, 0, middlePositionZ)}
                rotation={new Vector3(0, Tools.ToRadians(180), 0)}
                scaling={new Vector3(1, 1, headPieceScaleZ)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_head_right`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_head.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            {/* FRONT SIDE */}
            <transformNode
                name={'bathtub_tf_corner_front_left'}
                position={new Vector3(leftSidePositionX, 0, frontSidePositionZ)}
                scaling={new Vector3(1, 1, -1)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_corner_front_left`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'bathtub_tf_side_front'}
                position={new Vector3(middlePositionX, 0, frontSidePositionZ)}
                rotation={new Vector3(0, Tools.ToRadians(180), 0)}
                scaling={new Vector3(sidePieceScaleX, 1, 1)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_side_front`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_side.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
            <transformNode
                name={'bathtub_tf_corner_front_right'}
                rotation={new Vector3(0, Tools.ToRadians(180), 0)}
                position={new Vector3(rightSidePositionX, 0, frontSidePositionZ)}
            >
                <ThreeDComponent
                    name={`bathtub_threeD_corner_front_right`}
                    rootUrl={rootUrl}
                    sceneFilename={`bathtub_corner.glb`}
                    tagList={[TAGLIST.REFLECTION]}
                />
            </transformNode>
        </transformNode>
    );
};

export default BathtubComponent;

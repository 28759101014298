import LZUTF8 from 'lzutf8';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { convertToBlueprint } from '../../ng/helper/blueprintHelper';
import { Blueprint } from '../../ng/types/Blueprint';
import { StoreState } from '../../state/ducks';
import { architectureActions } from '../../state/ducks/architecture';
import { configuratorActions } from '../../state/ducks/configurator';
import { getFromParams } from '../../state/ducks/configurator/utils';
import { ConfigOptionEnum } from '../../state/ducks/system/interfaces';
import { AmbientOptions } from '../ambientMenu/AmbientMenuComponent.types';
import { decodeBase64UrlSave } from './Url.utils';

const Url = (): JSX.Element => {
    const history = useHistory();
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

    const config = useSelector((state: StoreState) => state.configurator.configuration);
    const roomConfig = useSelector((state: StoreState) => state.configurator.roomConfiguration);
    const catalogue = useSelector((state: StoreState) => state.configurator.catalogue);
    const search = useSelector((state: StoreState) => state.system.socialMedia?.search);

    const dispatch = useDispatch();
    const dispatchBlueprintLoaded = (blueprint: Blueprint) =>
        dispatch(architectureActions.blueprintLoadedSucess(blueprint));

    const loadSeries = (seriesId: string) =>
        dispatch(configuratorActions.selectConfigOption(ConfigOptionEnum.Series, seriesId, catalogue, false));
    const loadModel = (modelId: string) =>
        dispatch(configuratorActions.selectConfigOption(ConfigOptionEnum.Model, modelId, catalogue, false));
    const loadBase = (baseId: string) =>
        dispatch(configuratorActions.selectConfigOption(ConfigOptionEnum.Base, baseId, catalogue));
    const loadKnob = (knobId: string) =>
        dispatch(configuratorActions.selectConfigOption(ConfigOptionEnum.Knob, knobId, catalogue));
    const loadWallmount = (wallmountId: string) =>
        dispatch(configuratorActions.selectConfigOption(ConfigOptionEnum.Wallmount, wallmountId, catalogue));
    const loadBeam = (beamId: string) =>
        dispatch(configuratorActions.selectConfigOption(ConfigOptionEnum.Beam, beamId, catalogue));
    const selectAmbient = (fileName: string, optionType: AmbientOptions) =>
        dispatch(configuratorActions.selectAmbientOption(fileName, optionType));
    const updateShowerHeight = (height: number) => dispatch(architectureActions.updateShowerHeight(height));

    useEffect(() => {
        const id = params.get('id');
        if (id) {
            const searchBase64Decoded = decodeBase64UrlSave(id);
            const searchUint8Array = Uint8Array.from(searchBase64Decoded, (c) => c.charCodeAt(0));
            const searchDecompressed = LZUTF8.decompress(Buffer.from(searchUint8Array));

            const { m, b, k, w, be, s, bpl, rcf, rcw, rcs, sh } = getFromParams(
                new URLSearchParams(searchDecompressed)
            );

            if (bpl) {
                convertToBlueprint(JSON.parse(bpl)).subscribe((blueprint) => {
                    if (s && config.series.id !== s) {
                        loadSeries(s);
                    }

                    if (m && config.model.id !== m && blueprint) {
                        loadModel(m);
                    }

                    if (b && config.base.id !== b) {
                        loadBase(b);
                    }

                    if (k && config.knob && config.knob?.id !== k) {
                        loadKnob(k);
                    }

                    if (w && config.wallmount.id !== w) {
                        loadWallmount(w);
                    }

                    if (be && config.beam.id !== be) {
                        loadBeam(be);
                    }

                    if (rcf && roomConfig.floor !== rcf) {
                        selectAmbient(rcf, AmbientOptions.Floor);
                    }

                    if (rcw && roomConfig.wall !== rcw) {
                        selectAmbient(rcw, AmbientOptions.Wall);
                    }

                    if (rcs && roomConfig.shower !== rcs) {
                        selectAmbient(rcs, AmbientOptions.Shower);
                    }

                    if (sh) {
                        updateShowerHeight(parseInt(sh));
                    }

                    if (blueprint) {
                        dispatchBlueprintLoaded(blueprint);
                    }
                });
            }
        }
    }, []);

    useEffect(() => {
        params.set('id', search);
        const newParams = params.toString();
        if (search && newParams.length > 0) {
            history.replace(`?${params.toString()}`);
        }
    }, [search, history, params]);

    return <></>;
};

export default Url;

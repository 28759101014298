/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import menuHandleSvg from '../../assets/icons/menu/handle-dots.svg';
import { StoreState } from '../../state/ducks';
import { systemActions } from '../../state/ducks/system';
import ModalSuccessScreen from '../modal/modal-sucess-screen/ModalSuccessScreen.component';
import SendOffer from '../send-offer/SendOfferForm.component';
import MenuHeaderContent from './menu-header-content/MenuHeaderContent.component';
import './Menu.component.scss';
import MenuFooterComponent from './MenuFooterComponent/MenuFooterComponent';
import 'firebase/analytics';
import 'firebase/analytics';
import ProductOverviewComponent from './ProductOverviewComponent/ProductOverviewComponent';
import SubMenuList from './SubMenuListComponent/MenuOptionsList.component';
import ConfigOptionPage from '../config-option/config-option-page/ConfigOptionPage.component';
import { logAnalyticsEvent } from '../../ng/helper/analyticsHelper';
import ConfigOptionMeasurementPage from '../config-option/config-option-measurement-page/configOptionMeasurementPage.component';
import MountInfoPage from '../config-option/mount-info-page/MountInfoPage';

const MenuComponent = (): JSX.Element => {
    const hideFooter = useSelector((state: StoreState) => state.system.menu.productOverViewOpen);
    const menu = useSelector((state: StoreState) => state.system.menu);
    const dispatch = useDispatch();
    const toggleMenu = () => dispatch(systemActions.toggleMenu());
    const [showSendOfferModal, setShowSendOfferModal] = useState(false);
    const [showSuccessScreen, setShowSuccessScreen] = useState(false);
    const config = useSelector((state: StoreState) => state.configurator.configuration);
    const catalogue = useSelector((state: StoreState) => state.configurator.catalogue);

    const onToggleMenu = (): void => {
        logAnalyticsEvent('configurator_toggle_menu');
        toggleMenu();
    };

    const openSendOfferModal = (): void => {
        setShowSendOfferModal(true);
        logAnalyticsEvent('configurator_open_offer_form');
    };

    const closeSendOfferModal = (showSuccess = false): void => {
        setShowSuccessScreen(showSuccess);
        setShowSendOfferModal(false);
    };

    const sendOfferModelStyle = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            padding: '20px 40px',
            overflow: 'overlay',
            maxHeight: '734px',
            maxWidth: '572px',
            margin: 'auto'
        }
    };

    const successModalStyle = {
        ...sendOfferModelStyle,
        content: {
            ...sendOfferModelStyle.content,
            maxHeight: '490px',
            maxWidth: '658px',
            overflow: 'visible'
        }
    };

    return (
        <>
            <div className={`menu ${menu.menuOpen ? 'open' : 'close'}`}>
                <div className="menu-header menu-click" draggable="false" onClick={onToggleMenu}>
                    <MenuHeaderContent configOption={menu.openedMenu}></MenuHeaderContent>
                    <div id="menu-btn" className="menu-btn menu-draggable menu-click">
                        <img className="btn-image" src={menuHandleSvg} draggable="false" alt="Menu Handle" />
                    </div>
                </div>
                <div className="menu-content">
                    {!menu.openedMenu && <SubMenuList />}
                    {menu.openedMenu && !menu.measurementOpen && !menu.mountInfoOpen && (
                        <ConfigOptionPage
                            key={menu.openedMenu}
                            configOption={menu.openedMenu}
                            config={config}
                            catalogue={catalogue}
                        ></ConfigOptionPage>
                    )}
                    {menu.openedMenu && menu.measurementOpen && <ConfigOptionMeasurementPage />}
                    {menu.openedMenu && menu.mountInfoOpen && <MountInfoPage />}

                    <ProductOverviewComponent openSendOfferModal={openSendOfferModal} />
                    <MenuFooterComponent hidden={hideFooter} openSendOfferModal={openSendOfferModal} />
                    <Modal isOpen={showSendOfferModal} contentLabel="Angebot Anfordern" style={sendOfferModelStyle}>
                        <SendOffer onClose={closeSendOfferModal} />
                    </Modal>
                    {showSuccessScreen && (
                        <Modal
                            isOpen={showSuccessScreen}
                            contentLabel="Angebot erfolgreich abgegeben"
                            style={successModalStyle}
                            onRequestClose={() => {
                                closeSendOfferModal();
                            }}
                            shouldCloseOnOverlayClick={true}
                        >
                            <ModalSuccessScreen onClose={closeSendOfferModal} />
                        </Modal>
                    )}
                </div>
            </div>
        </>
    );
};

export default MenuComponent;

import { Vector3 } from '@babylonjs/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElementShowerSideSizes, ElementSize } from '../../state/ducks/architecture/reducers';
import { applyRotationY, applyTranslation, getRotationYFromDirection } from '../3d_helpers';
import {
    BathtubConfiguration,
    Blueprint,
    BlueprintBaseSizeConstraints,
    BlueprintDecoratorType,
    BlueprintElement,
    BlueprintLayoutType,
    BlueprintLight,
    BlueprintTransition
} from '../types/Blueprint';
import { PositionType } from '../types/Common';
import {
    bathtubDefaultDepthMM,
    bathtubDefaultHeightMM,
    bathtubDefaultLengthMM,
    bathtubElementOverlapMM
} from './bathtubHelper';
import { Axis, calculateAxisForRotation } from './commonHelper';
import { fetchBlueprintRx } from './fetchHelper';
import { getNextElementIndex, getPreviousElementIndex } from './layoutHelper';
import { showerBaseHeightMM } from './showerBaseHelper';

/**
 * This function returns a Vector3 containing the length of each shower side in cm.
 * @param layout The array of blue print elements to get the base size from
 */
export const calculateShowerBaseSize = (layout: BlueprintElement[], showerHeight: number): Vector3 => {
    let currentPositionCM = Vector3.Zero();
    let currentDirection = Vector3.Right();

    const coordinatesCM = layout?.map((element: BlueprintElement) => {
        switch (element.type) {
            case BlueprintLayoutType.Transition:
                currentDirection = applyRotationY(currentDirection, element.angle);
                break;
            case BlueprintLayoutType.Void:
            case BlueprintLayoutType.Door:
            case BlueprintLayoutType.Partition:
                currentPositionCM = applyTranslation(currentPositionCM, currentDirection, element.length / 10);
                break;
            default:
                break;
        }
        return currentPositionCM;
    });

    const maxXCM = Math.max(...coordinatesCM.map((c) => c.x));
    const minXCM = Math.min(...coordinatesCM.map((c) => c.x));
    const maxZCM = Math.max(...coordinatesCM.map((c) => c.z));
    const minZCM = Math.min(...coordinatesCM.map((c) => c.z));
    const sizeXCM = maxXCM - minXCM;
    const sizeZCM = maxZCM - minZCM;

    return new Vector3(Math.round(sizeXCM * 10), showerHeight, Math.round(sizeZCM * 10));
};

export const calculateDefaultElementSizes = (layout: BlueprintElement[]): ElementShowerSideSizes => {
    let currentDirection = Vector3.Right();

    const widthElements: ElementSize[] = [];
    const depthElements: ElementSize[] = [];

    layout.forEach((element, index) => {
        switch (element.type) {
            case BlueprintLayoutType.Transition:
                currentDirection = applyRotationY(currentDirection, element.angle);
                break;
            case BlueprintLayoutType.Void:
            case BlueprintLayoutType.Door:
            case BlueprintLayoutType.Partition:
                const currentRotation = getRotationYFromDirection(currentDirection);
                if (calculateAxisForRotation(currentRotation) === Axis.x) {
                    widthElements.push({ index, width: element.length });
                } else {
                    depthElements.push({ index, width: element.length });
                }
                break;
            default:
                break;
        }
    });

    return {
        width: widthElements,
        depth: depthElements
    };
};

export const calculateMinShowerBaseSize = (
    seriesConstraints?: BlueprintBaseSizeConstraints,
    modelConstraints?: BlueprintBaseSizeConstraints,
    beamConstraints?: { min: number; max: number },
    beamAxis?: Axis
): Vector3 => {
    const minShowerBaseSizeConstraints = new Vector3(600, 600, 600);

    if (seriesConstraints) {
        minShowerBaseSizeConstraints.x = seriesConstraints.min.x;
        minShowerBaseSizeConstraints.y = seriesConstraints.min.y;
        minShowerBaseSizeConstraints.z = seriesConstraints.min.z;
    }

    if (modelConstraints) {
        minShowerBaseSizeConstraints.x = modelConstraints.min.x;
        minShowerBaseSizeConstraints.y = modelConstraints.min.y;
        minShowerBaseSizeConstraints.z = modelConstraints.min.z;
    }

    if (beamConstraints && beamAxis) {
        if (beamAxis === Axis.x) {
            minShowerBaseSizeConstraints.x = Math.max(minShowerBaseSizeConstraints.x, beamConstraints.min);
        } else {
            minShowerBaseSizeConstraints.z = Math.max(minShowerBaseSizeConstraints.z, beamConstraints.min);
        }
    }

    return minShowerBaseSizeConstraints;
};

export const calculateMaxShowerBaseSize = (
    seriesConstraints?: BlueprintBaseSizeConstraints,
    modelConstraints?: BlueprintBaseSizeConstraints,
    beamConstraints?: { min: number; max: number },
    beamAxis?: Axis,
    accMaxLenghtForOnlyDoorAxisConstraints?: { [Axis.x]: number; [Axis.z]: number }
): Vector3 => {
    const maxShowerBaseSizeConstraints = new Vector3(3000, 2100, 3000);

    if (seriesConstraints) {
        maxShowerBaseSizeConstraints.x = seriesConstraints.max.x;
        maxShowerBaseSizeConstraints.y = seriesConstraints.max.y;
        maxShowerBaseSizeConstraints.z = seriesConstraints.max.z;
    }

    if (modelConstraints) {
        maxShowerBaseSizeConstraints.x = modelConstraints.max.x;
        maxShowerBaseSizeConstraints.y = modelConstraints.max.y;
        maxShowerBaseSizeConstraints.z = modelConstraints.max.z;
    }

    if (accMaxLenghtForOnlyDoorAxisConstraints) {
        maxShowerBaseSizeConstraints.x = Math.min(
            maxShowerBaseSizeConstraints.x,
            accMaxLenghtForOnlyDoorAxisConstraints.x
        );
        maxShowerBaseSizeConstraints.z = Math.min(
            maxShowerBaseSizeConstraints.z,
            accMaxLenghtForOnlyDoorAxisConstraints.z
        );
    }

    if (beamConstraints && beamAxis) {
        if (beamAxis === Axis.x) {
            maxShowerBaseSizeConstraints.x = Math.min(maxShowerBaseSizeConstraints.x, beamConstraints.max);
        } else {
            maxShowerBaseSizeConstraints.z = Math.min(maxShowerBaseSizeConstraints.z, beamConstraints.max);
        }
    }

    return maxShowerBaseSizeConstraints;
};

export const calculateRoomSizeAdjustment = (bathtubConfig?: BathtubConfiguration): Vector3 => {
    const roomSizeAdjustment = Vector3.Zero();

    if (bathtubConfig && !bathtubConfig.isUnderShower) {
        roomSizeAdjustment.x = bathtubDefaultLengthMM;
    }

    return roomSizeAdjustment;
};

/**
 * This function returns the type of the element that is previous to the transition element at the given index.
 * If the type of the previous element is Transition it is considered a Wall.
 *
 * If the function is called with a currentTransitionIndex that does not point to a Transition element the result
 * is always BlueprintLayoutType.Transition for incorrect usage.
 * @param currentTransitionIndex The index of the current element
 * @param layout The blueprint layout for the shower
 */
export const getPreviousElementType = (
    currentTransitionIndex: number,
    layout: BlueprintElement[]
): BlueprintLayoutType => {
    if (layout[currentTransitionIndex].type !== BlueprintLayoutType.Transition) {
        console.warn('Error: getPreviousElementType can only be used for transitions');
        return BlueprintLayoutType.Transition;
    }

    const previousElementIndex = getPreviousElementIndex(currentTransitionIndex, layout.length);
    const previousElementType = layout[previousElementIndex].type;

    if (previousElementType === BlueprintLayoutType.Transition) {
        return BlueprintLayoutType.Wall;
    } else {
        return previousElementType;
    }
};

/**
 * This function returns the type of the element that is next to the transition element at the given index.
 * If the type of the previous element is Transition it is considered a Wall.
 *
 * If the function is called with a currentTransitionIndex that does not point to a Transition element the result
 * is always BlueprintLayoutType.Transition for incorrect usage.
 * @param currentTransitionIndex The index of the current element
 * @param layout The blueprint layout for the shower
 */
export const getNextElementType = (currentTransitionIndex: number, layout: BlueprintElement[]): BlueprintLayoutType => {
    if (layout[currentTransitionIndex].type !== BlueprintLayoutType.Transition) {
        console.warn('Error: getNextElementType can only be used for transitions');
        return BlueprintLayoutType.Transition;
    }

    const nextElementIndex = getNextElementIndex(currentTransitionIndex, layout.length);
    const nextElementType = layout[nextElementIndex].type;

    if (nextElementType === BlueprintLayoutType.Transition) {
        return BlueprintLayoutType.Wall;
    } else {
        return nextElementType;
    }
};

export const getDirectionForCurrentIndexInLayout = (layout: BlueprintElement[], currentIndex: number): Vector3 => {
    let currentDirection = Vector3.Right();

    for (let i = 0; i <= currentIndex; i++) {
        const currentElement = layout[i];
        if (currentElement.type === BlueprintLayoutType.Transition) {
            currentDirection = applyRotationY(currentDirection, currentElement.angle);
        }
    }

    return currentDirection;
};

export const calcTransitionBottomOffsetMM = (
    layout: BlueprintElement[],
    currentIndex: number,
    showerDepthMM: number,
    hasShowerBase: boolean,
    bathtubConfig?: BathtubConfiguration
): number => {
    if (bathtubConfig) {
        if (bathtubConfig.isUnderShower) {
            return bathtubDefaultHeightMM;
        }
        const elementDirection = getDirectionForCurrentIndexInLayout(layout, currentIndex);
        const elementRotation = getRotationYFromDirection(elementDirection);

        if (
            calculateAxisForRotation(elementRotation) === Axis.z &&
            elementRotation.y > 0 &&
            showerDepthMM <= bathtubDefaultDepthMM
        ) {
            return hasShowerBase ? Math.max(bathtubDefaultHeightMM, showerBaseHeightMM) : bathtubDefaultHeightMM;
        }
    }

    return hasShowerBase ? showerBaseHeightMM : 0;
};

export const calcElementBottomOffsetMM = (
    layout: BlueprintElement[],
    currentIndex: number,
    showerDepthMM: number,
    seriesId: string,
    hasShowerBase: boolean,
    bathtubConfig?: BathtubConfiguration
): number => {
    if (bathtubConfig) {
        const overlapMM = seriesId === 'TA' ? 0 : bathtubElementOverlapMM;
        const bottomOffsetForBathtubMM = bathtubConfig.isUnderShower
            ? bathtubDefaultHeightMM
            : bathtubDefaultHeightMM - overlapMM;

        if (bathtubConfig.isUnderShower) {
            return bottomOffsetForBathtubMM;
        } else if (layout[currentIndex].type === BlueprintLayoutType.Door) {
            return hasShowerBase ? showerBaseHeightMM : 0;
        } else if (layout[currentIndex].type === BlueprintLayoutType.Partition) {
            const elementDirection = getDirectionForCurrentIndexInLayout(layout, currentIndex);
            const elementRotation = getRotationYFromDirection(elementDirection);

            if (
                calculateAxisForRotation(elementRotation) === Axis.z &&
                elementRotation.y > 0 &&
                showerDepthMM <= bathtubDefaultDepthMM
            ) {
                return hasShowerBase
                    ? Math.max(bottomOffsetForBathtubMM, showerBaseHeightMM)
                    : bottomOffsetForBathtubMM;
            }
        }
    }

    return hasShowerBase ? showerBaseHeightMM : 0;
};

export const getHingePositionType = (layout: BlueprintElement[], index: number): PositionType => {
    if (
        (layout[getPreviousElementIndex(index, layout.length)] as BlueprintTransition).decorators?.some(
            (x) => x.type === BlueprintDecoratorType.Hinge
        )
    ) {
        return PositionType.Left;
    } else if (
        (layout[getNextElementIndex(index, layout.length)] as BlueprintTransition).decorators?.some(
            (x) => x.type === BlueprintDecoratorType.Hinge
        )
    ) {
        return PositionType.Right;
    } else {
        return PositionType.None;
    }
};

export const convertToBlueprint = (blueprintLight: BlueprintLight): Observable<Blueprint> => {
    return fetchBlueprintRx(blueprintLight.id).pipe(
        map((bp) => {
            const blueprint = bp;
            blueprint.layout = bp.layout.map((el, i) =>
                blueprintLight.layout[i] ? { ...el, ...blueprintLight.layout[i] } : { ...el }
            );
            return blueprint;
        })
    );
};

export const convertToBlueprintLight = (blueprint: Blueprint): BlueprintLight => {
    const blueprintLight = {
        id: `${blueprint.id}`,
        layout: blueprint.layout.map((el) =>
            el.type !== BlueprintLayoutType.Transition ? { length: el.length, ratio: el.ratio } : null
        )
    };

    return blueprintLight;
};

export const calculateZLugPosition = (
    layout: BlueprintElement[],
    currentIndex: number,
    seriesId: string,
    showerDepthMM: number,
    bathtubConfig?: BathtubConfiguration
): PositionType => {
    if (
        bathtubConfig &&
        seriesId !== 'TA' &&
        !bathtubConfig.isUnderShower &&
        layout[currentIndex].type === BlueprintLayoutType.Partition
    ) {
        const elementDirection = getDirectionForCurrentIndexInLayout(layout, currentIndex);
        const elementRotation = getRotationYFromDirection(elementDirection);

        if (
            calculateAxisForRotation(elementRotation) === Axis.z &&
            elementRotation.y > 0 &&
            showerDepthMM <= bathtubDefaultDepthMM
        ) {
            const previousTransitionIndex = getPreviousElementIndex(currentIndex, layout.length);
            const previousElementType = getPreviousElementType(previousTransitionIndex, layout);

            if (previousElementType === BlueprintLayoutType.Wall) {
                return PositionType.Right;
            }

            const nextTransitionIndex = getNextElementIndex(currentIndex, layout.length);
            const nextElementType = getNextElementType(nextTransitionIndex, layout);

            if (nextElementType === BlueprintLayoutType.Wall) {
                return PositionType.Left;
            }
        }
    }

    return PositionType.None;
};

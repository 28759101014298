import { applyMiddleware, createStore, compose, combineReducers } from 'redux';
import { combineEpics, createEpicMiddleware } from 'redux-observable';
import { architectureEpics } from './ducks/architecture';
import { configuratorEpics } from './ducks/configurator';
import { systemEpics } from './ducks/system';
import * as reducers from './ducks';

const rootReducer = combineReducers(reducers);
const rootEpic = combineEpics(architectureEpics, configuratorEpics, systemEpics);
const epicMiddleware = createEpicMiddleware();
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(epicMiddleware)));

epicMiddleware.run(rootEpic);

export default store;

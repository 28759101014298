import { combineReducers } from 'redux';
import { createReducer } from '../../utils';
import { RESET_TO_DEFAULT_CONFIG } from '../configurator/types';
import {
    ConfigOptionEnum,
    LoggedInUserAction,
    OpenMenuWithAction,
    SetDisplayRatioOptions,
    SetMarkersHighlightedAction,
    SetTutorialActiveAction,
    SetMenuRestoreState,
    SetUserConfigAction,
    UpdateSocialMediaSuccessAction,
    ZoomAtAction
} from './interfaces';
import {
    CLOSE_AMBIENT_MENU,
    CLOSE_MENU,
    CLOSE_MENU_WITH,
    CLOSE_MODAL_PRIVACY,
    CLOSE_MODAL_RESET,
    CLOSE_MODAL_SHARE,
    CLOSE_PRODUCT_OVERVIEW,
    HIDE_MARKER,
    OPEN_AMBIENT_MENU,
    OPEN_MENU,
    OPEN_MENU_WITH,
    OPEN_MODAL_PRIVACY,
    OPEN_MODAL_RESET,
    OPEN_MODAL_SHARE,
    SET_MENU_RESTORE_STATE,
    SET_DISPLAY_RATIO_OPTIONS,
    SET_LOGGED_IN_USER,
    SET_MARKERS_HIGHLIGHTED,
    SET_TUTORIAL_ACTIVE,
    SET_USER_CONFIG,
    SET_ZOOM_AT,
    SHOW_MARKER,
    TOGGLE_AMBIENT_MENU,
    TOGGLE_MARKER_VISIBILITY,
    TOGGLE_MENU,
    TOGGLE_MODAL_PRIVACY,
    TOGGLE_PRODUCT_OVERVIEW,
    UPDATE_SOCIAL_MEDIA_SUCCESS,
    OPEN_PRODUCT_OVERVIEW
} from './types';

export interface MenuState {
    menuOpen: boolean;
    productOverViewOpen: boolean;
    openedMenu: ConfigOptionEnum | undefined;
    seriesOpen: boolean;
    modelOpen: boolean;
    baseOpen: boolean;
    doorOpen: boolean;
    glassOpen: boolean;
    wallmountOpen: boolean;
    knobOpen: boolean;
    beamOpen: boolean;
    materialOpen: boolean;
    measurementOpen: boolean;
    displayRatioOptions: boolean;
    mountInfoOpen: boolean;
}

export interface SocialMedia {
    title: string;
    description: string;
    image: string;
    url: string;
    crawlerUrl: string;
    search: string;
}
export type AmbientMenu = {
    menuOpen: boolean;
};

export type MenuRestoreState = {
    menuOpen: boolean;
    productOverViewOpen: boolean;
    openedMenu: ConfigOptionEnum | undefined;
};

export type UserConfig = {
    logoPath: string;
    logoUrl: string;
    primaryColor: string;
    primaryHoverColor: string;
    showOfferButton: boolean;
    showSideButtons: boolean;
    measurementMenuEnabled: boolean;
    accessoriesMenuEnabled: boolean;
};

export type SystemState = {
    loggedInUser?: string;
    menu: MenuState;
    menuRestoreState: MenuRestoreState | undefined;
    modalResetOpen: boolean;
    modalShareOpen: boolean;
    modalPrivacyOpen: boolean;
    socialMedia: SocialMedia;
    zoomAt: string | null;
    ambientMenu: AmbientMenu;
    markerVisibility: boolean;
    userConfig?: UserConfig;
    markersHighlighted: boolean;
    tutorialActive: boolean;
};

const initialMenuState: MenuState = {
    menuOpen: true,
    productOverViewOpen: false,
    openedMenu: undefined,
    seriesOpen: false,
    modelOpen: false,
    baseOpen: false,
    doorOpen: false,
    glassOpen: false,
    wallmountOpen: false,
    knobOpen: false,
    beamOpen: false,
    materialOpen: false,
    measurementOpen: false,
    displayRatioOptions: false,
    mountInfoOpen: false
};

const menu = createReducer<MenuState>(initialMenuState)({
    [TOGGLE_MENU]: (state: MenuState) => {
        return { ...state, menuOpen: !state.menuOpen };
    },
    [OPEN_MENU]: (state: MenuState) => ({ ...state, menuOpen: true }),
    [OPEN_MENU_WITH]: (state: MenuState, action: OpenMenuWithAction) => {
        switch (action.payload.optionPicker) {
            case ConfigOptionEnum.Knob:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    knobOpen: true,
                    openedMenu: ConfigOptionEnum.Knob
                };
            case ConfigOptionEnum.Wallmount:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    wallmountOpen: true,
                    openedMenu: ConfigOptionEnum.Wallmount
                };
            case ConfigOptionEnum.Glassmount:
                return { ...initialMenuState };
            // TODO When we can select different series this should be the option to go
            // return { ...initalMenuState, menuOpen: true, seriesOpen: true, openedMenu: ConfigOptionEnum.Door };
            case ConfigOptionEnum.Glass:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    glassOpen: true,
                    openedMenu: ConfigOptionEnum.Glass
                };
            case ConfigOptionEnum.Base:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    baseOpen: true,
                    openedMenu: ConfigOptionEnum.Base
                };
            case ConfigOptionEnum.Model:
                return {
                    ...initialMenuState,

                    menuOpen: true,
                    modelOpen: true,
                    openedMenu: ConfigOptionEnum.Model
                };
            case ConfigOptionEnum.Door:
                return {
                    ...initialMenuState,

                    menuOpen: true,
                    doorOpen: true,
                    openedMenu: ConfigOptionEnum.Door
                };
            case ConfigOptionEnum.Series:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    seriesOpen: true,
                    openedMenu: ConfigOptionEnum.Series
                };
            case ConfigOptionEnum.Beam:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    beamOpen: true,
                    openedMenu: ConfigOptionEnum.Beam
                };
            case ConfigOptionEnum.Measurement:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    measurementOpen: true,
                    openedMenu: ConfigOptionEnum.Measurement
                };
            case ConfigOptionEnum.Material:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    materialOpen: true,
                    openedMenu: ConfigOptionEnum.Material
                };
            case ConfigOptionEnum.MountInfo:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    mountInfoOpen: true,
                    openedMenu: ConfigOptionEnum.MountInfo
                };
            default:
                return {
                    ...initialMenuState,
                    menuOpen: true,
                    openedMenu: undefined
                };
        }
    },
    [CLOSE_MENU_WITH]: (state: MenuState, action: OpenMenuWithAction) => {
        switch (action.payload.optionPicker) {
            case ConfigOptionEnum.Knob:
                return { ...initialMenuState, menuOpen: true, knobOpen: false };
            case ConfigOptionEnum.Wallmount:
                return { ...initialMenuState, menuOpen: true, wallmountOpen: false };
            case ConfigOptionEnum.Glass:
                return { ...initialMenuState, menuOpen: true, glassOpen: false };
            case ConfigOptionEnum.Base:
                return { ...initialMenuState, menuOpen: true, baseOpen: false };
            case ConfigOptionEnum.Model:
                return { ...initialMenuState, menuOpen: true, modelOpen: false };
            case ConfigOptionEnum.Door:
                return { ...initialMenuState, menuOpen: true, doorOpen: false };
            case ConfigOptionEnum.Series:
                return { ...initialMenuState, menuOpen: true, seriesOpen: false };
            case ConfigOptionEnum.Beam:
                return { ...initialMenuState, menuOpen: true, beamOpen: false };
            case ConfigOptionEnum.Measurement:
                return { ...initialMenuState, menuOpen: true, measurementOpen: false };
            case ConfigOptionEnum.Material:
                return { ...initialMenuState, menuOpen: true, materialOpen: false };
            default:
                return { ...initialMenuState, menuOpen: false };
        }
    },
    [CLOSE_MENU]: () => ({ menuOpen: false }),
    [RESET_TO_DEFAULT_CONFIG]: () => initialMenuState,
    [SET_DISPLAY_RATIO_OPTIONS]: (state: MenuState, action: SetDisplayRatioOptions) => {
        return { ...state, displayRatioOptions: action.payload.displayRatioOptions };
    },
    [OPEN_PRODUCT_OVERVIEW]: (state: MenuState) => ({ ...state, productOverViewOpen: true }),
    [TOGGLE_PRODUCT_OVERVIEW]: (state: MenuState) => ({ ...state, productOverViewOpen: !state.productOverViewOpen }),
    [CLOSE_PRODUCT_OVERVIEW]: (state: MenuState) => ({ ...state, productOverViewOpen: false })
});

const ambientMenu = createReducer<AmbientMenu>({ menuOpen: false })({
    [OPEN_AMBIENT_MENU]: (state: AmbientMenu) => ({ ...state, menuOpen: true }),
    [CLOSE_AMBIENT_MENU]: (state: AmbientMenu) => ({ ...state, menuOpen: false }),
    [TOGGLE_AMBIENT_MENU]: (state: AmbientMenu) => ({ ...state, menuOpen: !state.menuOpen })
});

const socialMedia = createReducer<SocialMedia | null>(null)({
    [UPDATE_SOCIAL_MEDIA_SUCCESS]: (_: SocialMedia, action: UpdateSocialMediaSuccessAction) =>
        action.payload.socialMedia
});

const modalResetOpen = createReducer<boolean>(false)({
    [OPEN_MODAL_RESET]: () => true,
    [CLOSE_MODAL_RESET]: () => false
});

const modalShareOpen = createReducer<boolean>(false)({
    [OPEN_MODAL_SHARE]: () => true,
    [CLOSE_MODAL_SHARE]: () => false
});

const modalPrivacyOpen = createReducer<boolean>(true)({
    [OPEN_MODAL_PRIVACY]: () => true,
    [CLOSE_MODAL_PRIVACY]: () => false,
    [TOGGLE_MODAL_PRIVACY]: (state: boolean) => !state
});

const zoomAt = createReducer<string | null>(null)({
    [SET_ZOOM_AT]: (_: string, action: ZoomAtAction) => action.payload.zoomAt
});

const markerVisibility = createReducer<boolean>(true)({
    [SHOW_MARKER]: () => true,
    [HIDE_MARKER]: () => false,
    [TOGGLE_MARKER_VISIBILITY]: (state: boolean) => !state
});

const loggedInUser = createReducer<string | null>(null)({
    [SET_LOGGED_IN_USER]: (_: string | null, action: LoggedInUserAction) => action.payload.loggedInUser
});

const userConfig = createReducer<UserConfig | null>(null)({
    [SET_USER_CONFIG]: (_: UserConfig | null, action: SetUserConfigAction) => action.payload.userConfig
});

const markersHighlighted = createReducer<boolean>(false)({
    [SET_MARKERS_HIGHLIGHTED]: (state: boolean, action: SetMarkersHighlightedAction) =>
        action.payload.markersHighlighted
});

const tutorialActive = createReducer<boolean>(false)({
    [SET_TUTORIAL_ACTIVE]: (state: boolean, action: SetTutorialActiveAction) => action.payload.tutorialActive
});

const menuRestoreState = createReducer<MenuRestoreState | null>(null)({
    [SET_MENU_RESTORE_STATE]: (state: MenuRestoreState, action: SetMenuRestoreState) => action.payload.menuRestoreState
});

const reducer = combineReducers({
    menu,
    ambientMenu,
    modalResetOpen,
    modalShareOpen,
    modalPrivacyOpen,
    socialMedia,
    zoomAt,
    markerVisibility,
    loggedInUser,
    userConfig,
    markersHighlighted,
    tutorialActive,
    menuRestoreState
});

export default reducer;

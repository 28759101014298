import React, { useCallback, useState } from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';
import { useRef } from 'react';
import './RetailLogin.component.scss';
import sprinzLogoSvg from '../../assets/icons/menu/sprinz-logo.svg';

const RetailLoginComponent = (): JSX.Element => {
    const inputEmailRef = useRef<HTMLInputElement>(null);
    const inputPasswordRef = useRef<HTMLInputElement>(null);
    const buttonRef = useRef<HTMLButtonElement>(null);
    const [error, setErrorState] = useState(undefined);
    const loginCallback = useCallback(() => {
        setErrorState(undefined);
        if (inputEmailRef.current && inputPasswordRef.current) {
            firebase
                .auth()
                .signInWithEmailAndPassword(inputEmailRef.current?.value, inputPasswordRef.current?.value)
                .then(() => {
                    console.log(`Successfully logged in: ${inputEmailRef.current?.value}`);
                })
                .catch((error) => {
                    setErrorState(error.message);
                });
        }
    }, [inputEmailRef, inputPasswordRef]);

    return (
        <form
            onSubmit={(event) => {
                loginCallback();
                event.preventDefault();
            }}
            className="retail-login"
        >
            <img src={sprinzLogoSvg}></img>
            <h1>Händler Login</h1>
            <input ref={inputEmailRef} placeholder="e-mail"></input>
            <input ref={inputPasswordRef} type="password" placeholder="passwort"></input>
            {error && <div className="login-error-hint">Login-Error: {error}</div>}
            <button ref={buttonRef} type="submit">
                Login
            </button>
        </form>
    );
};

export default RetailLoginComponent;

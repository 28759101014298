import React from 'react';
import ShowerSizeMenuComponent from '../../../ng/components/ShowerSizeMenu/ShowerSizeMenuComponent';
const ConfigOptionMeasurementPage = (): JSX.Element => {
    return (
        <>
            <div className="config-option-measurement-header">
                Geben Sie die Tiefe und Breite des Duschbodens sowie die maximale Höhe der Duschwände ein.
            </div>
            <div className="config-option-measurement-content">
                <ShowerSizeMenuComponent />
            </div>
        </>
    );
};

export default ConfigOptionMeasurementPage;

import { Vector3 } from '@babylonjs/core';
import React from 'react';
import TransitionComponent from '../components/3d/Accessories/TransitionComponent';
import DoorComponent from '../components/3d/BlueprintBaseComponents/DoorComponent';
import PartitionComponent from '../components/3d/BlueprintBaseComponents/PartitionComponent';
import {
    BlueprintDoor,
    BlueprintElement,
    BlueprintLayoutType,
    BlueprintPartition,
    BlueprintTransition
} from '../types/Blueprint';
import { PositionType } from '../types/Common';
import { getNextElementType, getPreviousElementType } from './blueprintHelper';
import { getNextElementIndex, getPreviousElementIndex } from './layoutHelper';

export const createTransition = (
    element: BlueprintTransition,
    index: number,
    layout: BlueprintElement[],
    position: Vector3,
    previousWallDirection: Vector3,
    bottomOffsetMM: number
): JSX.Element => {
    const previousElementIndex = getPreviousElementIndex(index, layout.length);
    const previousElement = layout[previousElementIndex];
    const previousTransitionIndex =
        previousElement.type === BlueprintLayoutType.Transition
            ? previousElementIndex
            : getPreviousElementIndex(previousElementIndex, layout.length);
    const previousElementLengthMM =
        previousElement.type === BlueprintLayoutType.Transition ? 0 : previousElement.length;
    let beforePreviousTransitionIndex = getPreviousElementIndex(previousTransitionIndex, layout.length);
    if (layout[beforePreviousTransitionIndex].type !== BlueprintLayoutType.Transition) {
        beforePreviousTransitionIndex = getPreviousElementIndex(beforePreviousTransitionIndex, layout.length);
    }

    const nextElementIndex = getNextElementIndex(index, layout.length);
    const nextElement = layout[nextElementIndex];
    const nextTransitionIndex =
        nextElement.type === BlueprintLayoutType.Transition
            ? nextElementIndex
            : getNextElementIndex(nextElementIndex, layout.length);
    const nextElementLengthMM = nextElement.type === BlueprintLayoutType.Transition ? 0 : nextElement.length;
    let afterNextTransitionIndex = getNextElementIndex(nextTransitionIndex, layout.length);
    if (layout[afterNextTransitionIndex].type !== BlueprintLayoutType.Transition) {
        afterNextTransitionIndex = getNextElementIndex(afterNextTransitionIndex, layout.length);
    }

    return (
        <TransitionComponent
            element={element}
            beforePreviousTransition={layout[beforePreviousTransitionIndex] as BlueprintTransition}
            beforePreviousElementType={getPreviousElementType(previousTransitionIndex, layout)}
            previousElementType={getPreviousElementType(index, layout)}
            previousTransition={layout[previousTransitionIndex] as BlueprintTransition}
            previousElementLengthMM={previousElementLengthMM}
            nextTransititon={layout[nextTransitionIndex] as BlueprintTransition}
            afterNextTransition={layout[afterNextTransitionIndex] as BlueprintTransition}
            nextElementType={getNextElementType(index, layout)}
            afterNextElementType={getNextElementType(nextTransitionIndex, layout)}
            nextElementLengthMM={nextElementLengthMM}
            positionCM={position}
            previousWallDirection={previousWallDirection}
            bottomOffsetMM={bottomOffsetMM}
            key={`transition_${index}`}
        />
    );
};

export const createPartition = (
    element: BlueprintPartition,
    position: Vector3,
    rotation: Vector3,
    index: number,
    hingePosition: PositionType,
    bottomOffsetMM: number,
    zLugPosititon: PositionType,
    heightAdjustment: number
): JSX.Element => {
    return (
        <PartitionComponent
            widthMM={element.length}
            depthMM={8}
            position={position}
            rotation={rotation}
            decorators={element.decorators}
            sliderLabel={element.sliderLabel}
            hingePosition={hingePosition}
            bottomOffsetMM={bottomOffsetMM}
            zLugPosition={zLugPosititon}
            heightAdjustment={heightAdjustment}
            key={`partition_${index}`}
            index={index}
        />
    );
};

export const createDoor = (
    element: BlueprintDoor,
    position: Vector3,
    rotation: Vector3,
    index: number,
    hingePosition: PositionType,
    bottomOffsetMM: number
): JSX.Element => {
    const positionOffset = element.offset
        ? new Vector3(element.offset.x / 10, element.offset.y / 10, element.offset.z / 10)
        : Vector3.Zero();
    return (
        <DoorComponent
            widthMM={element.length}
            depthMM={8}
            position={position}
            rotation={rotation}
            hingePosition={hingePosition}
            sliderLabel={element.sliderLabel}
            positionOffset={positionOffset}
            decorators={element.decorators}
            bottomOffsetMM={bottomOffsetMM}
            key={`door_${index}`}
            index={index}
        />
    );
};

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import menuCaretSvg from '../../../assets/icons/menu/caret.svg';
import { getDiscountPrice, hasSpecialOffer, useDiscount } from '../../../ng/helper/discountHelper';
import { StoreState } from '../../../state/ducks';
import { calculateNumberOfDoors } from '../../../state/ducks/configurator/utils';
import { systemActions } from '../../../state/ducks/system';
import InfoDialogComponent from '../../common/info-dialog/InfoDialogComponent';
import ProductItem from '../../product-item/ProductItem.component';
import './ProductOverviewComponent.scss';
import ProductOverViewDiscountComponent from './ProductOverViewDiscountComponent/ProductOverViewDiscountInfoComponent';

type ProductOverviewComponentProps = {
    openSendOfferModal: () => void;
};

const ProductOverviewComponent = ({ openSendOfferModal }: ProductOverviewComponentProps): JSX.Element => {
    const config = useSelector((state: StoreState) => state.configurator.configuration);
    const showerSize = useSelector((state: StoreState) => state.architecture.showerBaseSize);
    const showerSizePrice = useSelector((state: StoreState) => state.configurator.price.showerBaseSize);
    const menuIsOpened = useSelector((state: StoreState) => state.system.menu.menuOpen);

    const totalPrice = useSelector((state: StoreState) => state.configurator.price.total);
    const numberOfDoors = calculateNumberOfDoors(
        useSelector((state: StoreState) => state.architecture.blueprint?.layout || [])
    );

    const dispatch = useDispatch();

    const showProductOverview = useSelector((state: StoreState) => state.system.menu.productOverViewOpen);

    const onProductOverviewClicked = (): void => {
        dispatch(systemActions.toggleProductOverview());
    };

    useEffect(() => {
        dispatch(systemActions.closeProductOverview());
    }, [menuIsOpened]);

    const discountData = useDiscount();
    let specialOfferActive = false;
    let discountPrice = 0;
    if (discountData) {
        discountPrice = getDiscountPrice(totalPrice, discountData.percentage);
        specialOfferActive = hasSpecialOffer(discountData.startDate, discountData.endDate);
    }

    return (
        <div className={`product-overview ${showProductOverview ? 'open' : 'closed'}`}>
            <div className="product-overview-header" onClick={onProductOverviewClicked}>
                <div className="product-overview-header-label">Produktübersicht</div>
                <div className="product-overview-caret-wrapper">
                    <img src={menuCaretSvg} alt="Open/Close Menu" className="product-overview-caret" />
                </div>
            </div>

            <div className="product-overview-content">
                <ul className="product-overview-content-product-list">
                    <ProductItem
                        label="Modell"
                        description={config.series.description + ' | ' + config.door?.description}
                        price={showerSizePrice}
                        noSign={true}
                        goldenDescription
                    ></ProductItem>
                    <ProductItem
                        label="Modellvariante"
                        description={config.model.description + ' | ' + config.base.description}
                        price={config.base.price}
                    ></ProductItem>
                    <ProductItem
                        label="Wandbefestigung"
                        description={config.wallmount.type}
                        price={config.wallmount.price}
                    ></ProductItem>
                    <ProductItem
                        label="Masse"
                        description={`H: ${showerSize.y} mm, T: ${showerSize.z} mm, B: ${showerSize.x} mm`}
                    ></ProductItem>
                    {config.knob && (
                        <ProductItem
                            label="Griffe"
                            description={config.knob.description}
                            price={config.knob.price * numberOfDoors}
                            mountType={config.knob.mountType}
                        ></ProductItem>
                    )}
                    <ProductItem
                        label="Beschlagsfarbe"
                        description={config.material.description}
                        price={config.material.price}
                    ></ProductItem>
                    <ProductItem
                        label="Glas"
                        description={config.glass.description}
                        price={config.glass.price}
                    ></ProductItem>
                    <ProductItem
                        label="Zubehör"
                        description={config.beam.description}
                        price={config.beam.price}
                    ></ProductItem>
                </ul>
                <div className="product-overview-content-srp-footer">
                    <div
                        className={`product-overview-content-srp-footer-price ${specialOfferActive ? 'discount' : ''}`}
                    >
                        <div className="product-overview-content-srp-footer-inner-container">
                            <div className="product-overview-content-srp-footer-price-label-with-info">
                                <div className="product-overview-content-srp-footer-price-label">Preisempfehlung</div>
                                <InfoDialogComponent>
                                    Nicht die gewünschte Dusche gefunden? Senden Sie uns Ihre individuelle Anfrage über
                                    das Kontaktformular auf{' '}
                                    <a href="https://www.sprinz.eu/index.php?Kontakt" target="_blank" rel="noreferrer">
                                        unserer Website
                                    </a>
                                    .
                                </InfoDialogComponent>
                            </div>
                            <div>
                                <div className={specialOfferActive ? 'price-cut' : ''}>ab {totalPrice},- EUR</div>
                                {specialOfferActive && <div>ab {discountPrice},- EUR</div>}
                                <div className="product-overview-content-srp-footer-tax-hint">zzgl. MwSt.</div>
                            </div>
                        </div>
                    </div>
                    {discountData && specialOfferActive && (
                        <ProductOverViewDiscountComponent
                            startDate={discountData.startDate}
                            endDate={discountData.endDate}
                            orderDeadline={discountData.orderDeadLine}
                            percentage={discountData.percentage}
                        />
                    )}
                    <button className="product-overview-content-btn" onClick={openSendOfferModal}>
                        Angebot anfragen
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ProductOverviewComponent;

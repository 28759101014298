import React from 'react';
import { Vector2 } from '@babylonjs/core';

type ScaledTextureComponentProps = {
    sizeCM: Vector2;
    assignTo: string;
    texture: string;
    baseSize: Vector2;
    scaling?: Vector2;
};

const ScaledTextureComponent = ({
    sizeCM,
    assignTo,
    texture,
    baseSize,
    scaling = Vector2.One()
}: ScaledTextureComponentProps): JSX.Element => (
    <texture
        assignTo={assignTo}
        uScale={(sizeCM.x * 10 * scaling.x) / baseSize.x}
        vScale={(sizeCM.y * 10 * scaling.y) / baseSize.y}
        url={`/assets/textures/${texture}`}
        isBlocking={false}
    />
);

export default ScaledTextureComponent;

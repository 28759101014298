import { Vector3 } from '@babylonjs/core';
import { combineEpics, ofType, StateObservable } from 'redux-observable';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { StoreState } from '..';
import { applyRotationY, applyTranslation, getRotationYFromDirection } from '../../../ng/3d_helpers';
import { calculateBeamAxisForLayout } from '../../../ng/helper/beamHelper';
import {
    calculateDefaultElementSizes,
    calculateMaxShowerBaseSize,
    calculateMinShowerBaseSize,
    calculateRoomSizeAdjustment,
    calculateShowerBaseSize
} from '../../../ng/helper/blueprintHelper';
import { calculateAxisForRotation, calculateSideForRotation } from '../../../ng/helper/commonHelper';
import {
    fetchBeamTechConfigRx,
    fetchBlueprintRx,
    fetchHingeTechConfigRx,
    fetchKnobTechConfigRx,
    fetchMountTechConfigRx,
    fetchRoomplanRx
} from '../../../ng/helper/fetchHelper';
import {
    applyConstraints,
    applyLayoutConstraints,
    calculateElementsOfSamePlane,
    getPreviousElementIndex
} from '../../../ng/helper/layoutHelper';
import { calculateRoomSizeAndPosition, calculateWallLength } from '../../../ng/helper/roomplanHelper';
import { isShowerBaseId } from '../../../ng/helper/showerBaseHelper';
import { decoratorDepthMM } from '../../../ng/RoomplanLoaderComponent';
import { BeamTechConfig } from '../../../ng/types/BeamTechConfig';
import {
    Blueprint,
    BlueprintDoor,
    BlueprintLayoutType,
    BlueprintPartition,
    BlueprintVoid
} from '../../../ng/types/Blueprint';
import { HingeTechConfig } from '../../../ng/types/HingeTechConfig';
import { KnobTechConfig } from '../../../ng/types/KnobTechConfig';
import { MountTechConfig } from '../../../ng/types/MountTechConfig';
import { RoomElement, RoomLayoutType, Roomplan } from '../../../ng/types/Roomplan';
import { configuratorActions } from '../configurator';
import {
    CatalogueModelComposition,
    CatalogueSeries,
    ConfigurationDefaultLoadedAction,
    SelectConfigOptionAction
} from '../configurator/interfaces';
import { CONFIGURATION_DEFAULT_LOADED_SUCCESS, SELECT_CONFIG_OPTION } from '../configurator/types';
import {
    getDefaultBeamIdForModel,
    getDefaultConfiguration,
    getDefaultWallmountId,
    getModelForSeries
} from '../configurator/utils';
import { ConfigOptionEnum } from '../system/interfaces';
import {
    beamLoadedError,
    beamLoadedSucess,
    blueprintLoadedError,
    blueprintLoadedSucess,
    blueprintUpdated,
    glassMountTechConfigLoadedError,
    glassMountTechConfigLoadedSucess,
    hingeTechConfigLoadedError,
    hingeTechConfigLoadedSucess,
    knobTechConfigLoadedError,
    knobTechConfigLoadedSucess,
    loadBlueprint,
    loadGlassMountTechConfig,
    loadHingeTechConfig,
    loadKnob,
    loadPossibleBeams,
    loadRoomplan,
    loadTechConfigBeam,
    loadWallMountTechConfig,
    possibleBeamsLoadedError,
    possibleBeamsLoadedSuccess,
    roomplanLoadedError,
    roomplanLoadedSucess,
    selectArchitecture,
    updateBlueprintRatio,
    updateDefaultShowerSize,
    updateRoomSize,
    updateRoomSizeAdjustment,
    updateShowerBaseSize,
    wallMountTechConfigLoadedError,
    wallMountTechConfigLoadedSucess
} from './actions';
import {
    BeamTechConfigLoadedErrorAction,
    BeamTechConfigLoadedSuccessAction,
    BlueprintLoadedErrorAction,
    BlueprintLoadedSuccessAction,
    BlueprintUpdatedAction,
    GlassMountTechConfigLoadedErrorAction,
    GlassMountTechConfigLoadedSuccessAction,
    HingeTechConfigLoadedErrorAction,
    HingeTechConfigLoadedSuccessAction,
    KnobTechConfigLoadedErrorAction,
    KnobTechConfigLoadedSuccessAction,
    LoadBeamTechConfigAction,
    LoadBlueprintAction,
    LoadGlassMountTechConfigAction,
    LoadHingeTechConfigAction,
    LoadKnobTechConfigAction,
    LoadPossibleBeamTechConfigsAction,
    LoadPossibleBeamTechConfigsErrorAction,
    LoadPossibleBeamTechConfigsSuccessAction,
    LoadRoomplanAction,
    LoadWallMountTechConfigAction,
    RoomplanLoadedErrorAction,
    RoomplanLoadedSuccessAction,
    SelectArchitectureAction,
    SelectConfigActions,
    UpdateBlueprintRatioAction,
    UpdateDefaultShowerSizeAction,
    UpdateLengthMMAction,
    UpdateRoomSizeAction,
    UpdateRoomSizeAdjustmentAction,
    UpdateShowerBaseSizeAction,
    UpdateShowerBaseSizeConstraintsAction,
    WallMountTechConfigLoadedErrorAction,
    WallMountTechConfigLoadedSuccessAction
} from './interfaces';
import { defaultShowerHeightMM } from './reducers';
import {
    BEAM_TECH_CONFIG_LOADED_SUCCESS,
    BLUEPRINT_LOADED_SUCCESS,
    BLUEPRINT_UPDATED,
    LOAD_BEAM_TECH_CONFIG,
    LOAD_BLUEPRINT,
    LOAD_GLASS_MOUNT_TECH_CONFIG,
    LOAD_HINGE_TECH_CONFIG,
    LOAD_KNOB_TECH_CONFIG,
    LOAD_POSSIBLE_BEAM_TECH_CONFIGS,
    LOAD_ROOMPLAN,
    LOAD_WALL_MOUNT_TECH_CONFIG,
    ROOMPLAN_LOADED_SUCCESS,
    SELECT_ARCHITECTURE,
    UPDATE_LENGTH_MM,
    UPDATE_ROOM_SIZE_ADJUSTMENT,
    UPDATE_SHOWER_BASESIZE,
    UPDATE_SHOWER_BASESIZE_CONSTRAINTS
} from './types';

const selectArchitectureEpic = (
    action$: Observable<SelectArchitectureAction>
): Observable<LoadBlueprintAction | LoadRoomplanAction> =>
    action$.pipe(
        ofType(SELECT_ARCHITECTURE),
        mergeMap((action: SelectArchitectureAction) => {
            return action.payload.blueprintId
                ? of(loadBlueprint(action.payload.blueprintId), loadRoomplan(action.payload.roomplanId))
                : of(loadRoomplan(action.payload.roomplanId));
        })
    );

const loadBlueprintEpic = (
    action$: Observable<LoadBlueprintAction>
): Observable<BlueprintLoadedSuccessAction | BlueprintLoadedErrorAction> =>
    action$.pipe(
        ofType(LOAD_BLUEPRINT),
        switchMap((action) =>
            fetchBlueprintRx(action.payload.id).pipe(
                map((blueprint: Blueprint) => blueprintLoadedSucess(blueprint)),
                catchError((err) => of(blueprintLoadedError(err)))
            )
        )
    );

const loadRoomplanEpic = (
    action$: Observable<LoadRoomplanAction>
): Observable<RoomplanLoadedSuccessAction | RoomplanLoadedErrorAction> =>
    action$.pipe(
        ofType(LOAD_ROOMPLAN),
        switchMap((action) =>
            fetchRoomplanRx(action.payload.id).pipe(
                map((roomplan: Roomplan) => {
                    return roomplanLoadedSucess(roomplan);
                }),
                catchError((err) => of(roomplanLoadedError(err)))
            )
        )
    );

const loadKnobTechConfigEpic = (
    action$: Observable<LoadKnobTechConfigAction>
): Observable<KnobTechConfigLoadedSuccessAction | KnobTechConfigLoadedErrorAction> =>
    action$.pipe(
        ofType(LOAD_KNOB_TECH_CONFIG),
        switchMap((action) =>
            fetchKnobTechConfigRx(action.payload.id).pipe(
                map((knob: KnobTechConfig) => knobTechConfigLoadedSucess(knob)),
                catchError((err) => of(knobTechConfigLoadedError(err)))
            )
        )
    );

const loadWallMountTechConfigEpic = (
    action$: Observable<LoadWallMountTechConfigAction>
): Observable<WallMountTechConfigLoadedSuccessAction | WallMountTechConfigLoadedErrorAction> =>
    action$.pipe(
        ofType(LOAD_WALL_MOUNT_TECH_CONFIG),
        switchMap((action) =>
            fetchMountTechConfigRx(action.payload.id).pipe(
                map((mount: MountTechConfig) => wallMountTechConfigLoadedSucess(mount)),
                catchError((err) => of(wallMountTechConfigLoadedError(err)))
            )
        )
    );

const loadGlassMountTechConfigEpic = (
    action$: Observable<LoadGlassMountTechConfigAction>
): Observable<GlassMountTechConfigLoadedSuccessAction | GlassMountTechConfigLoadedErrorAction> =>
    action$.pipe(
        ofType(LOAD_GLASS_MOUNT_TECH_CONFIG),
        switchMap((action) =>
            fetchMountTechConfigRx(action.payload.id).pipe(
                map((mount: MountTechConfig) => glassMountTechConfigLoadedSucess(mount)),
                catchError((err) => of(glassMountTechConfigLoadedError(err)))
            )
        )
    );

const loadHingeTechConfigEpic = (
    action$: Observable<LoadHingeTechConfigAction>
): Observable<HingeTechConfigLoadedSuccessAction | HingeTechConfigLoadedErrorAction> =>
    action$.pipe(
        ofType(LOAD_HINGE_TECH_CONFIG),
        switchMap((action) =>
            fetchHingeTechConfigRx(action.payload.id).pipe(
                map((hingeTechConfig: HingeTechConfig) => hingeTechConfigLoadedSucess(hingeTechConfig)),
                catchError((err) => of(hingeTechConfigLoadedError(err)))
            )
        )
    );

const loadBeamTechConfigEpic = (
    action$: Observable<LoadBeamTechConfigAction>
): Observable<BeamTechConfigLoadedSuccessAction | BeamTechConfigLoadedErrorAction> =>
    action$.pipe(
        ofType(LOAD_BEAM_TECH_CONFIG),
        switchMap((action) => {
            if (!action.payload.id) {
                return of(beamLoadedSucess(null));
            } else {
                return fetchBeamTechConfigRx(action.payload.id).pipe(
                    map((beam: BeamTechConfig) => beamLoadedSucess(beam)),
                    catchError((err) => of(beamLoadedError(err)))
                );
            }
        })
    );

const loadPossibleBeamTechConfigsEpic = (
    action$: Observable<LoadPossibleBeamTechConfigsAction>
): Observable<LoadPossibleBeamTechConfigsSuccessAction | LoadPossibleBeamTechConfigsErrorAction> =>
    action$.pipe(
        ofType(LOAD_POSSIBLE_BEAM_TECH_CONFIGS),
        switchMap((action) => {
            const beamObservables = action.payload.ids
                .filter((id) => id !== null && id !== undefined)
                .map((id) => fetchBeamTechConfigRx(id));
            return combineLatest(beamObservables);
        }),
        map((x) => possibleBeamsLoadedSuccess(x)),
        catchError((err) => of(possibleBeamsLoadedError(err)))
    );

const updateShowerBaseSizeEpic = (
    action$: Observable<BlueprintLoadedSuccessAction>,
    state$: StateObservable<StoreState>
): Observable<UpdateShowerBaseSizeAction> =>
    action$.pipe(
        ofType(BLUEPRINT_LOADED_SUCCESS),
        map((action: BlueprintLoadedSuccessAction) => {
            const maxMeasurementHeight = state$.value.configurator.configuration.model.measurements[0].height;

            const showerHeight = Math.min(defaultShowerHeightMM, maxMeasurementHeight);
            const newShowerBaseSize = calculateShowerBaseSize(action.payload.blueprint.layout, showerHeight);

            return updateShowerBaseSize(newShowerBaseSize);
        })
    );

const updateRoomSizeEpic = (
    action$: Observable<RoomplanLoadedSuccessAction | UpdateShowerBaseSizeAction | UpdateRoomSizeAdjustmentAction>,
    state$: StateObservable<StoreState>
): Observable<UpdateRoomSizeAction> =>
    action$.pipe(
        ofType(ROOMPLAN_LOADED_SUCCESS, UPDATE_SHOWER_BASESIZE, UPDATE_ROOM_SIZE_ADJUSTMENT),
        mergeMap(() => {
            const roomplan = state$.value.architecture.roomplan;
            const showerBaseSize = state$.value.architecture.showerBaseSize;
            const roomSizeAdjustment = state$.value.architecture.roomSizeAdjustment;

            let currentPositionMM = Vector3.Zero();
            let currentDirection = Vector3.Right();
            let currentRotation = getRotationYFromDirection(currentDirection);

            const roomCoordinatesMM: Vector3[] = [];

            if (roomSizeAdjustment && showerBaseSize && roomplan) {
                roomplan?.layout.forEach((element: RoomElement) => {
                    switch (element.type) {
                        case RoomLayoutType.Transition:
                            currentDirection = applyRotationY(currentDirection, element.angle);
                            currentRotation = getRotationYFromDirection(currentDirection);
                            break;
                        case RoomLayoutType.Wall:
                            const showerSideLengthMM = calculateSideForRotation(currentRotation, showerBaseSize);
                            const roomSideAdjustmentLengthMM = calculateSideForRotation(
                                currentRotation,
                                roomSizeAdjustment
                            );
                            const wallLengthMM = calculateWallLength(
                                element,
                                showerSideLengthMM,
                                roomSideAdjustmentLengthMM
                            );

                            currentPositionMM = applyTranslation(currentPositionMM, currentDirection, wallLengthMM);
                            roomCoordinatesMM.push(currentPositionMM);
                            break;
                        default:
                            break;
                    }
                });

                const { size, position } = calculateRoomSizeAndPosition(roomCoordinatesMM, decoratorDepthMM);

                return [updateRoomSize(size, position)];
            } else {
                return [];
            }
        })
    );

const updateShowerBaseSizeConstraintsEpic = (
    action$: Observable<BlueprintLoadedSuccessAction | BeamTechConfigLoadedSuccessAction | BlueprintUpdatedAction>,
    state$: StateObservable<StoreState>
): Observable<UpdateShowerBaseSizeConstraintsAction> =>
    action$.pipe(
        ofType(BLUEPRINT_LOADED_SUCCESS, BEAM_TECH_CONFIG_LOADED_SUCCESS, BLUEPRINT_UPDATED),
        map(() => {
            const selectedSeriesId = state$.value.configurator.configuration.series.id;
            const series: CatalogueSeries | undefined = state$.value.configurator.catalogue.series.find(
                (s) => s.id === selectedSeriesId
            );

            const selectedModelId = state$.value.configurator.configuration.model.id;
            const model: CatalogueModelComposition | undefined = series?.models.find((m) => m.id === selectedModelId);

            const selectedBeamId = state$.value.configurator.configuration.beam.id;
            const beamTechConfig = state$.value.architecture.techConfigs.beam;
            const beamAxis = calculateBeamAxisForLayout(state$.value.architecture.blueprint.layout);

            const seriesConstraints = series?.sizeConstraints;
            const modelConstraints = model?.sizeConstraints;
            const beamConstraints = selectedBeamId ? beamTechConfig?.sizeConstraints : undefined;

            const blueprint = state$.value.architecture.blueprint;

            const constraints = {
                min: calculateMinShowerBaseSize(seriesConstraints, modelConstraints, beamConstraints, beamAxis),
                max: calculateMaxShowerBaseSize(
                    seriesConstraints,
                    modelConstraints,
                    beamConstraints,
                    beamAxis,
                    blueprint.accMaxLenghtForOnlyDoorAxisConstraints
                )
            };

            return {
                type: UPDATE_SHOWER_BASESIZE_CONSTRAINTS,
                payload: {
                    constraints
                }
            };
        })
    );

const updateDefaultShowerSizeEpic = (
    action$: Observable<BlueprintLoadedSuccessAction>,
    state$: StateObservable<StoreState>
): Observable<UpdateDefaultShowerSizeAction | BlueprintLoadedErrorAction> =>
    action$.pipe(
        ofType(BLUEPRINT_LOADED_SUCCESS),
        switchMap((action: BlueprintLoadedSuccessAction) =>
            fetchBlueprintRx(action.payload.blueprint.id.toString()).pipe(
                map(
                    (blueprint: Blueprint) => {
                        const showerHeight = state$.value.architecture.showerBaseSize.y;
                        const defaultShowerBaseSize = calculateShowerBaseSize(blueprint.layout, showerHeight);
                        const defaultElementSizes = calculateDefaultElementSizes(blueprint.layout);

                        const defaultShowerSize = {
                            baseSize: defaultShowerBaseSize,
                            elementSizes: defaultElementSizes
                        };

                        return updateDefaultShowerSize(defaultShowerSize);
                    },
                    catchError((err) => of(blueprintLoadedError(err)))
                )
            )
        )
    );

const updateRoomSizeAdjustmentEpic = (
    action$: Observable<BlueprintLoadedSuccessAction>
): Observable<UpdateRoomSizeAdjustmentAction> =>
    action$.pipe(
        ofType(BLUEPRINT_LOADED_SUCCESS),
        map((action: BlueprintLoadedSuccessAction) => {
            return updateRoomSizeAdjustment(calculateRoomSizeAdjustment(action.payload.blueprint.bathtubConfig));
        })
    );

const updateShowerOriginalBaseSizeEpic = (
    action$: Observable<UpdateShowerBaseSizeAction>,
    state$: StateObservable<StoreState>
): Observable<UpdateBlueprintRatioAction> =>
    action$.pipe(
        ofType(UPDATE_SHOWER_BASESIZE),
        map(() => {
            const blueprint = state$.value.architecture.blueprint;
            const showerBaseSize = state$.value.architecture.showerBaseSize;
            return updateBlueprintRatio(showerBaseSize, blueprint);
        })
    );

const updateBlueprintEpic = (
    action$: Observable<UpdateShowerBaseSizeAction | UpdateBlueprintRatioAction>,
    state$: StateObservable<StoreState>
): Observable<BlueprintUpdatedAction> =>
    action$.pipe(
        ofType(UPDATE_SHOWER_BASESIZE),
        map(() => {
            const blueprint = state$.value.architecture.blueprint;
            const showerBaseSize = state$.value.architecture.showerBaseSize;
            let labelIndex = 0;
            let currentDirection = Vector3.Right();
            let prevDirection: Vector3 | null = null;
            let currentRotation = getRotationYFromDirection(currentDirection);

            const baseID = state$.value.configurator.configuration.base.id;
            const hasShowerBase = isShowerBaseId(baseID);
            const bathtubConfig = state$.value.architecture.blueprint.bathtubConfig;

            const series = state$.value.configurator.configuration.series as CatalogueSeries;

            // Apply Minimum/Maximum to Layout
            const layoutWithConstraints = applyLayoutConstraints(
                blueprint.layout,
                series.blueprintElementSizeConstraints,
                showerBaseSize,
                series.id,
                hasShowerBase,
                bathtubConfig
            );

            let accMaxLenghtForOnlyDoorAxisConstraints = { x: 99999, y: 99999, z: 99999 };

            layoutWithConstraints.map((element, idx) => {
                if (element.type === BlueprintLayoutType.Transition) {
                    prevDirection = new Vector3(currentDirection.x, currentDirection.y, currentDirection.z);
                    currentDirection = applyRotationY(currentDirection, element.angle);
                    currentRotation = getRotationYFromDirection(currentDirection);
                }

                if (prevDirection?.equals(currentDirection)) {
                    const prevElementIndex = getPreviousElementIndex(idx, layoutWithConstraints.length);
                    const prevElement = layoutWithConstraints[prevElementIndex];

                    if (
                        prevElement.type === BlueprintLayoutType.Door ||
                        (prevElement.type === BlueprintLayoutType.Partition && !prevElement.sliderLabel)
                    ) {
                        prevElement.sliderLabel = String.fromCharCode(65 + labelIndex);
                        labelIndex++;
                    }

                    if (element.type === BlueprintLayoutType.Door || element.type === BlueprintLayoutType.Partition) {
                        element.sliderLabel = String.fromCharCode(65 + labelIndex);
                        labelIndex++;
                    }
                }

                if (element.type !== BlueprintLayoutType.Transition) {
                    const showerSideLengthMM = calculateSideForRotation(currentRotation, showerBaseSize);
                    const elementsOfSamePlane = calculateElementsOfSamePlane(idx, layoutWithConstraints);

                    const elementsOfSamePlaneAreOnlyDoors =
                        elementsOfSamePlane.filter((e) => e.type !== BlueprintLayoutType.Door).length === 0;

                    if (elementsOfSamePlaneAreOnlyDoors && element.type === BlueprintLayoutType.Door) {
                        const currentAxis = calculateAxisForRotation(currentRotation);
                        const maxLenghtPerAxis = elementsOfSamePlane
                            .filter((el) => el.type !== BlueprintLayoutType.Transition)
                            .map((el) => el as BlueprintDoor)
                            .map((el) => el.maxLengthWeight || 0)
                            .reduce((acc, cum) => acc + cum, 0);

                        accMaxLenghtForOnlyDoorAxisConstraints = {
                            ...accMaxLenghtForOnlyDoorAxisConstraints,
                            [currentAxis]: Math.min(
                                accMaxLenghtForOnlyDoorAxisConstraints[currentAxis],
                                maxLenghtPerAxis
                            )
                        };
                    }

                    let showerSideRest = showerSideLengthMM;
                    elementsOfSamePlane
                        .filter((el) => el.type !== BlueprintLayoutType.Transition)
                        .map((el) => el as BlueprintDoor | BlueprintPartition | BlueprintVoid)
                        .forEach((el, j, a) => {
                            if (el.ratio) {
                                el.length =
                                    j === 0
                                        ? Math.ceil(showerSideLengthMM * el.ratio)
                                        : Math.floor(showerSideLengthMM * el.ratio);

                                showerSideRest -= el.length;
                                if (j === a.length - 1) {
                                    el.length += showerSideRest;
                                }
                            }
                        });
                }
                return element;
            });

            const elementsOutOfConstraints = layoutWithConstraints
                .filter((el) => el.type !== BlueprintLayoutType.Transition)
                .map((el) => el as BlueprintDoor | BlueprintPartition | BlueprintVoid)
                .filter((el) => {
                    return el.length < el.minLength || el.length > el.maxLength;
                });

            elementsOutOfConstraints.forEach((element) => {
                const elementIndex = layoutWithConstraints.indexOf(element);
                const elementsInSamePlane = calculateElementsOfSamePlane(elementIndex, layoutWithConstraints);

                const elementIsTooSmall = element.length < element.minLength;
                const otherElementsShouldShrink = elementIsTooSmall;
                let deltaLength = otherElementsShouldShrink
                    ? element.minLength - element.length
                    : element.length - element.maxLength;

                elementIsTooSmall ? (element.length += deltaLength) : (element.length -= deltaLength);

                // Shrink/Grow all outOfConstraints Elements and their corresponding other Elements in same layout plane
                layoutWithConstraints
                    .filter((el, idx) => el.type !== BlueprintLayoutType.Transition && idx !== elementIndex)
                    .map((el) => el as BlueprintDoor | BlueprintPartition | BlueprintVoid)
                    .filter((el) => elementsInSamePlane.some((elInSamePlane) => elInSamePlane === el))
                    .map((element) => {
                        if (otherElementsShouldShrink) {
                            const lengthUntilMininum = element.length - element.minLength;
                            if (lengthUntilMininum >= deltaLength) {
                                element.length -= deltaLength;
                                deltaLength = 0;
                            } else {
                                element.length -= lengthUntilMininum;
                                deltaLength -= lengthUntilMininum;
                            }
                        } else {
                            const lenghtUntilMaximum = element.maxLength - element.length;
                            if (lenghtUntilMaximum >= deltaLength) {
                                element.length += deltaLength;
                                deltaLength = 0;
                            } else {
                                element.length += lenghtUntilMaximum;
                                deltaLength -= lenghtUntilMaximum;
                            }
                        }

                        return element;
                    });
            });

            // Revert slider labels
            layoutWithConstraints
                .filter((el) => el.type !== BlueprintLayoutType.Transition && el.sliderLabel)
                .map((element, idx, allElements) => {
                    (element as BlueprintPartition | BlueprintDoor | BlueprintVoid).sliderLabel = String.fromCharCode(
                        64 + allElements.length - idx
                    );
                });

            const updatedBlueprint = {
                ...blueprint,
                layout: layoutWithConstraints,
                accMaxLenghtForOnlyDoorAxisConstraints
            };

            return blueprintUpdated(updatedBlueprint);
        })
    );

const updateLengthMMEpic = (
    action$: Observable<UpdateLengthMMAction>,
    state$: StateObservable<StoreState>
): Observable<BlueprintUpdatedAction | UpdateBlueprintRatioAction> =>
    action$.pipe(
        ofType(UPDATE_LENGTH_MM),
        mergeMap((action: UpdateLengthMMAction) => {
            const { elementIndex, elementLengthMM: newElementLengthMM } = action.payload;
            const blueprint = state$.value.architecture.blueprint;
            const showerBaseSize = state$.value.architecture.showerBaseSize;

            const element = blueprint.layout[elementIndex] as BlueprintDoor | BlueprintPartition | BlueprintVoid;
            let deltaLength = Math.abs(element.length - newElementLengthMM);
            const otherElementsShouldShrink = element.length - newElementLengthMM < 0;

            // Apply new length to element
            element.length = applyConstraints(newElementLengthMM, element.minLength, element.maxLength);

            const elementsInSamePlane = calculateElementsOfSamePlane(elementIndex, blueprint.layout)
                .filter((el) => el.type !== BlueprintLayoutType.Transition)
                .map((el) => el as BlueprintDoor | BlueprintPartition | BlueprintVoid);

            // Shrink/Grow other Elements in same layout plane
            blueprint.layout
                .filter((el, idx) => el.type !== BlueprintLayoutType.Transition && idx !== elementIndex)
                .map((el) => el as BlueprintDoor | BlueprintPartition | BlueprintVoid)
                .filter((el) => elementsInSamePlane.some((elInSamePlane) => elInSamePlane === el))
                .map((element) => {
                    if (otherElementsShouldShrink) {
                        const lengthUntilMininum = element.length - element.minLength;
                        if (lengthUntilMininum >= deltaLength) {
                            element.length -= deltaLength;
                            deltaLength = 0;
                        } else {
                            element.length -= lengthUntilMininum;
                            deltaLength -= lengthUntilMininum;
                        }
                    } else {
                        const lenghtUntilMaximum = element.maxLength - element.length;
                        if (lenghtUntilMaximum >= deltaLength) {
                            element.length += deltaLength;
                            deltaLength = 0;
                        } else {
                            element.length += lenghtUntilMaximum;
                            deltaLength -= lenghtUntilMaximum;
                        }
                    }

                    return element;
                });

            const updatedBlueprint = {
                ...blueprint
            };

            return [blueprintUpdated(updatedBlueprint), updateBlueprintRatio(showerBaseSize, updatedBlueprint)];
        })
    );

const mapping = [
    { id: 'mv-1', blueprintId: '1', roomplanId: 'normal' },
    { id: 'mv-3', blueprintId: '3', roomplanId: 'normal' },
    { id: 'mv-4', blueprintId: '4', roomplanId: 'normal' },
    { id: 'mv-6', blueprintId: '6', roomplanId: 'normal' },
    { id: 'mv-7', blueprintId: '7', roomplanId: 'normal' },
    { id: 'mv-8', blueprintId: '8', roomplanId: 'recess' },
    { id: 'mv-9', blueprintId: '9', roomplanId: 'recess' },
    { id: 'mv-10', blueprintId: '10', roomplanId: 'recess' },
    { id: 'mv-11', blueprintId: '11', roomplanId: 'recess' },
    { id: 'mv-12', blueprintId: '12', roomplanId: 'recess' },
    { id: 'mv-13', blueprintId: '13', roomplanId: 'normal-x-offset' },
    { id: 'mv-14', blueprintId: '14', roomplanId: 'normal-x-offset' },
    { id: 'mv-15', blueprintId: '15', roomplanId: 'normal' },
    { id: 'mv-16', blueprintId: '16', roomplanId: 'normal' },
    { id: 'mv-17', blueprintId: '17', roomplanId: 'normal' },
    { id: 'mv-18', blueprintId: '18', roomplanId: 'normal' },
    { id: 'mv-19', blueprintId: '19', roomplanId: 'normal' },
    { id: 'mv-20', blueprintId: '20', roomplanId: 'normal' },
    { id: 'mv-21', blueprintId: '21', roomplanId: 'normal' },
    { id: 'mv-22', blueprintId: '22', roomplanId: 'normal-x-offset' },
    { id: 'mv-23', blueprintId: '23', roomplanId: 'normal-x-offset' },
    { id: 'mv-24', blueprintId: '24', roomplanId: 'normal-x-offset' },
    { id: 'mv-25', blueprintId: '25', roomplanId: 'normal' },
    { id: 'mv-26', blueprintId: '26', roomplanId: 'normal' },
    { id: 'mv-27', blueprintId: '27', roomplanId: 'normal-x-offset' },
    { id: 'mv-28', blueprintId: '28', roomplanId: 'normal-x-offset' },
    { id: 'mv-29', blueprintId: '29', roomplanId: 'recess' },
    { id: 'mv-30', blueprintId: '30', roomplanId: 'normal' },
    { id: 'mv-31', blueprintId: '31', roomplanId: 'normal-x-offset' },
    { id: 'mv-32', blueprintId: '32', roomplanId: 'normal' },
    { id: 'mv-33', blueprintId: '33', roomplanId: 'recess' },
    { id: 'mv-34', blueprintId: '34', roomplanId: 'normal' },
    { id: 'mv-35', blueprintId: '35', roomplanId: 'normal' }
];

const selectConfigOptionEpic = (
    action$: Observable<SelectConfigOptionAction>,
    state$: StateObservable<StoreState>
): Observable<SelectConfigActions> =>
    action$.pipe(
        ofType(SELECT_CONFIG_OPTION),
        mergeMap((action: SelectConfigOptionAction) => {
            const catalogue = state$.value.configurator.catalogue;
            const actions: SelectConfigActions[] = [];

            switch (action.payload.optionType) {
                case ConfigOptionEnum.Series:
                    const seriesId = action.payload.itemId;

                    const defaultConfigSeries = getDefaultConfiguration(catalogue, seriesId);
                    const defaultModel = defaultConfigSeries.model;
                    const defaultHingeSeries = defaultConfigSeries.hinge;
                    const defaultKnob = defaultConfigSeries.knob;
                    const defaultWallmount = defaultConfigSeries.wallmount;
                    const defaultBeam = defaultConfigSeries.beam;

                    const series = catalogue.series.find((s) => (seriesId ? s.id === seriesId : s.default));
                    const model = series?.models.find((m) => m.default);
                    const modelBeamIds = model?.beams.map((b) => b.id);
                    const defaultHingeModel = model?.hinges?.find((h) => h.default);

                    const defaultHinge = defaultHingeModel || defaultHingeSeries;
                    const architectureDefaultModel = mapping.find((m) => m.id === defaultModel.id);

                    if (architectureDefaultModel) {
                        actions.push(
                            selectArchitecture(
                                action.payload.loadBlueprint ? architectureDefaultModel.blueprintId : undefined,
                                architectureDefaultModel.roomplanId
                            ),
                            loadWallMountTechConfig(defaultWallmount.id),
                            loadTechConfigBeam(defaultBeam.id),
                            loadPossibleBeams(modelBeamIds || [])
                        );
                        defaultHinge && actions.push(loadHingeTechConfig(defaultHinge.id));
                        defaultKnob && actions.push(loadKnob(defaultKnob.id));
                    } else {
                        console.warn(`Found no architecture for model: ${defaultModel.id} in series: ${seriesId}`);
                    }

                    break;

                case ConfigOptionEnum.Model:
                    const architecture = mapping.find((m) => m.id === action.payload.itemId);

                    if (architecture) {
                        const series = state$.value.configurator.configuration.series;
                        const model = getModelForSeries(catalogue, series.id, action.payload.itemId);
                        const defaultBeamId = model && getDefaultBeamIdForModel(model);
                        const defaultWallmountId = model && getDefaultWallmountId(model, series);
                        const defaultHingeSeries = series?.hinges?.find((h) => h.default);
                        const defaultHingeModel = model?.hinges?.find((h) => h.default);
                        const defaultHinge = defaultHingeModel || defaultHingeSeries;
                        const modelBeamIds = model?.beams.map((beam) => beam.id);

                        actions.push(
                            selectArchitecture(
                                action.payload.loadBlueprint ? architecture.blueprintId : undefined,
                                architecture.roomplanId
                            ),
                            loadPossibleBeams(modelBeamIds || [])
                        );

                        defaultBeamId !== undefined && actions.push(loadTechConfigBeam(defaultBeamId));
                        defaultHinge && actions.push(loadHingeTechConfig(defaultHinge.id));

                        defaultWallmountId !== undefined &&
                            actions.push(
                                configuratorActions.selectConfigOption(
                                    ConfigOptionEnum.Wallmount,
                                    defaultWallmountId,
                                    catalogue
                                )
                            );
                    } else {
                        console.warn(
                            `Found no architecture for model: ${action.payload.itemId} in series: ${state$.value.configurator.configuration.series.id}`
                        );
                    }
                    break;

                case ConfigOptionEnum.Knob:
                    actions.push(loadKnob(action.payload.itemId));
                    break;
                case ConfigOptionEnum.Wallmount:
                    actions.push(loadWallMountTechConfig(action.payload.itemId));
                    break;
                case ConfigOptionEnum.Beam:
                    actions.push(loadTechConfigBeam(action.payload.itemId));
                    break;
                case ConfigOptionEnum.Hinge:
                    actions.push(loadHingeTechConfig(action.payload.itemId));
                    break;
            }

            return actions;
        })
    );

const selectArchitectureOptionEpic = (
    action$: Observable<ConfigurationDefaultLoadedAction>
): Observable<SelectConfigActions> =>
    action$.pipe(
        ofType(CONFIGURATION_DEFAULT_LOADED_SUCCESS),
        mergeMap((action: ConfigurationDefaultLoadedAction) => {
            const architecture = mapping.find((m) => m.id === action.payload.configuration.model.id) ?? {
                id: 'default',
                blueprintId: '7',
                roomplanId: 'normal'
            };

            const actions: SelectConfigActions[] = [
                selectArchitecture(architecture.blueprintId, architecture.roomplanId),
                loadWallMountTechConfig(action.payload.configuration.wallmount.id),
                loadGlassMountTechConfig(action.payload.configuration.glassmount.id)
            ];

            action.payload.configuration.knob && actions.push(loadKnob(action.payload.configuration.knob.id));
            action.payload.configuration.hinge &&
                actions.push(loadHingeTechConfig(action.payload.configuration.hinge.id));
            action.payload.configuration.beam.id &&
                actions.push(loadTechConfigBeam(action.payload.configuration.beam.id));

            return actions;
        })
    );

export default combineEpics(
    selectArchitectureEpic,
    loadBlueprintEpic,
    loadRoomplanEpic,
    updateShowerBaseSizeEpic,
    updateShowerBaseSizeConstraintsEpic,
    updateDefaultShowerSizeEpic,
    updateRoomSizeAdjustmentEpic,
    updateShowerOriginalBaseSizeEpic,
    updateLengthMMEpic,
    selectConfigOptionEpic,
    selectArchitectureOptionEpic,
    loadBeamTechConfigEpic,
    loadPossibleBeamTechConfigsEpic,
    loadKnobTechConfigEpic,
    loadWallMountTechConfigEpic,
    loadGlassMountTechConfigEpic,
    updateBlueprintEpic,
    updateRoomSizeEpic,
    loadHingeTechConfigEpic
);

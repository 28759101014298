import React from 'react';
import { useDispatch } from 'react-redux';
import { systemActions } from '../../state/ducks/system';
import { ConfigOptionEnum } from '../../state/ducks/system/interfaces';
import './ConfigOption.component.scss';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../ng/helper/analyticsHelper';

interface ConfigOptionProps {
    label: string;
    open?: boolean;
    configOption: ConfigOptionEnum;
}

const ConfigOption = ({ label, open, configOption }: ConfigOptionProps): JSX.Element => {
    const dispatch = useDispatch();
    const onClick = (): void => {
        if (open) {
            dispatch(systemActions.closeMenuWith(configOption));
        } else {
            dispatch(systemActions.openMenuWith(configOption));
            logAnalyticsEvent(`configurator_select_menu:${configOption}`);
        }
    };

    return (
        <li onClick={onClick}>
            <label>{label}</label>
        </li>
    );
};

export default ConfigOption;

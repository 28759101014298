import { Vector3 } from '@babylonjs/core';
import React from 'react';
import { TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';

type ShowerCollisionBoxComponentProps = {
    showerOriginMM: Vector3;
    showerBaseSizeMM: Vector3;
};

const ShowerCollisionBoxComponent = ({
    showerOriginMM,
    showerBaseSizeMM
}: ShowerCollisionBoxComponentProps): JSX.Element => {
    const collisionPadding = 20;
    const showerBaseSizeCM = showerBaseSizeMM.divide(new Vector3(10, 10, 10));
    const { x, z } = showerBaseSizeCM.add(new Vector3(collisionPadding, collisionPadding * 10, collisionPadding));
    const showerOriginCM = showerOriginMM.divide(new Vector3(10, 10, 10));

    const position = showerOriginCM.add(showerBaseSizeCM.divide(new Vector3(2, 2, -2)));

    return (
        <box
            position={position}
            name="collision-box"
            checkCollisions
            width={x}
            height={3000}
            depth={z}
            layerMask={TOTAL_LAYERMASK}
            visibility={0}
            isPickable={false}
            key={`width=${x}_depth=${z}`}
        />
    );
};

export default ShowerCollisionBoxComponent;

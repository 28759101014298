import { MenuRestoreState, SocialMedia, UserConfig } from './reducers';
import {
    CLOSE_AMBIENT_MENU,
    CLOSE_MENU,
    CLOSE_MENU_WITH,
    CLOSE_MODAL_PRIVACY,
    CLOSE_MODAL_RESET,
    CLOSE_MODAL_SHARE,
    CLOSE_PRODUCT_OVERVIEW,
    HIDE_MARKER,
    OPEN_AMBIENT_MENU,
    OPEN_MENU,
    OPEN_MENU_WITH,
    OPEN_MODAL_PRIVACY,
    OPEN_MODAL_RESET,
    OPEN_MODAL_SHARE,
    OPEN_PRODUCT_OVERVIEW,
    SET_DISPLAY_RATIO_OPTIONS,
    SET_MENU_RESTORE_STATE,
    SET_LOGGED_IN_USER,
    SET_USER_CONFIG,
    SET_ZOOM_AT,
    SHOW_MARKER,
    TOGGLE_AMBIENT_MENU,
    TOGGLE_MARKER_VISIBILITY,
    TOGGLE_MENU,
    TOGGLE_MODAL_PRIVACY,
    TOGGLE_PRODUCT_OVERVIEW,
    UPDATE_SOCIAL_MEDIA_SUCCESS,
    SET_MARKERS_HIGHLIGHTED,
    SET_TUTORIAL_ACTIVE
} from './types';

export interface ToggleMenuAction {
    type: TOGGLE_MENU;
}

export interface OpenMenuAction {
    type: OPEN_MENU;
}

export interface OpenMenuWithAction {
    type: OPEN_MENU_WITH;
    payload: {
        optionPicker: ConfigOptionEnum;
    };
}
export interface CloseMenuWithAction {
    type: CLOSE_MENU_WITH;
    payload: {
        optionPicker: ConfigOptionEnum;
    };
}

export interface CloseMenuAction {
    type: CLOSE_MENU;
}

export interface OpenProductOverviewAction {
    type: OPEN_PRODUCT_OVERVIEW;
}

export interface ToggleProductOverViewAction {
    type: TOGGLE_PRODUCT_OVERVIEW;
}

export interface CloseProductOverviewAction {
    type: CLOSE_PRODUCT_OVERVIEW;
}

export interface OpenAmbientMenuAction {
    type: OPEN_AMBIENT_MENU;
}
export interface CloseAmbientMenuAction {
    type: CLOSE_AMBIENT_MENU;
}
export interface ToggleAmbientMenuAction {
    type: TOGGLE_AMBIENT_MENU;
}

export interface OpenModalResetAction {
    type: OPEN_MODAL_RESET;
}

export interface CloseModalResetAction {
    type: CLOSE_MODAL_RESET;
}

export interface OpenModalShareAction {
    type: OPEN_MODAL_SHARE;
}

export interface CloseModalShareAction {
    type: CLOSE_MODAL_SHARE;
}

export interface OpenModalPrivacyAction {
    type: OPEN_MODAL_PRIVACY;
}

export interface CloseModalPrivacyAction {
    type: CLOSE_MODAL_PRIVACY;
}

export interface ToggleModalPrivacyAction {
    type: TOGGLE_MODAL_PRIVACY;
}

export interface UpdateSocialMediaSuccessAction {
    type: UPDATE_SOCIAL_MEDIA_SUCCESS;
    payload: {
        socialMedia: SocialMedia;
    };
}

export interface ZoomAtAction {
    type: SET_ZOOM_AT;
    payload: {
        zoomAt: string | null;
    };
}

export interface ShowMarkerAction {
    type: SHOW_MARKER;
}

export interface ToggleMarkerVisibilityAction {
    type: TOGGLE_MARKER_VISIBILITY;
}

export interface HideMarkerAction {
    type: HIDE_MARKER;
}

export interface LoggedInUserAction {
    type: SET_LOGGED_IN_USER;
    payload: {
        loggedInUser: string | null;
    };
}

export interface SetUserConfigAction {
    type: SET_USER_CONFIG;
    payload: {
        userConfig: UserConfig | null;
    };
}

export interface SetDisplayRatioOptions {
    type: SET_DISPLAY_RATIO_OPTIONS;
    payload: {
        displayRatioOptions: boolean;
    };
}

export interface SetMarkersHighlightedAction {
    type: SET_MARKERS_HIGHLIGHTED;
    payload: {
        markersHighlighted: boolean;
    };
}

export interface SetTutorialActiveAction {
    type: SET_TUTORIAL_ACTIVE;
    payload: {
        tutorialActive: boolean;
    };
}

export interface SetMenuRestoreState {
    type: SET_MENU_RESTORE_STATE;
    payload: {
        menuRestoreState: MenuRestoreState;
    };
}

export type SystemActions =
    | ToggleMenuAction
    | OpenMenuAction
    | OpenMenuWithAction
    | CloseMenuAction
    | CloseMenuWithAction
    | OpenModalResetAction
    | CloseModalResetAction
    | OpenAmbientMenuAction
    | CloseAmbientMenuAction
    | ToggleAmbientMenuAction
    | OpenModalShareAction
    | CloseModalShareAction
    | OpenModalPrivacyAction
    | CloseModalPrivacyAction
    | ZoomAtAction
    | ShowMarkerAction
    | ToggleMarkerVisibilityAction
    | HideMarkerAction
    | LoggedInUserAction
    | SetUserConfigAction
    | SetDisplayRatioOptions
    | SetMarkersHighlightedAction
    | SetTutorialActiveAction
    | SetDisplayRatioOptions
    | SetMenuRestoreState;

export enum ConfigOptionEnum {
    'Series' = 'series',
    'Model' = 'model',
    'Base' = 'base',
    'Wallmount' = 'wallmount',
    'Glassmount' = 'glassmount',
    'Door' = 'door',
    'Knob' = 'knob',
    'Glass' = 'glass',
    'Measurement' = 'measurement',
    'Hinge' = 'hinge',
    'Material' = 'material',
    'Beam' = 'beam',
    'MountInfo' = 'mountInfo'
}

import { Vector3 } from '@babylonjs/core';

export enum Axis {
    x = 'x',
    y = 'y',
    z = 'z'
}

/**
 * This function calculates the in which direction the given rotation is heading
 * and returns the corresponding DirectionAxis type.
 * IMPORTANT: This only works correct with roomplans that only contain 90 degree angles
 * @param rotation The rotation to check against
 */
export const calculateAxisForRotation = (rotation: Vector3): Axis => {
    if (
        // rot > -45° && rot < 45°
        (rotation.y > -Math.PI / 4 && rotation.y < Math.PI / 4) ||
        // rot > 135° && rot <= 180° || rot >= -180° && rot < -135°
        (rotation.y > 0.75 * Math.PI && rotation.y <= Math.PI) ||
        (rotation.y >= -Math.PI && rotation.y < 0.75 * -Math.PI)
    ) {
        return Axis.x;
    } else {
        return Axis.z;
    }
};

export enum RotationCorner {
    BackLeft = 'backLeft',
    BackRight = 'backRight',
    FrontRight = 'frontRight',
    FrontLeft = 'frontLeft'
}

/**
 * This function calculates for which of the 4 corners of the shower a rotation is used.
 * @param rotation
 */
export const calculateRotationCornerFromRotation = (rotation: Vector3): RotationCorner => {
    const axis = calculateAxisForRotation(rotation);
    if (axis === Axis.z) {
        if (rotation.y > 0) {
            return RotationCorner.BackRight;
        } else {
            return RotationCorner.FrontLeft;
        }
    } else {
        // rot > -45° && rot < 45°
        if (rotation.y > -Math.PI / 4 && rotation.y < Math.PI / 4) {
            return RotationCorner.BackLeft;
        } else {
            return RotationCorner.FrontRight;
        }
    }
};

/**
 * This function calculates in which direction the given rotation is heading
 * and returns the corresponding side of the given sideData.
 * IMPORTANT: This only works correct with roomplans that only contain 90 degree angles
 * IMPORTANT2: This only works for the xz-plane
 * @param rotation The rotation to check against
 * @param sideData The data to get the corresponding value from
 */
export const calculateSideForRotation = (rotation: Vector3, sideData: Vector3): number =>
    calculateAxisForRotation(rotation) === Axis.x ? sideData.x : sideData.z;

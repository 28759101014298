import React from 'react';
import './ModalHeader.component.scss';

interface ModalHeaderProps {
    children?: React.ReactChild;
    success?: boolean;
}

const ModalHeader = ({ children, success }: ModalHeaderProps): JSX.Element => {
    return <div className={`sp-modal-header ${success ? 'success' : ''}`}>{children}</div>;
};

export default ModalHeader;

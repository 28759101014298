/* eslint-disable @typescript-eslint/ban-ts-comment */
import { BlueprintElement, BlueprintLayoutType } from '../../../ng/types/Blueprint';
import { ConfigOptionEnum } from '../system/interfaces';
import {
    GeneralCatalogueBase,
    GeneralCatalogueBeam,
    Catalogue,
    CatalogueModelComposition,
    Configuration,
    GeneralCatalogueDoor,
    GeneralCatalogueGlass,
    GeneralCatalogueItem,
    GeneralCatalogueKnob,
    MergedCatalogueItem,
    CatalogueSeries,
    CatalogueItemReference,
    CatalogueBaseReference,
    CatalogueGlassReference,
    CatalogueWallmountReference,
    CatalogueGlassmountReference,
    CatalogueMeasurementReference,
    CatalogueMaterialReference,
    CatalogueBeamReference,
    CatalogueKnobReference,
    CatalogueDoorReference,
    CatalogueHingeReference,
    MergedCatalogueKnob,
    MergedCatalogueDoor,
    MergedCatalogueHinge,
    MergedCatalogueBase,
    MergedCatalogueGlass,
    MergedCatalogueWallmount,
    MergedCatalogueMaterial,
    MergedCatalogueBeam,
    GeneralCatalogueMaterial,
    MergedCatalogueModel,
    MergedCatalogueMeasurement
} from './interfaces';

export const getKnobs = (catalogue: Catalogue, seriesId?: string, modelId?: string): GeneralCatalogueKnob[] => {
    if (!modelId || !seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    const tmpModel = tmpSeries?.models.find((m) => m.id === modelId);

    return (
        tmpModel?.knobs?.map((modelKnob) => {
            const knob = catalogue.knobs.find((j) => j.id === modelKnob.id);
            if (knob) {
                knob.price = modelKnob.price ?? knob.price;
            }
            return knob as GeneralCatalogueKnob;
        }) ?? []
    );
};

export const getBeams = (catalogue: Catalogue, seriesId?: string, modelId?: string): GeneralCatalogueBeam[] => {
    if (!modelId || !seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    const tmpModel = tmpSeries?.models.find((m) => m.id === modelId);

    return (
        tmpModel?.beams.map((b) => {
            const beam = catalogue.beams.find((j) => j.id === b.id);
            if (beam) {
                beam.price = b.price ?? beam.price;
            }
            return beam as GeneralCatalogueBeam;
        }) ?? []
    );
};

export const getMaterials = (catalogue: Catalogue, seriesId?: string, modelId?: string): GeneralCatalogueMaterial[] => {
    if (!modelId || !seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    const tmpModel = tmpSeries?.models.find((m) => m.id === modelId);

    return (
        tmpModel?.materials.map((modelMaterial) => {
            const foundCatalogMaterial = catalogue.materials.find((mat) => mat.id === modelMaterial.id);
            if (foundCatalogMaterial) {
                foundCatalogMaterial.price = modelMaterial.price ?? foundCatalogMaterial.price;
            }
            return foundCatalogMaterial as GeneralCatalogueMaterial;
        }) ?? []
    );
};

export const getSeries = (catalogue: Catalogue): CatalogueSeries[] => {
    return catalogue.series;
};

export const getModels = (catalogue: Catalogue, seriesId?: string): GeneralCatalogueItem[] => {
    if (seriesId) {
        const models = catalogue.series.find((s) => s.id === seriesId)?.models;
        if (models) {
            return models.map((model) => {
                const type = catalogue.modelTypes.find((mt) => mt.id === model.typeId)?.type || 'NoType';
                return {
                    id: model.id,
                    type,
                    description: model.description,
                    price: model.price
                } as GeneralCatalogueItem;
            });
        }
    }
    return [];
};

export const getBases = (catalogue: Catalogue, seriesId?: string): GeneralCatalogueBase[] => {
    if (!seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    return catalogue.bases.filter((m) => tmpSeries?.bases.some((t) => t.id === m.id));
};

export const getWallmounts = (catalogue: Catalogue, seriesId?: string, modelId?: string): GeneralCatalogueBase[] => {
    if (!seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    const tmpModel = tmpSeries?.models.find((m) => m.id === modelId);

    return catalogue.wallmounts.filter((m) =>
        tmpModel && tmpModel?.wallmounts
            ? tmpModel?.wallmounts.some((t) => t.id === m.id)
            : tmpSeries?.wallmounts.some((t) => t.id === m.id)
    );
};

export const getGlassmounts = (catalogue: Catalogue, seriesId?: string): GeneralCatalogueBase[] => {
    if (!seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    return catalogue.glassmounts.filter((m) => tmpSeries?.glassmounts.some((t) => t.id === m.id));
};

export const getDoors = (catalogue: Catalogue, seriesId?: string, modelId?: string): GeneralCatalogueDoor[] => {
    if (!modelId || !seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    const tmpModel = tmpSeries?.models.find((m) => m.id === modelId);

    return (
        tmpModel?.doors?.map((d) => {
            const door = catalogue.doors.find((j) => j.id === d.id);
            if (door) {
                door.price = d.price ?? door.price;
                door.description = d.description ?? door.description;
            }
            return door as GeneralCatalogueDoor;
        }) ?? []
    );
};

export const getGlasses = (catalogue: Catalogue, seriesId?: string): GeneralCatalogueGlass[] => {
    if (!seriesId) return [];

    const tmpSeries = catalogue.series.find((s) => s.id === seriesId);
    return catalogue.glasses.filter((g) => tmpSeries?.glasses.some((t) => t.id === g.id));
};

function getMergedModelForModelComposition(
    model: CatalogueModelComposition,
    catalogue: Catalogue
): MergedCatalogueModel {
    return {
        ...model,
        default: model.default ?? false,
        description: model.description ?? '',
        price: model.price ?? 0,
        type: catalogue.modelTypes.find((mt) => mt.id === model.typeId)?.type || 'NoType',
        ignoreDepthForShowerSizePrice: model.ignoreDepthForShowerSizePrice ?? false,
        measurements: model.measurements.map((modelMeasurement) => {
            return getMergedItemForItemReference(
                modelMeasurement,
                catalogue.measurements,
                ConfigOptionEnum.Measurement
            );
        })
    };
}

function getMergedBeamForBeamReference(
    beamReference: CatalogueBeamReference,
    generalBeamInfos: GeneralCatalogueBeam[]
): MergedCatalogueBeam {
    const generalItem = generalBeamInfos.find((m) => m.id === beamReference.id);

    return {
        ...generalItem,
        ...beamReference,
        type: generalItem?.type ?? '',
        description: beamReference.description ?? generalItem?.description ?? '',
        default: beamReference.default ?? false,
        pricing: generalItem?.pricing ?? [],
        price: 0
    };
}

function getMergedItemForItemReference<T extends MergedCatalogueItem>(
    itemReference: CatalogueItemReference,
    generalItems: GeneralCatalogueItem[],
    configOption: ConfigOptionEnum
): T {
    const generalItem = generalItems.find((m) => m.id === itemReference.id);

    if (!generalItem) {
        console.error(
            `Could not find general information in catalogue for ${configOption} with id: ${itemReference.id}`
        );
    }

    return {
        ...generalItem,
        ...itemReference,
        price: itemReference.price ?? generalItem?.price ?? 0,
        description: itemReference.description ?? generalItem?.description ?? '',
        default: itemReference.default ?? false
    } as T;
}

export const getMergedConfiguration = (
    catalogue: Catalogue,
    series: CatalogueSeries,
    model: CatalogueModelComposition,
    base: CatalogueBaseReference,
    glass: CatalogueGlassReference,
    wallmount: CatalogueWallmountReference,
    glassmount: CatalogueGlassmountReference,
    measurement: CatalogueMeasurementReference,
    material: CatalogueMaterialReference,
    beam: CatalogueBeamReference,
    knob?: CatalogueKnobReference,
    door?: CatalogueDoorReference,
    hinge?: CatalogueHingeReference
): Configuration => {
    const configuration: Configuration = {
        series: series,
        model: getMergedModelForModelComposition(model, catalogue),
        base: getMergedItemForItemReference<MergedCatalogueBase>(base, catalogue.bases, ConfigOptionEnum.Base),
        glass: getMergedItemForItemReference<MergedCatalogueGlass>(glass, catalogue.glasses, ConfigOptionEnum.Glass),
        wallmount: getMergedItemForItemReference<MergedCatalogueWallmount>(
            wallmount,
            catalogue.wallmounts,
            ConfigOptionEnum.Wallmount
        ),
        glassmount: getMergedItemForItemReference<MergedCatalogueGlass>(
            glassmount,
            catalogue.glassmounts,
            ConfigOptionEnum.Glassmount
        ),
        material: getMergedItemForItemReference<MergedCatalogueMaterial>(
            material,
            catalogue.materials,
            ConfigOptionEnum.Material
        ),
        beam: getMergedBeamForBeamReference(beam, catalogue.beams),
        measurement: getMergedItemForItemReference<MergedCatalogueMeasurement>(
            measurement,
            catalogue.measurements,
            ConfigOptionEnum.Measurement
        )
    };

    if (knob) {
        configuration[ConfigOptionEnum.Knob] = getMergedItemForItemReference<MergedCatalogueKnob>(
            knob,
            catalogue.knobs,
            ConfigOptionEnum.Knob
        );
    }
    if (door) {
        configuration[ConfigOptionEnum.Door] = getMergedItemForItemReference<MergedCatalogueDoor>(
            door,
            catalogue.doors,
            ConfigOptionEnum.Door
        );
    }
    if (hinge) {
        configuration[ConfigOptionEnum.Hinge] = getMergedItemForItemReference<MergedCatalogueHinge>(
            hinge,
            catalogue.hinges,
            ConfigOptionEnum.Hinge
        );
    }

    return configuration;
};

export const getDefaultConfiguration = (catalogue: Catalogue, seriesId?: string): Configuration => {
    const series = catalogue.series.find((s) => (seriesId ? s.id === seriesId : s.default));
    const model = series?.models.find((m) => m.default);
    const base = series?.bases.find((b) => b.default);
    const knob = model?.knobs?.find((k) => k.default);
    const door = model?.doors?.find((d) => d.default);
    const glass = series?.glasses.find((g) => g.default);
    const wallmount = series?.wallmounts.find((w) => w.default);
    const glassmount = series?.glassmounts.find((g) => g.default);
    const measurement = model?.measurements.length && model?.measurements[0];
    const material = model?.materials.find((m) => m.default);
    const hinge = series?.hinges?.find((h) => h.default);
    const beam = model?.beams.find((b) => b.default);

    if (series && model && base && glass && wallmount && glassmount && measurement && material && beam) {
        return getMergedConfiguration(
            catalogue,
            series,
            model,
            base,
            glass,
            wallmount,
            glassmount,
            measurement,
            material,
            beam,
            knob,
            door,
            hinge
        );
    } else {
        console.error("Catalogue didn't define a proper default configuration");
        throw new Error();
    }
};

export const getConfiguration = (
    catalogue: Catalogue,
    seriesId: string,
    modelId: string,
    baseId: string,
    glassId: string,
    wallmountId: string,
    glassmountId: string,
    measurementId: string,
    materialId: string,
    beamId: string,
    knobId?: string,
    doorId?: string,
    hingeId?: string
): Configuration => {
    const series = catalogue.series.find((s) => s.id === seriesId);
    const model = series?.models.find((m) => m.id === modelId) ?? series?.models.find((m) => m.default);
    const base = series?.bases.find((b) => b.id === baseId) ?? series?.bases.find((b) => b.default);
    const knob = model?.knobs?.find((k) => k.id === knobId) ?? model?.knobs?.find((k) => k.default);
    const door = model?.doors?.find((d) => d.id === doorId) ?? model?.doors?.find((d) => d.default);
    const glass = series?.glasses.find((g) => g.id === glassId) ?? series?.glasses.find((g) => g.default);
    const wallmount = series?.wallmounts.find((w) => w.id === wallmountId) ?? series?.wallmounts.find((w) => w.default);
    const glassmount =
        series?.glassmounts.find((g) => g.id === glassmountId) ?? series?.glassmounts.find((g) => g.default);
    const measurement =
        model?.measurements.find((m) => m.id === measurementId) ??
        (model?.measurements.length && model?.measurements[0]);
    const material = model?.materials.find((m) => m.id === materialId) ?? model?.materials.find((m) => m.default);
    const hinge = series?.hinges?.find((h) => h.id === hingeId) ?? series?.hinges?.find((h) => h.default);
    const beam = model?.beams.find((b) => b.id === beamId) ?? model?.beams.find((b) => b.default);

    if (series && model && base && glass && wallmount && glassmount && measurement && material && beam) {
        return getMergedConfiguration(
            catalogue,
            series,
            model,
            base,
            glass,
            wallmount,
            glassmount,
            measurement,
            material,
            beam,
            knob,
            door,
            hinge
        );
    } else {
        console.error("Catalogue didn't define a proper default configuration");
        throw new Error();
    }
};

export const calculateNumberOfDoors = (layout: BlueprintElement[]): number => {
    return layout.filter((element) => element.type === BlueprintLayoutType.Door).length;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const keys = <O extends object>(obj: O): Array<keyof O> => {
    return Object.keys(obj) as Array<keyof O>;
};

export const createNewParams = (params: URLSearchParams, queries: { key: string; value: string }[]): string => {
    queries.forEach((k: { key: string; value: string }) => {
        if (params.has(k.key)) {
            params.set(k.key, k.value);
        } else {
            params.append(k.key, k.value);
        }
    });
    params.sort();
    return `?${params.toString()}`;
};

export const getFromParams = (params: URLSearchParams): { [key: string]: string } => {
    const ret: { [key: string]: string } = {};
    params.forEach((v, k) => (ret[k] = v));
    return ret;
};

export const getAllModelsForSeries = (
    catalogue: Catalogue,
    idSeries: string
): CatalogueModelComposition[] | undefined => {
    const series = catalogue.series.find((s) => s.id === idSeries);

    return series?.models;
};

export const getModelForSeries = (
    catalogue: Catalogue,
    seriesId: string,
    modelId: string
): CatalogueModelComposition | undefined => {
    const series = catalogue.series.find((s) => s.id === seriesId);
    const model = series?.models.find((m) => m.id === modelId);

    return model;
};

export const getDefaultBeamIdForModel = (model: CatalogueModelComposition): string | undefined => {
    const beam = model.beams.find((b: { id: string; default?: boolean }) => b.default);
    if (!beam) {
        console.warn(`Could not find default beam for model ${model.id}`);
    }

    return beam?.id;
};

export const getDefaultWallmountId = (
    model: CatalogueModelComposition,
    series: CatalogueSeries
): string | undefined => {
    const wallmountModel = model.wallmounts?.find((wm: { id: string; default?: boolean }) => wm.default);
    const wallmountSeries = series.wallmounts.find((wm: { id: string; default?: boolean }) => wm.default);

    if (!wallmountModel && !wallmountSeries) {
        console.warn(`Could not find default wallmount for model ${model.id}`);
    }

    return wallmountModel ? wallmountModel.id : wallmountSeries?.id;
};

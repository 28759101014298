import { Vector3 } from '@babylonjs/core';
import React, { useEffect } from 'react';
import useZoomAt from '../../../../hooks/useZoomAt';
import { ConfigOptionEnum } from '../../../../state/ducks/system/interfaces';
import { TAGLIST } from '../../../helper/tagHelper';
import { DETAIL_LAYERMASK, TOTAL_LAYERMASK } from '../../../helper/zoomAtHelper';
import { RotationCorner } from '../../../helper/commonHelper';
import ThreeDComponent from '../BaseComponents/ThreeDComponent';

type MountComponentProps = {
    sceneFilename: string;
    position: Vector3;
    scaling: Vector3;
    rotation: Vector3;
    detailViewScaleY: number;
    corner: RotationCorner;
    usesWholeSide: boolean | undefined;
};

const MountComponent = ({
    sceneFilename,
    position,
    scaling,
    rotation,
    detailViewScaleY,
    corner,
    usesWholeSide
}: MountComponentProps): JSX.Element => {
    // TODO What happens when the mount is teilgerahmt
    let tfName = '';
    if (usesWholeSide) {
        tfName = `mount_tf_wholeSide_rot=${corner.toString()}`;
    } else {
        const positionAsString = position.y > 95 ? 'top' : 'bottom';
        tfName = `mount_tf_pos=${positionAsString}_rot=${corner.toString()}`;
    }
    const [zoomAt, setZoomAt] = useZoomAt();
    const isZoomedAt = zoomAt === tfName;
    const configOption = sceneFilename.startsWith('gg') ? ConfigOptionEnum.Glassmount : ConfigOptionEnum.Wallmount;

    useEffect(() => {
        const regex = new RegExp('rot=.*');
        const cornerZoomAt = regex.exec(zoomAt || '');
        if (
            ((zoomAt?.startsWith('mount_tf_wholeSide') && !tfName.startsWith('mount_tf_wholeSide')) ||
                (zoomAt?.startsWith('mount_tf_pos') && !tfName.startsWith('mount_tf_pos'))) &&
            cornerZoomAt &&
            cornerZoomAt.length > 0 &&
            tfName.includes(cornerZoomAt[0])
        ) {
            setZoomAt(tfName, configOption, sceneFilename);
        }
    }, []);

    return (
        <transformNode
            name={tfName}
            position={position}
            scaling={isZoomedAt ? new Vector3(scaling.x, detailViewScaleY, scaling.z) : scaling}
            rotation={rotation}
        >
            <ThreeDComponent
                name={`mount_threeD_y=${position.y}`}
                rootUrl="assets/3d/mounts/"
                sceneFilename={sceneFilename}
                tagList={[TAGLIST.REFLECTION]}
                onClick={isZoomedAt ? undefined : () => setZoomAt(tfName, configOption, sceneFilename)}
                layerMask={isZoomedAt ? DETAIL_LAYERMASK : TOTAL_LAYERMASK}
            />
        </transformNode>
    );
};

export default MountComponent;

import React from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../state/ducks';
import { configuratorActions } from '../../state/ducks/configurator';
import { systemActions } from '../../state/ducks/system';
import ButtonComponent, { ButtonType } from '../button/ButtonComponent';
import ModalContent from '../modal/modal-content/ModalContent.component';
import ModalFooter from '../modal/modal-footer/ModalFooter.component';
import ModalHeader from '../modal/modal-header/ModalHeader.component';
import './ResetConfigModal.component.scss';
import 'firebase/analytics';
import { logAnalyticsEvent } from '../../ng/helper/analyticsHelper';

const ResetConfigModal = (): JSX.Element => {
    const modalOpen = useSelector((state: StoreState) => {
        return state.system.modalResetOpen;
    });

    const dispatch = useDispatch();

    const reset = () => {
        dispatch(configuratorActions.resetToDefaultConfig());
    };
    const close = () => {
        dispatch(systemActions.closeModalReset());
    };

    const resetConfiguration = (): void => {
        logAnalyticsEvent('configurator_reset_config_using_modal');
        reset();
        close();
    };

    const abortResetConfiguration = (): void => {
        logAnalyticsEvent('configurator_abort_reset_modal');
        close();
    };

    const modalStyleReset = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
        },
        content: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            maxHeight: '281px',
            height: '281px',
            maxWidth: '680px',
            margin: 'auto',
            overflow: 'visible'
        }
    };

    return (
        <Modal isOpen={modalOpen} contentLabel="Konfiguration zurücksetzen" style={modalStyleReset}>
            <div className="reset-config-screen">
                <ModalHeader></ModalHeader>
                <ModalContent>
                    <div className="reset-config-screen-header">Konfiguration zurücksetzen?</div>
                    <div className="reset-config-screen-description">
                        Sind Sie sicher?<br></br>
                        <br></br> Ihre bisherige Auswahl wird gelöscht und der Konfigurator wird wieder die
                        Grundeinstellungen anzeigen.
                    </div>
                </ModalContent>
                <ModalFooter>
                    <ButtonComponent onClick={resetConfiguration} type={ButtonType.dominant} width={306}>
                        Zurücksetzen
                    </ButtonComponent>
                    <ButtonComponent onClick={abortResetConfiguration} type={ButtonType.light} width={306}>
                        Abbrechen
                    </ButtonComponent>
                </ModalFooter>
            </div>
        </Modal>
    );
};

export default ResetConfigModal;

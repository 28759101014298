import fb from 'firebase/app';
import React, { MutableRefObject, useCallback, useContext, useEffect, useRef, useState } from 'react';
import FirebaseContext from '../../firebase/context';
import { DropzoneIcon } from './DropzoneIcon.component';
import { DropzoneLoading } from './DropzoneLoading.component';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
export const DropzonePreview = (props: any): JSX.Element => {
    const [state, setState] = useState({
        progress: 0,
        bytesTransferred: 0,
        totalBytes: 0
    });

    const firebase = useContext(FirebaseContext);
    const file = props.fileWithMeta;
    const uploadTask: MutableRefObject<fb.storage.UploadTask | null> = useRef(null);

    useEffect(() => {
        uploadTask.current = firebase.uploadFile(file.file, firebase.attachmentId);

        // Listen for state changes, errors, and completion of the upload.
        if (uploadTask && uploadTask.current) {
            uploadTask.current.on(
                fb.storage.TaskEvent.STATE_CHANGED,
                (snapshot: fb.storage.UploadTaskSnapshot) => {
                    // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
                    const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                    const newState = {
                        progress: Math.floor(progress),
                        bytesTransferred: Math.round(snapshot.bytesTransferred / 100) / 10,
                        totalBytes: Math.round(snapshot.totalBytes / 100) / 10
                    };
                    setState(newState);
                },
                (error: Error) => {
                    console.log(error);
                },
                () => {
                    // Upload completed successfully
                    firebase.addAttachedFile(file.file.name);
                }
            );
        }
    }, [file, firebase]);

    const onDelete = useCallback((): void => {
        uploadTask.current?.cancel();
        uploadTask.current?.snapshot.ref.delete();
        file.remove();
        firebase.removeAttachedFile(file.file.name);
    }, [file, firebase]);

    return (
        <div className="sp-preview">
            <div hidden={state.progress !== 100} className="sp-preview-icon">
                <DropzoneIcon icon={props.meta.type}></DropzoneIcon>
            </div>
            <div className="sp-preview-content">
                <div hidden={state.progress !== 100} className="sp-preview-content-name">
                    {props.meta.name}
                </div>

                <div hidden={state.progress === 100} className="sp-preview-content-loading">
                    <DropzoneLoading actual={state.progress}></DropzoneLoading>
                    {state.progress}%
                </div>
            </div>

            <div className="sp-preview-btns">
                <DropzoneIcon onClick={onDelete} icon="trash"></DropzoneIcon>
            </div>
        </div>
    );
};

import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../state/ducks';
import { systemActions } from '../../../state/ducks/system';
import { ConfigOptionEnum } from '../../../state/ducks/system/interfaces';
import './MountInfoPage.scss';

const MountInfoPage = (): JSX.Element => {
    const dispatch = useDispatch();
    const seriesMountInfo = useSelector((state: StoreState) => state.configurator.configuration.series.seriesMountInfo);
    const onConfigClick = () => {
        dispatch(systemActions.setZoomAt(null));
        dispatch(systemActions.openMenuWith(ConfigOptionEnum.Series));
    };

    return (
        <div className="mount-info-page">
            <div className="mount-info-page-info-text">{seriesMountInfo}</div>
            <a onClick={onConfigClick} className="configure-series-link">
                Anderes Modell konfigurieren
                <svg className="configure-series-link-caret"></svg>
            </a>
        </div>
    );
};

export default MountInfoPage;

// Loading Catalogue

export const LOAD_CATALOGUE = 'LOAD_CATALOGUE';
export type LOAD_CATALOGUE = typeof LOAD_CATALOGUE;

export const CATALOGUE_LOADED_SUCCESS = 'CATALOGUE_LOADED_SUCCESS';
export type CATALOGUE_LOADED_SUCCESS = typeof CATALOGUE_LOADED_SUCCESS;

export const CATALOGUE_LOADED_ERROR = 'CATALOGUE_LOADED_ERROR';
export type CATALOGUE_LOADED_ERROR = typeof CATALOGUE_LOADED_ERROR;

export const CONFIGURATION_DEFAULT_LOADED_SUCCESS = 'CONFIGURATION_DEFAULT_LOADED_SUCCESS';
export type CONFIGURATION_DEFAULT_LOADED_SUCCESS = typeof CONFIGURATION_DEFAULT_LOADED_SUCCESS;

export const CONFIGURATION_DEFAULT_LOADED_ERROR = 'CONFIGURATION_DEFAULT_LOADED_ERROR';
export type CONFIGURATION_DEFAULT_LOADED_ERROR = typeof CONFIGURATION_DEFAULT_LOADED_ERROR;

export const SELECT_CONFIG_OPTION = 'SELECT_CONFIG_OPTION';
export type SELECT_CONFIG_OPTION = typeof SELECT_CONFIG_OPTION;

export const UPDATE_BEAM_PRICE = 'UPDATE_BEAM_PRICE';
export type UPDATE_BEAM_PRICE = typeof UPDATE_BEAM_PRICE;

export const SELECT_AMBIENT_OPTION = 'SELECT_AMBIENT_OPTION';
export type SELECT_AMBIENT_OPTION = typeof SELECT_AMBIENT_OPTION;

// Configuration
export const UPDATE_CONFIG = 'UPDATE_CONFIG';
export type UPDATE_CONFIG = typeof UPDATE_CONFIG;

export const RESET_TO_DEFAULT_CONFIG = 'RESET_TO_DEFAULT_CONFIG';
export type RESET_TO_DEFAULT_CONFIG = typeof RESET_TO_DEFAULT_CONFIG;

// Price
export const UPDATE_SHOWER_SIZE_PRICE = 'UPDATE_SHOWER_SIZE_PRICE';
export type UPDATE_SHOWER_SIZE_PRICE = typeof UPDATE_SHOWER_SIZE_PRICE;

export const UPDATE_PRICE = 'UPDATE_PRICE';
export type UPDATE_PRICE = typeof UPDATE_PRICE;

export const UPDATE_PRICE_ERROR = 'UPDATE_PRICE_ERROR';
export type UPDATE_PRICE_ERROR = typeof UPDATE_PRICE_ERROR;

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/analytics';
import { from, Observable } from 'rxjs';
import { v1 as uuidv1 } from 'uuid';
import config from '../../firebase_app.json';
import { configuratorActions } from '../../state/ducks/configurator';
import { Offer } from '../../state/ducks/configurator/interfaces';
import { systemActions } from '../../state/ducks/system';
import { UserConfig } from '../../state/ducks/system/reducers';
import store from '../../state/store';
import { ContactFormData } from '../send-offer/SendOfferForm.component';
import FirebaseContext from './context';
import {
    Cookies,
    getAnalyticsIDFromParams as getAnalyticsUserIDFromParams,
    getCookieAcceptanceFromLocalStorage,
    getCookieAcceptanceFromUrlParams
} from './Firebase.utils';
import { initAnalytics, setTrackingDisabled, setTrackingUserID } from '../../ng/helper/analyticsHelper';

export type FirebaseConfiguration = {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    additionalTrackingIds?: string[];
};

class Firebase {
    public firestore: firebase.firestore.Firestore;
    public storage: firebase.storage.Storage;
    public attachmentId: string;
    public attachedFiles: string[];

    constructor() {
        firebase.initializeApp(config);
        this.firestore = firebase.firestore();
        this.storage = firebase.storage();
        // TODO: Refactor (e.g. Button)
        firebase.auth().signOut();

        initAnalytics();
        this.startAnalyticsAccordingToPrivacySettings();

        // TODO: Refactor with Router
        if (window.location.pathname.startsWith('/retailer') || window.location.pathname.startsWith('/app/retailer')) {
            firebase.auth().onAuthStateChanged((user) => {
                if (user && user.email) {
                    store.dispatch(configuratorActions.loadCatalogue(user.email ?? undefined));
                    store.dispatch(systemActions.setLoggedInUser(user.email));

                    this.getUserConfigFrom(user.email).then((querySnapshot) => {
                        const config = querySnapshot.docs[0]?.data().config;
                        const userConfig = config as UserConfig;
                        if (userConfig) {
                            store.dispatch(systemActions.setUserConfig(userConfig));
                            userConfig.primaryColor &&
                                document.documentElement.style.setProperty('--primary-color', userConfig.primaryColor);
                            userConfig.primaryHoverColor &&
                                document.documentElement.style.setProperty(
                                    '--primary-color-hover',
                                    userConfig.primaryHoverColor
                                );
                        }
                    });
                } else {
                    /* Show Login */
                    store.dispatch(systemActions.setLoggedInUser(null));
                }
            });
        } else {
            firebase.auth().signInAnonymously();
            store.dispatch(configuratorActions.loadCatalogue());
        }

        if (window.location.hostname === 'localhost') {
            this.firestore.settings({
                host: 'localhost:8080', // Default firestore port, change if necessary
                ssl: false
            });
        }

        this.attachmentId = Date.now() + '_';
        this.attachedFiles = [];
    }

    startAnalyticsAccordingToPrivacySettings(): void {
        let privacyStatus = getCookieAcceptanceFromUrlParams();
        privacyStatus = getCookieAcceptanceFromLocalStorage();

        if (privacyStatus === Cookies.OnlyEssentials) {
            store.dispatch(systemActions.closeModalPrivacy());
            window.localStorage.setItem('ga', Cookies.OnlyEssentials);
        }

        if (privacyStatus === Cookies.Accepted) {
            const referredGoogleAnalyticsID = getAnalyticsUserIDFromParams();

            store.dispatch(systemActions.closeModalPrivacy());
            setTrackingDisabled(false);
            referredGoogleAnalyticsID && setTrackingUserID(referredGoogleAnalyticsID);
            window.localStorage.setItem('ga', Cookies.Accepted);
        }
    }

    reset(): void {
        this.attachmentId = Date.now() + '_';
        this.attachedFiles = [];
    }

    saveContactToDB(
        contactData: ContactFormData,
        config: Offer,
        configUrl: string,
        fileNames: string[]
    ): Observable<unknown> {
        const uuid = uuidv1();
        const data = {
            offer: { url: configUrl },
            contact: contactData,
            config,
            fileNames
        };
        return from(this.firestore.collection('offers').doc(`offer_${Date.now()}_${uuid}`).set(data));
    }

    saveImageToDB(id: string, jpeg: Uint8Array): Observable<unknown> {
        return from(
            this.storage
                .ref()
                .child(`images/${id}.jpeg`)
                .getDownloadURL()
                .catch(() =>
                    this.storage.ref().child(`images/${id}.jpeg`).put(jpeg, {
                        contentType: 'image/jpeg'
                    })
                )
        );
    }

    uploadFile(file: File, id: string): firebase.storage.UploadTask {
        return this.storage
            .ref()
            .child(`upload/${id + file.name}`)
            .put(file);
    }

    addAttachedFile(filename: string): void {
        this.attachedFiles.push(`${this.attachmentId}${filename}`);
    }

    removeAttachedFile(filename: string): void {
        const index = this.attachedFiles.indexOf(`${this.attachmentId}${filename}`);

        if (index !== -1) {
            this.attachedFiles.splice(index, 1);
        }
    }

    getUserConfigFrom(email: string): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
        return firebase.firestore().collection('retailers').where('email', '==', email).limit(1).get();
    }
}
export default new Firebase();

export { FirebaseContext };
